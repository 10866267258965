import { MultiFactorResolver, getMultiFactorResolver } from 'firebase/auth';
import { paths } from 'paths';
import { showAuthError } from 'shared/show-auth-error';
import { Nullable } from 'types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Loader, Stack, Text } from '@mantine/core';
import { auth } from 'lib/firebase';
import { AuthLayout } from './auth-layout';
import { MfaSignInModal } from './mfa-sign-in-modal';
import { startMfaSignin } from './start-mfa-sign-in';
import { MULTIFACTOR_ERROR_CODE, useAuth } from './use-auth';

export const EmailSignInComplete = ({ onError }: { onError?: (error: Error) => void }) => {
    const [multiFactorResolver, setMultiFactorResolver] = useState<MultiFactorResolver | null>(null);
    const [verificationId, setVerificationId] = useState<Nullable<string>>(null);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { signInWithEmailLink } = useAuth();

    const params = new URLSearchParams(window.location.search);
    const hasCode = params.get('apiKey') && params.get('oobCode');

    const handleError = useCallback(
        (error: Error) => {
            showAuthError(error);
            if (onError) {
                onError(error);
            } else {
                navigate(paths.email());
            }
        },
        [onError, navigate]
    );

    useEffect(() => {
        if (!hasCode) {
            if (onError) {
                onError(new Error('Missing params to complete sign in'));
            } else {
                navigate(paths.email());
            }
        }

        const handleSignIn = async () => {
            try {
                await signInWithEmailLink();
            } catch (error: any) {
                if (error.code === MULTIFACTOR_ERROR_CODE) {
                    const resolver = getMultiFactorResolver(auth, error);
                    const verificationId = await startMfaSignin(resolver.hints[0], resolver.session);
                    if (resolver && verificationId) {
                        setMultiFactorResolver(resolver);
                        setVerificationId(verificationId);
                        return;
                    }
                }
                handleError(error);
            }
        };

        handleSignIn();
    }, [hasCode, navigate, onError, signInWithEmailLink, handleError]);

    const showMfaModal = !!multiFactorResolver && !!verificationId;

    return (
        <>
            <AuthLayout>
                <Text fw={500} maw={300} size="sm" mb="lg" ta="center" mx="auto">
                    {t('emailSignInVerifying')}
                </Text>
                <Stack align="center" mx="auto">
                    <Loader color="blue" size="xl" />
                </Stack>
            </AuthLayout>
            <MfaSignInModal
                isOpen={showMfaModal}
                verificationId={verificationId}
                multiFactorResolver={multiFactorResolver}
                onClose={() => {
                    setMultiFactorResolver(null);
                    setVerificationId(null);
                }}
            />
        </>
    );
};
