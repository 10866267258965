import { useTranslation } from 'react-i18next';
import { Alert } from '@mantine/core';
import { getErrorMessage } from 'utils/get-error-message';

export const ErrorAlert = ({ error }: { error: Error }) => {
    const { t } = useTranslation();
    return (
        <Alert my="lg" title={t('failedToLoadData')} color="red" variant="light">
            {getErrorMessage(error)}
        </Alert>
    );
};
