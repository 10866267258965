import { DataRow } from './create-empty-row';

const DEFAULT_COUNT = 1;

export type Counts = {
    materialCount: number;
    ecolabelCount: number;
    transportCount: number;
    energySourceCount: number;
    primaryPackagingMaterialsCount: number;
    secondaryPackagingMaterialsCount: number;
    tertiaryPackagingMaterialsCount: number;
};

export const defaultCounts = {
    energySourceCount: DEFAULT_COUNT,
    materialCount: DEFAULT_COUNT,
    ecolabelCount: DEFAULT_COUNT,
    transportCount: DEFAULT_COUNT,
    primaryPackagingMaterialsCount: DEFAULT_COUNT,
    secondaryPackagingMaterialsCount: DEFAULT_COUNT,
    tertiaryPackagingMaterialsCount: DEFAULT_COUNT
};

export const getSessionState = (
    key: string,
    initialRows: DataRow[] = []
): {
    rows: DataRow[];
} & Counts => {
    try {
        const storageStr = window.localStorage.getItem(key);
        if (!storageStr)
            return {
                ...defaultCounts,
                rows: initialRows
            };
        return JSON.parse(storageStr);
    } catch {
        return {
            ...defaultCounts,
            rows: initialRows
        };
    }
};

export const persistSessionState = (key: string, rows: DataRow[], counts: Counts) => {
    try {
        window.localStorage.setItem(
            key,
            JSON.stringify({
                rows,
                ...counts
            })
        );
    } catch {}
};
