import { MantineThemeOverride, rem, MantineThemeColors } from '@mantine/core';

export const primaryColor: MantineThemeColors['blue'] = [
    '#e9f2ff',
    '#d2e1fc',
    '#a3c0f7',
    '#719df3',
    '#487fef',
    '#306cee',
    '#2263ee',
    '#1653d5',
    '#0b4abf',
    '#003fa8'
];

const secondaryColor: MantineThemeColors['yellow'] = [
    '#fff9e3',
    '#fff2cd',
    '#ffe39c',
    '#ffd466',
    '#fec73a',
    '#febf1f',
    '#ffba0f',
    '#e3a400',
    '#ca9100',
    '#af7c00'
];

export const theme: MantineThemeOverride = {
    primaryShade: {
        light: 8,
        dark: 6
    },
    defaultRadius: 'md',
    respectReducedMotion: true,
    cursorType: 'pointer',
    white: '#fff',
    black: '#00091f',
    primaryColor: 'primary',

    fontFamily:
        "'Inter Variable', -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Cantarell, Ubuntu, roboto, noto, arial, sans-serif",
    fontFamilyMonospace: 'ui-monospace, SFMono-Regular, SF Mono,Consolas, Liberation Mono, Menlo,monospace',
    colors: {
        primary: primaryColor,
        secondary: secondaryColor,
        blue: primaryColor,
        yellow: secondaryColor,
        dark: [
            // NOTE: This can be removed after the Mantine v7 upgrade
            '#c9c9c9',
            '#b8b8b8',
            '#828282',
            '#696969',
            '#424242',
            '#3b3b3b',
            '#2e2e2e',
            '#242424',
            '#1f1f1f',
            '#141414'
        ]
    },
    headings: {
        fontWeight: '700'
    },
    spacing: {
        xxs: rem(4),
        xs: rem(8),
        sm: rem(12),
        md: rem(16),
        lg: rem(20),
        xl: rem(24),
        xxl: rem(32)
    },
    fontSizes: {
        display: rem(40),
        detail: rem(12)
    },
    autoContrast: true,
    components: {
        Select: {
            defaultProps: {
                checkIconPosition: 'right'
            }
        },
        MultiSelect: {
            defaultProps: {
                checkIconPosition: 'right'
            }
        },
        Paper: {
            defaultProps: {
                withBorder: true,
                shadow: 'md'
            }
        },
        Table: {
            defaultProps: {
                verticalSpacing: 'sm',
                horizontalSpacing: 'sm'
            }
        },
        Modal: {
            styles: {
                title: {
                    fontWeight: 700,
                    fontSize: rem(20)
                }
            },
            defaultProps: {
                centered: true,
                closeButtonProps: {
                    'data-testid': 'close-modal'
                }
            }
        },
        Badge: {
            defaultProps: {
                variant: 'light',
                size: 'md'
            },
            styles: {
                root: {
                    padding: rem(8)
                }
            }
        },
        ActionIcon: {
            defaultProps: {
                variant: 'transparent',
                c: 'grey'
            }
        }
    }
};
