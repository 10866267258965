import { Badge, Group, Tooltip, rem } from '@mantine/core';
import { truncate } from 'utils/truncate';

const MAX_LENGTH = 20;

export const TagList = ({ data }: { data: (string | null | undefined)[] }) => {
    return (
        <Group gap={rem(4)}>
            {data.map((current) => {
                if (!current) return null;
                if (current.length > MAX_LENGTH) {
                    return (
                        <Tooltip key={current} label={current}>
                            <Badge key={current} size="sm">
                                {truncate(current, MAX_LENGTH)}
                            </Badge>
                        </Tooltip>
                    );
                }
                return (
                    <Badge key={current} size="sm">
                        {current}
                    </Badge>
                );
            })}
        </Group>
    );
};
