import useSWR from 'swr';
import { Filter, QueryReturnResult } from 'types';
import { Dimension } from 'types/shared';
import { useMemo } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { DEFAULT_MAX_RESULTS, RunQueryConfig, runQuery } from 'utils/run-query';
import { truncate } from 'utils/truncate';

export type Result = {
    product_name?: string;
    product_id?: string;
    brand?: string;
    division?: string;
    category_id?: string;
    category?: string;
    parent_category?: string;
    score: number;
    total_products?: number;
    dimension: Dimension;
};

export type ScoreComparisonVariant = 'category' | 'brand' | 'division' | 'product';

const variantToQueryName = {
    brand: 'insight_brand_score_comparison',
    category: 'insight_category_score_comparison',
    division: 'insight_division_score_comparison',
    product: 'insight_product_score_comparison'
};
export const useEnterpriseScoreComparison = (
    variant: ScoreComparisonVariant,
    dimension: Dimension,
    filters: Filter[] = [],
    desc = true
) => {
    const query: RunQueryConfig = {
        name: variantToQueryName[variant],
        pageSize: DEFAULT_MAX_RESULTS,
        params: [
            ...filters,
            {
                name: 'dimension',
                operation: '=',
                value: dimension as string
            }
        ],
        orderByParams: [
            {
                name: 'score',
                desc
            }
        ]
    };

    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');
    const { data, isLoading, error } = useSWR<QueryReturnResult<Result>>([query.name, query], ([_, query]) =>
        runQuery(query as RunQueryConfig)
    );

    const formatResult = (current: Result) => {
        const truncateName = (name: string) => truncate(name, isSmallDevice ? 30 : 50);

        switch (variant) {
            case 'brand':
                if (current.brand) {
                    return {
                        id: current.brand,
                        value: current.score,
                        name: `${truncateName(current.brand)}('TTT'${current.total_products})`,
                        metric: dimension
                    };
                }
                break;
            case 'division':
                if (current.division) {
                    return {
                        id: current.division,
                        value: current.score,
                        name: `${truncateName(current.division)} ('TTT'${current.total_products})`,
                        metric: dimension
                    };
                }
                break;
            case 'category':
                if (current.category && current.category_id) {
                    return {
                        id: current.category_id,
                        name: truncate(
                            isSmallDevice
                                ? current.category
                                : `${current.parent_category} ${current.category} (Total Products:${current.total_products})`.trim(),
                            isSmallDevice ? 30 : 50
                        ),
                        value: current.score,
                        metric: dimension
                    };
                }
                break;
            case 'product':
                if (current.product_id && current.product_name) {
                    return {
                        id: current.product_id,
                        name: truncateName(current.product_name),
                        value: current.score,
                        metric: dimension
                    };
                }
                break;
        }

        return null;
    };

    const formatted = useMemo(() => {
        if (!data) return [];
        return data.results
            .map(formatResult)
            .filter(Boolean) as Array<{
            name: string;
            id: string;
            value: number;
            metric: string;
        }>;
    }, [data, variant, isSmallDevice]);

    return {
        isLoading,
        error,
        data,
        formatted
    };
};
