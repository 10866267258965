import { paths } from 'paths';
import { ScoreComparisonVariant } from './use-enterprise-score-comparison';

export const getComparisonChartNavigationPath = (
    variant: ScoreComparisonVariant,
    organizationId?: string,
    entry?: { id: string; name: string; value: number }
) => {
    if (!entry || !organizationId) return paths.products(organizationId);

    if (variant === 'brand') {
        return paths.products(organizationId, { brand: entry.id });
    }

    if (variant === 'category') {
        return paths.products(organizationId, { category: entry.id });
    }

    if (variant === 'product') {
        return paths.scoreReport(organizationId, entry.id, { name: entry.name });
    }

    return paths.products(organizationId);
};
