import { DownloadButton } from 'shared/download-button';
import { ErrorCard } from 'shared/error-card';
import ScoreCircleGraph from 'shared/score-circle-graph';
import { Section } from 'shared/section';
import { StaticTable } from 'shared/static-table';
import { useOrganizationId } from 'shared/use-organization-id';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbRefresh } from 'react-icons/tb';
import { Link, useParams } from 'react-router-dom';
import { ActionIcon, Anchor, Breadcrumbs, Button, Group, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ProductBadges } from './product-badges';
import { ProductModal } from './product-modal';
import { scoringResultsToProductHistory } from './scoring-results-to-product-history-rows';
import { useProductScoringResults } from './use-product-scoring-results';
import { useScoringResults } from './use-scoring-results';

const SCORE_CELL_WIDTH = 160;

const renderScoreCell = (value: number) => <ScoreCircleGraph percentage={Math.round(value ?? 0)} size="xs" />;

const SCORE_COLUMNS = [
    {
        key: 'total',
        translationKey: 'summary'
    },
    {
        key: 'circularity',
        translationKey: 'circularity'
    },
    {
        key: 'ecosystem',
        translationKey: 'ecosystemImpact'
    },
    {
        key: 'climate',
        translationKey: 'climateImpact'
    },
    {
        key: 'livelihood_and_wellbeing',
        translationKey: 'livelihoodsAndWellbeing'
    },
    {
        key: 'purpose',
        translationKey: 'purpose'
    }
];

export const ProductHistory = () => {
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');
    const [showProductModal, setShowProductModal] = useState(false);
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { data: matchingProducts } = useProductScoringResults(id);
    const { data: scoringResults, error, isLoading, mutate, isValidating } = useScoringResults(id);
    const organizationId = useOrganizationId();

    const product = matchingProducts?.results?.[0];

    const data = useMemo(() => scoringResultsToProductHistory(scoringResults), [scoringResults]);

    if (error) {
        return <ErrorCard error={error} />;
    }

    return (
        <>
            <Group align="center" gap="xs" justify="space-between" mb="md">
                {isSmallDevice ? (
                    <Title size="h3">
                        {t('scoringHistory')}: {product?.name ?? '-'}
                    </Title>
                ) : (
                    <Breadcrumbs>
                        <Anchor component={Link} to={`/organization/${organizationId}/products`}>
                            {t('products')}
                        </Anchor>
                        <Text>{product?.name ?? '-'}</Text>
                        <Text>{t('scoringHistory')}</Text>
                    </Breadcrumbs>
                )}

                <Button size="xs" variant="outline" onClick={() => setShowProductModal(true)}>
                    {t('viewProduct')}
                </Button>
            </Group>

            <ProductBadges product={product} />

            <Section>
                <StaticTable
                    rowKey={(row) => row.scored_at}
                    actions={
                        <Group gap="xs">
                            <ActionIcon data-testid="refresh" variant="default" size="lg" loading={isValidating} onClick={() => mutate()}>
                                <TbRefresh />
                            </ActionIcon>
                            <DownloadButton
                                disabled={!scoringResults?.results}
                                exports={[
                                    {
                                        name: 'product_scoring_history',
                                        data: scoringResults?.results
                                    }
                                ]}
                            />
                        </Group>
                    }
                    isLoading={isLoading}
                    data={data}
                    columns={[
                        {
                            key: 'scored_at',
                            name: t('scoredOn'),
                            sortable: true,
                            render: (value) => new Date(value).toLocaleString()
                        },
                        ...SCORE_COLUMNS.map((current) => ({
                            key: current.key,
                            name: t(current.translationKey),
                            sortable: true,
                            render: renderScoreCell,
                            width: SCORE_CELL_WIDTH
                        }))
                    ]}
                />
            </Section>

            <ProductModal isOpen={showProductModal} onClose={() => setShowProductModal(false)} product={product} />
        </>
    );
};
