import { ResponsiveContainer, BarChart, Bar, YAxis, Cell, XAxis, CartesianGrid, LabelList } from 'recharts';
import { useMantineColorScheme } from '@mantine/core';
import { useScoreColor } from 'utils/use-score-color';

interface BarItem {
    value: number;
    name: string;
}

export const ScoreReportBarChart = ({ data: initialData }: { data: (BarItem | undefined | false)[] }) => {
    const { colorScheme } = useMantineColorScheme();
    const data = initialData.filter((current) => !!current) as BarItem[];
    const getScoreColor = useScoreColor();

    const labelColor = colorScheme === 'dark' ? '#fff' : '#757575';

    return (
        <ResponsiveContainer height={190} width="100%">
            <BarChart
                data={data}
                layout="vertical"
                maxBarSize={16}
                margin={{
                    right: 24,
                    left: 0,
                    bottom: 20
                }}
            >
                <CartesianGrid horizontal={false} stroke="#e7e7e7" />
                <YAxis axisLine={false} dataKey="name" fill={labelColor} fontSize={12} tickLine={false} type="category" width={120} />
                <XAxis
                    axisLine={false}
                    dataKey="value"
                    domain={[0, 100]}
                    fontSize={12}
                    stroke={labelColor}
                    tickLine={false}
                    type="number"
                />
                <Bar dataKey="value" fill="#8884d8" radius={[4, 4, 4, 4]}>
                    {data.map((entry) => (
                        <Cell key={entry.name} fill={getScoreColor(entry.value)} />
                    ))}
                    <LabelList
                        dataKey="value"
                        fill={labelColor}
                        fontSize={12}
                        formatter={(value: number) => Math.round(value)}
                        position="right"
                    />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};
