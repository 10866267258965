import { Material } from './use-materials';

type MaterialItem = {
    category: string;
    id: string;
    weight: number;
    end_of_life: null | string;
};

export const getCircularityPercentages = (
    listedMaterials: Array<{
        id: string;
        category: string;
        weight: number;
    }>,
    materials: Array<Material>
) => {
    if (!listedMaterials || !materials) return;
    const currentMaterials = listedMaterials.map((current) => {
        const match = materials.find((material) => material.id === current.id);

        if (!match) return null;

        return {
            category: current.category,
            id: current.id,
            weight: current.weight,
            end_of_life: match.end_of_life
        };
    });
    const productMaterials = currentMaterials.filter((material) => material && material.category === 'product') as Array<MaterialItem>;
    const packagingMaterials = currentMaterials.filter((material) => material && material.category === 'packaging') as Array<MaterialItem>;
    const totalProductWeight = productMaterials.reduce((acc, current) => acc + current.weight, 0);
    const totalPackagingWeight = packagingMaterials.reduce((acc, current) => acc + current.weight, 0);

    const productPercentageByType = productMaterials.reduce((acc, current) => {
        if (current.end_of_life) {
            const percentage = (current.weight / totalProductWeight) * 100;
            if (acc[current.end_of_life]) {
                acc[current.end_of_life] = acc[current.end_of_life] + percentage;
            } else {
                acc[current.end_of_life] = percentage;
            }
        }
        return acc;
    }, {} as any);

    const packagingPercentageByType = packagingMaterials.reduce((acc, current) => {
        if (current.end_of_life) {
            const percentage = (current.weight / totalPackagingWeight) * 100;
            if (acc[current.end_of_life]) {
                acc[current.end_of_life] = acc[current.end_of_life] + percentage;
            } else {
                acc[current.end_of_life] = percentage;
            }
        }
        return acc;
    }, {} as any);

    return {
        product: productPercentageByType ?? {},
        packaging: packagingPercentageByType ?? {}
    };
};
