import { useTableFilters } from 'shared/use-table-filters';
import useSWR from 'swr';
import { QueryReturnResult } from 'types';
import { useEffect } from 'react';
import { RunQueryConfig, runQuery } from 'utils/run-query';

export const useQueryResult = (current?: RunQueryConfig) => {
    const { onPageTokenMappingChange, page, onPage, onLimitChange, limit, query, pageTokeMapping } = useTableFilters({
        variant: 'query'
    });

    const requestQuery = current
        ? {
              ...current,
              pageSize: limit ? Number.parseInt(limit, 10) : 0,
              pageToken: query.page_token
          }
        : null;
    const { data, error, mutate, isLoading } = useSWR<QueryReturnResult>(
        requestQuery ? ['/query-result', requestQuery] : null,
        async ([_, query]) => runQuery(query as any)
    );

    useEffect(() => {
        const shouldUpdate = data && !isLoading && data.next_page_token;
        if (shouldUpdate) {
            onPageTokenMappingChange((current) => ({
                ...current,
                [page + 1]: data.next_page_token
            }));
        }
    }, [data, isLoading, onPageTokenMappingChange, page]);

    return {
        data,
        error,
        mutate,
        isLoading,
        onPageTokenMappingChange,
        page,
        onPage,
        onLimitChange,
        limit,
        query,
        pageTokeMapping
    };
};
