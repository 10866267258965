import { useUser } from 'shared/use-user';
import { useTranslation } from 'react-i18next';
import { Text, Button, Group } from '@mantine/core';
import { AuthLayout } from './auth-layout';

export const RequestAccessError = () => {
    const { onLogout } = useUser();
    const { t } = useTranslation();
    return (
        <AuthLayout>
            <Text c="blue" fw={700} size="xl" ta="center" mb="md">
                {t('requestAccess')}
            </Text>
            <Text fw={500} size="sm" mb="lg" ta="center">
                {t('requestAccessDescription')}
            </Text>
            <Group justify="center">
                <Button onClick={onLogout} color="blue">
                    {t('backToSignIn')}
                </Button>
            </Group>
        </AuthLayout>
    );
};
