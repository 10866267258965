import { IngestedFile, Nullable } from 'types';
import { useTranslation } from 'react-i18next';
import { TbCheck, TbCopy } from 'react-icons/tb';
import { ActionIcon, CopyButton, Modal, Stack, TagsInput, TextInput } from '@mantine/core';
import { formatBytes } from 'utils/format-bytes';
import { fileCategoryTypes } from './file-category-types';

export const DataModal = ({ data, isOpen, onClose }: { data: Nullable<IngestedFile>; isOpen: boolean; onClose: () => void }) => {
    const { t } = useTranslation();
    if (!data) return null;
    return (
        <Modal size="lg" title={t('viewData')} opened={isOpen} onClose={onClose}>
            <form>
                <Stack mb="xl" mt="md" gap="xs">
                    <TextInput
                        label={t('key')}
                        value={data.key ?? ''}
                        readOnly
                        rightSection={
                            data.key && (
                                <CopyButton value={data.key}>
                                    {({ copied, copy }) => (
                                        <ActionIcon variant="transparent" onClick={copy}>
                                            {copied ? <TbCheck /> : <TbCopy />}
                                        </ActionIcon>
                                    )}
                                </CopyButton>
                            )
                        }
                    />
                    <TextInput label={t('name')} value={data.name ?? ''} readOnly />
                    <TextInput label={t('size')} value={data.size ? formatBytes(data.size) : ''} readOnly />
                    <TextInput label={t('createdOn')} value={data.created_on ? new Date(data.created_on).toLocaleString() : ''} readOnly />
                    <TextInput label={t('updatedOn')} value={data.updated_on ? new Date(data.updated_on).toLocaleString() : ''} readOnly />
                    <TextInput
                        label={t('uploadTime')}
                        value={data.upload_time ? new Date(data.updated_on).toLocaleString() : ''}
                        readOnly
                    />
                    <TextInput
                        label={t('category')}
                        value={fileCategoryTypes.find((current) => current.value === data.category)?.label}
                        readOnly
                    />
                    <TagsInput label={t('tags')} readOnly defaultValue={data.tags?.filter(Boolean)} />
                </Stack>
            </form>
        </Modal>
    );
};
