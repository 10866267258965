import { Nullable } from 'types';
import { useScoreReportQuery } from './use-score-report-query';

export interface Product {
    sku: string;
    name: string;
    id: string;
    version: string;
    record_hash: string;
    category_id: string;
    file_id: string;
    parent_category: string;
    category: string;
    production_sector: string;
    brand: Nullable<string>;
    division: Nullable<string>;
    tags: Array<string>;
    country_of_production: string;
    product_weight: Weight;
    product_sustainability_characteristics: ProductSustainabilityCharacteristics;
    livelihood_and_wellbeing: LivelihoodAndWellbeing;
    eco_labels: EcoLabel[];
    production: Production;
    environmental_impact_assessment: EnvironmentalImpactAssessment;
    transports: Transport[];
    materials: ProductMaterial[];
    material_category_type_properties: MaterialCategoryTypeProperty[];
    supplier: string
}

interface Weight {
    value: number;
    unit: string;
}

interface ProductSustainabilityCharacteristics {
    durable: boolean | null;
    package_free: boolean | null;
    easy_delivery: boolean | null;
    low_energy: boolean | null;
    shift_behavior: boolean | null;
    sustainable_materials: boolean | null;
    is_non_consumable: boolean | null;
    average_product_lifespan: number | null;
    legal_guarantee: number | null;
    extra_guarantee_duration: number | null;
    repair_service: boolean | null;
    leasing_scheme: boolean | null;
    spare_parts: boolean | null;
    product_components: ProductComponents;
}

interface ProductComponents {
    proprietary_parts: boolean | null;
    moving_parts: boolean | null;
    small_components: boolean | null;
    standard_fixings: boolean | null;
}

interface LivelihoodAndWellbeing {
    fair_pay: FairPay;
    working_conditions: WorkingConditions;
    workers_protection: WorkersProtection;
    child_and_forced_labor: ChildAndForcedLabor;
}

interface FairPay {
    living_wage: Claim;
    gender_wage_gap: Claim;
}

interface WorkingConditions {
    working_hours: Claim;
    collective_bargaining: Claim;
}

interface WorkersProtection {
    youth_labor: Claim;
    work_safety: Claim;
}

interface ChildAndForcedLabor {
    forced_labor: Claim;
    child_labor: Claim;
}

interface Claim {
    claim: boolean | null;
    proof_file_key: Nullable<string>;
}

interface EcoLabel {
    title: string;
    id: Nullable<string>;
    category: string;
}

interface Production {
    water_consumption: WaterConsumption;
    energy_consumption: EnergyConsumption;
}

interface WaterConsumption {
    value: number | null;
    unit: Nullable<string>;
    water_source: Nullable<string>;
}

interface EnergyConsumption {
    is_proxy: boolean;
    totals: EnergyTotal[];
    energy_sources: EnergySource[];
}

interface EnergyTotal {
    value: number;
    unit: string;
    usage_type: string;
}

interface EnergySource {
    energy_type: string;
    percentage: number | null;
    usage_type: string;
    energy_use: EnergyUse;
}

interface EnergyUse {
    value: number;
    unit: string;
}

interface EnvironmentalImpactAssessment {
    EIA_conducted_type: Nullable<string>;
    EIA_type: Nullable<string>;
    reviewed_by_external_organization: boolean | null;
    follows_international_standards: boolean | null;
    results: EnvironmentalImpactResult[];
    proof_files: Array<string>;
}

interface EnvironmentalImpactResult {
    impact: Impact;
    impact_category: Nullable<string>;
    production_stage: Nullable<string>;
}

interface Impact {
    value: number | null;
    unit: Nullable<string>;
}

interface Transport {
    origin: Location;
    destination: Location;
    mode: string;
    medium: string;
}

interface Location {
    id: string;
    country: string;
    lat: number;
    lng: number;
    city: string;
}

export interface ProductMaterial {
    category: string;
    type: string;
    material_id: string;
    material_name: string;
    description: Nullable<string>;
    percentage: number;
    weight: Weight;
    source_country: string;
    reused: boolean | null;
    recycled: boolean | null;
    organic: boolean | null;
    biobased: boolean | null;
    component: Nullable<string>;
    material_version: string;
}

interface MaterialCategoryTypeProperty {
    category: string;
    type: string;
    weight: Weight;
    PVC: boolean | null;
    additives: boolean | null;
    adhesives: boolean | null;
    elastomers: boolean | null;
    materials_separability_type: Nullable<string>;
    best_eol_disposal_method: Nullable<string>;
    eol_treatment_type: Nullable<string>;
    communication_end_of_life_treatment: boolean | null;
}

export const useProduct = (productId?: string) => useScoreReportQuery<Product>('product', productId, 'id');
