import { paths } from 'paths';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './use-user';

export const useDayrizeOnlyGuard = () => {
    const navigate = useNavigate();
    const { isDayrize, isLoading } = useUser();
    useEffect(() => {
        if (!isLoading && !isDayrize) {
            navigate(paths.root());
        }
    }, [isDayrize, navigate, isLoading]);
};
