import { IngestedFile } from 'types';
import { day } from 'lib/day';

const NUMBER_OF_DAYS_TO_EXPIRATION = 14;

export const isFileCloseToExpiration = (file?: IngestedFile, targetDate = day()) => {
    if (!file?.expire_at) return false;
    const expirationDate = day(file.expire_at);
    const diff = expirationDate.diff(targetDate, 'day');
    return diff <= NUMBER_OF_DAYS_TO_EXPIRATION;
};
