import { useParams, useSearchParams } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { truncate } from 'utils/truncate';

export const useProductName = () => {
    const { id } = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');
    const productNameLength = isSmallDevice ? 8 : 40;
    const nameParam = searchParams.get('name');
    return nameParam ? truncate(decodeURIComponent(nameParam), productNameLength) : id;
};
