import { Nullable } from 'types';

export const createEmptyRow = (id: number) => ({
    // Local
    id,
    validation_errors: ['Add a SKU for the new product'],
    invalid_fields: ['sku'],
    holding_area_error: null as Nullable<string>,
    materials_total_percentage: 0,
    materials_primary_total_percentage: 0,
    materials_secondary_total_percentage: 0,
    materials_tertiary_total_percentage: 0,

    // Necessary product information
    sku: '',
    name: '',
    supplier: '',
    brand: null as Nullable<string>,
    custom_category: '',
    google_taxonomy_path: '',
    description: null as Nullable<string>,
    country_of_production: null as Nullable<string>,
    product_weight_value: '',
    product_weight_unit: '',
    material_category_type_properties_primary_weight_value: '',
    material_category_type_properties_primary_weight_unit: '',
    material_category_type_properties_secondary_weight_value: '',
    material_category_type_properties_secondary_weight_unit: '',
    material_category_type_properties_tertiary_weight_value: '',
    material_category_type_properties_tertiary_weight_unit: '',
    transports_0_origin_city: null as Nullable<string>,
    transports_0_origin_country: null as Nullable<string>,
    transports_0_destination_city: null as Nullable<string>,
    transports_0_destination_country: null as Nullable<string>,
    transports_0_transport_mode: null as Nullable<string>,
    transports_0_transport_medium: null as Nullable<string>,
    transports_1_origin_city: null as Nullable<string>,
    transports_1_origin_country: null as Nullable<string>,
    transports_1_destination_city: null as Nullable<string>,
    transports_1_destination_country: null as Nullable<string>,
    transports_1_transport_mode: null as Nullable<string>,
    transports_1_transport_medium: null as Nullable<string>,
    transports_2_origin_city: null as Nullable<string>,
    transports_2_origin_country: null as Nullable<string>,
    transports_2_destination_city: null as Nullable<string>,
    transports_2_destination_country: null as Nullable<string>,
    transports_2_transport_mode: null as Nullable<string>,
    transports_2_transport_medium: null as Nullable<string>,
    transports_3_origin_city: null as Nullable<string>,
    transports_3_origin_country: null as Nullable<string>,
    transports_3_destination_city: null as Nullable<string>,
    transports_3_destination_country: null as Nullable<string>,
    transports_3_transport_mode: null as Nullable<string>,
    transports_3_transport_medium: null as Nullable<string>,
    transports_4_origin_city: null as Nullable<string>,
    transports_4_origin_country: null as Nullable<string>,
    transports_4_destination_city: null as Nullable<string>,
    transports_4_destination_country: null as Nullable<string>,
    transports_4_transport_mode: null as Nullable<string>,
    transports_4_transport_medium: null as Nullable<string>,
    transports_5_origin_city: null as Nullable<string>,
    transports_5_origin_country: null as Nullable<string>,
    transports_5_destination_city: null as Nullable<string>,
    transports_5_destination_country: null as Nullable<string>,
    transports_5_transport_mode: null as Nullable<string>,
    transports_5_transport_medium: null as Nullable<string>,
    transports_6_origin_city: null as Nullable<string>,
    transports_6_origin_country: null as Nullable<string>,
    transports_6_destination_city: null as Nullable<string>,
    transports_6_destination_country: null as Nullable<string>,
    transports_6_transport_mode: null as Nullable<string>,
    transports_6_transport_medium: null as Nullable<string>,
    transports_7_origin_city: null as Nullable<string>,
    transports_7_origin_country: null as Nullable<string>,
    transports_7_destination_city: null as Nullable<string>,
    transports_7_destination_country: null as Nullable<string>,
    transports_7_transport_mode: null as Nullable<string>,
    transports_7_transport_medium: null as Nullable<string>,
    transports_8_origin_city: null as Nullable<string>,
    transports_8_origin_country: null as Nullable<string>,
    transports_8_destination_city: null as Nullable<string>,
    transports_8_destination_country: null as Nullable<string>,
    transports_8_transport_mode: null as Nullable<string>,
    transports_8_transport_medium: null as Nullable<string>,
    transports_9_origin_city: null as Nullable<string>,
    transports_9_origin_country: null as Nullable<string>,
    transports_9_destination_city: null as Nullable<string>,
    transports_9_destination_country: null as Nullable<string>,
    transports_9_transport_mode: null as Nullable<string>,
    transports_9_transport_medium: null as Nullable<string>,
    transports_10_origin_city: null as Nullable<string>,
    transports_10_origin_country: null as Nullable<string>,
    transports_10_destination_city: null as Nullable<string>,
    transports_10_destination_country: null as Nullable<string>,
    transports_10_transport_mode: null as Nullable<string>,
    transports_10_transport_medium: null as Nullable<string>,
    transports_11_origin_city: null as Nullable<string>,
    transports_11_origin_country: null as Nullable<string>,
    transports_11_destination_city: null as Nullable<string>,
    transports_11_destination_country: null as Nullable<string>,
    transports_11_transport_mode: null as Nullable<string>,
    transports_11_transport_medium: null as Nullable<string>,
    transports_12_origin_city: null as Nullable<string>,
    transports_12_origin_country: null as Nullable<string>,
    transports_12_destination_city: null as Nullable<string>,
    transports_12_destination_country: null as Nullable<string>,
    transports_12_transport_mode: null as Nullable<string>,
    transports_12_transport_medium: null as Nullable<string>,
    transports_13_origin_city: null as Nullable<string>,
    transports_13_origin_country: null as Nullable<string>,
    transports_13_destination_city: null as Nullable<string>,
    transports_13_destination_country: null as Nullable<string>,
    transports_13_transport_mode: null as Nullable<string>,
    transports_13_transport_medium: null as Nullable<string>,
    transports_14_origin_city: null as Nullable<string>,
    transports_14_origin_country: null as Nullable<string>,
    transports_14_destination_city: null as Nullable<string>,
    transports_14_destination_country: null as Nullable<string>,
    transports_14_transport_mode: null as Nullable<string>,
    transports_14_transport_medium: null as Nullable<string>,
    transports_15_origin_city: null as Nullable<string>,
    transports_15_origin_country: null as Nullable<string>,
    transports_15_destination_city: null as Nullable<string>,
    transports_15_destination_country: null as Nullable<string>,
    transports_15_transport_mode: null as Nullable<string>,
    transports_15_transport_medium: null as Nullable<string>,

    // Necessary product information
    division: null as Nullable<string>,
    tags: [] as Array<string>,
    eco_label_0_title: '',
    eco_label_0_type: '',
    eco_label_1_title: '',
    eco_label_1_type: '',
    eco_label_2_title: '',
    eco_label_2_type: '',
    eco_label_3_title: '',
    eco_label_3_type: '',
    eco_label_4_title: '',
    eco_label_4_type: '',
    eco_label_5_title: '',
    eco_label_5_type: '',
    eco_label_6_title: '',
    eco_label_6_type: '',
    eco_label_7_title: '',
    eco_label_7_type: '',
    eco_label_8_title: '',
    eco_label_8_type: '',
    eco_label_9_title: '',
    eco_label_9_type: '',
    eco_label_10_title: '',
    eco_label_10_type: '',
    eco_label_11_title: '',
    eco_label_11_type: '',
    eco_label_12_title: '',
    eco_label_12_type: '',
    eco_label_13_title: '',
    eco_label_13_type: '',
    eco_label_14_title: '',
    eco_label_14_type: '',
    eco_label_15_title: '',
    eco_label_15_type: '',

    // Additional product information
    water_consumption_value: null as Nullable<string>,
    water_consumption_unit: null as Nullable<string>,
    water_consumption_source: null as Nullable<string>,

    energy_sources_0_energy_type: null as Nullable<string>,
    energy_sources_0_usage_type: null as Nullable<string>,
    energy_sources_0_energy_use_value: null as Nullable<number>,
    energy_sources_0_energy_use_unit: null as Nullable<string>,
    energy_sources_1_energy_type: null as Nullable<string>,
    energy_sources_1_usage_type: null as Nullable<string>,
    energy_sources_1_energy_use_value: null as Nullable<number>,
    energy_sources_1_energy_use_unit: null as Nullable<string>,
    energy_sources_1_percentage: null as Nullable<string>,
    energy_sources_2_energy_type: null as Nullable<string>,
    energy_sources_2_usage_type: null as Nullable<string>,
    energy_sources_2_energy_use_value: null as Nullable<number>,
    energy_sources_2_energy_use_unit: null as Nullable<string>,
    energy_sources_3_energy_type: null as Nullable<string>,
    energy_sources_3_usage_type: null as Nullable<string>,
    energy_sources_3_energy_use_value: null as Nullable<number>,
    energy_sources_3_energy_use_unit: null as Nullable<string>,
    energy_sources_4_energy_type: null as Nullable<string>,
    energy_sources_4_usage_type: null as Nullable<string>,
    energy_sources_4_energy_use_value: null as Nullable<number>,
    energy_sources_4_energy_use_unit: null as Nullable<string>,
    energy_sources_5_energy_type: null as Nullable<string>,
    energy_sources_5_usage_type: null as Nullable<string>,
    energy_sources_5_energy_use_value: null as Nullable<number>,
    energy_sources_5_energy_use_unit: null as Nullable<string>,
    energy_sources_6_energy_type: null as Nullable<string>,
    energy_sources_6_usage_type: null as Nullable<string>,
    energy_sources_6_energy_use_value: null as Nullable<number>,
    energy_sources_6_energy_use_unit: null as Nullable<string>,
    energy_sources_7_energy_type: null as Nullable<string>,
    energy_sources_7_usage_type: null as Nullable<string>,
    energy_sources_7_energy_use_value: null as Nullable<number>,
    energy_sources_7_energy_use_unit: null as Nullable<string>,
    energy_sources_8_energy_type: null as Nullable<string>,
    energy_sources_8_usage_type: null as Nullable<string>,
    energy_sources_8_energy_use_value: null as Nullable<number>,
    energy_sources_8_energy_use_unit: null as Nullable<string>,
    energy_sources_9_energy_type: null as Nullable<string>,
    energy_sources_9_usage_type: null as Nullable<string>,
    energy_sources_9_energy_use_value: null as Nullable<number>,
    energy_sources_9_energy_use_unit: null as Nullable<string>,
    energy_sources_10_energy_type: null as Nullable<string>,
    energy_sources_10_usage_type: null as Nullable<string>,
    energy_sources_10_energy_use_value: null as Nullable<number>,
    energy_sources_10_energy_use_unit: null as Nullable<string>,
    energy_sources_11_energy_type: null as Nullable<string>,
    energy_sources_11_usage_type: null as Nullable<string>,
    energy_sources_11_energy_use_value: null as Nullable<number>,
    energy_sources_11_energy_use_unit: null as Nullable<string>,
    energy_sources_12_energy_type: null as Nullable<string>,
    energy_sources_12_usage_type: null as Nullable<string>,
    energy_sources_12_energy_use_value: null as Nullable<number>,
    energy_sources_12_energy_use_unit: null as Nullable<string>,
    energy_sources_13_energy_type: null as Nullable<string>,
    energy_sources_13_usage_type: null as Nullable<string>,
    energy_sources_13_energy_use_value: null as Nullable<number>,
    energy_sources_13_energy_use_unit: null as Nullable<string>,
    energy_sources_14_energy_type: null as Nullable<string>,
    energy_sources_14_usage_type: null as Nullable<string>,
    energy_sources_14_energy_use_value: null as Nullable<number>,
    energy_sources_14_energy_use_unit: null as Nullable<string>,
    energy_sources_15_energy_type: null as Nullable<string>,
    energy_sources_15_usage_type: null as Nullable<string>,
    energy_sources_15_energy_use_value: null as Nullable<number>,
    energy_sources_15_energy_use_unit: null as Nullable<string>,

    // Optional sustainability product information
    product_components_proprietary_parts: null as Nullable<string>,
    product_components_moving_parts: null as Nullable<string>,
    product_components_standard_fixings: null as Nullable<string>,
    product_components_small_components: null as Nullable<string>,
    repair_service: null as Nullable<string>,
    legal_guarantee: null as Nullable<string>,
    extra_guarantee_duration: null as Nullable<number>,
    average_product_lifespan: null as Nullable<string>,
    best_eol_disposal_method: null as Nullable<string>,
    eol_treatment_type: null as Nullable<string>,
    communication_end_of_life_treatment: null as Nullable<string>,

    // Product material details
    materials_0_material_name: '',
    materials_0_id: '',
    materials_0_percentage: '' as string | number,
    materials_0_recycled: null as Nullable<string>,
    materials_0_reused: null as Nullable<string>,
    materials_0_organic: null as Nullable<string>,
    materials_0_source_country: null as Nullable<string>,
    materials_1_material_name: '',
    materials_1_id: '',
    materials_1_percentage: '' as string | number,
    materials_1_recycled: null as Nullable<string>,
    materials_1_reused: null as Nullable<string>,
    materials_1_organic: null as Nullable<string>,
    materials_1_source_country: null as Nullable<string>,
    materials_2_material_name: '',
    materials_2_id: '',
    materials_2_percentage: '' as string | number,
    materials_2_recycled: null as Nullable<string>,
    materials_2_reused: null as Nullable<string>,
    materials_2_organic: null as Nullable<string>,
    materials_2_source_country: null as Nullable<string>,
    materials_3_material_name: '',
    materials_3_id: '',
    materials_3_percentage: '' as string | number,
    materials_3_recycled: null as Nullable<string>,
    materials_3_reused: null as Nullable<string>,
    materials_3_organic: null as Nullable<string>,
    materials_3_source_country: null as Nullable<string>,
    materials_4_material_name: '',
    materials_4_id: '',
    materials_4_percentage: '' as string | number,
    materials_4_recycled: null as Nullable<string>,
    materials_4_reused: null as Nullable<string>,
    materials_4_organic: null as Nullable<string>,
    materials_4_source_country: null as Nullable<string>,
    materials_5_material_name: '',
    materials_5_id: '',
    materials_5_percentage: '' as string | number,
    materials_5_recycled: null as Nullable<string>,
    materials_5_reused: null as Nullable<string>,
    materials_5_organic: null as Nullable<string>,
    materials_5_source_country: null as Nullable<string>,
    materials_6_material_name: '',
    materials_6_id: '',
    materials_6_percentage: '' as string | number,
    materials_6_recycled: null as Nullable<string>,
    materials_6_reused: null as Nullable<string>,
    materials_6_organic: null as Nullable<string>,
    materials_6_source_country: null as Nullable<string>,
    materials_7_material_name: '',
    materials_7_id: '',
    materials_7_percentage: '' as string | number,
    materials_7_recycled: null as Nullable<string>,
    materials_7_reused: null as Nullable<string>,
    materials_7_organic: null as Nullable<string>,
    materials_7_source_country: null as Nullable<string>,
    materials_8_material_name: '',
    materials_8_id: '',
    materials_8_percentage: '' as string | number,
    materials_8_recycled: null as Nullable<string>,
    materials_8_reused: null as Nullable<string>,
    materials_8_organic: null as Nullable<string>,
    materials_8_source_country: null as Nullable<string>,
    materials_9_material_name: '',
    materials_9_id: '',
    materials_9_percentage: '' as string | number,
    materials_9_recycled: null as Nullable<string>,
    materials_9_reused: null as Nullable<string>,
    materials_9_organic: null as Nullable<string>,
    materials_9_source_country: null as Nullable<string>,
    materials_10_material_name: '',
    materials_10_id: '',
    materials_10_percentage: '' as string | number,
    materials_10_recycled: null as Nullable<string>,
    materials_10_reused: null as Nullable<string>,
    materials_10_organic: null as Nullable<string>,
    materials_10_source_country: null as Nullable<string>,
    materials_11_material_name: '',
    materials_11_id: '',
    materials_11_percentage: '' as string | number,
    materials_11_recycled: null as Nullable<string>,
    materials_11_reused: null as Nullable<string>,
    materials_11_organic: null as Nullable<string>,
    materials_11_source_country: null as Nullable<string>,
    materials_12_material_name: '',
    materials_12_id: '',
    materials_12_percentage: '' as string | number,
    materials_12_recycled: null as Nullable<string>,
    materials_12_reused: null as Nullable<string>,
    materials_12_organic: null as Nullable<string>,
    materials_12_source_country: null as Nullable<string>,
    materials_13_material_name: '',
    materials_13_id: '',
    materials_13_percentage: '' as string | number,
    materials_13_recycled: null as Nullable<string>,
    materials_13_reused: null as Nullable<string>,
    materials_13_organic: null as Nullable<string>,
    materials_13_source_country: null as Nullable<string>,
    materials_14_material_name: '',
    materials_14_id: '',
    materials_14_percentage: '' as string | number,
    materials_14_recycled: null as Nullable<string>,
    materials_14_reused: null as Nullable<string>,
    materials_14_organic: null as Nullable<string>,
    materials_14_source_country: null as Nullable<string>,
    materials_15_material_name: '',
    materials_15_id: '',
    materials_15_percentage: '' as string | number,
    materials_15_recycled: null as Nullable<string>,
    materials_15_reused: null as Nullable<string>,
    materials_15_organic: null as Nullable<string>,
    materials_15_source_country: null as Nullable<string>,

    // Primary packaging materials
    materials_primary_0_material_name: '',
    materials_primary_0_id: '',
    materials_primary_0_percentage: '' as string | number,
    materials_primary_0_recycled: null as Nullable<string>,
    materials_primary_0_reused: null as Nullable<string>,
    materials_primary_0_organic: null as Nullable<string>,
    materials_primary_0_source_country: null as Nullable<string>,
    materials_primary_1_material_name: '',
    materials_primary_1_id: '',
    materials_primary_1_percentage: '' as string | number,
    materials_primary_1_recycled: null as Nullable<string>,
    materials_primary_1_reused: null as Nullable<string>,
    materials_primary_1_organic: null as Nullable<string>,
    materials_primary_1_source_country: null as Nullable<string>,
    materials_primary_2_material_name: '',
    materials_primary_2_id: '',
    materials_primary_2_percentage: '' as string | number,
    materials_primary_2_recycled: null as Nullable<string>,
    materials_primary_2_reused: null as Nullable<string>,
    materials_primary_2_organic: null as Nullable<string>,
    materials_primary_2_source_country: null as Nullable<string>,
    materials_primary_3_material_name: '',
    materials_primary_3_id: '',
    materials_primary_3_percentage: '' as string | number,
    materials_primary_3_recycled: null as Nullable<string>,
    materials_primary_3_reused: null as Nullable<string>,
    materials_primary_3_organic: null as Nullable<string>,
    materials_primary_3_source_country: null as Nullable<string>,
    materials_primary_4_material_name: '',
    materials_primary_4_id: '',
    materials_primary_4_percentage: '' as string | number,
    materials_primary_4_recycled: null as Nullable<string>,
    materials_primary_4_reused: null as Nullable<string>,
    materials_primary_4_organic: null as Nullable<string>,
    materials_primary_4_source_country: null as Nullable<string>,
    materials_primary_5_material_name: '',
    materials_primary_5_id: '',
    materials_primary_5_percentage: '' as string | number,
    materials_primary_5_recycled: null as Nullable<string>,
    materials_primary_5_reused: null as Nullable<string>,
    materials_primary_5_organic: null as Nullable<string>,
    materials_primary_5_source_country: null as Nullable<string>,
    materials_primary_6_material_name: '',
    materials_primary_6_id: '',
    materials_primary_6_percentage: '' as string | number,
    materials_primary_6_recycled: null as Nullable<string>,
    materials_primary_6_reused: null as Nullable<string>,
    materials_primary_6_organic: null as Nullable<string>,
    materials_primary_6_source_country: null as Nullable<string>,
    materials_primary_7_material_name: '',
    materials_primary_7_id: '',
    materials_primary_7_percentage: '' as string | number,
    materials_primary_7_recycled: null as Nullable<string>,
    materials_primary_7_reused: null as Nullable<string>,
    materials_primary_7_organic: null as Nullable<string>,
    materials_primary_7_source_country: null as Nullable<string>,
    materials_primary_8_material_name: '',
    materials_primary_8_id: '',
    materials_primary_8_percentage: '' as string | number,
    materials_primary_8_recycled: null as Nullable<string>,
    materials_primary_8_reused: null as Nullable<string>,
    materials_primary_8_organic: null as Nullable<string>,
    materials_primary_8_source_country: null as Nullable<string>,
    materials_primary_9_material_name: '',
    materials_primary_10_id: '',
    materials_primary_9_percentage: '' as string | number,
    materials_primary_9_recycled: null as Nullable<string>,
    materials_primary_9_reused: null as Nullable<string>,
    materials_primary_9_organic: null as Nullable<string>,
    materials_primary_9_source_country: null as Nullable<string>,
    materials_primary_10_material_name: '',
    materials_primary_9_id: '',
    materials_primary_10_percentage: '' as string | number,
    materials_primary_10_recycled: null as Nullable<string>,
    materials_primary_10_reused: null as Nullable<string>,
    materials_primary_10_organic: null as Nullable<string>,
    materials_primary_10_source_country: null as Nullable<string>,
    materials_primary_11_material_name: '',
    materials_primary_11_id: '',
    materials_primary_11_percentage: '' as string | number,
    materials_primary_11_recycled: null as Nullable<string>,
    materials_primary_11_reused: null as Nullable<string>,
    materials_primary_11_organic: null as Nullable<string>,
    materials_primary_11_source_country: null as Nullable<string>,
    materials_primary_12_material_name: '',
    materials_primary_12_id: '',
    materials_primary_12_percentage: '' as string | number,
    materials_primary_12_recycled: null as Nullable<string>,
    materials_primary_12_reused: null as Nullable<string>,
    materials_primary_12_organic: null as Nullable<string>,
    materials_primary_12_source_country: null as Nullable<string>,
    materials_primary_13_material_name: '',
    materials_primary_13_id: '',
    materials_primary_13_percentage: '' as string | number,
    materials_primary_13_recycled: null as Nullable<string>,
    materials_primary_13_reused: null as Nullable<string>,
    materials_primary_13_organic: null as Nullable<string>,
    materials_primary_13_source_country: null as Nullable<string>,
    materials_primary_14_material_name: '',
    materials_primary_14_id: '',
    materials_primary_14_percentage: '' as string | number,
    materials_primary_14_recycled: null as Nullable<string>,
    materials_primary_14_reused: null as Nullable<string>,
    materials_primary_14_organic: null as Nullable<string>,
    materials_primary_14_source_country: null as Nullable<string>,
    materials_primary_15_material_name: '',
    materials_primary_15_id: '',
    materials_primary_15_percentage: '' as string | number,
    materials_primary_15_recycled: null as Nullable<string>,
    materials_primary_15_reused: null as Nullable<string>,
    materials_primary_15_organic: null as Nullable<string>,
    materials_primary_15_source_country: null as Nullable<string>,

    // Secondary packaging materials
    materials_secondary_0_material_name: '',
    materials_secondary_0_id: '',
    materials_secondary_0_percentage: 0,
    materials_secondary_0_recycled: null as Nullable<string>,
    materials_secondary_0_reused: null as Nullable<string>,
    materials_secondary_0_organic: null as Nullable<string>,
    materials_secondary_0_source_country: null as Nullable<string>,
    materials_secondary_1_material_name: '',
    materials_secondary_1_id: '',
    materials_secondary_1_percentage: '' as string | number,
    materials_secondary_1_recycled: null as Nullable<string>,
    materials_secondary_1_reused: null as Nullable<string>,
    materials_secondary_1_organic: null as Nullable<string>,
    materials_secondary_1_source_country: null as Nullable<string>,
    materials_secondary_2_material_name: '',
    materials_secondary_2_id: '',
    materials_secondary_2_percentage: '' as string | number,
    materials_secondary_2_recycled: null as Nullable<string>,
    materials_secondary_2_reused: null as Nullable<string>,
    materials_secondary_2_organic: null as Nullable<string>,
    materials_secondary_2_source_country: null as Nullable<string>,
    materials_secondary_3_material_name: '',
    materials_secondary_3_id: '',
    materials_secondary_3_percentage: '' as string | number,
    materials_secondary_3_recycled: null as Nullable<string>,
    materials_secondary_3_reused: null as Nullable<string>,
    materials_secondary_3_organic: null as Nullable<string>,
    materials_secondary_3_source_country: null as Nullable<string>,
    materials_secondary_4_material_name: '',
    materials_secondary_4_id: '',
    materials_secondary_4_percentage: '' as string | number,
    materials_secondary_4_recycled: null as Nullable<string>,
    materials_secondary_4_reused: null as Nullable<string>,
    materials_secondary_4_organic: null as Nullable<string>,
    materials_secondary_4_source_country: null as Nullable<string>,
    materials_secondary_5_material_name: '',
    materials_secondary_5_id: '',
    materials_secondary_5_percentage: '' as string | number,
    materials_secondary_5_recycled: null as Nullable<string>,
    materials_secondary_5_reused: null as Nullable<string>,
    materials_secondary_5_organic: null as Nullable<string>,
    materials_secondary_5_source_country: null as Nullable<string>,
    materials_secondary_6_material_name: '',
    materials_secondary_6_id: '',
    materials_secondary_6_percentage: '' as string | number,
    materials_secondary_6_recycled: null as Nullable<string>,
    materials_secondary_6_reused: null as Nullable<string>,
    materials_secondary_6_organic: null as Nullable<string>,
    materials_secondary_6_source_country: null as Nullable<string>,
    materials_secondary_7_material_name: '',
    materials_secondary_7_id: '',
    materials_secondary_7_percentage: '' as string | number,
    materials_secondary_7_recycled: null as Nullable<string>,
    materials_secondary_7_reused: null as Nullable<string>,
    materials_secondary_7_organic: null as Nullable<string>,
    materials_secondary_7_source_country: null as Nullable<string>,
    materials_secondary_8_material_name: '',
    materials_secondary_8_id: '',
    materials_secondary_8_percentage: '' as string | number,
    materials_secondary_8_recycled: null as Nullable<string>,
    materials_secondary_8_reused: null as Nullable<string>,
    materials_secondary_8_organic: null as Nullable<string>,
    materials_secondary_8_source_country: null as Nullable<string>,
    materials_secondary_9_material_name: '',
    materials_secondary_9_id: '',
    materials_secondary_9_percentage: '' as string | number,
    materials_secondary_9_recycled: null as Nullable<string>,
    materials_secondary_9_reused: null as Nullable<string>,
    materials_secondary_9_organic: null as Nullable<string>,
    materials_secondary_9_source_country: null as Nullable<string>,
    materials_secondary_10_material_name: '',
    materials_secondary_10_id: '',
    materials_secondary_10_percentage: '' as string | number,
    materials_secondary_10_recycled: null as Nullable<string>,
    materials_secondary_10_reused: null as Nullable<string>,
    materials_secondary_10_organic: null as Nullable<string>,
    materials_secondary_10_source_country: null as Nullable<string>,
    materials_secondary_11_material_name: '',
    materials_secondary_11_id: '',
    materials_secondary_11_percentage: '' as string | number,
    materials_secondary_11_recycled: null as Nullable<string>,
    materials_secondary_11_reused: null as Nullable<string>,
    materials_secondary_11_organic: null as Nullable<string>,
    materials_secondary_11_source_country: null as Nullable<string>,
    materials_secondary_12_material_name: '',
    materials_secondary_12_id: '',
    materials_secondary_12_percentage: '' as string | number,
    materials_secondary_12_recycled: null as Nullable<string>,
    materials_secondary_12_reused: null as Nullable<string>,
    materials_secondary_12_organic: null as Nullable<string>,
    materials_secondary_12_source_country: null as Nullable<string>,
    materials_secondary_13_material_name: '',
    materials_secondary_13_id: '',
    materials_secondary_13_percentage: '' as string | number,
    materials_secondary_13_recycled: null as Nullable<string>,
    materials_secondary_13_reused: null as Nullable<string>,
    materials_secondary_13_organic: null as Nullable<string>,
    materials_secondary_13_source_country: null as Nullable<string>,
    materials_secondary_14_material_name: '',
    materials_secondary_14_id: '',
    materials_secondary_14_percentage: '' as string | number,
    materials_secondary_14_recycled: null as Nullable<string>,
    materials_secondary_14_reused: null as Nullable<string>,
    materials_secondary_14_organic: null as Nullable<string>,
    materials_secondary_14_source_country: null as Nullable<string>,
    materials_secondary_15_material_name: '',
    materials_secondary_15_id: '',
    materials_secondary_15_percentage: '' as string | number,
    materials_secondary_15_recycled: null as Nullable<string>,
    materials_secondary_15_reused: null as Nullable<string>,
    materials_secondary_15_organic: null as Nullable<string>,
    materials_secondary_15_source_country: null as Nullable<string>,

    // Tertiary packaging materials
    materials_tertiary_0_material_name: '',
    materials_tertiary_0_id: '',
    materials_tertiary_0_percentage: 0,
    materials_tertiary_0_recycled: null as Nullable<string>,
    materials_tertiary_0_reused: null as Nullable<string>,
    materials_tertiary_0_organic: null as Nullable<string>,
    materials_tertiary_0_source_country: null as Nullable<string>,
    materials_tertiary_1_material_name: '',
    materials_tertiary_1_id: '',
    materials_tertiary_1_percentage: '' as string | number,
    materials_tertiary_1_recycled: null as Nullable<string>,
    materials_tertiary_1_reused: null as Nullable<string>,
    materials_tertiary_1_organic: null as Nullable<string>,
    materials_tertiary_1_source_country: null as Nullable<string>,
    materials_tertiary_2_material_name: '',
    materials_tertiary_2_id: '',
    materials_tertiary_2_percentage: '' as string | number,
    materials_tertiary_2_recycled: null as Nullable<string>,
    materials_tertiary_2_reused: null as Nullable<string>,
    materials_tertiary_2_organic: null as Nullable<string>,
    materials_tertiary_2_source_country: null as Nullable<string>,
    materials_tertiary_3_material_name: '',
    materials_tertiary_3_id: '',
    materials_tertiary_3_percentage: '' as string | number,
    materials_tertiary_3_recycled: null as Nullable<string>,
    materials_tertiary_3_reused: null as Nullable<string>,
    materials_tertiary_3_organic: null as Nullable<string>,
    materials_tertiary_3_source_country: null as Nullable<string>,
    materials_tertiary_4_material_name: '',
    materials_tertiary_4_id: '',
    materials_tertiary_4_percentage: '' as string | number,
    materials_tertiary_4_recycled: null as Nullable<string>,
    materials_tertiary_4_reused: null as Nullable<string>,
    materials_tertiary_4_organic: null as Nullable<string>,
    materials_tertiary_4_source_country: null as Nullable<string>,
    materials_tertiary_5_material_name: '',
    materials_tertiary_5_id: '',
    materials_tertiary_5_percentage: '' as string | number,
    materials_tertiary_5_recycled: null as Nullable<string>,
    materials_tertiary_5_reused: null as Nullable<string>,
    materials_tertiary_5_organic: null as Nullable<string>,
    materials_tertiary_5_source_country: null as Nullable<string>,
    materials_tertiary_6_material_name: '',
    materials_tertiary_6_id: '',
    materials_tertiary_6_percentage: '' as string | number,
    materials_tertiary_6_recycled: null as Nullable<string>,
    materials_tertiary_6_reused: null as Nullable<string>,
    materials_tertiary_6_organic: null as Nullable<string>,
    materials_tertiary_6_source_country: null as Nullable<string>,
    materials_tertiary_7_material_name: '',
    materials_tertiary_7_id: '',
    materials_tertiary_7_percentage: '' as string | number,
    materials_tertiary_7_recycled: null as Nullable<string>,
    materials_tertiary_7_reused: null as Nullable<string>,
    materials_tertiary_7_organic: null as Nullable<string>,
    materials_tertiary_7_source_country: null as Nullable<string>,
    materials_tertiary_8_material_name: '',
    materials_tertiary_8_id: '',
    materials_tertiary_8_percentage: '' as string | number,
    materials_tertiary_8_recycled: null as Nullable<string>,
    materials_tertiary_8_reused: null as Nullable<string>,
    materials_tertiary_8_organic: null as Nullable<string>,
    materials_tertiary_8_source_country: null as Nullable<string>,
    materials_tertiary_9_material_name: '',
    materials_tertiary_9_id: '',
    materials_tertiary_9_percentage: '' as string | number,
    materials_tertiary_9_recycled: null as Nullable<string>,
    materials_tertiary_9_reused: null as Nullable<string>,
    materials_tertiary_9_organic: null as Nullable<string>,
    materials_tertiary_9_source_country: null as Nullable<string>,
    materials_tertiary_10_material_name: '',
    materials_tertiary_10_id: '',
    materials_tertiary_10_percentage: '' as string | number,
    materials_tertiary_10_recycled: null as Nullable<string>,
    materials_tertiary_10_reused: null as Nullable<string>,
    materials_tertiary_10_organic: null as Nullable<string>,
    materials_tertiary_10_source_country: null as Nullable<string>,
    materials_tertiary_11_material_name: '',
    materials_tertiary_11_id: '',
    materials_tertiary_11_percentage: '' as string | number,
    materials_tertiary_11_recycled: null as Nullable<string>,
    materials_tertiary_11_reused: null as Nullable<string>,
    materials_tertiary_11_organic: null as Nullable<string>,
    materials_tertiary_11_source_country: null as Nullable<string>,
    materials_tertiary_12_material_name: '',
    materials_tertiary_12_id: '',
    materials_tertiary_12_percentage: '' as string | number,
    materials_tertiary_12_recycled: null as Nullable<string>,
    materials_tertiary_12_reused: null as Nullable<string>,
    materials_tertiary_12_organic: null as Nullable<string>,
    materials_tertiary_12_source_country: null as Nullable<string>,
    materials_tertiary_13_material_name: '',
    materials_tertiary_13_id: '',
    materials_tertiary_13_percentage: '' as string | number,
    materials_tertiary_13_recycled: null as Nullable<string>,
    materials_tertiary_13_reused: null as Nullable<string>,
    materials_tertiary_13_organic: null as Nullable<string>,
    materials_tertiary_13_source_country: null as Nullable<string>,
    materials_tertiary_14_material_name: '',
    materials_tertiary_14_id: '',
    materials_tertiary_14_percentage: '' as string | number,
    materials_tertiary_14_recycled: null as Nullable<string>,
    materials_tertiary_14_reused: null as Nullable<string>,
    materials_tertiary_14_organic: null as Nullable<string>,
    materials_tertiary_14_source_country: null as Nullable<string>,
    materials_tertiary_15_material_name: '',
    materials_tertiary_15_id: '',
    materials_tertiary_15_percentage: '' as string | number,
    materials_tertiary_15_recycled: null as Nullable<string>,
    materials_tertiary_15_reused: null as Nullable<string>,
    materials_tertiary_15_organic: null as Nullable<string>,
    materials_tertiary_15_source_country: null as Nullable<string>,

    // Livelihoods & Wellbeing
    child_and_forced_labor: null as Nullable<string>,
    youth_labor: null as Nullable<string>,
    work_safety: null as Nullable<string>,
    gender_wage_gap: null as Nullable<string>,
    forced_labor: null as Nullable<string>,
    living_wage: null as Nullable<string>,
    working_hours: null as Nullable<string>,
    collective_bargaining: null as Nullable<string>
});

export type DataRow = ReturnType<typeof createEmptyRow>;
