import { useUser } from 'shared/use-user';
import useSWR from 'swr';
import { QueryResult, User } from 'types';
import { request } from 'lib/request-client';

interface Impersonation {
    deleted: boolean;
    user_keys: Array<string>;
    namespace_id: string;
    created_on: string;
    updated_on: string;
    key: string;
    impersonated_user_key: string;
}

export const useNamespaceUsers = (namespace: string | null) => {
    const { activeUser } = useUser();
    const {
        data: users,
        error: usersError,
        isLoading: isUsersLoading
    } = useSWR<QueryResult<User>>(
        namespace ? `/_api/internal/namespace/${namespace}/users/list` : null,
        (url: string) => request.url(url, true).get() as Promise<QueryResult<User>>
    );

    const {
        data: impersonations,
        isLoading: isImpersonationLoading,
        mutate: mutateImpersonations,
        error: impersonationsError
    } = useSWR<QueryResult<Impersonation>>(
        namespace ? `/_api/internal/impersonations/query?namespace_id=${namespace}` : null,
        (url: string) => request.url(url, true).get() as Promise<QueryResult<Impersonation>>
    );

    const createUserImpersonation = async ({
        impersonated_user_key,
        namespace_id
    }: {
        impersonated_user_key: string;
        namespace_id: string;
    }) => {
        if (!activeUser) return;
        const result = await request.url(`/_api/internal/impersonation/create`, true).post({
            user_key: activeUser.key,
            impersonated_user_key,
            namespace_id
        });
        await mutateImpersonations();
        return result;
    };

    const deleteUserImpersonation = async (params: { namespace_id: string; impersonated_user_key: string; user_key: string }) => {
        const result = await request.url(`/_api/internal/impersonation`, true).query(params).delete();
        await mutateImpersonations();
        return result;
    };

    return {
        users,
        error: usersError || impersonationsError,
        isUsersLoading,
        createUserImpersonation,
        deleteUserImpersonation,
        impersonations,
        isImpersonationLoading,
        isLoading: isImpersonationLoading || isUsersLoading
    };
};
