import { useScoreReportSections } from 'shared/use-score-report-sections';
import { Dimension } from 'types/shared';
import { useTranslation } from 'react-i18next';
import { Box, Select, Text, UnstyledButton, rem, useMantineColorScheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import classes from './dimension-nav-links.module.css';

export const DimensiontNavLinks = ({ value, onChange }: { value: Dimension; onChange: (value: Dimension) => void }) => {
    const { colorScheme } = useMantineColorScheme();
    const { t } = useTranslation();
    const scoreReportSections = useScoreReportSections();
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');

    if (isSmallDevice)
        return (
            <Select
                aria-label={t('section')}
                placeholder={t('section')}
                value={value}
                mb={rem(16)}
                onChange={(value) => {
                    if (value) onChange(value as Dimension);
                }}
                data={scoreReportSections.map((section) => ({ value: section.value, label: section.title }))}
            />
        );

    return (
        <Box component="ul" className={classes.list}>
            {scoreReportSections.map((section) => {
                const isActive = section.value === value;
                return (
                    <li key={section.value}>
                        <UnstyledButton
                            data-testid={`link-${section.value}`}
                            aria-current={isActive ? 'true' : undefined}
                            onClick={() => {
                                onChange(section.value);
                            }}
                            className={classes.button}
                        >
                            <Box
                                component="img"
                                style={{ filter: colorScheme === 'dark' || isActive ? 'brightness(100)' : 'none' }}
                                width={20}
                                height={20}
                                src={section.icon}
                                alt=""
                            />

                            <Text>{section.title}</Text>
                        </UnstyledButton>
                    </li>
                );
            })}
        </Box>
    );
};
