import { Badge } from '@mantine/core';

export const EnvironmentBadge = ({ value = import.meta.env.VITE_BUILD_ENV }: { value?: string }) => {
    if (['localhost', 'test', 'stage'].includes(value)) {
        return (
            <Badge size="xs">
                {value} {import.meta.env.VITE_VERSION ? `v${import.meta.env.VITE_VERSION}` : null}
            </Badge>
        );
    }
    return null;
};
