import { multiFactor } from 'firebase/auth';
import { showAuthError } from 'shared/show-auth-error';
import { auth } from 'lib/firebase';

export const unenrollMultifactor = async () => {
    try {
        const checkMfaEnrollment = multiFactor(auth.currentUser!).enrolledFactors;
        for (let enrollment of checkMfaEnrollment) {
            await multiFactor(auth.currentUser!).unenroll(enrollment.uid);
        }
    } catch (error) {
        showAuthError(error);
        return false;
    }
    return true;
};
