import { useQueryResult } from 'queries/use-query-result';
import { DownloadButton } from 'shared/download-button';
import { ErrorCard } from 'shared/error-card';
import { Section } from 'shared/section';
import { Table } from 'shared/table';
import { useDayrizeOnlyGuard } from 'shared/use-dayrize-only-guard';
import { useTranslation } from 'react-i18next';
import { Box, Group, Skeleton, Text, Title } from '@mantine/core';
import { queryResultToTable } from 'utils/query-result-to-table';

export const Materials = () => {
    useDayrizeOnlyGuard();
    const { t } = useTranslation();

    const { data, isLoading, error, limit, onLimitChange, page, onPage } = useQueryResult({
        name: 'materials'
    });

    const { columns: resultColumns, data: resultData } = queryResultToTable(data?.results);

    if (error) {
        return <ErrorCard error={error} />;
    }

    return (
        <>
            <Group justify="space-between" gap="xs" mb="xs">
                <Box>
                    <Title size="h3">{t('materials')}</Title>
                    <Text c="dimmed" mb="md">
                        {t('materialsDescription')}
                    </Text>
                </Box>

                <DownloadButton
                    disabled={isLoading}
                    exports={[
                        {
                            name: `materials_${page}`,
                            data: data?.results
                        }
                    ]}
                />
            </Group>

            <Section>
                {isLoading ? (
                    <Skeleton data-testid="loading" height={200} width="100%" />
                ) : (
                    <Table
                        isLoading={isLoading}
                        columns={resultColumns}
                        data={resultData as any}
                        limit={limit}
                        onLimitChange={onLimitChange}
                        page={page}
                        onPage={onPage}
                    />
                )}
            </Section>
        </>
    );
};
