import useSWR from 'swr';
import { request } from 'lib/request-client';

export const useOpenApiSpec = () => {
    return useSWR<{
        paths: {
            [path: string]: {
                [method: string]: {
                    description: string;
                    summary: string;
                };
            };
        };
    }>('/static/openapi.json', (path: string) => request.url(path, true).get() as any);
};
