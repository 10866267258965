import { useScoreReportQuery } from 'products/use-score-report-query';
import { Nullable } from 'types';
import { Dimension } from 'types/shared';

const PRODUCT_QUERY_NAME = 'score_report_circularity_product';
const MATERAILS_QUERY_NAME = 'score_report_circularity_materials';

export type CircularityProductResult = {
    sku: Nullable<string>;
    product_name: Nullable<string>;
    dimension: Dimension;
    category_average: Nullable<number>;
    durability: string;
    product_circularity: Nullable<number>;
    total_product_circularity: Nullable<number>;
    product_circularity_average: Nullable<number>;
    packaging_circularity: Nullable<number>;
    packaging_circularity_average: Nullable<number>;
    product_lifetime_extension: Nullable<number>;
};

export const useCircularity = (productId?: string) => {
    const productResult = useScoreReportQuery<CircularityProductResult>(PRODUCT_QUERY_NAME, productId);
    const materialsResult = useScoreReportQuery<{
        product_id: Nullable<string>;
        material_category: Nullable<string>;
        characteristic_type: Nullable<string>;
        characteristic: Nullable<string>;
        percentage: Nullable<number>;
        materials: Array<string>;
    }>(MATERAILS_QUERY_NAME, productId);

    return {
        productResult,
        materialsResult
    };
};
