import { convertCsvToJsonl } from 'data-management/data-ingestion/convert-csv-to-jsonl';
import { jsonlFileToJsonArr } from 'data-management/data-ingestion/jsonl-file-to-json-arr';
import { showErrorMessage } from 'utils/show-error-message';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

export const getDataRowsFromFile = async (file: File | undefined) => {
    if (!file) return null;

    if (file.size > MAX_FILE_SIZE) {
        showErrorMessage('The imported file is too large. The current maximum file size is 5MB');
        return null;
    }

    const newFile = await convertCsvToJsonl(file);
    const data = await jsonlFileToJsonArr(newFile);

    return data;
};
