import { useMemo } from 'react';
import { Box, Group, Stack, rem, Text, StackProps } from '@mantine/core';
import { getLargestRemainder } from 'utils/get-largest-remainder';
import { sumArr } from 'utils/sum-arr';
import classes from './waffle-graph.module.css';

export type WaffleCell = {
    title: string;
    value: number;
    color: string;
};

const toRows = (arr: any[], numberOfRows = 10) => {
    const rowSize = arr.length / numberOfRows;
    const rows: any[][] = [];

    for (let index = 0; index < arr.length; index = index + rowSize) {
        rows.push(arr.slice(index, index + rowSize));
    }

    return rows;
};

export interface WaffleGraphProps extends StackProps {
    onHover?: (item: any) => void;
    active?: WaffleCell;
    data: WaffleCell[];
    title?: string;
    cellSize?: string;
}

export const WaffleGraph = ({ data, onHover, active, title, cellSize = '24px', ...rest }: WaffleGraphProps) => {
    const dataPoints = useMemo(() => {
        const points: WaffleCell[] = [];
        const total = sumArr(data, 'value');

        if (total === 0) {
            return [];
        }

        const relativeData = getLargestRemainder(
            data.map((item) => ({
                ...item,
                value: item.value > 0 ? (item.value / total) * 100 : 0
            })),
            100
        );

        relativeData.forEach((item) => {
            const current = Array.from({ length: item.value }).fill(item) as WaffleCell[];
            points.push(...current);
        });

        return toRows(points);
    }, [data]);

    const label = useMemo(() => {
        return data.map((current) => `${current.title} is ${current.value}%`).join(', ');
    }, [data]);

    if (dataPoints.length === 0) {
        return null;
    }

    return (
        <Stack align="center" gap="md">
            <Stack aria-label={label} align="center" gap={rem(4)} m="0 auto" {...rest}>
                {dataPoints.map((row, index) => (
                    <Group key={index} gap={rem(4)} align="center" justify="center">
                        {row.map((cell, innerIndex) => (
                            <Box
                                key={innerIndex}
                                onMouseOver={() => {
                                    if (onHover) onHover(cell);
                                }}
                                style={{
                                    '--size': cellSize
                                }}
                                bg={cell.color ?? '#111'}
                                data-active={active?.title === cell.title ? 'true' : 'false'}
                                className={classes.cell}
                            />
                        ))}
                    </Group>
                ))}
            </Stack>

            {title && (
                <Text size="lg" fw={500}>
                    {title}
                </Text>
            )}
        </Stack>
    );
};
