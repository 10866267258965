import { useMantineTheme } from '@mantine/core';
import { getEnvironmentalColor } from './get-environmetal-color';
import { getDefaultColor } from './get-default-color';

export const useScoreColor = () => {
    const theme = useMantineTheme();

    return (score: number, metric: string, inverted: boolean = false) => {
        const value = inverted ? 100 - score : score;

        return ['carbon_emissions', 'water_depletion', 'land_use'].includes(metric) 
            ? getEnvironmentalColor(value, theme) 
            : getDefaultColor(value, theme);
    };
};