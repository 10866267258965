import { ErrorAlert } from 'shared/error-alert';
import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import ScoreCircleGraph from 'shared/score-circle-graph';
import { Section } from 'shared/section';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Alert, Anchor, Badge, Box, Group, Skeleton, Stack, Text, rem, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { RangeSelect } from './range-select';

export const ListCard = ({
    title,
    count = 5,
    description,
    isLoading = false,
    error,
    variant = 'score',
    data
}: {
    count?: number;
    variant?: 'score' | 'count';
    isLoading?: boolean;
    error?: Error;
    title: string;
    description?: string;
    data?: {
        label: string;
        value: number;
        to?: string;
    }[];
}) => {
    const { t } = useTranslation();
    const isEmpty = data?.length === 0;
    const { colorScheme } = useMantineColorScheme();
    const theme = useMantineTheme();

    return (
        <Section
            style={{
                flex: '1 !important',
                minWidth: rem(300)
            }}
        >
            <Group mb="lg" justify="space-between" align="center" gap={0}>
                <Group gap={rem(2)}>
                    <Text fw={700} size="md">
                        {title}
                    </Text>

                    {description && (
                        <MoreInfoHoverCard>
                            <Text size="sm" c="dimmed">
                                {description}
                            </Text>
                        </MoreInfoHoverCard>
                    )}
                </Group>
                <RangeSelect />
            </Group>

            {error ? (
                <ErrorAlert error={error} />
            ) : isLoading ? (
                <Stack gap="lg" data-testid="loading">
                    {Array.from({ length: count }).map((_, index) => (
                        <Skeleton key={index} height={rem(20)} width="100%" />
                    ))}
                </Stack>
            ) : isEmpty ? (
                <Alert title={t('noDataAvailable')} color="orange" variant="light">
                    {t('noDataForPeriod')}
                </Alert>
            ) : (
                <Box
                    component="ul"
                    style={{
                        padding: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: 0
                    }}
                >
                    {data?.map((current, index) => {
                        const border = colorScheme === 'dark' ? '1px solid rgb(55 58 64 / 30%)' : '1px solid rgb(206 212 218 / 25%)';
                        return (
                            <Box
                                key={current.label}
                                component="li"
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    gap: theme.spacing.md,
                                    paddingTop: index === 0 ? 0 : theme.spacing.sm,
                                    paddingBottom: theme.spacing.sm,
                                    borderBottom: data.length - 1 === index ? 'none' : border
                                }}
                            >
                                <Group align="center" gap="xs">
                                    {current.to ? (
                                        <Anchor lineClamp={1} ta="left" underline="hover" c="dimmed" component={Link} to={current.to}>
                                            {current.label}
                                        </Anchor>
                                    ) : (
                                        <Text c="dimmed" lineClamp={1}>
                                            {current.label}
                                        </Text>
                                    )}
                                </Group>
                                {variant === 'score' && <ScoreCircleGraph percentage={Math.round(current.value)} size="xs" />}
                                {variant === 'count' && <Badge size="lg">{Math.round(current.value)}</Badge>}
                            </Box>
                        );
                    })}
                </Box>
            )}
        </Section>
    );
};
