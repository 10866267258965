export interface DqrDataPoints {
    product_category: string;
    product_total_weight: string;
    product_weight_breakdown: string;
    packaging_total_weight: string;
    packaging_weight_breakdown: string;
    product_material_names: string;
    packaging_material_names: string;
    country_of_manufacture: string;
    distribution_origin_city: string;
    distribution_destination_city: string;
    distribution_transport_mode: string;
    separability_of_product_materials: string;
    separability_of_packaging_materials: string;
    pvc_coatings_silicones_elatomers_in_product: string;
    pvc_coatings_silicones_elatomers_in_packaging: string;
    product_complexity_attestations: string;
    basic_warranty: string;
    extended_warranty: string;
    repair_and_maintenance_service: string;
    spare_parts: string;
    leasing_program: string;
    disposal_method: string;
    disposal_instructions_on_product: string;
    disposal_instructions_on_packaging: string;
    product_material_source_countries: string;
    packaging_material_source_countries: string;
    lca_data_product_materials_gwp: string;
    lca_data_packaging_gwp: string;
    lca_data_manufacturing_gwp: string;
    manufacturing_energy_value: string;
    manufacturing_energy_sources: string;
    manufacturing_water_value: string;
    manufacturing_water_sources: string;
    lca_data_product_alop: string;
    lca_data_packaging_alop: string;
    lca_data_manufacturing_alop: string;
    lca_data_product_wdp: string;
    lca_data_packaging_wdp: string;
    lca_data_manufacturing_wdp: string;
    documentation_living_wages: string;
    documentation_gender_wage_gap: string;
    documentation_working_hours: string;
    documentation_collective_bargaining: string;
    documentation_youth_worker_protection: string;
    documentation_workplace_safety: string;
    documentation_forced_labor: string;
    documentation_child_labor: string;
    ecolabels: string;
    extra_long_lifetime_attestation: string;
    impact_displacement: string;
    material_characteristics_recycled: string;
    material_characteristics_organic: string;
  }
  
  export const dqrDataPoints: DqrDataPoints = {
    product_category: 'Product Category',
    product_total_weight: 'Product Total Weight',
    product_weight_breakdown: 'Product Weight Breakdown',
    packaging_total_weight: 'Packaging Total Weight',
    packaging_weight_breakdown: 'Packaging Weight Breakdown',
    product_material_names: 'Product Material Names',
    packaging_material_names: 'Packaging Material Names',
    country_of_manufacture: 'Country of Manufacture',
    distribution_origin_city: 'Distribution Origin City',
    distribution_destination_city: 'Distribution Destination City',
    distribution_transport_mode: 'Distribution Transport Mode',
    separability_of_product_materials: 'Separability of Product Materials',
    separability_of_packaging_materials: 'Separability of Packaging Materials',
    pvc_coatings_silicones_elatomers_in_product: 'PVC/Coatings/Silicones/Elastomers in Product',
    pvc_coatings_silicones_elatomers_in_packaging: 'PVC/Coatings/Silicones/Elastomers in Packaging',
    product_complexity_attestations: 'Product Complexity Attestations',
    basic_warranty: 'Basic Warranty',
    extended_warranty: 'Extended Warranty',
    repair_and_maintenance_service: 'Repair & Maintenance Service',
    spare_parts: 'Spare Parts',
    leasing_program: 'Leasing Program',
    disposal_method: 'Disposal Method - Return to Producer or Not',
    disposal_instructions_on_product: 'Disposal Instructions on Product',
    disposal_instructions_on_packaging: 'Disposal Instructions on Packaging',
    product_material_source_countries: 'Product Material Source Countries',
    packaging_material_source_countries: 'Packaging Material Source Countries',
    lca_data_product_materials_gwp: 'LCA data: Product Materials GWP Value & Unit',
    lca_data_packaging_gwp: 'LCA data: Packaging GWP Value & Unit',
    lca_data_manufacturing_gwp: 'LCA data: Manufacturing GWP Value & Unit',
    manufacturing_energy_value: 'Manufacturing Energy Value & Unit',
    manufacturing_energy_sources: 'Manufacturing Energy Sources',
    manufacturing_water_value: 'Manufacturing Water Value & Unit',
    manufacturing_water_sources: 'Manufacturing Water Sources',
    lca_data_product_alop: 'LCA data: Product ALOP Value & Unit',
    lca_data_packaging_alop: 'LCA data: Packaging ALOP Value & Unit',
    lca_data_manufacturing_alop: 'LCA data: Manufacturing ALOP Value & Unit',
    lca_data_product_wdp: 'LCA data: Product WDP Value & Unit',
    lca_data_packaging_wdp: 'LCA data: Packaging WDP Value & Unit',
    lca_data_manufacturing_wdp: 'LCA data: manufacturing WDP Value & Unit',
    documentation_living_wages: 'Labor Policy Documentation - Living Wages',
    documentation_gender_wage_gap: 'Labor Policy Documentation - Gender Wage Gap',
    documentation_working_hours: 'Labor Policy Documentation - Working Hours',
    documentation_collective_bargaining: 'Labor Policy Documentation - Collective Bargaining',
    documentation_youth_worker_protection: 'Labor Policy Documentation - Youth Worker Protection',
    documentation_workplace_safety: 'Labor Policy Documentation - Workplace Safety',
    documentation_forced_labor: 'Labor Policy Documentation - Forced Labor',
    documentation_child_labor: 'Labor Policy Documentation - Child Labor',
    ecolabels: 'Ecolabels',
    extra_long_lifetime_attestation: 'Extra-long Lifetime Attestation',
    impact_displacement: 'Impact Displacement',
    material_characteristics_recycled: 'Material Characteristics - Recycled',
    material_characteristics_organic: 'Material Characteristics - Organic'
  };
  
 