import { Nullable } from 'types';
import { useProductDqrQuery } from './use-product-dqr-query';

const QUERY_NAME = 'product_dqr';

export type Result = {
    total_primary_dqr: Nullable<number>;
    circularity_dqr: Nullable<number>;
    climate_dqr: Nullable<number>;
    ecosystems_dqr: Nullable<number>;
    livelihoods_and_wellbeing_dqr: Nullable<number>;
    purpose_dqr: Nullable<number>;
    product_category: Nullable<number>;
    product_total_weight: Nullable<number>;
    product_weight_breakdown: Nullable<number>;
    packaging_total_weight: Nullable<number>;
    packaging_weight_breakdown: Nullable<number>;
    product_material_names: Nullable<number>;
    packaging_material_names: Nullable<number>;
    country_of_manufacture: Nullable<number>;
    distribution_origin_city: Nullable<number>;
    distribution_destination_city: Nullable<number>;
    distribution_transport_mode: Nullable<number>;
    separability_of_product_materials: Nullable<number>;
    separability_of_packaging_materials: Nullable<number>;
    pvc_coatings_silicones_elatomers_in_product: Nullable<number>;
    pvc_coatings_silicones_elatomers_in_packaging: Nullable<number>;
    product_complexity_attestations: Nullable<number>;
    basic_warranty: Nullable<number>;
    extended_warranty: Nullable<number>;
    repair_and_maintenance_service: Nullable<number>;
    spare_parts: Nullable<number>;
    leasing_program: Nullable<number>;
    disposal_method: Nullable<number>;
    disposal_instructions_on_product: Nullable<number>;
    disposal_instructions_on_packaging: Nullable<number>;
    product_material_source_countries: Nullable<number>;
    packaging_material_source_countries: Nullable<number>;
    lca_data_product_materials_gwp: Nullable<number>;
    lca_data_packaging_gwp: Nullable<number>;
    lca_data_manufacturing_gwp: Nullable<number>;
    manufacturing_energy_value: Nullable<number>;
    manufacturing_energy_sources: Nullable<number>;
    manufacturing_water_value: Nullable<number>;
    manufacturing_water_sources: Nullable<number>;
    lca_data_product_alop: Nullable<number>;
    lca_data_packaging_alop: Nullable<number>;
    lca_data_manufacturing_alop: Nullable<number>;
    lca_data_product_wdp: Nullable<number>;
    lca_data_packaging_wdp: Nullable<number>;
    lca_data_manufacturing_wdp: Nullable<number>;
    documentation_living_wages: Nullable<number>;
    documentation_gender_wage_gap: Nullable<number>;
    documentation_working_hours: Nullable<number>;
    documentation_collective_bargaining: Nullable<number>;
    documentation_youth_worker_protection: Nullable<number>;
    documentation_workplace_safety: Nullable<number>;
    documentation_forced_labor: Nullable<number>;
    documentation_child_labor: Nullable<number>;
    ecolabels: Nullable<number>;
    extra_long_lifetime_attestation: Nullable<number>;
    impact_displacement: Nullable<number>;
    material_characteristics_recycled: Nullable<number>;
    material_characteristics_organic: Nullable<number>;
};

export const useProductDqr = (productId?: string) => useProductDqrQuery<Result>(QUERY_NAME, productId);
