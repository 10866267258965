import useSWR from 'swr';
import { request } from 'lib/request-client';

type Response = {
    namespace_id: string;
    region: string;
}[];

export const useNamespaces = () => {
    const { data, error, mutate, isLoading } = useSWR<Response>(
        `/_api/internal/namespace/list`,
        () => request.url('/_api/internal/namespace/list', true).get() as Promise<Response>
    );
    return { data, error, mutate, isLoading };
};
