import { useTranslation } from 'react-i18next';
import { Alert, Button, Checkbox, Code, Group, Modal, Text } from '@mantine/core';

const getFormattedError = (errorMessage: string) => {
    try {
        if (!errorMessage.includes(':')) return <Text size="sm">{errorMessage}</Text>;
        const titleEndIndex = errorMessage.indexOf(':');
        const keyEndIndex = errorMessage.lastIndexOf(':');
        const title = errorMessage.slice(0, titleEndIndex);
        const error = errorMessage.slice(titleEndIndex + 1, keyEndIndex).trim();
        const key = errorMessage
            .slice(keyEndIndex + 1)
            .trim()
            .replace(/^[.$]+/, '');

        return (
            <Text size="sm">
                <strong>{title}</strong>: {error}{' '}
                {key ? (
                    <>
                        {' '}
                        (for field <Code>{key}</Code>)
                    </>
                ) : null}
            </Text>
        );
    } catch {
        return <Text size="sm">{errorMessage}</Text>;
    }
};

export const ValidationResultModal = ({
    file,
    isOpen,
    onClose,
    errors,
    onUpload
}: {
    file?: File | null;
    isOpen: boolean;
    onClose: () => void;
    errors: Array<string>;
    onUpload?: () => void;
}) => {
    const { t } = useTranslation();
    const isValid = errors.length === 0;
    if (!isOpen) return null;

    return (
        <Modal size="xl" title={t('validationResult')} opened={isOpen} onClose={onClose} p="lg">
            {isValid ? (
                <Alert title={t('successfullyValidated')} color="green" variant="light">
                    {file?.name ?? 'The current file'} is in a valid format and can be uploaded.
                </Alert>
            ) : (
                <>
                    <Alert title={`Validation check failed for ${file?.name ?? 'the current file'}`} color="red" variant="light" mb="sm">
                        Validation has failed for this file. Please correct listed errors and try again.
                    </Alert>

                    <>
                        {errors.map((current) => (
                            <Checkbox mb="sm" key={current} size="sm" defaultChecked={false} label={getFormattedError(current)} />
                        ))}
                    </>
                </>
            )}

            {isValid && onUpload && (
                <Group align="center" justify="flex-end" mt="lg">
                    <Button onClick={onUpload}>{t('proceedToUpload')}</Button>
                </Group>
            )}
        </Modal>
    );
};
