import { PARENT_NAMESPACE_KEY } from 'account-management/auth/use-auth';
import { useTranslation } from 'react-i18next';
import { Button, Group, Modal, Select, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { validation } from 'utils/validation';

export const SwitchOrganizationModal = ({
    isOpen,
    namespaces = [],
    onClose,
    authDomain
}: {
    isOpen: boolean;
    onClose: () => void;
    namespaces?: {
        name: string;
        namespace_id: string;
    }[];
    authDomain?: string;
}) => {
    const { t } = useTranslation();
    const currentNamespace = authDomain?.replaceAll('.', '-');
    const options = namespaces
        .filter((current) => current.namespace_id !== currentNamespace)
        .map((option) => ({
            label: option.name,
            value: option.namespace_id
        }));

    const form = useForm({
        initialValues: {
            namespace: ''
        },
        validate: {
            namespace: validation.required
        }
    });

    const handleSubmit = form.onSubmit((values) => {
        if (currentNamespace) {
            window.sessionStorage.setItem(PARENT_NAMESPACE_KEY, values.namespace);
            window.location.href = '/';
        }
    });

    return (
        <Modal size="sm" opened={isOpen} onClose={onClose} title={t('switchOrganization')} centered>
            <form onSubmit={handleSubmit}>
                <Stack gap="xs">
                    <Select withAsterisk data={options} label={t('organization')} {...form.getInputProps('namespace')} />
                </Stack>
                <Group mt="lg" justify="flex-end">
                    <Button type="submit">{t('switch')}</Button>
                </Group>
            </form>
        </Modal>
    );
};
