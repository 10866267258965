import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbX } from 'react-icons/tb';
import { ActionIcon, Badge, Group, Stack, TextInput, rem } from '@mantine/core';
import { isValidEmail } from 'utils/is-valid-email';

export const EmailWhitelist = ({ value, onChange }: { value: Array<string>; onChange: (value: Array<string>) => void }) => {
    const [inputValue, setInputValue] = useState('');
    const { t } = useTranslation();
    return (
        <Stack gap="xs">
            <TextInput
                label={t('emailWhitelist')}
                value={inputValue}
                type="email"
                onChange={(event) => setInputValue(event.currentTarget.value)}
                description={t('emailWhitelistDescription')}
                onKeyDown={(event) => {
                    if (event.key === 'Tab' || event.key === 'Enter') {
                        event.preventDefault();
                        if (isValidEmail(inputValue)) {
                            onChange([...value, inputValue]);
                            setInputValue('');
                        }
                    }
                }}
            />
            <Group gap="xs">
                {value.map((badgeValue) => (
                    <Badge
                        key={badgeValue}
                        style={{ padding: rem(2), textTransform: 'lowercase' }}
                        size="sm"
                        rightSection={
                            <ActionIcon
                                size="xs"
                                variant="transparent"
                                onClick={() => {
                                    onChange(value.filter((current) => current !== badgeValue));
                                }}
                            >
                                <TbX size={rem(14)} />
                            </ActionIcon>
                        }
                    >
                        {badgeValue}
                    </Badge>
                ))}
            </Group>
        </Stack>
    );
};
