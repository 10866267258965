import { useTableFilters } from 'shared/use-table-filters';
import useSWR from 'swr';
import { IngestedFile } from 'types';
import { useEffect } from 'react';
import { auth } from 'lib/firebase';
import { request } from 'lib/request-client';
import { uploadFile } from './upload-file';

export interface FileResponse {
    kind: string;
    page_token: string;
    cache_hit: boolean;
    size: number;
    current_items: number;
    result_datetime: string;
    filter: string;
    limit: number;
    order: string;
    items: IngestedFile[];
}

export const useIngestion = () => {
    const { query, onPageTokenMappingChange, limit, onLimitChange, page, onPage, onOrderChange, search, onSearchChange, order } =
        useTableFilters({
            searchKey: 'name'
        });

    const { data, error, mutate, isLoading } = useSWR<FileResponse>([`/ingest/files/query`, query]);

    useEffect(() => {
        if (data && !isLoading && data.page_token) {
            onPageTokenMappingChange((current) => ({
                ...current,
                [page + 1]: data.page_token
            }));
        }
    }, [data, isLoading, onPageTokenMappingChange, page]);

    const remove = async (id: string) => {
        await request.url(`/ingest/file/file/${id}`).delete();
        mutate();
    };

    const validate = async (file: File, fetcher = fetch) => {
        const token = await auth.currentUser?.getIdToken();
        const form = new FormData();
        form.set('file', file);
        const response = await fetcher('/api/ingest/file/validate/schema', {
            method: 'POST',
            body: form,
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        if (response.ok) return { detail: [] };
        return response.json() as Promise<{ detail: Array<string> }>;
    };

    return {
        data,
        error,
        upload: uploadFile,
        mutate,
        remove,
        validate,
        isLoading,
        limit,
        onLimitChange,
        order,
        onOrderChange,
        search,
        onSearchChange,
        page,
        onPage
    };
};
