import { useAuth } from 'account-management/auth/use-auth';
import { useOrganization } from 'account-management/organizations/use-organization';
import { CloudRegion } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextInput, Group, Stack, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { getAuthDomain } from 'utils/get-auth-domain';
import { showErrorMessage } from 'utils/show-error-message';
import { showSuccessMessage } from 'utils/show-success-message';
import { validation } from 'utils/validation';
import { countries } from './countries';
import { EmailWhitelist } from './email-whitelist';
import { useUser } from './use-user';

const regionOptions = ['US', 'EU', 'ASIA'];

export const CreateOrganizationForm = ({
    variant = 'user',
    onComplete
}: {
    variant?: 'user' | 'admin' | 'partner';
    onComplete: () => void;
}) => {
    const { t } = useTranslation();
    const { create } = useOrganization();
    const { user } = useAuth();
    const { organization } = useUser();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const form = useForm({
        initialValues: {
            adminEmail: variant === 'user' ? user?.email ?? '' : '',
            name: '',
            description: '',
            region: 'EU',
            addressStreet: '',
            addressNumber: '',
            addressZipcode: '',
            addressCity: '',
            addressCountry: '',
            vatNumber: '',
            website: '',
            userWhitelist: [] as Array<string>,
            financeEmail: '',
            contactEmail: '',
            phoneNumber: ''
        },
        validate: {
            name: validation.required,
            addressStreet: validation.required,
            addressNumber: validation.requiredNumber,
            addressZipcode: validation.required,
            addressCity: validation.required,
            addressCountry: validation.required,
            adminEmail: variant === 'user' ? undefined : validation.requiredEmail,
            website: validation.url,
            financeEmail: validation.email,
            contactEmail: validation.email
        }
    });

    const handleSubmit = form.onSubmit(async (values) => {
        try {
            setIsSubmitting(true);
            const adminEmail = variant === 'user' ? user?.email : values.adminEmail;
            if (!adminEmail) return;
            await create({
                auth_domain: getAuthDomain(adminEmail),
                name: values.name,
                description: values.description,
                region: values.region as CloudRegion,
                address: {
                    street: values.addressStreet,
                    number: values.addressNumber,
                    zipcode: values.addressZipcode,
                    city: values.addressCity,
                    country: values.addressCountry
                },
                type: variant === 'partner' ? 'Partner' : 'Main',
                vat_number: values.vatNumber,
                website: values.website,
                dashboard: '',
                parent_organization_id: variant === 'partner' ? organization?.key : undefined,
                user_whitelist: [...new Set([adminEmail, ...values.userWhitelist])],
                finance_email: values.financeEmail,
                contact_email: values.contactEmail,
                phone_number: values.phoneNumber
            });
            showSuccessMessage(t('organizationCreationInProgress'));
            onComplete();
        } catch (error) {
            showErrorMessage(error);
        } finally {
            setIsSubmitting(false);
        }
    });

    return (
        <form onSubmit={handleSubmit} noValidate>
            <Stack gap="xs">
                <TextInput withAsterisk label={t('organizationName')} {...form.getInputProps('name')} />
                <TextInput label={t('description')} {...form.getInputProps('description')} />
                <Select searchable withAsterisk label={t('region')} {...form.getInputProps('region')} data={regionOptions} />
                <TextInput withAsterisk label={t('streetNumber')} type="number" {...form.getInputProps('addressNumber')} />
                <TextInput withAsterisk label={t('streetAddress')} {...form.getInputProps('addressStreet')} />
                <TextInput withAsterisk label={t('zipCode')} {...form.getInputProps('addressZipcode')} />
                <TextInput withAsterisk label={t('city')} {...form.getInputProps('addressCity')} />
                <Select searchable withAsterisk label={t('country')} {...form.getInputProps('addressCountry')} data={countries} />
                <TextInput label={t('vatNumber')} {...form.getInputProps('vatNumber')} />
                <TextInput label={t('website')} type="url" {...form.getInputProps('website')} />
                {variant !== 'user' && (
                    <TextInput
                        withAsterisk
                        description="The organization's domain will be obtained from this email address."
                        label={t('adminEmail')}
                        type="email"
                        {...form.getInputProps('adminEmail')}
                    />
                )}
                <EmailWhitelist value={form.values.userWhitelist} onChange={(value) => form.setFieldValue('userWhitelist', value)} />
                <TextInput label={t('financeEmail')} type="email" {...form.getInputProps('financeEmail')} />
                <TextInput label={t('contactEmail')} type="email" {...form.getInputProps('contactEmail')} />
                <TextInput label={t('phoneNumber')} {...form.getInputProps('phoneNumber')} />
                <Group justify="flex-end" mt="md">
                    <Button loading={isSubmitting} type="submit">
                        {variant === 'partner' ? t('createPartnerOrganization') : t('createOrganization')}
                    </Button>
                </Group>
            </Stack>
        </form>
    );
};
