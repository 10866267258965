import { EolResult } from 'types';

export const formatEol = (current: EolResult) => {
    if (current.characteristic === null) {
        return {
            ...current,
            characteristic: 'nac'
        };
    }
    return current;
};
