import { useTranslation } from 'react-i18next';
import { Alert } from '@mantine/core';

export const NoDataAlert = () => {
    const { t } = useTranslation();
    return (
        <Alert title="No data found" color="orange" variant="light" my="lg">
            {t('noDataForInsightReport')}
        </Alert>
    );
};
