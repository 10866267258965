import { Filter, Ordering, QueryReturnResult } from 'types';
import { request } from 'lib/request-client';

const DEFAULT_TIMEOUT_IN_SECONDS = 30;
export const DEFAULT_MAX_RESULTS = 1000;

export type RunQueryConfig = {
    query?: string;
    name?: string;
    jobId?: string;
    dryRun?: boolean;
    pageSize?: number;
    pageToken?: string;
    params?: Filter[];
    orderByParams?: Ordering[];
};

export const runQuery = async ({
    name,
    query,
    params,
    jobId,
    orderByParams,
    pageToken,
    dryRun = false,
    pageSize = DEFAULT_MAX_RESULTS
}: RunQueryConfig) => {
    if (jobId) {
        return request
            .url(`/query_manager/query/execute/result/${jobId}`)
            .query({
                max_results: pageSize,
                page_size: pageSize
            })
            .get() as Promise<QueryReturnResult>;
    }

    if (!name && !query) {
        throw Error('Name or query string is required');
    }

    const body: {
        [key: string]: any;
    } = {
        store_query: false,
        dry_run: dryRun,
        max_results: pageSize,
        page_size: pageSize,
        timeout: DEFAULT_TIMEOUT_IN_SECONDS
    };

    if (params) {
        body.query_params = params;
    }

    if (orderByParams) {
        body.order_by_params = orderByParams;
    }

    if (pageToken) {
        body.page_token = pageToken;
    }

    if (name) {
        body.query_name = name;
    } else {
        body.query_str = query;
    }

    return request.url(`/query_manager/query/execute`).post(body) as Promise<QueryReturnResult>;
};
