import { MutableRefObject, useCallback } from 'react';

export interface UseComponentToPdfOptions {
    filename?: string;
    align?: 'top' | 'center' | 'bottom' | number;
    onComplete?: () => void;
    delay?: number;
}

const defaultOptions = {
    filename: 'file.pdf',
    align: 'top',
    onComplete: undefined,
    padding: 16,
    delay: 0
};

const downloadPdf = (bytes: Uint8Array, filename: string) => {
    const blob = new Blob([bytes], {
        type: 'application/pdf'
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    // the filename you want
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
};

export const useComponentToPdf = (
    target: MutableRefObject<any> | MutableRefObject<any>[],
    options?: UseComponentToPdfOptions
): ((currentOptions?: UseComponentToPdfOptions) => Promise<void>) => {
    const toPdf = useCallback(
        async (currentOptions?: UseComponentToPdfOptions) => {
            const combinedOptions = {
                ...defaultOptions,
                ...options,
                ...currentOptions
            };

            const { filename, onComplete, align, padding, delay } = combinedOptions;
            const sources = Array.isArray(target) ? target : [target];
            const { PDFDocument } = await import('pdf-lib');
            const pdfDoc = await PDFDocument.create();

            await new Promise((resolve) => {
                setTimeout(async () => {
                    for (let i = 0; i < sources.length; i++) {
                        const target = sources[i];

                        if (!target.current) {
                            throw new Error('useComponentToPdf: Invalid ref provided.');
                        }
                        const { default: html2canvas } = await import('html2canvas');
                        const canvas = await html2canvas(target.current, {
                            logging: false,
                            useCORS: true
                        });

                        const page = pdfDoc.addPage([canvas.width / 2, canvas.height / 2]);
                        const { width, height } = page.getSize();

                        const imgData = canvas.toDataURL();

                        const image = await pdfDoc.embedPng(imgData);

                        let imageDims = image.size();

                        if (imageDims.width > width || imageDims.height > height) {
                            imageDims = image.scaleToFit(width, height);
                        }

                        const y =
                            typeof align === 'number'
                                ? align
                                : align === 'top'
                                  ? height - imageDims.height
                                  : align === 'bottom'
                                    ? 0
                                    : height / 2 - imageDims.height / 2;

                        page.drawImage(image, {
                            x: width / 2 - imageDims.width / 2 + padding,
                            y: y + padding,
                            width: imageDims.width - padding * 2,
                            height: imageDims.height - padding * 2
                        });
                    }

                    const pdfBytes = await pdfDoc.save();
                    downloadPdf(pdfBytes, filename);
                    if (onComplete) onComplete();
                    resolve(undefined);
                }, delay);
            });
        },
        [options, target]
    );

    return toPdf;
};
