import { Badge, Tooltip } from '@mantine/core';

export const PreviewBadge = () => {
    return (
        <Tooltip label="This feature is in preview and might be subject to change">
            <Badge variant="light" size="sm">
                Preview
            </Badge>
        </Tooltip>
    );
};
