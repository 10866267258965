import { ErrorAlert } from 'shared/error-alert';
import { useUser } from 'shared/use-user';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbAlertCircle } from 'react-icons/tb';
import { Alert, Button, Group, Select, Text } from '@mantine/core';
import { showErrorMessage } from 'utils/show-error-message';
import { showSuccessMessage } from 'utils/show-success-message';
import { useNamespaceUsers } from './use-namespace-users';

export const CreateImpersonationForm = ({ namespace, onComplete }: { namespace: string | null; onComplete: () => void }) => {
    const [value, setValue] = useState<null | string>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t } = useTranslation();
    const { activeUser } = useUser();

    const { users, isLoading, error, createUserImpersonation, deleteUserImpersonation, impersonations } = useNamespaceUsers(namespace);

    const matchingImpersonation = useMemo(() => {
        if (!impersonations || !activeUser) return false;
        return impersonations.items.find((impersonation) => impersonation.user_keys.includes(activeUser.key));
    }, [impersonations, activeUser]);

    const options = useMemo(() => {
        if (!users) return [];
        return users.items.map((current) => ({
            label: current.email,
            value: current.key
        }));
    }, [users]);

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            await createUserImpersonation({
                impersonated_user_key: value!,
                namespace_id: namespace!
            });
            onComplete();
            showSuccessMessage(t('impersonationCreated'));
        } catch (error) {
            showErrorMessage(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDelete = async () => {
        try {
            setIsSubmitting(true);
            if (!matchingImpersonation || !activeUser) return;
            await deleteUserImpersonation({
                namespace_id: namespace!,
                impersonated_user_key: matchingImpersonation.impersonated_user_key,
                user_key: activeUser.key
            });
            onComplete();
            showSuccessMessage(t('impersonationDeleted'));
        } catch (error) {
            showErrorMessage(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        const isValid = options.some((current) => current.value === value);
        if (!isValid) {
            setValue(null);
        }
    }, [options, value]);

    const hasExistingImpersonation = !isLoading && !!matchingImpersonation;
    const hasNoOptions = !isLoading && !options.length;

    if (error) return <ErrorAlert error={error} />;

    if (hasNoOptions) {
        return (
            <Alert icon={<TbAlertCircle size={20} />} title={t('noUsersAvailable')} color="red">
                {t('noUsersAvailableDescription')}
            </Alert>
        );
    }

    if (hasExistingImpersonation) {
        return (
            <>
                <Alert icon={<TbAlertCircle size={20} />} title={t('alreadyImpersonation')} color="red">
                    {t('alreadyImpersonationDescription')}
                </Alert>
                <Group mt="lg" justify="flex-end">
                    <Button type="button" color="red" loading={isSubmitting} onClick={handleDelete}>
                        {t('deleteImpersonation')}
                    </Button>
                </Group>
            </>
        );
    }

    return (
        <>
            <Text size="sm" c="dimmed" mb="sm">
                Create a new impersonation for <strong>{namespace}</strong>. Once created you will be able to impersonation the user.
            </Text>
            <Select
                comboboxProps={{ position: 'bottom', withinPortal: true }}
                data={options}
                disabled={isLoading}
                label={t('email')}
                placeholder={t('email')}
                onChange={setValue}
                value={value}
            />
            <Group mt="lg" justify="flex-end">
                <Button
                    onClick={handleSubmit}
                    data-testid={isLoading ? 'loading' : undefined}
                    disabled={!value}
                    loading={isLoading || isSubmitting}
                >
                    {t('createImpersonation')}
                </Button>
            </Group>
        </>
    );
};
