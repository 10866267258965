import { BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, Bar } from 'recharts';
import { ErrorAlert } from 'shared/error-alert';
import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import { Section } from 'shared/section';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Group, Skeleton, Text, rem, useMantineColorScheme, useMantineTheme } from '@mantine/core';

export const BarChartCard = ({
    title,
    description,
    isLoading = false,
    tooltipLabel,
    error,
    data,
    filters
}: {
    tooltipLabel?: string;
    isLoading?: boolean;
    error?: Error;
    title: string;
    description?: string;
    data?: {
        label: string;
        value: number;
    }[];
    filters?: ReactNode;
}) => {
    const { t } = useTranslation();
    const theme = useMantineTheme();
    const isEmpty = data?.length === 0;

    const { colorScheme } = useMantineColorScheme();

    const textColor = colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.dark[7];
    const gridColor = colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.dark[0];

    return (
        <Section
            shadow="md"
            style={{
                flex: '1 !important',
                minWidth: rem(300),
                height: rem(348)
            }}
        >
            <Group justify="space-between" mb="xl" gap={0}>
                <Group gap={rem(2)}>
                    <Text fw={700} size="md">
                        {title}
                    </Text>
                    {description && (
                        <MoreInfoHoverCard>
                            <Text size="sm" c="dimmed">
                                {description}
                            </Text>
                        </MoreInfoHoverCard>
                    )}
                </Group>

                {filters}
            </Group>

            {error ? (
                <ErrorAlert error={error} />
            ) : isLoading ? (
                <Skeleton width="100%" height={260} data-testid="loading" />
            ) : isEmpty ? (
                <Alert title={t('noDataAvailable')} color="orange" variant="light">
                    {t('noDataForPeriod')}
                </Alert>
            ) : (
                <ResponsiveContainer width="100%" height={260}>
                    <BarChart
                        width={520}
                        data={data}
                        margin={{
                            left: 8
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" stroke={gridColor} />
                        <XAxis dataKey="label" axisLine={false} tickLine={false} fontSize={12} stroke={textColor} />
                        <YAxis axisLine={false} tickLine={false} fontSize={12} stroke={textColor} width={48} />
                        {tooltipLabel && (
                            <Tooltip
                                cursor={false}
                                content={({ active, payload }) => {
                                    if (active && payload && payload.length) {
                                        return (
                                            <Section shadow="md">
                                                <Text>
                                                    {tooltipLabel}: <strong>{payload[0].value}</strong>
                                                </Text>
                                            </Section>
                                        );
                                    }

                                    return null;
                                }}
                            />
                        )}
                        <Bar barSize={20} dataKey="value" fill={theme.colors.blue[4]} radius={[4, 4, 0, 0]} />
                    </BarChart>
                </ResponsiveContainer>
            )}
        </Section>
    );
};
