import { NAMESPACE_STORAGE_KEY, PARENT_NAMESPACE_KEY } from 'account-management/auth/use-auth';
import wretch, { ConfiguredMiddleware } from 'wretch';
import FormDataAddon from 'wretch/addons/formData';
import QueryStringAddon from 'wretch/addons/queryString';
import { dedupe } from 'wretch/middlewares';
import { auth } from './firebase';

export const authMiddleware: ConfiguredMiddleware = (next) => async (url, opts) => {
    const token = await auth.currentUser?.getIdToken();
    if (token && !opts.headers?.Authorization) {
        opts.headers = {
            ...opts.headers,
            Authorization: `Bearer ${token}`
        };
    }
    const namespaceId = sessionStorage.getItem(NAMESPACE_STORAGE_KEY);
    if (namespaceId) {
        opts.headers = {
            ...opts.headers,
            'X-Active-Namespace-Id': namespaceId
        };
    }
    const parentNamespace = sessionStorage.getItem(PARENT_NAMESPACE_KEY);
    if (parentNamespace) {
        opts.headers = {
            ...opts.headers,
            'X-Parent-Org-Namespace-Id': parentNamespace
        };
    }

    return next(url, opts);
};

export const request = wretch('/api')
    .middlewares([authMiddleware, dedupe()])
    .addon(QueryStringAddon)
    .addon(FormDataAddon)
    .content('application/json')
    .errorType('json')
    .resolve((r) => r.json());
