import { CreateOrganizationForm } from 'shared/create-organization-form';
import { Section } from 'shared/section';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Text, Title } from '@mantine/core';

export const CreateOrganization = () => {
    const navigate = useNavigate();
    const { type } = useParams<{ type: 'admin' | 'partner' }>();
    const { t } = useTranslation();

    return (
        <>
            <Title size="h3">{type === 'partner' ? t('createPartnerOrganization') : t('createOrganization')}</Title>
            <Text c="dimmed" size="sm" mb="md">
                {type === 'partner' ? t('createPartnerOrganizationDescription') : t('createOrganizationDescription')}
            </Text>
            <Section>
                <CreateOrganizationForm variant={type} onComplete={() => navigate(0)} />
            </Section>
        </>
    );
};
