import { links } from 'links';
import { useTranslation } from 'react-i18next';
import { TbCode, TbFileText, TbHelp, TbPhoneCall } from 'react-icons/tb';
import { ActionIcon, Anchor, Drawer, Group, List, Text, ThemeIcon } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

export const HelpMenu = ({ initialOpen = false }: { initialOpen?: boolean }) => {
    const [opened, { open, close }] = useDisclosure(initialOpen);
    const { t } = useTranslation();

    const options = [
        {
            label: t('contactSupport'),
            href: links.contactSupport,
            icon: <TbPhoneCall size={20} />
        },
        {
            label: t('browseDocumentation'),
            href: links.customerService.main,
            icon: <TbFileText size={20} />
        },
        {
            label: t('apiDocumentation'),
            href: '/docs',
            icon: <TbCode size={20} />
        }
    ];

    return (
        <>
            <Drawer
                padding="md"
                styles={{
                    title: {
                        fontWeight: 700
                    },
                    content: {
                        display: 'flex',
                        flexDirection: 'column'
                    },
                    body: {
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column'
                    },
                    header: {
                        backgroundColor: 'transparent'
                    }
                }}
                opened={opened}
                onClose={close}
                title={t('help')}
                position="right"
                size="sm"
                overlayProps={{ opacity: 0.2, blur: 4 }}
            >
                <List spacing="sm" size="sm" mt="lg" center style={{ flex: 1 }}>
                    {options.map((option) => (
                        <List.Item key={option.href} icon={<ThemeIcon variant="light">{option.icon}</ThemeIcon>}>
                            <Anchor c="inherit" component="a" target="_blank" href={option.href}>
                                {option.label}
                            </Anchor>
                        </List.Item>
                    ))}
                </List>
                {import.meta.env.VITE_VERSION && (
                    <Group justify="flex-end">
                        <Text size="xs" fw={500} c="dimmed">
                            v{import.meta.env.VITE_VERSION}
                        </Text>
                    </Group>
                )}
            </Drawer>

            <ActionIcon onClick={open} variant="default" size={32}>
                <TbHelp size={20} />
            </ActionIcon>
        </>
    );
};
