import clipboard from 'clipboardy';
import { DataRow, createEmptyRow } from './create-empty-row';

export const getPasteUpdatedRows = async (rows: DataRow[], rowIdx: number, column: { key: string }) => {
    const text = await clipboard.read();
    if (!text) return rows;
    let updatedRows = [...rows];
    text.split('\n').forEach((value: string, index) => {
        const targetIndex = rowIdx + index;
        if (!rows[targetIndex]) {
            updatedRows = [...updatedRows, createEmptyRow(targetIndex + 1)];
        }
        const updatedRow = {
            ...updatedRows[targetIndex],
            [column.key]: value
        };
        updatedRows[targetIndex] = updatedRow;
    });
    return updatedRows;
};
