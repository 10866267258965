import { useOrganization } from 'account-management/organizations/use-organization';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group, Modal, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { showErrorMessage } from 'utils/show-error-message';
import { showSuccessMessage } from 'utils/show-success-message';
import { validation } from 'utils/validation';

export const AddToWhitelistModal = ({
    currentWhitelist,
    organizationId,
    isOpen,
    onComplete,
    onClose
}: {
    organizationId?: string;
    currentWhitelist: Array<string>;
    isOpen: boolean;
    onComplete: () => void;
    onClose: () => void;
}) => {
    const { t } = useTranslation();
    const [isSubmitting, setSubmitting] = useState(false);
    const { addToWhitelist, mutate } = useOrganization(organizationId);

    const form = useForm({
        initialValues: {
            email: ''
        },
        validate: {
            email: validation.requiredEmail
        }
    });

    const handleSubmit = form.onSubmit(async (values) => {
        if (currentWhitelist.includes(values.email)) {
            notifications.show({ message: t('emailAlreadyWhitelisted'), color: 'red' });
            return;
        }

        try {
            setSubmitting(true);
            await addToWhitelist(values.email);
            await mutate();
            showSuccessMessage(t('emailAddedToWhitelist'));
            onComplete();
            onClose();
            form.reset();
        } catch (error) {
            showErrorMessage(error);
        } finally {
            setSubmitting(false);
        }
    });

    return (
        <Modal size="md" opened={isOpen} onClose={onClose} title={t('addUserToWhitelist')} centered>
            <form onSubmit={handleSubmit}>
                <Stack gap="xs">
                    <TextInput withAsterisk label="Email" type="email" {...form.getInputProps('email')} />
                </Stack>
                <Group mt="lg" justify="flex-end">
                    <Button type="submit" loading={isSubmitting}>
                        {t('addUser')}
                    </Button>
                </Group>
            </form>
        </Modal>
    );
};
