import { PermissionError } from 'account-management/auth/permission-error';
import { useAuth } from 'account-management/auth/use-auth';
import { StatusCodes } from 'http-status-codes';
import { paths } from 'paths';
import { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbChevronLeft, TbMenu2, TbSearch } from 'react-icons/tb';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ActionIcon, AppShell, Burger, Container, Group, rem, useComputedColorScheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { nprogress } from '@mantine/nprogress';
import { Spotlight, SpotlightActionData, spotlight } from '@mantine/spotlight';
import { FlagName } from 'utils/get-flag-value';
import { EnvironmentBadge } from './environment-badge';
import { HelpMenu } from './help-menu';
import { LogoIcon } from './logo-icon';
import { MainLinks } from './main-links';
import { NamespaceBadge } from './namespace-badge';
import { useFeatureEnabled } from './use-feature-enabled';
import { useOrganizationId } from './use-organization-id';
import { useUser } from './use-user';
import { UserMenu } from './user-menu';

const HEADER_HEIGHT = '60px';
const MENU_KEY = 'menu-open';

export const Layout = () => {
    const location = useLocation();
    const { isEnabled } = useFeatureEnabled();
    const computedColorScheme = useComputedColorScheme('light');
    const { t } = useTranslation();
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');
    const [opened, setOpened] = useState(false);
    const { isMultifactorRequired } = useAuth();
    const { hasPermission, isDayrize, error, isLoading, activeUser } = useUser();
    const navigate = useNavigate();
    const organizationId = useOrganizationId();

    const actions = [
        hasPermission('/query_manager/query/execute', 'POST') && {
            id: 'dashboard',
            label: t('dashboard'),
            description: t('dashboardDescription'),
            onClick: () => navigate(paths.dashboard(organizationId))
        },
        hasPermission('/ingest/files/query') && {
            id: 'ingest',
            label: t('dataIngestion'),
            description: t('dataIngestionDescription'),
            onClick: () => navigate(paths.dataManagement.ingest(organizationId))
        },
        isDayrize &&
            hasPermission('/ingest/schemas/product') && {
                id: 'product-upload',
                label: t('singleProductUpload'),
                description: t('singleProductUploadDescription'),
                to: paths.customerSuccess.singleProductUpload(organizationId)
            },
        hasPermission('/query_manager/query/execute', 'POST') && {
            id: 'auditLog',
            label: t('auditLog'),
            description: t('auditLogDescription'),
            onClick: () => navigate(paths.accountManagement.auditLog(organizationId))
        },
        isEnabled(FlagName.BulkImportEdit) &&
            hasPermission('/query_manager/query/execute', 'POST') &&
            !isSmallDevice && {
                id: 'bulk',
                label: t('bulkImportAndEdit'),
                description: t('modifyAndUploadMultipleProducts'),
                onClick: () => navigate(paths.dataManagement.bulkImportEdit(organizationId))
            },
        hasPermission('/') &&
            isDayrize && {
                id: 'namespaces',
                label: t('namespaces'),
                description: t('namespacesDescription'),
                onClick: () => navigate(paths.customerSuccess.namespaces(organizationId))
            },
        hasPermission('/account_manager/users/query') && {
            id: 'users',
            label: t('users'),
            description: t('usersDescription'),
            onClick: () => navigate(paths.accountManagement.users(organizationId))
        },
        hasPermission('/query_manager/queries/query') && {
            id: 'queries',
            label: t('queries'),
            description: t('queriesDescription'),
            onClick: () => navigate(paths.queries(organizationId))
        },
        hasPermission('/query_manager/queries/query') && {
            id: 'products',
            label: t('products'),
            description: t('productsDescription'),
            onClick: () => navigate(paths.products(organizationId))
        },
        hasPermission('/account_manager/groups/query') && {
            id: 'groups',
            label: t('groups'),
            description: t('groupsDescription'),
            onClick: () => navigate(paths.accountManagement.groups(organizationId))
        },
        isEnabled(FlagName.Prism) &&
            hasPermission('/query_manager/query/execute', 'POST') && {
                id: 'prism',
                label: t('productAndRangeImpactSimulationModel'),
                description: t('productAndRangeImpactSimulationModelDescription'),
                onClick: () => navigate(paths.insights.prism(organizationId))
            }
    ].filter((current) => !!current) as SpotlightActionData[];

    const isInitialized = activeUser && !isLoading && !error;

    useEffect(() => {
        if (!isInitialized) {
            nprogress.start();
        } else {
            nprogress.stop();
            nprogress.reset();
        }
        return () => {
            nprogress.stop();
            nprogress.reset();
        };
    }, [isInitialized]);

    useEffect(() => {
        if (isMultifactorRequired && organizationId && !location.pathname.includes('user-settings')) {
            navigate(paths.userSettings(organizationId));
        }
    }, [isMultifactorRequired, navigate, organizationId, location.pathname]);

    useEffect(() => {
        if (isSmallDevice !== undefined) {
            const menuOpenStorage = window.sessionStorage.getItem(MENU_KEY);
            const isInitialOpen = isSmallDevice ? false : menuOpenStorage ? menuOpenStorage === 'true' : true;
            setOpened(isInitialOpen);
        }
    }, [isSmallDevice]);

    const handleToggleOpen = () => {
        const isOpen = !opened;
        window.sessionStorage.setItem(MENU_KEY, isOpen.toString());
        setOpened(isOpen);
    };

    const isBulkImportEdit = location.pathname.includes('/bulk');
    const isImpactSimulator = location.pathname.includes('/impact-simulator');

    const shouldCollapse = isBulkImportEdit || isImpactSimulator;

    useEffect(() => {
        if (shouldCollapse) {
            setOpened(false);
        }
    }, [shouldCollapse]);

    if (error?.status && [StatusCodes.FORBIDDEN, StatusCodes.UNAUTHORIZED].includes(error.status)) return <PermissionError />;

    return (
        <>
            <Spotlight
                actions={actions}
                shortcut="mod + K"
                nothingFound={t('nothingFound')}
                highlightQuery
                searchProps={{
                    leftSection: <TbSearch size={24} />,
                    placeholder: t('globalSearch')
                }}
            />
            <AppShell
                padding={{ base: 'sm', sm: 'md', lg: 'xl' }}
                header={{ height: HEADER_HEIGHT }}
                navbar={{
                    width: { base: 230, md: 260, xl: 280 },

                    breakpoint: 'sm',
                    collapsed: { mobile: !opened, desktop: !opened }
                }}
                styles={(theme) => ({
                    main: {
                        backgroundColor: computedColorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                        height: `calc(100vh - ${HEADER_HEIGHT})`
                    }
                })}
            >
                <AppShell.Header data-testid="header">
                    <Group h="100%" px={20} py={4} justify="space-between">
                        <Group gap="xs">
                            {isSmallDevice ? (
                                <Burger opened={opened} onClick={handleToggleOpen} size="sm" />
                            ) : (
                                <ActionIcon data-testid="toggle-menu" variant="default" onClick={handleToggleOpen}>
                                    {opened ? <TbChevronLeft size={16} /> : <TbMenu2 size={16} />}
                                </ActionIcon>
                            )}

                            <LogoIcon />
                            <NamespaceBadge />
                            <EnvironmentBadge />
                        </Group>

                        <Group gap="xs">
                            <ActionIcon
                                data-testid="search"
                                disabled={!isInitialized || isMultifactorRequired}
                                variant="default"
                                size={32}
                                onClick={() => spotlight.open()}
                            >
                                <TbSearch size={20} />
                            </ActionIcon>
                            <HelpMenu />
                        </Group>
                    </Group>
                </AppShell.Header>

                <AppShell.Navbar p={0} hidden={!opened}>
                    <AppShell.Section grow py={rem(8)} style={{ height: '100%', overflowY: 'auto' }}>
                        <MainLinks
                            onClick={() => {
                                if (isSmallDevice) {
                                    setOpened(false);
                                }
                            }}
                        />
                    </AppShell.Section>
                    <AppShell.Section>
                        <UserMenu />
                    </AppShell.Section>
                </AppShell.Navbar>

                <AppShell.Main>
                    <Container size={shouldCollapse ? '100%' : 'xl'} p={0} pb="lg">
                        <Suspense fallback={null}>{isInitialized && <Outlet />}</Suspense>
                    </Container>
                </AppShell.Main>
            </AppShell>
        </>
    );
};
