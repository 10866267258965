import { useOrganization } from 'account-management/organizations/use-organization';
import { EmailWhitelist } from 'shared/email-whitelist';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group, Modal, Skeleton, Stack, TextInput, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { getErrorMessage } from 'utils/get-error-message';
import { showErrorMessage } from 'utils/show-error-message';
import { showSuccessMessage } from 'utils/show-success-message';
import { validation } from 'utils/validation';

export const OrganizationModal = ({ id, isOpen, onClose }: { id?: string; isOpen: boolean; onClose: () => void }) => {
    const { data, update, isLoading, mutate, error } = useOrganization(id);
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const form = useForm({
        initialValues: {
            name: '',
            description: '',
            addressStreet: '',
            addressNumber: '',
            addressZipcode: '',
            addressCity: '',
            addressCountry: '',
            region: '',
            vatNumber: '',
            website: '',
            userWhitelist: [] as Array<string>,
            financeEmail: '',
            contactEmail: '',
            phoneNumber: ''
        },
        validate: {
            addressStreet: validation.required,
            addressNumber: validation.required,
            addressZipcode: validation.required,
            addressCity: validation.required,
            addressCountry: validation.required
        }
    });

    const handleSubmit = form.onSubmit(async (values) => {
        try {
            setIsSubmitting(true);
            await update({
                description: values.description,
                address: {
                    street: values.addressStreet,
                    number: values.addressNumber,
                    zipcode: values.addressZipcode,
                    city: values.addressCity,
                    country: values.addressCountry
                },
                vat_number: values.vatNumber,
                website: values.website,
                finance_email: values.financeEmail,
                contact_email: values.contactEmail,
                phone_number: values.phoneNumber,
                user_whitelist_add: values.userWhitelist.filter((current) => !data?.user_whitelist.includes(current)),
                user_whitelist_remove: data?.user_whitelist.filter((current) => !values.userWhitelist.includes(current))
            });
            showSuccessMessage(t('organizationUpdated'));
            mutate();
            onClose();
        } catch (error) {
            showErrorMessage(error);
        } finally {
            setIsSubmitting(false);
        }
    });

    useEffect(() => {
        if (data) {
            const values = {
                name: data.name ?? '',
                description: data.description ?? '',
                addressStreet: data.address.street ?? '',
                addressNumber: data.address.number ?? '',
                addressZipcode: data.address.zipcode ?? '',
                addressCity: data.address.city ?? '',
                addressCountry: data.address.country ?? '',
                region: data.region ?? '',
                authDomain: data.auth_domain ?? '',
                vatNumber: data.vat_number ?? '',
                website: data.website ?? '',
                userWhitelist: data.user_whitelist ?? [],
                financeEmail: data.finance_email ?? '',
                contactEmail: data.contact_email ?? '',
                phoneNumber: data.phone_number ?? ''
            };
            form.setValues(values);
            form.resetDirty(values);
        }
    }, [data]);

    return (
        <Modal size="lg" title={t('manageOrganization')} opened={isOpen} onClose={onClose}>
            {error ? (
                <Title c="red" ta="center">
                    {getErrorMessage(error)}
                </Title>
            ) : (
                <form onSubmit={handleSubmit}>
                    {isLoading ? (
                        <Skeleton height={500} />
                    ) : (
                        <Stack gap="xs">
                            <TextInput disabled label={t('name')} {...form.getInputProps('name')} />
                            <TextInput disabled label={t('authDomain')} {...form.getInputProps('authDomain')} />
                            <TextInput disabled label={t('region')} {...form.getInputProps('region')} />
                            <TextInput label={t('description')} {...form.getInputProps('description')} />
                            <TextInput withAsterisk label={t('streetAddress')} {...form.getInputProps('addressStreet')} />
                            <TextInput withAsterisk label={t('streetNumber')} {...form.getInputProps('addressNumber')} />
                            <TextInput withAsterisk label={t('zipCode')} {...form.getInputProps('addressZipcode')} />
                            <TextInput withAsterisk label={t('city')} {...form.getInputProps('addressCity')} />
                            <TextInput withAsterisk label={t('country')} {...form.getInputProps('addressCountry')} />
                            <TextInput label={t('vatNumber')} {...form.getInputProps('vatNumber')} />
                            <TextInput label={t('website')} type="url" {...form.getInputProps('website')} />

                            <EmailWhitelist
                                value={form.values.userWhitelist}
                                onChange={(value) => form.setFieldValue('userWhitelist', value)}
                            />
                            <TextInput label={t('financeEmail')} type="email" {...form.getInputProps('financeEmail')} />
                            <TextInput label={t('contactEmail')} type="email" {...form.getInputProps('contactEmail')} />
                            <TextInput label={t('phoneNumber')} {...form.getInputProps('phoneNumber')} />
                            <Group justify="flex-end" mt="md">
                                <Button loading={isSubmitting} type="submit">
                                    {t('update')}
                                </Button>
                            </Group>
                        </Stack>
                    )}
                </form>
            )}
        </Modal>
    );
};
