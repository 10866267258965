import { Dimension } from 'types/shared';
import { ScoreReportLayout } from './score-report-layout';
import { SummaryCard } from './summary-card';

export const Summary = () => {
    return (
        <ScoreReportLayout dimension={Dimension.Total}>
            <SummaryCard />
        </ScoreReportLayout>
    );
};
