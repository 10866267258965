import { Dimension } from 'types/shared';
import { DataQualityCards } from './data-quality-cards';
import { ScoreReportLayout } from './score-report-layout';

export const DataQuality = () => {
    return (
        <ScoreReportLayout dimension={Dimension.DataQualityRating}>
            <DataQualityCards />
        </ScoreReportLayout>
    );
};
