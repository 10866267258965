export const toFixedIfNeeded = (value: string | number | null, fractionDigits = 5) => {
    if (value === null) return '-';
    const val = typeof value === 'number' ? value.toString() : value;
    if (val.includes('.') && val.split('.')?.[1].length > fractionDigits) {
        return Number.parseFloat(val)
            .toFixed(fractionDigits + 1)
            .slice(0, -1);
    }
    return val;
};
