import dayjs from 'dayjs';
import { ConfirmationModal } from 'shared/confirmation-modal';
import { ErrorCard } from 'shared/error-card';
import { Section } from 'shared/section';
import { StaticTable } from 'shared/static-table';
import { useUser } from 'shared/use-user';
import { CreateApiKeyResult } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbCheck, TbCopy, TbDotsVertical } from 'react-icons/tb';
import { ActionIcon, Badge, Button, CopyButton, Group, Menu, Text, Title } from '@mantine/core';
import { getIdFromKey } from 'utils/get-id-from-key';
import { showErrorMessage } from 'utils/show-error-message';
import { showSuccessMessage } from 'utils/show-success-message';
import { CreateApiKeyModal } from './create-api-key-modal';
import { SecretModal } from './secret-modal';
import { useApiKeys } from './use-api-keys';

const obfuscateApiKey = (apiKey: string) => {
    let visibleStart = apiKey.substring(0, 4);
    let visibleEnd = apiKey.substring(apiKey.length - 4);
    let obfuscatedMiddle = '*'.repeat(apiKey.length - 8);
    return visibleStart + obfuscatedMiddle + visibleEnd;
};

export const ApiKeys = () => {
    const [createResult, setCreateResult] = useState<CreateApiKeyResult | null>(null);
    const { t } = useTranslation();
    const { hasPermission } = useUser();
    const { error, remove, data, isLoading } = useApiKeys();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [id, setId] = useState<undefined | string>();

    if (error) {
        return <ErrorCard error={error} />;
    }

    const handleDeleteKeyConfirm = async () => {
        if (!id) return;
        try {
            await remove(id);
            showSuccessMessage(t('apiKeyDeleted'));
            setShowDeleteModal(false);
            setId(undefined);
        } catch (error) {
            showErrorMessage(error);
        }
    };

    return (
        <>
            <Title size="h3">{t('apiKeys')}</Title>
            <Text c="dimmed" mb="md">
                {t('apiKeysDescription')}
            </Text>

            <Section>
                <StaticTable
                    isLoading={isLoading}
                    actions={
                        hasPermission('/account_manager/key_management/create', 'POST') && (
                            <Button onClick={() => setShowCreateModal(true)}>{t('createApiKey')}</Button>
                        )
                    }
                    data={data}
                    rowKey={(row) => row.key}
                    columns={[
                        {
                            key: 'name',
                            name: t('name'),
                            sortable: true
                        },
                        {
                            key: 'valid_until',
                            name: t('validUntil'),
                            render: (value: string) => {
                                const date = dayjs(value);
                                const isExpired = dayjs().isAfter(date, 'minute');
                                if (isExpired) return <Badge color="red">Expired</Badge>;
                                return new Date(value).toLocaleString();
                            }
                        },
                        {
                            key: 'api_key',
                            name: t('apiKey'),
                            render: (value: string) => (
                                <Group gap="xs">
                                    <Text size="sm">{obfuscateApiKey(value)}</Text>
                                    <CopyButton value={value}>
                                        {({ copied, copy }) => (
                                            <ActionIcon variant="transparent" onClick={copy}>
                                                {copied ? <TbCheck /> : <TbCopy />}
                                            </ActionIcon>
                                        )}
                                    </CopyButton>
                                </Group>
                            )
                        },
                        {
                            key: 'actions',
                            align: 'right',
                            name: '',
                            render: (_, row) => {
                                if (!hasPermission('/account_manager/key_management', 'DELETE')) return null;
                                return (
                                    <Menu shadow="md">
                                        <Menu.Target>
                                            <ActionIcon data-testid={`menu-${row.key}`}>
                                                <TbDotsVertical size={16} />
                                            </ActionIcon>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            <Menu.Item
                                                data-testid={`delete-${row.key}`}
                                                color="red"
                                                onClick={() => {
                                                    setShowDeleteModal(true);
                                                    setId(getIdFromKey(row.key));
                                                }}
                                            >
                                                {t('delete')}
                                            </Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                );
                            }
                        }
                    ]}
                />
            </Section>

            <ConfirmationModal
                title={t('confirmDeleteTitle')}
                variant="delete"
                isOpen={showDeleteModal}
                onClose={() => {
                    setShowDeleteModal(false);
                }}
                onConfirm={handleDeleteKeyConfirm}
            >
                {t('apiKeyDeleteConfirmation')}
            </ConfirmationModal>

            <CreateApiKeyModal
                isOpen={showCreateModal}
                onComplete={(result) => {
                    setCreateResult(result);
                }}
                onClose={() => {
                    setShowCreateModal(false);
                }}
            />
            <SecretModal
                isOpen={!!createResult}
                onClose={() => {
                    setCreateResult(null);
                }}
                data={createResult}
            />
        </>
    );
};
