import { Nullable } from 'types';
import { Dimension } from 'types/shared';
import { useScoreReportQuery } from './use-score-report-query';

const PRODUCT_QUERY_NAME = 'score_report_ecosystem_product';
const PER_MATERIAL_QUERY_NAME = 'score_report_ecosystem_impact_per_material';
const HIGHEST_IMPACT_MATERIALS_QUERY_NAME = 'score_report_ecosystem_highest_impact_materials';
const BIODIV_WATER_STATS_QUERY_NAME = 'score_report_ecosystem_biodiv_water_stats';

export const useEcosystemImpact = (productId?: string) => {
    const productResult = useScoreReportQuery<{
        product_id: string;
        dimension: Dimension;
        score: number;
        category_average: number;
        biodiversity_impact_score: number;
        water_impact_score: number;
    }>(PRODUCT_QUERY_NAME, productId);
    const perMaterialResult = useScoreReportQuery<{
        product_id: Nullable<string>;
        material_category: 'product' | 'packaging';
        biodiv_percentage: Nullable<number>;
        biodiversity_impact: Nullable<number>;
        biodiversity_impact_unit: Nullable<string>;
        water_percentage: Nullable<number>;
        water_impact: Nullable<number>;
        water_impact_unit: Nullable<string>;
    }>(PER_MATERIAL_QUERY_NAME, productId);
    const highestImpactMaterialsResult = useScoreReportQuery<{
        beautified_name: string;
        material_category: 'product' | 'packaging';
        subdimension: 'biodiversity' | 'water';
        material_impact: number;
    }>(HIGHEST_IMPACT_MATERIALS_QUERY_NAME, productId);
    const bioDivWaterStatsResult = useScoreReportQuery<{
        product_id: string;
        biodiversity_importance_risk: number;
        organic_weight_average: number;
        water_risk_average: number;
    }>(BIODIV_WATER_STATS_QUERY_NAME, productId);
    return {
        productResult,
        perMaterialResult,
        highestImpactMaterialsResult,
        bioDivWaterStatsResult
    };
};
