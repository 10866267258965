import { AuthProvider } from 'account-management/auth/use-auth';
import { SWRConfig } from 'swr';
import { swrConfig } from 'swr-config';
import { theme } from 'theme';
import { ReactNode } from 'react';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { NavigationProgress } from '@mantine/nprogress';

export const Providers = ({ children }: { children: ReactNode }) => {
    return (
        <AuthProvider>
            <MantineProvider theme={theme} withCssVariables>
                <Notifications />
                <NavigationProgress />
                <SWRConfig value={swrConfig}>{children}</SWRConfig>
            </MantineProvider>
        </AuthProvider>
    );
};
