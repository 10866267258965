import { Nullable } from 'types';
import { useScoreReportQuery } from './use-score-report-query';

const QUERY_NAME = 'score_report_livelihood_product';

export type LivelihoodsAndWellbeingResult = {
    product_id: Nullable<string>;
    total: Nullable<number>;
    category_average: Nullable<number>;
    fair_pay: Nullable<number>;
    working_conditions: Nullable<number>;
    workers_protection: Nullable<number>;
    transparency: Nullable<number>;
};

export const useLivelihoodsAndWellbeing = (productId?: string) => useScoreReportQuery<LivelihoodsAndWellbeingResult>(QUERY_NAME, productId);
