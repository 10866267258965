import { MultiFactorResolver, UserCredential, getMultiFactorResolver } from 'firebase/auth';
import { paths } from 'paths';
import { showAuthError } from 'shared/show-auth-error';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGoogle, FaMicrosoft } from 'react-icons/fa';
import { MdOutlineMail } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Text, Button, rem, Stack } from '@mantine/core';
import { auth } from 'lib/firebase';
import { AuthLayout } from './auth-layout';
import { MfaSignInModal } from './mfa-sign-in-modal';
import { startMfaSignin } from './start-mfa-sign-in';
import { MULTIFACTOR_ERROR_CODE, useAuth } from './use-auth';

export const SignIn = () => {
    const [multiFactorResolver, setMultiFactorResolver] = useState<MultiFactorResolver | null>(null);
    const [verificationId, setVerificationId] = useState<string | null>(null);
    const { t } = useTranslation();
    const { signInWithGoogle, signInWithMicrosoft } = useAuth();

    const handleSignIn = (method: () => Promise<UserCredential>) => async () => {
        try {
            await method();
        } catch (error: any) {
            if (error.code === MULTIFACTOR_ERROR_CODE) {
                const resolver = getMultiFactorResolver(auth, error);
                const verificationId = await startMfaSignin(resolver.hints[0], resolver.session);
                if (resolver && verificationId) {
                    setMultiFactorResolver(resolver);
                    setVerificationId(verificationId);
                    return;
                }
            }
            showAuthError(error);
        }
    };

    const showMfaModal = !!multiFactorResolver && !!verificationId;

    return (
        <>
            <AuthLayout>
                <Text c="blue" fw={700} size="xl" ta="center" mb="md">
                    {t('welcomeBack')}
                </Text>
                <Text size="sm" mb="lg" ta="center" mx="auto">
                    {t('signInDescription')}
                </Text>

                <Stack align="center" justify="center" gap="sm" mb="xs">
                    <Button
                        leftSection={<FaGoogle size={24} />}
                        onClick={handleSignIn(signInWithGoogle)}
                        variant="outline"
                        radius="md"
                        size="md"
                        miw={rem(260)}
                    >
                        {t('google')}
                    </Button>
                    <Button
                        leftSection={<FaMicrosoft size={24} />}
                        onClick={handleSignIn(signInWithMicrosoft)}
                        variant="outline"
                        radius="md"
                        size="md"
                        miw={rem(260)}
                    >
                        {t('microsoft')}
                    </Button>
                </Stack>
                <Stack align="center" justify="center" gap="sm" mb="sm">
                    <Text size="sm" ta="center">
                        {t('orContinueWith')}
                    </Text>
                    <Button
                        component={Link}
                        to={paths.email()}
                        size="sm"
                        radius="md"
                        miw={rem(260)}
                        variant="default"
                        leftSection={<MdOutlineMail size={24} />}
                    >
                        {t('passwordlessEmail')}
                    </Button>
                </Stack>
            </AuthLayout>
            <MfaSignInModal
                isOpen={showMfaModal}
                verificationId={verificationId}
                multiFactorResolver={multiFactorResolver}
                onClose={() => {
                    setMultiFactorResolver(null);
                    setVerificationId(null);
                }}
            />
        </>
    );
};
