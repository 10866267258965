import { useOrganizationId } from 'shared/use-organization-id';
import useSWR from 'swr';
import { QueryReturnResult, ListWidget as ListWidgetType } from 'types';
import { useMemo } from 'react';
import { RunQueryConfig, runQuery } from 'utils/run-query';
import { ListCard } from './list-card';
import { useWidgetQuery } from './use-widget-query';

export const ListWidget = ({ config, range }: { config: ListWidgetType; range: string }) => {
    const organizationId = useOrganizationId();
    const query = useWidgetQuery(config, range);
    const result = useSWR<QueryReturnResult<any>>(config ? [config.query_name, query] : null, async ([_, query]) =>
        runQuery(query as RunQueryConfig)
    );

    const data = useMemo(() => {
        if (!result.data) return [];
        return result.data.results.map((current) => {
            const label = current[config.label_column_name];
            const value = current[config.value_column_name];
            const keys = Object.keys(current);
            const to =
                organizationId && config.link
                    ? keys.reduce(
                          (str, key) => str?.replaceAll(`{${key}}`, current[key]),
                          config.link.replaceAll('{organizationId}', organizationId)
                      )
                    : undefined;
            return {
                label,
                value,
                to
            };
        });
    }, [result.data, organizationId, config.label_column_name, config.value_column_name, config.link]);

    return (
        <ListCard
            variant={config.variant}
            title={config.name}
            description={config.description}
            count={config.pageSize}
            data={data}
            isLoading={result.isLoading}
            error={result.error}
        />
    );
};
