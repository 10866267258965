import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import { BarChartWidget as BarChartWidgetType, Dashboard, ValueWidget as ValueWidgetType, ListWidget as ListWidgetType } from 'types';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { Grid, Group, Text, Title, rem } from '@mantine/core';
import { BarChartWidget } from './bar-chart-widget';
import { ListWidget } from './list-widget';
import { DEFAULT_RANGE_OPTION } from './range-select';
import { ValueWidget } from './value-widget';

export const DashboardConfiguration = ({ data }: { data: Dashboard }) => {
    const [range] = useQueryParam('range', withDefault(StringParam, DEFAULT_RANGE_OPTION));

    return (
        <>
            <Group gap={rem(2)} mb="md">
                <Title>{data.name}</Title>

                {data.description && (
                    <MoreInfoHoverCard>
                        <Text size="sm" c="dimmed">
                            {data.description}
                        </Text>
                    </MoreInfoHoverCard>
                )}
            </Group>

            <Grid align="stretch" mb="md" gutter="sm">
                {data.widgets.map((widget) => {
                    if (widget.type === 'value')
                        return (
                            <Grid.Col key={widget.name} span={{ md: widget.span, sm: 12 }}>
                                <ValueWidget range={range} config={widget as ValueWidgetType} />
                            </Grid.Col>
                        );

                    if (widget.type === 'bar-chart') {
                        return (
                            <Grid.Col key={widget.name} span={{ md: widget.span, sm: 12 }}>
                                <BarChartWidget range={range} config={widget as BarChartWidgetType} />
                            </Grid.Col>
                        );
                    }

                    if (widget.type === 'list') {
                        return (
                            <Grid.Col key={widget.name} span={{ md: widget.span, sm: 12 }}>
                                <ListWidget range={range} config={widget as ListWidgetType} />
                            </Grid.Col>
                        );
                    }

                    return null;
                })}
            </Grid>
        </>
    );
};
