import { ElementRef, useEffect, useRef, useState } from 'react';
import { TbMinus, TbPlus } from 'react-icons/tb';
import { Box, NumberInput, NumberInputProps } from '@mantine/core';
import styles from './impact-number-input.module.css';

const STEP_SIZE = 0.1;

export const ImpactNumberInput = ({
    value: initialValue,
    onChange,
    variant = 'product',
    ...rest
}: {
    value?: string | number;
    onChange: (value: string | number) => void;
    variant?: 'product' | 'range';
} & NumberInputProps) => {
    const ref = useRef<ElementRef<typeof NumberInput>>(null);
    const [value, setValue] = useState<number | string | undefined>(initialValue);
    const numValue = Number(value);
    const isProductVariant = variant === 'product';
    const isRangeVariant = variant === 'range';

    useEffect(() => {
        if (value !== undefined) onChange(value);
    }, [value]);

    useEffect(() => {
        if (initialValue) {
            setValue(initialValue);
        }
    }, [initialValue]);

    return (
        <NumberInput
            ref={ref}
            maw={100}
            value={value}
            step={isProductVariant ? STEP_SIZE : 1}
            clampBehavior="strict"
            thousandSeparator=","
            allowNegative={false}
            decimalScale={2}
            onChange={setValue}
            size="xs"
            rightSectionPointerEvents="auto"
            rightSection={
                <Box className={styles.stepper}>
                    <button
                        type="button"
                        color="inherit"
                        data-testid="stepper-increase"
                        className={styles.stepperButton}
                        onClick={() => {
                            ref.current?.focus();
                            if (isProductVariant) {
                                setValue(Number.parseFloat((numValue + STEP_SIZE).toFixed(2)));
                            } else {
                                const newValue = Math.round(numValue) > numValue ? Math.round(numValue) : Math.round(numValue + 1);
                                setValue(newValue);
                            }
                        }}
                    >
                        <TbPlus size={12} />
                    </button>
                    <button
                        type="button"
                        color="inherit"
                        data-testid="stepper-decrease"
                        disabled={isRangeVariant && numValue === 1}
                        className={styles.stepperButton}
                        onClick={() => {
                            ref.current?.focus();
                            if (isProductVariant) {
                                setValue(Number.parseFloat((numValue - STEP_SIZE).toFixed(2)));
                            } else {
                                const newValue = Math.round(numValue) < numValue ? Math.round(numValue) : Math.round(numValue - 1);
                                setValue(newValue);
                            }
                        }}
                    >
                        <TbMinus size={12} />
                    </button>
                </Box>
            }
            {...rest}
        />
    );
};
