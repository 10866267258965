import { Dimension } from 'types/shared';
import { LivelihoodsAndWellbeingCards } from './livelihoods-and-wellbeing-cards';
import { ScoreReportLayout } from './score-report-layout';

export const LivelihoodsAndWellbeing = () => {
    return (
        <ScoreReportLayout dimension={Dimension.Livelihood}>
            <LivelihoodsAndWellbeingCards />
        </ScoreReportLayout>
    );
};
