import { useTranslation } from 'react-i18next';
import { TbAlertCircle } from 'react-icons/tb';
import { Alert } from '@mantine/core';
import { getErrorMessage } from 'utils/get-error-message';
import { Section } from './section';

export const ErrorCard = ({ error }: { error: Error }) => {
    const { t } = useTranslation();
    return (
        <Section>
            <Alert icon={<TbAlertCircle size={16} />} title={t('unexpectedError')} color="red">
                {getErrorMessage(error)}
            </Alert>
        </Section>
    );
};
