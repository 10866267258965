import { Nullable } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FileInput, Group, Modal, Select, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showErrorMessage } from 'utils/show-error-message';
import { showSuccessMessage } from 'utils/show-success-message';
import { ColumnMapping, useColumnMapping } from './use-column-mapping';

export const ColumnMappingUploadModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const { t } = useTranslation();
    const { update, mutate } = useColumnMapping();
    const [isSubmitting, setSubmitting] = useState(false);

    const form = useForm({
        initialValues: {
            file: null as Nullable<File>,
            mapping: ColumnMapping.Primary_Packaging
        },
        validate: {
            file: (value) => (!value ? t('dataIsRequired') : null)
        }
    });

    const handleSubmit = form.onSubmit(async (values) => {
        if (!values.file) return;
        try {
            setSubmitting(true);
            await update(values.mapping, values.file);
            showSuccessMessage(t('columnMappingUpdated'));
            form.reset();
            mutate();
            onClose();
        } catch (error) {
            showErrorMessage(error);
        } finally {
            setSubmitting(false);
        }
    });

    return (
        <Modal size="md" opened={isOpen} onClose={onClose} title={t('updateColumnMappings')} centered>
            <form onSubmit={handleSubmit}>
                <Stack gap="xs">
                    <Select
                        label={t('mapping')}
                        comboboxProps={{ position: 'bottom' }}
                        data={Object.keys(ColumnMapping)}
                        {...form.getInputProps('mapping')}
                    />
                    <FileInput label={t('dataCsv')} withAsterisk accept=".csv" {...form.getInputProps('file')} />
                </Stack>
                <Group mt="lg" justify="flex-end">
                    <Button type="submit" loading={isSubmitting}>
                        {t('update')}
                    </Button>
                </Group>
            </form>
        </Modal>
    );
};
