import ScoreCircleGraph from 'shared/score-circle-graph';
import { TagList } from 'shared/tag-list';
import { Dimension } from 'types/shared';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, MultiSelect } from '@mantine/core';
import { formatHeader } from 'utils/format-header';

export const DEFAULT_COLUMNS = ['name', 'brand', 'category', 'tags', 'total_score'];

const COLUMNS = [
    'name',
    'brand',
    'supplier',
    'category',
    'custom_category',
    'parent_category',
    'google_taxonomy_path',
    'tags',
    'updated_at',
    'scored_at',
    'total_score',
    'circularity_score',
    'ecosystem_score',
    'climate_score',
    'livelihood_and_wellbeing_score',
    'purpose_score'
];

const NON_SORTABLE_COLUMNS = ['tags'];

export const keyToColumnMapping = (columnKey: string, organizationId?: string) => {
    return {
        key: columnKey,
        name: formatHeader(columnKey),
        sortable: !NON_SORTABLE_COLUMNS.includes(columnKey),
        render: (value: any, row: any) => {
            if (Array.isArray(value)) {
                return <TagList data={value} />;
            }

            if (columnKey.endsWith('_at')) {
                return value ? new Date(value).toLocaleString() : '-';
            }

            if (columnKey.endsWith('_score')) {
                return (
                    <Box
                        component={Link}
                        aria-label="View score report"
                        c="inherit"
                        to={`/organization/${organizationId}/products/${row.id}/score-report/${
                            Dimension.Total
                        }?name=${encodeURIComponent(row.name)}&sku=${row.sku}`}
                    >
                        <ScoreCircleGraph percentage={Math.round(value ?? 0)} size="xs" />
                    </Box>
                );
            }

            return value ?? '-';
        }
    };
};

export const ProductColumnFilter = ({ value, onChange }: { value: Array<string>; onChange: (values: Array<string>) => void }) => {
    const { t } = useTranslation();
    return (
        <MultiSelect
            label={t('columns')}
            description={t('columnsDescription')}
            value={value}
            data={COLUMNS.map((name) => ({
                value: name,
                label: formatHeader(name)
            }))}
            onChange={onChange}
        />
    );
};
