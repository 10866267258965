import { toErrorWithMessage } from './to-error-with-message';

export const getErrorMessage = (error: unknown) => {
    const errorWithMessage = toErrorWithMessage(error);
    try {
        const json = JSON.parse(errorWithMessage.message);
        if (typeof json.detail === 'string') return json.detail;
        return errorWithMessage.message;
    } catch {
        return errorWithMessage.message;
    }
};
