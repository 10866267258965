import useSWR from 'swr';
import { Filter, QueryReturnResult } from 'types';
import { runQuery } from 'utils/run-query';

export const useInsightQuery = <T = any>(key: string, params?: Filter[]) => {
    const { data, error, isLoading } = useSWR<QueryReturnResult<T>>([key, params], async ([_, params]) =>
        runQuery({
            name: key,
            params: params as Filter[]
        })
    );

    return { data, error, isLoading };
};
