import { BulkImportEdit } from 'data-management/bulk-import-edit';
import { productSchemaToDataRow } from 'data-management/bulk-import-edit/data-row-product-schema-conversion';
import { isValidRow } from 'data-management/bulk-import-edit/is-valid-row';
import { setMaterialTotals } from 'data-management/bulk-import-edit/set-material-totals';
import { setRelatedFields } from 'data-management/bulk-import-edit/set-related-fields';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@mantine/core';
import { SchemaProduct } from './use-holding-area';

export const BulkEditModal = ({ rows, onClose }: { rows: SchemaProduct[]; onClose: () => void }) => {
    const { t } = useTranslation();

    const initialRows = useMemo(() => {
        return rows.map((current, index) => {
            const row = productSchemaToDataRow(current, index + 1);
            setMaterialTotals(row);
            const withRelatedFieldsSet = setRelatedFields(row);
            const { errors, invalidFields } = isValidRow(withRelatedFieldsSet);
            withRelatedFieldsSet.validation_errors = errors;
            withRelatedFieldsSet.invalid_fields = invalidFields;
            return withRelatedFieldsSet;
        });
    }, [rows]);

    const key = useMemo(() => `${rows.length}_${Date.now()}`, [rows]);
    return (
        <Modal
            opened={true}
            onClose={onClose}
            title={t('editProducts')}
            fullScreen
            radius={0}
            transitionProps={{ transition: 'fade', duration: 200 }}
        >
            <BulkImportEdit variant="holding" initialRows={initialRows} storageKey={key} />
        </Modal>
    );
};
