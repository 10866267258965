import { ResponsiveContainer, BarChart, CartesianGrid, YAxis, XAxis, Bar, Cell, LabelList } from 'recharts';
import { Section } from 'shared/section';
import { Group, Text, rem, useMantineColorScheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useScoreColor } from 'utils/use-score-color';
import { InsightDataCurrentEmissions } from 'types';
import { useTranslation } from 'react-i18next';

export const InsightCard = ({
    name,
    newName,
    data,
}: {
    name: string
    newName: string
    data: (InsightDataCurrentEmissions) []
}) => {
    const { colorScheme } = useMantineColorScheme();
    const labelColor = colorScheme === 'dark' ? '#fff' : '#656565';
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');
    const getScoreColor = useScoreColor();
    const { t } = useTranslation();
    return (
        <Section
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Group justify="space-between" align="center" mb="md" gap="xs">
                <Group gap={rem(2)}>
                    <span><h4>{name} </h4></span>
                    <Text fw={200} size="md">
                        {t('currentEmmissionsStart')}
                    </Text>
                    <span><h4>{data[0]?.value} </h4></span>
                    <Text fw={200} size="md">
                        {t('currentEmmissionsEnd')}
                    </Text>
                </Group>
            </Group>
            <ResponsiveContainer height={data.length * 40 + 40} width="100%">
                <BarChart 
                    data={data}
                    height={100}
                    layout="vertical"
                    maxBarSize={24}
                    margin={{
                        right: 40,
                    }}
                    style={{
                        right: isSmallDevice ? '0px' : '130px'
                    }}
                >
                    <CartesianGrid horizontal={false} stroke={colorScheme === 'dark' ? '#5d5e61' : '#e7e7e7'} />
                    <YAxis
                        axisLine={false}
                        dataKey="name"
                        stroke={labelColor}
                        fontSize={12}
                        tickLine={false}
                        type="category"
                        width={isSmallDevice ? 120 : 200}
                    />
                    <XAxis
                        axisLine={false}
                        dataKey="value"
                        domain={[0, 100]}
                        fontSize={12}
                        stroke={labelColor}
                        tickLine={false}
                        type="number"
                    />
                    <Bar animationBegin={200} dataKey="value" fill="#8884d8" radius={[4, 4, 4, 4]}>
                        <Cell key="ik" style={{ cursor: 'pointer' }} fill={getScoreColor(data[0]?.value, 'total')} />
                        <LabelList
                            dataKey="value"
                            fill={labelColor}
                            fontSize={8}
                            position="right"
                        />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            <Group justify="space-between" align="center" mb="md" gap="xs">
            <Group gap={rem(2)}>
                    <Text fw={200} size="md">
                    {t('currentEmmissionsReplace')}
                    </Text>
                    <span><h4>{newName} </h4></span>                    
                    <Text fw={200} size="md">
                    {t('currentEmmissionsReplaceEnd')}
                    <strong style={{margin: '5px'}}>{data[1]?.current_reduction_percent}%</strong>
                    </Text>
                    
                </Group>
            </Group>
        </Section>
    );
};
