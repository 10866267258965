import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Group, Modal, Text, Button, rem, useMantineColorScheme } from '@mantine/core';

export const ConfirmationModal = ({
    title = 'Confirmation',
    isOpen,
    onClose,
    children,
    onConfirm,
    confirmText = 'Yes, confirm',
    variant
}: {
    title?: string;
    children: ReactNode;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => Promise<void>;
    confirmText?: string;
    variant?: 'default' | 'delete';
}) => {
    const { t } = useTranslation();
    const { colorScheme } = useMantineColorScheme();
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirm = async () => {
        setIsLoading(true);
        await onConfirm();
        setIsLoading(false);
    };

    const handleClose = () => {
        if (isLoading) return;
        onClose();
    };

    return (
        <Modal opened={isOpen} title={title} onClose={handleClose}>
            <Text>{children}</Text>
            <Group justify="flex-end" gap="sm" mt={rem(32)}>
                <Button
                    data-testid="cancel"
                    color={colorScheme === 'light' ? 'dark' : 'gray'}
                    disabled={isLoading}
                    onClick={handleClose}
                    variant="outline"
                >
                    {t('cancel')}
                </Button>
                <Button color={variant === 'delete' ? 'red' : 'blue'} data-testid="confirm" loading={isLoading} onClick={handleConfirm}>
                    {confirmText}
                </Button>
            </Group>
        </Modal>
    );
};
