import { useTranslation } from 'react-i18next';
import { Alert, AlertProps } from '@mantine/core';

export const NoDataAlert = (props: AlertProps) => {
    const { t } = useTranslation();
    return (
        <Alert title={t('noScoringDataFound')} color="orange" variant="light" {...props}>
            {t('noScoringDataForProduct')}
        </Alert>
    );
};
