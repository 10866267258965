import { OrderDirection } from 'types/shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useOrdering = (initial = true) => {
    const { t } = useTranslation();
    const [isDescending, setIsDescending] = useState(initial);

    return {
        ordering: isDescending ? OrderDirection.Descending : OrderDirection.Ascending,
        onOrderingChange: (value: string | null) => {
            if (value) setIsDescending(value === OrderDirection.Descending);
        },
        isDescending,
        orderingOptions: [
            {
                label: t('descending'),
                value: OrderDirection.Descending
            },
            {
                label: t('ascending'),
                value: OrderDirection.Ascending
            }
        ]
    };
};
