import { Nullable } from 'types';
import { useScoreReportQuery } from './use-score-report-query';

const SCORE_REPORT_CLIMATE_MATERIALS_QUERY_NAME = 'score_report_climate_materials';
const SCORE_REPORT_CLIMATE_PRODUCTION_STAGE = 'score_report_climate_production_stage';
const TRANSPORT_QUERY_NAME = 'score_report_climate_transport';

export const useClimateImpact = (productId?: string) => {
    const climateMaterialsResult = useScoreReportQuery<{
        product_id: Nullable<string>;
        beautified_name: Nullable<string>;
        material_category: Nullable<string>;
        emissions: Nullable<number>;
        unit: Nullable<string>;
    }>(SCORE_REPORT_CLIMATE_MATERIALS_QUERY_NAME, productId);
    const climateProductionStageResult = useScoreReportQuery<{
        product_id: string;
        product_materials: Nullable<number>;
        packaging_materials: Nullable<number>;
        distribution: Nullable<number>;
        manufacturing: Nullable<number>;
        total_impact: Nullable<number>;
        total: Nullable<number>;
        transport_gwp_per_kg_of_product: Nullable<number>;
        transport_gwp_absolute: Nullable<number>;
        product_gwp_absolute: Nullable<number>;
        product_gwp_per_kg_of_product: Nullable<number>;
        packaging_gwp_absolute: Nullable<number>;
        packaging_gwp_per_kg_of_product: Nullable<number>;
        manufacturing_gwp_per_kg_of_product: Nullable<number>;
        manufacturing_gwp_absolute: Nullable<number>;
        transport_chain: {
            transport_mode: string;
            distance_km: Nullable<number>;
            gwp_per_kg: Nullable<number>;
            gwp_absolute: Nullable<number>;
        }[];
        product_materials_percentage: Nullable<number>;
        packaging_materials_percentage: Nullable<number>;
        distribution_percentage: Nullable<number>;
        manufacturing_percentage: Nullable<number>;
        unit: string;
    }>(SCORE_REPORT_CLIMATE_PRODUCTION_STAGE, productId);
    const transportResult = useScoreReportQuery<{
        product_id: Nullable<string>;
        transport_mode: Nullable<string>;
        distance_km: Nullable<number>;
        gwp_absolute: Nullable<number>;
        unit: Nullable<string>;
    }>(TRANSPORT_QUERY_NAME, productId);

    return {
        climateMaterialsResult,
        climateProductionStageResult,
        transportResult
    };
};
