import useSWR from 'swr';
import { useMemo } from 'react';
import { auth } from 'lib/firebase';
import { formatHeader } from 'utils/format-header';

export enum ColumnMapping {
    Survey_Inputs = 'Survey_Inputs',
    Primary_Packaging = 'Primary_Packaging',
    Secondary_Packaging = 'Secondary_Packaging',
    Tertiary_Packaging = 'Tertiary_Packaging'
}

export const useColumnMapping = () => {
    const { data, error, mutate, isLoading } = useSWR<{
        [name: string]: Array<string>;
    }>([`/ingest/file/column-mapping`]);

    const tableData = useMemo(() => {
        if (!data) return [];
        const names = Object.keys(data);
        return names.map((name) => ({
            name: formatHeader(name),
            columns: data[name]
        }));
    }, [data]);

    const update = async (mapping: ColumnMapping, file: File) => {
        const token = await auth.currentUser?.getIdToken();
        const data = new FormData();
        data.set('file', file);
        const response = await fetch(`/api/ingest/file/column-mapping/${mapping}`, {
            method: 'PATCH',
            // @ts-ignore
            body: data,
            headers: {
                Authorization: `Bearer ${token ?? ''}`
            }
        });
        if (!response.ok) throw Error('Failed to update column mapping');
    };

    return {
        data,
        tableData,
        error,
        mutate,
        update,
        isLoading
    };
};
