import { ReactNode, useRef, ElementRef } from 'react';
import { Button, Tooltip } from '@mantine/core';

export const ImportButton = ({
    children,
    tooltip,
    accept = '.jsonl,.csv',
    isLoading = false,
    onChange
}: {
    children: ReactNode;
    tooltip: string;
    accept?: string;
    isLoading?: boolean;
    onChange: (file: File | undefined) => void | Promise<void>;
}) => {
    const inputRef = useRef<ElementRef<'input'>>(null);

    return (
        <>
            <Tooltip label={tooltip}>
                <Button
                    variant="outline"
                    onClick={() => {
                        if (inputRef.current) {
                            inputRef.current.value = '';
                            inputRef.current.click();
                        }
                    }}
                    loading={isLoading}
                >
                    {children}
                </Button>
            </Tooltip>
            <input
                ref={inputRef}
                type="file"
                hidden
                accept={accept}
                onChange={(event) => {
                    onChange(event.target.files?.[0]);
                }}
            />
        </>
    );
};
