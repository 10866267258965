import { ErrorCard } from 'shared/error-card';
import ScoreCircleGraph from 'shared/score-circle-graph';
import { ScoreGauge } from 'shared/score-gauge';
import { Section } from 'shared/section';
import { useFeatureEnabled } from 'shared/use-feature-enabled';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Group, PaperProps, Skeleton, Stack, Text, Title, rem, useMantineColorScheme } from '@mantine/core';
import { FlagName } from 'utils/get-flag-value';
import classificationDarkSrc from './classification-dark.png';
import classificationSrc from './classification.png';
import { DataQualityCard } from './data-quality-card';
import { getPurposeClassification } from './get-purpose-classification';
import { NoDataAlert } from './no-data-alert';
import classes from './purpose-cards.module.css';
import { useScoreReport } from './use-score-report';

export const PurposeCards = (props: PaperProps) => {
    const { t } = useTranslation();
    const { isEnabled } = useFeatureEnabled();
    const { colorScheme } = useMantineColorScheme();
    const { id } = useParams<{ id: string }>();

    const { purpose, dqr } = useScoreReport(id);
    const purposeDqr = dqr?.data?.results[0]?.purpose_dqr;
    const { error, isLoading, data } = purpose;

    const first = data?.results[0];

    const hasNoData = !isLoading && !first;

    const classification = first?.value !== null && first?.value !== undefined ? getPurposeClassification(first.value) : null;

    if (error) {
        return <ErrorCard error={error} />;
    }

    return (
        <>
            {hasNoData ? (
                <NoDataAlert />
            ) : (
                <>
                    <Section {...props}>
                        <Group align="flex-start" justify="space-between" gap="xs">
                            <Stack gap={rem(4)} className={classes.introduction}>
                                <Title size="h2">{t('purpose')}</Title>
                                <Text c="dimmed" size="sm">
                                    {t('purposeDescription')}
                                </Text>
                            </Stack>

                            {first && first.total !== null && (
                                <ScoreCircleGraph
                                    peerPercentage={first.category_average ?? 0}
                                    percentage={first.total ?? 0}
                                    size="md"
                                    className={classes.scoreCircle}
                                    bottom={
                                        <Text size="sm" fw={500}>
                                            {t('categoryAverage')}: {first.category_average ?? 0}
                                        </Text>
                                    }
                                />
                            )}
                        </Group>
                    </Section>

                    <Section mt="md" {...props}>
                        <Title size="h4" mb={rem(4)}>
                            {t('classification')}
                        </Title>
                        <Text c="dimmed" size="sm">
                            {t('classificationDescription')}
                        </Text>

                        {isLoading ? (
                            <Skeleton height={180} mt="lg" data-testid="loading-classification" />
                        ) : classification ? (
                            <Group mt="xl" align="center" style={{ justifyContent: 'space-evenly' }}>
                                <Stack align="center" gap="xs">
                                    <Box
                                        component="img"
                                        src={classification.src}
                                        width={60}
                                        height={60}
                                        alt=""
                                        className={classes.classification}
                                    />
                                    <Text size="lg" fw={500}>
                                        {classification.name}
                                    </Text>
                                </Stack>
                                <Box
                                    component="img"
                                    src={colorScheme === 'dark' ? classificationDarkSrc : classificationSrc}
                                    alt=""
                                    style={{
                                        width: '50%',
                                        minWidth: rem(340),
                                        objectFit: 'contain',
                                        height: 'auto'
                                    }}
                                />
                            </Group>
                        ) : (
                            <NoDataAlert mt="sm" />
                        )}
                    </Section>

                    <Section mt="md" {...props}>
                        <Title size="h4" mb={rem(4)}>
                            {t('catalystOfChange')}
                        </Title>
                        <Text c="dimmed" size="sm">
                            {t('catalystOfChangeDescription')}
                        </Text>
                        {isLoading ? (
                            <Skeleton height={180} mt="lg" data-testid="loading-catalyst-of-change" />
                        ) : first?.displacement === null ? (
                            <NoDataAlert mt="sm" />
                        ) : (
                            <Group justify="center" mt="xl">
                                <ScoreGauge score={first?.displacement ?? 0} />
                            </Group>
                        )}
                    </Section>
                    {isEnabled(FlagName.DataQualityRating) && (
                        <Section mt="md" {...props}>
                            <DataQualityCard score={purposeDqr ?? undefined} />
                        </Section>
                    )}
                    <Text c="dimmed" fs="italic" size="sm" my="lg">
                        {t('purposeFooterDescription')}
                    </Text>
                </>
            )}
        </>
    );
};
