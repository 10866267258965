import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Group, Table, Text } from '@mantine/core';
import { Column } from './table-header';

export const TableRows = ({
    rowKey,
    search = '',
    columns,
    isSearchSupported = false,
    data,
    selected = [],
    onSelect
}: {
    selected?: Array<string>;
    onSelect?: (id: Array<string>, rows: any[]) => void;
    rowKey: ((row: any) => string) | string;
    search?: string;
    columns: Column[];
    data?: any[];
    isSearchSupported?: boolean;
}) => {
    const { t } = useTranslation();
    if (!data) return null;
    return (
        <>
            {data.length === 0 ? (
                <Table.Tr>
                    <Table.Td colSpan={columns.length}>
                        <Text my="sm" fw={500} ta="center">
                            {isSearchSupported && search.length > 0 ? t('noSearchMatch') : t('noDataAvailable')}
                        </Text>
                    </Table.Td>
                </Table.Tr>
            ) : (
                data.map((row, index) => {
                    const id = typeof rowKey === 'string' ? row[rowKey] : rowKey(row);

                    return (
                        <Table.Tr key={id} data-key={id} data-testid={id}>
                            {onSelect && (
                                <Table.Td>
                                    <Checkbox
                                        size="xs"
                                        radius="xs"
                                        checked={selected.includes(id)}
                                        onChange={(event) => {
                                            if (event.currentTarget.checked) {
                                                onSelect([...selected, id], [row]);
                                            } else {
                                                onSelect(
                                                    selected.filter((current) => current !== id),
                                                    []
                                                );
                                            }
                                        }}
                                    />
                                </Table.Td>
                            )}

                            {columns.map((column) => {
                                const value = column.render ? column.render(row[column.key], row, index) : row[column.key];
                                return (
                                    <Box key={column.key} component={Table.Td} maw={column.width}>
                                        <Group justify={column.align ?? 'left'} align="center">
                                            {value}
                                        </Group>
                                    </Box>
                                );
                            })}
                        </Table.Tr>
                    );
                })
            )}
        </>
    );
};
