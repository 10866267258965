import { ErrorAlert } from 'shared/error-alert';
import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import { Section } from 'shared/section';
import { ReactNode } from 'react';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Group, Skeleton, Text, rem, Button } from '@mantine/core';

export const ValueCard = ({
    title,
    value,
    description,
    to,
    error,
    unit,
    filters
}: {
    unit?: string;
    error?: Error;
    to?: string;
    title: string;
    description?: string;
    value?: number;
    filters?: ReactNode;
}) => {
    const { t } = useTranslation();
    const isLoading = typeof value !== 'number';
    return (
        <Section
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}
        >
            <Group justify="space-between" align="center" mb="md" gap="xs">
                <Group gap={rem(2)}>
                    <Text fw={700} size="md">
                        {title}
                    </Text>

                    {description && (
                        <MoreInfoHoverCard>
                            <Text size="sm" c="dimmed">
                                {description}
                            </Text>
                        </MoreInfoHoverCard>
                    )}
                </Group>
                {filters}
            </Group>

            {error ? (
                <ErrorAlert error={error} />
            ) : isLoading ? (
                <Skeleton height={58} width={60} mt="xs" data-testid="loading" />
            ) : (
                <Group justify="space-between" align="end">
                    <Text fw={700} size="display" mt="xs" lh={1}>
                        <CountUp end={value} suffix={unit} duration={1} separator=" " decimal="," />
                    </Text>
                    {to && (
                        <Button component={Link} variant="subtle" size="xs" to={to}>
                            {t('view')}
                        </Button>
                    )}
                </Group>
            )}
        </Section>
    );
};
