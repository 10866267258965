import { Dayjs } from 'dayjs';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { useTranslation } from 'react-i18next';
import { Select, rem } from '@mantine/core';
import { day } from 'lib/day';

const DEFAULT_FORMAT = 'YYYY-MM-DD';

const formatTimestamp = (date: Dayjs) => day(date).utc().format(DEFAULT_FORMAT);

export const DEFAULT_RANGE_OPTION = formatTimestamp(day().subtract(7, 'day'));

export const RangeSelect = () => {
    const { t } = useTranslation();

    const rangeOptions = [
        {
            label: t('today'),
            value: formatTimestamp(day())
        },
        {
            label: t('lastWeek'),
            value: formatTimestamp(day().subtract(7, 'day'))
        },
        {
            label: t('lastMonth'),
            value: formatTimestamp(day().subtract(1, 'month'))
        },
        {
            label: t('lastYear'),
            value: formatTimestamp(day().subtract(1, 'year'))
        },
        {
            label: t('allTime'),
            value: ''
        }
    ];

    const [range, setRange] = useQueryParam('range', withDefault(StringParam, DEFAULT_RANGE_OPTION));

    return (
        <Select
            data={rangeOptions}
            placeholder={t('range')}
            size="xs"
            data-testid="range-select"
            maw={rem(112)}
            onChange={(value) => {
                if (value !== null) setRange(value);
            }}
            value={range}
        />
    );
};
