import { useScoreReportQuery } from './use-score-report-query';

const QUERY_NAME = 'scoring_results';

export type ScoringResult = {
    product_id: string;
    product_version: string;
    sku: string;
    scored_at: string;
    scores: {
        total: number;
        ecosystem: number;
        climate: number;
        purpose: number;
        biodiv_impact: number;
        livelihood_and_wellbeing: number;
        circularity: number;
        circularity_packaging: number;
        water_impact: number;
        biodiv_importance: number;
        circularity_product: number;
    };
};

export const useScoringResults = (productId?: string) => useScoreReportQuery<ScoringResult>(QUERY_NAME, productId);
