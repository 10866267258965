import { flatten } from 'flat';
import { exportAsCsv } from 'utils/export-as-csv';
import { jsonlFileToJsonArr } from './jsonl-file-to-json-arr';

/**
 * Convert a JSONL file to a CSV file
 * If not a JSONL file the step no export will occur.
 */
export const convertJsonlToCsv = async (file: File) => {
    if (file.name.endsWith('.jsonl')) {
        const jsonRows = await jsonlFileToJsonArr(file);
        const flattenedRows = jsonRows.map((current: any) => flatten(current));
        exportAsCsv(flattenedRows as any, file.name.replace('.jsonl', ''));
    }
};
