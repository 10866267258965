import { Nullable } from 'types';
import { useScoreReportQuery } from './use-score-report-query';

const QUERY_NAME = 'score_report_purpose_product';

export type Result = {
    product_id: Nullable<string>;
    total: Nullable<number>;
    value: Nullable<number>;
    category_average: Nullable<number>;
    displacement: Nullable<number>;
};

export const usePurpose = (productId?: string) => useScoreReportQuery<Result>(QUERY_NAME, productId);
