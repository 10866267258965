import { formatBytes } from 'utils/format-bytes';

const MAX_VALIDATION_SIZE = 33554432; // 32mb

export const getFileValidationError = (file: File): string | null => {
    if (file.size > MAX_VALIDATION_SIZE) {
        return `Data is too large to validate formatting (${formatBytes(file.size)})`;
    }

    if (!file.name.endsWith('jsonl')) return 'Data should be in JSONL format';
    return null;
};
