import { Filter } from 'types';
import { useInsightQuery } from './use-insight-query';

const AGGREGATED_DQR_RESULT = 'aggregated_dqr';

export const useAggregatedDqrReport = (params?: Filter[]) => {
    const { data: aggregatedDqrResult, error, isLoading } = useInsightQuery<{}>(AGGREGATED_DQR_RESULT, params);

    return { aggregatedDqrResult, error, isLoading, params };
};
