export const jsonlFileToJsonArr = (file: File, onError?: (error: any, item: string) => void): Promise<any[]> => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
            const result = reader.result as string;
            const json = result.split('\n');
            const exportRows = [];
            for (const item of json) {
                if (item) {
                    try {
                        const parsed = JSON.parse(item);
                        exportRows.push(parsed);
                    } catch (error) {
                        if (onError) onError(error, item);
                    }
                }
            }
            resolve(exportRows);
        };
    });
};
