import {
    GoogleAuthProvider,
    IdTokenResult,
    OAuthProvider,
    User,
    isSignInWithEmailLink,
    multiFactor,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    signInWithPopup
} from 'firebase/auth';
import { Nullable } from 'types';
import { createContext, useEffect, useState, ReactNode, useContext, useMemo, useCallback, useRef } from 'react';
import { auth } from 'lib/firebase';
import { getAuthDomain } from 'utils/get-auth-domain';
import { report } from 'utils/report-error';

export const NAMESPACE_STORAGE_KEY = 'impersonation-namespace-id';
export const PARENT_NAMESPACE_KEY = 'parent-namespace-id';
export const SIGN_IN_EMAIL_STORAGE_KEY = 'dayrize-sign-in-email';
export const MULTIFACTOR_ERROR_CODE = 'auth/multi-factor-auth-required';

const handleSignOut = () => auth.signOut();

const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
};

const handleMicrosoftSignIn = () => {
    const provider = new OAuthProvider('microsoft.com');
    return signInWithPopup(auth, provider);
};

const handleSendSignInEmailLink = async (email: string) => {
    const params = new URLSearchParams(window.location.search);
    const as = params.get('as') ? `&as=${params.get('as')}` : '';
    await sendSignInLinkToEmail(auth, email, {
        url: `${window.location.origin}/account/email/complete?email=${email}${as}`,
        handleCodeInApp: true
    });
    window.localStorage.setItem(SIGN_IN_EMAIL_STORAGE_KEY, email);
};

const handleSignInWithEmailLink = async () => {
    if (!isSignInWithEmailLink(auth, window.location.href)) {
        throw new Error('Invalid sign-in email link');
    }
    const params = new URLSearchParams(window.location.search);
    const email = params.get('email') ?? window.localStorage.getItem(SIGN_IN_EMAIL_STORAGE_KEY);
    if (!email) {
        throw new Error('No email found in sign-in email link');
    }
    return signInWithEmailLink(auth, email, window.location.href);
};

const THIRTY_MINUTES = 30 * 60 * 1000;

const useFirebaseAuth = () => {
    const refreshRef = useRef<Nullable<ReturnType<typeof setInterval>>>(null);
    const [idToken, setIdToken] = useState<Nullable<IdTokenResult>>(null);
    const [user, setUser] = useState<Nullable<User>>(null);
    const [isInitializing, setIsInitializing] = useState(true);

    const handleRefresh = useCallback(async (user: Nullable<User>) => {
        if (user) {
            const tokenResult = await user.getIdTokenResult();
            setIdToken(tokenResult);
        } else {
            setIdToken(null);
        }
    }, []);

    const handleUpdateUser = useCallback(
        async (user: Nullable<User>) => {
            setUser(user);
            setIsInitializing(false);
            handleRefresh(user);
        },
        [handleRefresh]
    );

    useEffect(() => {
        const unsubscribeAuthStateChanged = auth.onAuthStateChanged((user) => {
            handleUpdateUser(user).catch(report);
        });
        const unsubscribeIdTokenChanged = auth.onIdTokenChanged((user) => {
            handleUpdateUser(user).catch(report);
        });

        return () => {
            unsubscribeAuthStateChanged();
            unsubscribeIdTokenChanged();
        };
    }, [handleUpdateUser]);

    useEffect(() => {
        if (refreshRef.current) clearTimeout(refreshRef.current);
        refreshRef.current = setInterval(() => {
            handleRefresh(auth.currentUser);
        }, THIRTY_MINUTES);
        return () => {
            if (refreshRef.current) {
                clearTimeout(refreshRef.current);
            }
        };
    }, [handleRefresh]);

    const hasNoMultifactor = !!user && multiFactor(user).enrolledFactors.length === 0;
    const hasPasswordProvider = !!user && user.providerData.some((provider) => provider.providerId === 'password');
    const isMostRecentProviderPassword = !!idToken && idToken.claims.firebase?.sign_in_provider === 'password';
    const isMultifactorRequired = hasPasswordProvider && hasNoMultifactor && isMostRecentProviderPassword;

    return useMemo(
        () => ({
            idToken,
            user,
            userAuthDomain: getAuthDomain(user?.email),
            isInitializing,
            signOut: handleSignOut,
            signInWithGoogle: handleGoogleSignIn,
            signInWithMicrosoft: handleMicrosoftSignIn,
            sendSignInEmailLink: handleSendSignInEmailLink,
            signInWithEmailLink: handleSignInWithEmailLink,
            isMultifactorRequired,
            refreshUser: () => handleRefresh(auth.currentUser)
        }),
        [user, isInitializing, idToken, isMultifactorRequired, handleRefresh]
    );
};

const AuthContext = createContext<ReturnType<typeof useFirebaseAuth> | null>(null);

export const useAuth = () => {
    const result = useContext(AuthContext);
    if (!result) throw new Error('useAuth must be used within a AuthProvider');
    return result;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const state = useFirebaseAuth();
    return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};
