import { saveAs } from 'file-saver';
import { JsonValue } from './export-as-json';
import { flattenObj } from './flatten-obj';

const getAllHeaders = (json: Record<string, JsonValue>[]) => {
    const headers: Array<string> = [];
    json.forEach((row) => {
        const currentHeaders = Object.keys(row);
        currentHeaders.forEach((header) => {
            if (!headers.includes(header)) {
                headers.push(header);
            }
        });
    });
    return headers;
};

export const exportAsCsv = (json: Record<string, JsonValue>[], filename: string) => {
    const flattened = json.map((row) => flattenObj(row));
    const headers = getAllHeaders(flattened);
    const headerLine = headers.join(',');

    const rows = flattened.map((obj) => {
        return headers
            .map((header) => {
                let value = obj[header];
                if (value === null || value === undefined) {
                    value = '';
                }

                if (Array.isArray(value) && value.every((item: any) => typeof item === 'string')) {
                    return value.join(', ');
                }

                if (header.includes('proof_file_key')) return value;
                return `"${String(value).replace(/"/g, '""')}"`;
            })
            .join(',');
    });

    const csvFile = `${headerLine}\n${rows.join('\n')}`;
    const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${filename}.csv`);
    return csvFile;
};
