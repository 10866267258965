import StackdriverErrorReporter from 'stackdriver-errors-js';

let errorHandler: StackdriverErrorReporter | null = null;

const isLoggingEnabled = import.meta.env.VITE_ERROR_REPORTING_API_KEY && import.meta.env.PROD;

if (isLoggingEnabled) {
    errorHandler = new StackdriverErrorReporter();
    errorHandler.start({
        key: import.meta.env.VITE_ERROR_REPORTING_API_KEY,
        projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID
    });
}

export const report = (error: any) => {
    if (errorHandler) {
        errorHandler.report(error);
    } else {
        console.error(error);
    }
};
