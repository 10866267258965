import { CreateApiKeyResult } from 'types';
import { useTranslation } from 'react-i18next';
import { TbCheck, TbCopy, TbKey } from 'react-icons/tb';
import { ActionIcon, Button, CopyButton, Group, Modal, Stack, TextInput } from '@mantine/core';

export const SecretModal = ({ isOpen, onClose, data }: { isOpen: boolean; onClose: () => void; data: CreateApiKeyResult | null }) => {
    const { t } = useTranslation();

    return (
        <Modal closeOnClickOutside={false} size="md" opened={isOpen} onClose={onClose} title={t('apiKeyDetails')} centered>
            <form>
                <Stack gap="xs">
                    <TextInput
                        leftSection={<TbKey size={16} />}
                        label={t('apiKey')}
                        value={data?.api_key ?? ''}
                        readOnly
                        rightSection={
                            data?.api_key && (
                                <CopyButton value={data.api_key}>
                                    {({ copied, copy }) => (
                                        <ActionIcon variant="transparent" onClick={copy}>
                                            {copied ? <TbCheck /> : <TbCopy />}
                                        </ActionIcon>
                                    )}
                                </CopyButton>
                            )
                        }
                    />
                    <TextInput
                        label={t('secret')}
                        value={data?.secret ?? ''}
                        description={t('secretDescription')}
                        readOnly
                        rightSection={
                            data?.secret && (
                                <CopyButton value={data.secret}>
                                    {({ copied, copy }) => (
                                        <ActionIcon variant="transparent" onClick={copy}>
                                            {copied ? <TbCheck /> : <TbCopy />}
                                        </ActionIcon>
                                    )}
                                </CopyButton>
                            )
                        }
                    />
                </Stack>
                <Group mt="lg" justify="flex-end">
                    <Button type="button">{t('done')}</Button>
                </Group>
            </form>
        </Modal>
    );
};
