import { PhoneAuthProvider, PhoneMultiFactorGenerator, multiFactor } from 'firebase/auth';
import { showAuthError } from 'shared/show-auth-error';
import { auth } from 'lib/firebase';

export const finishEnrollMultiFactor = async (verificationId: string, verificationCode: string) => {
    if (!auth.currentUser) return;
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    try {
        await multiFactor(auth.currentUser).enroll(multiFactorAssertion);
        return true;
    } catch (error: any) {
        showAuthError(error);
        return false;
    }
};
