import { useCircularity } from './use-circularity';
import { useClimateImpact } from './use-climate-impact';
import { useEcosystemImpact } from './use-ecosystem-impact';
import { useLivelihoodsAndWellbeing } from './use-livelihoods-and-wellbeing';
import { useProductDqr } from './use-product-drq';
import { usePurpose } from './use-purpose';
import { useSummary } from './use-summary';

export const useScoreReport = (id?: string | undefined) => {
    const { data: summaryResult, isLoading: isSummaryLoading } = useSummary(id);
    const circularity = useCircularity(id);
    const ecosystem = useEcosystemImpact(id);
    const climate = useClimateImpact(id);
    const livelihoodsAndWellbeing = useLivelihoodsAndWellbeing(id);
    const purpose = usePurpose(id);
    const dqr = useProductDqr(id);

    const isCircularityLoading = circularity.materialsResult.isLoading || circularity.productResult.isLoading;
    const isEcosystemLoading =
        ecosystem.bioDivWaterStatsResult.isLoading ||
        ecosystem.highestImpactMaterialsResult.isLoading ||
        ecosystem.perMaterialResult.isLoading ||
        ecosystem.productResult.isLoading;
    const isClimateImpactLoading =
        climate.climateProductionStageResult.isLoading || climate.climateMaterialsResult.isLoading || climate.transportResult.isLoading;
    const isLivelihoodsAndWellbeingLoading = livelihoodsAndWellbeing.isLoading;
    const isPurposeLoading = purpose.isLoading;
    const isDqrLoading = dqr.isLoading;

    return {
        summaryResult,
        summary: summaryResult?.results,
        circularity,
        livelihoodsAndWellbeing,
        ecosystem,
        climate,
        purpose,
        dqr,
        isSummaryLoading,
        isCircularityLoading,
        isEcosystemLoading,
        isClimateImpactLoading,
        isLivelihoodsAndWellbeingLoading,
        isPurposeLoading,
        isDqrLoading,
        isLoading:
            isSummaryLoading ||
            isCircularityLoading ||
            isEcosystemLoading ||
            isClimateImpactLoading ||
            isLivelihoodsAndWellbeingLoading ||
            isPurposeLoading || 
            isDqrLoading
    };
};
