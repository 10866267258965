import { DataLevelPermission } from 'types';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Button, Code, Group, Modal, Select, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { validation } from 'utils/validation';

const operatorOptions = ['=', '>', '<', '>=', '<=', '!=', 'BETWEEN', 'LIKE', 'IN'];
const resourceOptions = ['audit_events', 'product', 'scoring_results'];

export const DataLevelPermissionModal = ({
    isOpen,
    onClose,
    onSubmit,
    currentPermissions,
    initialValues = {
        name: '',
        resource: '',
        column: '',
        operator: '',
        value: ''
    }
}: {
    currentPermissions: DataLevelPermission[];
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (values: DataLevelPermission) => void;
    initialValues?: {
        name: string;
        resource: string;
        column: string;
        operator: string;
        value: string;
    };
}) => {
    const { t } = useTranslation();

    const form = useForm({
        initialValues,
        validate: {
            name: validation.required,
            resource: validation.required,
            column: validation.required,
            operator: validation.required,
            value: validation.required
        }
    });

    const handleSubmit = form.onSubmit((values) => {
        const nameExists = !!currentPermissions.find((current) => current.name === values.name);
        if (nameExists) {
            notifications.show({ message: t('nameAlreadyExists'), color: 'red' });
            return;
        }
        onSubmit(values);
        onClose();
        form.reset();
    });

    return (
        <Modal size="md" opened={isOpen} onClose={onClose} title={t('addDataLevelPermission')} centered>
            <form onSubmit={handleSubmit}>
                <Stack gap="xs">
                    <TextInput {...form.getInputProps('name')} withAsterisk label={t('name')} />
                    <Autocomplete
                        withAsterisk
                        label={t('resource')}
                        description={t('dataLevelPermissionNameDescription')}
                        data={resourceOptions}
                        {...form.getInputProps('resource')}
                    />
                    <TextInput
                        withAsterisk
                        {...form.getInputProps('column')}
                        label={t('column')}
                        description={
                            <>
                                {t('dataLevelPermissionColumnDescription')} <Code>STRING</Code>, <Code>INT64</Code>, <Code>FLOAT64</Code>,{' '}
                                <Code>BOOL</Code>,<Code>TIMESTAMP</Code> and{' '}
                                <Code>
                                    ARRAY{'<'}STRING{'>'}
                                </Code>
                            </>
                        }
                    />
                    <Select
                        comboboxProps={{ position: 'bottom', withinPortal: true }}
                        label={t('operator')}
                        withAsterisk
                        allowDeselect={false}
                        data={operatorOptions}
                        description={
                            <>
                                Operator to use. If the value is one of <Code>NULL</Code>, <Code>TRUE</Code>, or <Code>FALSE</Code> we will
                                change the operator to <Code>IS</Code>
                            </>
                        }
                        {...form.getInputProps('operator')}
                    />
                    <TextInput
                        {...form.getInputProps('value')}
                        description={
                            form.values.operator === 'BETWEEN' ? (
                                <>
                                    Only two values set separated by <Code>,</Code> character
                                </>
                            ) : form.values.operator === 'IN' ? (
                                <>
                                    values should be separated by <Code>,</Code> character
                                </>
                            ) : undefined
                        }
                        label={t('value')}
                        withAsterisk
                    />
                </Stack>
                <Group mt="lg" justify="flex-end">
                    <Button type="submit">{t('add')}</Button>
                </Group>
            </form>
        </Modal>
    );
};
