import { isValidEmail } from './is-valid-email';
import { isValidUrl } from './is-valid-url';

const numberRegex = /^\d+$/;

export const validation = {
    required: (value: string | number) => (value && value.toString().length > 0 ? null : 'Field is required'),
    requiredEmail: (value: string) => (value && isValidEmail(value) ? null : 'Invalid email'),
    requiredNumber: (value: string | number) => {
        const valStr = value.toString();
        if (!valStr) return 'Field is required';
        if (!numberRegex.test(valStr)) return 'Must be number';
        return null;
    },
    email: (value: string) => {
        if (!value) return null;
        return isValidEmail(value) ? null : 'Invalid email';
    },
    url: (value: string) => {
        if (!value) return null;
        return isValidUrl(value) ? null : 'Invalid URL';
    }
};
