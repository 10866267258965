import { CreateOrganizationForm } from 'shared/create-organization-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Text } from '@mantine/core';
import { AuthLayout } from './auth-layout';

export const AddOrganization = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <AuthLayout>
            <Text c="blue" fw={700} size="xl" ta="center" mb="md">
                {t('createNewOrganization')}
            </Text>
            <Text size="md" fw={500} mb="lg" ta="center">
                {t('accountNotConnectedToOrganization')}
            </Text>
            <CreateOrganizationForm onComplete={async () => navigate('/organization/pending')} />
        </AuthLayout>
    );
};
