import { useMantineTheme } from '@mantine/core';

export const useRecycledOrReusedMaterialsMapping = () => {
    const theme = useMantineTheme();
    return {
        new: {
            title: 'New',
            color: theme.colors.blue[4]
        },
        recycled: {
            title: 'Recycled',
            color: theme.colors.yellow[5]
        },
        're-used': {
            title: 'Re-used',
            color: theme.colors.violet[4]
        }
    };
};
