import {
    MultiFactorInfo,
    MultiFactorSession,
    PhoneInfoOptions,
    PhoneAuthProvider,
    PhoneMultiFactorGenerator,
    RecaptchaVerifier
} from 'firebase/auth';
import { auth } from 'lib/firebase';
import { showErrorMessage } from 'utils/show-error-message';

export const startMfaSignin = async (multiFactorHint: MultiFactorInfo, session: MultiFactorSession) => {
    if (!window.signInRecaptchaVerifier) {
        window.signInRecaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', { size: 'invisible' });
    }

    if (multiFactorHint.factorId !== PhoneMultiFactorGenerator.FACTOR_ID) {
        showErrorMessage('Only phone based multi-factor authentication is supported.');
        return;
    }
    try {
        const phoneInfoOptions: PhoneInfoOptions = {
            multiFactorHint: multiFactorHint,
            session: session
        };
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.signInRecaptchaVerifier);
        return verificationId;
    } catch (error) {
        showErrorMessage(error);
    }
};
