import { paths } from 'paths';
import { useProducts, ProductWithScoringResults } from 'products/use-products';
import { DimensiontNavLinks } from 'shared/dimension-nav-links';
import { DownloadButton } from 'shared/download-button';
import { ErrorCard } from 'shared/error-card';
import { ProductFilters } from 'shared/product-filters';
import { Section } from 'shared/section';
import { TooManyResultsAlert } from 'shared/too-many-results-alert';
import { useOrganizationId } from 'shared/use-organization-id';
import { Dimension, OrderDirection } from 'types/shared';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbAlertCircle } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Group, Select, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { DEFAULT_MAX_RESULTS } from 'utils/run-query';
import { truncate } from 'utils/truncate';
import { useProductFilters } from '../shared/use-product-filters';
import { ComparisonChart } from './comparison-chart';

const dimensionToKeyMap: {
    [key: string]: string;
} = {
    [Dimension.Total]: 'total_score',
    [Dimension.Circularity]: 'circularity_score',
    [Dimension.Climate]: 'climate_score',
    [Dimension.Ecosystem]: 'ecosystem_score',
    [Dimension.Livelihood]: 'livelihood_and_wellbeing_score',
    [Dimension.Purpose]: 'purpose_score'
};

export const ProductScoreComparison = () => {
    const { t } = useTranslation();
    const organizationId = useOrganizationId();
    const navigate = useNavigate();
    const [dimension, setDimension] = useState(Dimension.Total);
    const { filters, hasFilters, ...rest } = useProductFilters();
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');

    const { error, data, isLoading, onOrderChange, order, orderDirection, onOrderDirectionChange } = useProducts(filters, {
        variant: 'report',
        initialOrdering: 'total_score',
        isEnabled: hasFilters
    });

    const graphData = useMemo(() => {
        if (!data?.results) return [];
        return data.results.map((current) => {
            const valueKey = dimensionToKeyMap[dimension];
            return {
                id: current.id,
                name: truncate(current.name, isSmallDevice ? 30 : 50),
                value: (current[valueKey as keyof ProductWithScoringResults] as number) ?? 0
            };
        });
    }, [data?.results, isSmallDevice, dimension]);

    useEffect(() => {
        if (order !== dimensionToKeyMap[dimension]) onOrderChange(dimensionToKeyMap[dimension]);
    }, [dimension, onOrderChange, order]);

    if (error) {
        return <ErrorCard error={error} />;
    }

    return (
        <>
            <Group gap="xs" justify="space-between" align="flex-start" mb="sm">
                <Box>
                    <Title size="h3">{t('productComparison')}</Title>
                    <Text c="dimmed" size="sm">
                        {t('productComparisonDescription')}
                    </Text>
                </Box>

                <ProductFilters
                    {...rest}
                    additionalFilters={
                        <Select
                            label={t('ordering')}
                            data={[
                                {
                                    label: t('descending'),
                                    value: OrderDirection.Descending
                                },
                                {
                                    label: t('ascending'),
                                    value: OrderDirection.Ascending
                                }
                            ]}
                            allowDeselect={false}
                            onChange={(value) => {
                                if (value) onOrderDirectionChange(value);
                            }}
                            value={orderDirection}
                        />
                    }
                >
                    <DownloadButton
                        disabled={!data?.results}
                        exports={[
                            {
                                name: 'products',
                                data: data?.results
                            }
                        ]}
                    />
                </ProductFilters>
            </Group>

            <DimensiontNavLinks value={dimension} onChange={setDimension} />

            {data?.num_results === DEFAULT_MAX_RESULTS && <TooManyResultsAlert maxResults={DEFAULT_MAX_RESULTS} />}

            {hasFilters ? (
                <Section>
                    <ComparisonChart
                        onClick={(data) => {
                            navigate(
                                paths.scoreReport(organizationId, data.id, {
                                    name: data.name
                                })
                            );
                        }}
                        data={graphData}
                        isLoading={isLoading}
                    />
                </Section>
            ) : (
                <Alert icon={<TbAlertCircle size={20} />} title={t('selectFilterRangeTitle')} color="blue">
                    {t('selectFilterRangeDescription')}
                </Alert>
            )}
        </>
    );
};
