import { Filter, Widget } from 'types';
import { useMemo } from 'react';
import { RunQueryConfig } from 'utils/run-query';

export const useWidgetQuery = (config: Widget, range?: string) => {
    return useMemo(() => {
        const query: RunQueryConfig = {
            name: config.query_name,
            params: []
        };

        if (config.pageSize) {
            query.pageSize = config.pageSize;
        }

        if (config.filters) {
            query.params = config.filters;
        }

        if (config.ordering) {
            query.orderByParams = config.ordering;
        }

        const rangeFilter = config.selects?.find((current) => current.type === 'interval');
        if (rangeFilter && range) {
            const filter: Filter = {
                name: rangeFilter.column_name,
                operation: '>=',
                value: range
            };
            query.params = query.params ? [...query.params, filter] : [filter];
        }

        return query;
    }, [config, range]);
};
