import { DownloadButton } from 'shared/download-button';
import { ErrorCard } from 'shared/error-card';
import { ProductFilters } from 'shared/product-filters';
import { Section } from 'shared/section';
import { TooManyResultsAlert } from 'shared/too-many-results-alert';
import { useOrganizationId } from 'shared/use-organization-id';
import { useProductFilters } from 'shared/use-product-filters';
import { Dimension } from 'types/shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Group, Select, Text, Title } from '@mantine/core';
import { DEFAULT_MAX_RESULTS } from 'utils/run-query';
import { ComparisonChart } from './comparison-chart';
import { getComparisonChartNavigationPath } from './get-comparison-chart-navigation-path';
import { useEnterpriseScoreComparison, ScoreComparisonVariant } from './use-enterprise-score-comparison';
import { useOrdering } from './use-ordering';

const variantOptions: Array<{ label: string; value: ScoreComparisonVariant }> = [
    { label: 'Category', value: 'category' },
    { label: 'Brand', value: 'brand' },
    { label: 'Division', value: 'division' },
    { label: 'Product', value: 'product' }
];

const metricOptions: Array<{
    label: string;
    value: Dimension;
}> = [
    { label: 'Dayrize Score', value: Dimension.Total },
    { label: 'Circularity', value: Dimension.Circularity },
    { label: 'Ecosystem Impact', value: Dimension.Ecosystem },
    { label: 'Climate Impact', value: Dimension.Climate },
    { label: 'Livelihood & Wellbeing', value: Dimension.Livelihood },
    { label: 'Purpose', value: Dimension.Purpose },
    { label: 'Carbon Emissions', value: Dimension.CarbonEmissions },
    { label: 'Water Depletion', value: Dimension.WaterDepletion },
    { label: 'Land Use', value: Dimension.LandUse }
];

export const EnterpriseScoreComparison = () => {
    const navigate = useNavigate();
    const organizationId = useOrganizationId();
    const { ordering, onOrderingChange, orderingOptions, isDescending } = useOrdering();
    const { t } = useTranslation();
    const [dimension, setDimension] = useState(metricOptions[0].value);
    const [variant, setVariant] = useState<ScoreComparisonVariant>(variantOptions[0].value);
    const { filters, ...rest } = useProductFilters();

    const { formatted, error, isLoading, data } = useEnterpriseScoreComparison(variant, dimension, filters, isDescending);

    return (
        <>
            <Group justify="space-between" align="center" mb="sm">
                <Box>
                    <Title size="h3">{t('enterpriseScoreComparison')}</Title>
                    <Text c="dimmed" size="sm">
                        {t('enterpriseScoreComparisonDescription')}
                    </Text>
                </Box>
                <ProductFilters
                    {...rest}
                    enabledFilters={['parentCategory', 'category', 'brand', 'tags']}
                    additionalFilters={
                        <Select
                            allowDeselect={false}
                            label={t('ordering')}
                            onChange={onOrderingChange}
                            value={ordering}
                            data={orderingOptions}
                        />
                    }
                >
                    <DownloadButton
                        exports={[
                            {
                                name: `${dimension}_brand_score_comparison`,
                                data: data?.results
                            }
                        ]}
                        disabled={!data?.results}
                    />
                </ProductFilters>
            </Group>

            {data?.num_results === DEFAULT_MAX_RESULTS && <TooManyResultsAlert maxResults={DEFAULT_MAX_RESULTS} />}

            <Section>
                <Group mb="md" gap="sm">
                    <Select
                        label="Group"
                        value={variant}
                        onChange={(value) => {
                            if (value) setVariant(value as ScoreComparisonVariant);
                        }}
                        data={variantOptions}
                        allowDeselect={false}
                    />

                    <Select
                        label="Metric"
                        value={dimension}
                        onChange={(value) => {
                            if (value) setDimension(value as Dimension);
                        }}
                        data={metricOptions}
                        allowDeselect={false}
                    />
                </Group>

                {error ? (
                    <ErrorCard error={error} />
                ) : (
                    <ComparisonChart
                        onClick={(data) => {
                            navigate(getComparisonChartNavigationPath(variant, organizationId, data));
                        }}
                        data={formatted}
                        isLoading={isLoading}
                        dimension={dimension}
                    />
                )}
            </Section>
        </>
    );
};
