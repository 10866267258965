import { GraphLegend } from 'shared/graph-legend';
import ScoreCircleGraph from 'shared/score-circle-graph';
import { ScoreGauge } from 'shared/score-gauge';
import { Section } from 'shared/section';
import { StaticTable } from 'shared/static-table';
import { Column } from 'shared/table-header';
import { useFeatureEnabled } from 'shared/use-feature-enabled';
import { WaffleGraph } from 'shared/waffle-graph';
import { Dimension } from 'types/shared';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Group, PaperProps, Skeleton, Stack, Text, Title, rem, useMantineTheme } from '@mantine/core';
import { displayRoundedValue } from 'utils/display-rounded-value';
import { FlagName } from 'utils/get-flag-value';
import { toPercentage } from 'utils/to-percentage';
import { DataQualityCard } from './data-quality-card';
import classes from './ecosystem-impact-cards.module.css';
import { roundRisk } from './round-risk';
import { useScoreReport } from './use-score-report';

const ChartSection = ({
    title,
    description,
    isLoading,
    data,
    ...props
}: {
    title: string;
    description: string;
    isLoading: boolean;
    data: {
        title: string;
        description: string;
        value: number;
        m: number;
        subtitle: string;
        color: string;
    }[];
}) => {
    return (
        <Section mt="md" {...props}>
            <Title size="h4" mb={rem(4)}>
                {title}
            </Title>
            <Text c="dimmed" size="sm">
                {description}
            </Text>

            {isLoading ? (
                <Skeleton mt="lg" height={180} />
            ) : (
                <Group
                    my={rem(40)}
                    style={{
                        justifyContent: 'space-evenly'
                    }}
                    gap="xl"
                >
                    <WaffleGraph data={data} m={0} />
                    <GraphLegend
                        items={data.map((row) => ({
                            ...row,
                            value: toPercentage(row.value)
                        }))}
                    />
                </Group>
            )}
        </Section>
    );
};

const StyledTable = ({ data, columns, isLoading }: { columns: Column[]; data: any; isLoading: boolean }) => {
    return (
        <StaticTable
            m={`${rem(20)} 0`}
            searchable={false}
            isLoading={isLoading}
            defaultSort="material_impact"
            columns={columns}
            rowKey={(row) => row.beautified_name}
            data={data ?? []}
        />
    );
};

export const EcosystemImpactCards = (props: PaperProps) => {
    const { isEnabled } = useFeatureEnabled();
    const { t } = useTranslation();
    const theme = useMantineTheme();
    const { id } = useParams<{ id: string }>();
    const { ecosystem, dqr } = useScoreReport(id);
    const ecosystemDqr = dqr?.data?.results?.[0]?.ecosystems_dqr;

    const { productResult, perMaterialResult, highestImpactMaterialsResult, bioDivWaterStatsResult } = ecosystem;

    const ecosystemSummary = productResult.data?.results.find((current) => current.dimension === Dimension.Ecosystem);
    const ecosystemWaterSummary = productResult.data?.results.find((current) => current.dimension === Dimension.EcosystemWater);
    const ecosystemBiodivSummary = productResult.data?.results.find((current) => current.dimension === Dimension.EcosystemBiodiversity);

    const firstBioDivWaterStatsResult = bioDivWaterStatsResult.data?.results[0];

    const biodiversityImpactColumns = [
        {
            key: 'beautified_name',
            name: t('material'),
            sortable: true
        },
        {
            key: 'material_impact',
            name: 'm²',
            sortable: true,
            render: (value: number) => displayRoundedValue(value, { unit: 'm²' }),
            width: 180
        }
    ];

    const waterImpactColumns = [
        {
            key: 'beautified_name',
            name: t('material'),
            sortable: true
        },
        {
            key: 'material_impact',
            name: t('liters'),
            sortable: true,
            render: (value: number) => displayRoundedValue(value, { unit: t('liters') }),
            width: 180
        }
    ];

    const biodiversityImpact = useMemo(() => {
        if (!perMaterialResult.data?.results) return [];

        const product = perMaterialResult.data?.results.find((current) => current.material_category === 'product');
        const packaging = perMaterialResult.data?.results.find((current) => current.material_category === 'packaging');

        return [
            {
                title: t('productMaterials'),
                description: t('productMaterialsBiodiversityImpactDescription'),
                value: product?.biodiv_percentage ?? 0,
                m: product?.biodiversity_impact ?? 0,
                subtitle: `= ${product?.biodiversity_impact?.toFixed(5) ?? 0} ${product?.biodiversity_impact_unit ?? 'm²'}`,
                color: theme.colors.blue[4]
            },
            {
                title: t('packagingMaterials'),
                description: t('packagingMaterialsBiodiversityImpactDescription'),
                value: packaging?.biodiv_percentage ?? 0,
                m: packaging?.biodiversity_impact ?? 0,
                subtitle: `= ${packaging?.biodiversity_impact?.toFixed(5) ?? 0} ${packaging?.biodiversity_impact_unit ?? 'm²'}`,
                color: theme.colors.yellow[5]
            }
        ];
    }, [perMaterialResult.data?.results, theme, t]);

    const waterSystemImpact = useMemo(() => {
        if (!perMaterialResult.data?.results) return [];

        const product = perMaterialResult.data?.results.find((current) => current.material_category === 'product');
        const packaging = perMaterialResult.data?.results.find((current) => current.material_category === 'packaging');

        return [
            {
                title: t('productMaterials'),
                description: "Water depleted in the production of the product's raw materials eg. cotton thread",
                value: product?.water_percentage ?? 0,
                m: product?.water_impact ?? 0,
                subtitle: `= ${product?.water_impact?.toFixed(5) ?? 0} ${product?.water_impact_unit ?? ''}`.trim(),
                color: theme.colors.blue[4]
            },
            {
                title: t('packagingMaterials'),
                description: "Water depleted in the production of the product's packaging eg. cardboard",
                value: packaging?.water_percentage ?? 0,
                m: packaging?.water_impact ?? 0,
                subtitle: `= ${packaging?.water_impact?.toFixed(5) ?? 0} ${packaging?.water_impact_unit ?? ''}`.trim(),
                color: theme.colors.yellow[5]
            }
        ];
    }, [perMaterialResult.data?.results, theme, t]);

    return (
        <>
            <Section {...props}>
                <Group align="flex-start" justify="space-between" gap="xs">
                    <Stack gap={rem(4)} className={classes.introduction}>
                        <Title size="h2">{t('ecosystemImpact')}</Title>
                        <Text c="dimmed" size="sm">
                            {t('ecosystemImpactScoreReportDescription')}
                        </Text>
                    </Stack>

                    {ecosystemSummary && (
                        <ScoreCircleGraph
                            peerPercentage={ecosystemSummary.category_average ?? 0}
                            percentage={ecosystemSummary.score ?? 0}
                            size="md"
                            className={classes.scoreCircle}
                            bottom={
                                <Text size="sm" fw={500}>
                                    Category average: {ecosystemSummary.category_average ?? 0}
                                </Text>
                            }
                        />
                    )}
                </Group>
            </Section>

            <ChartSection
                title={t('biodiversityImpact')}
                description={t('biodiversityImpactScoreReportDescription')}
                isLoading={perMaterialResult.isLoading}
                data={biodiversityImpact}
                {...props}
            />

            <Section mt="md" {...props}>
                <Title size="h4" mb={rem(4)}>
                    {t('productMaterialsWithTheHighestBiodiversityImpact')}
                </Title>
                <Text c="dimmed" size="sm">
                    {t('productMaterialsWithTheHighestBiodiversityImpactDescription')}
                </Text>

                <StyledTable
                    isLoading={highestImpactMaterialsResult.isLoading}
                    columns={biodiversityImpactColumns}
                    data={highestImpactMaterialsResult.data?.results.filter(
                        (current) => current.subdimension === 'biodiversity' && current.material_category === 'product'
                    )}
                />
            </Section>

            <Section mt="md" {...props}>
                <Title size="h4">{t('packagingMaterialsWithTheHighestBiodiversityImpact')}</Title>

                <StyledTable
                    columns={biodiversityImpactColumns}
                    isLoading={highestImpactMaterialsResult.isLoading}
                    data={highestImpactMaterialsResult.data?.results.filter(
                        (current) => current.subdimension === 'biodiversity' && current.material_category === 'packaging'
                    )}
                />
            </Section>

            <Section mt="md" {...props}>
                <Title size="h4" mb={rem(4)}>
                    {t('biodiversityImportanceRisk')}
                </Title>
                <Text c="dimmed" size="sm" mb={rem(32)}>
                    {t('biodiversityImportanceRiskDescription')}
                </Text>

                {firstBioDivWaterStatsResult ? (
                    <Group align="center" justify="center">
                        <ScoreGauge score={roundRisk(firstBioDivWaterStatsResult.biodiversity_importance_risk)} />
                    </Group>
                ) : (
                    <Skeleton mt="lg" height={100} />
                )}
            </Section>

            <Section mt="md" {...props}>
                <Title size="h4" mb={rem(4)}>
                    {t('shareOfMaterialsProducedOrganically')}
                </Title>
                <Text c="dimmed" size="sm" mb={rem(32)}>
                    {t('shareOfMaterialsProducedOrganicallyDescription')}
                </Text>

                {firstBioDivWaterStatsResult ? (
                    <Group align="center" justify="center">
                        <ScoreCircleGraph percentage={firstBioDivWaterStatsResult.organic_weight_average} />
                    </Group>
                ) : (
                    <Skeleton mt="lg" height={100} />
                )}
            </Section>

            <Section mt="md" {...props}>
                <Title size="h4" mb={rem(4)}>
                    {t('biodiversityImpactRating')}
                </Title>
                <Text c="dimmed" size="sm" mb={rem(32)}>
                    {t('biodiversityImpactRatingDescription')}
                </Text>

                {ecosystemBiodivSummary ? (
                    <Group align="center" justify="center">
                        <ScoreCircleGraph
                            peerPercentage={ecosystemBiodivSummary.category_average}
                            percentage={ecosystemBiodivSummary.biodiversity_impact_score}
                            size="lg"
                            bottom={
                                <Text size="sm" fw={500}>
                                    Category average: {ecosystemBiodivSummary.category_average}
                                </Text>
                            }
                        />
                    </Group>
                ) : (
                    <Skeleton height={180} />
                )}
            </Section>

            <ChartSection
                title={t('waterSystemImpact')}
                description={t('waterSystemImpactDescription')}
                isLoading={perMaterialResult.isLoading}
                data={waterSystemImpact}
                {...props}
            />

            <Section mt="md" {...props}>
                <Title size="h4" mb={rem(4)}>
                    {t('productMaterialsWithTheHighestWaterImpact')}
                </Title>
                <Text c="dimmed" size="sm">
                    {t('productMaterialsWithTheHighestWaterImpactDescription')}
                </Text>

                <StyledTable
                    isLoading={highestImpactMaterialsResult.isLoading}
                    columns={waterImpactColumns}
                    data={highestImpactMaterialsResult.data?.results.filter(
                        (current) => current.subdimension === 'water' && current.material_category === 'product'
                    )}
                />
            </Section>

            <Section mt="md" {...props}>
                <Title size="h4">{t('packagingMaterialsWithTheHighestWaterImpact')}</Title>
                <StyledTable
                    columns={waterImpactColumns}
                    isLoading={highestImpactMaterialsResult.isLoading}
                    data={highestImpactMaterialsResult.data?.results.filter(
                        (current) => current.subdimension === 'water' && current.material_category === 'packaging'
                    )}
                />
            </Section>

            <Section mt="md" {...props}>
                <Title size="h4" mb={rem(4)}>
                    {t('weightedRiskOfWaterScarcity')}
                </Title>
                <Text c="dimmed" size="sm" mb={rem(32)}></Text>
                {firstBioDivWaterStatsResult ? (
                    <Group align="center" justify="center">
                        <ScoreGauge score={roundRisk(firstBioDivWaterStatsResult.water_risk_average)} />
                    </Group>
                ) : (
                    <Skeleton mt="lg" height={100} />
                )}
            </Section>

            <Section mt="md" {...props}>
                <Title size="h4" mb={rem(4)}>
                    {t('waterImpactRating')}
                </Title>
                <Text c="dimmed" size="sm" mb={rem(32)}>
                    {t('waterImpactRatingDescription')}
                </Text>

                {ecosystemWaterSummary ? (
                    <Group align="center" justify="center">
                        <ScoreCircleGraph
                            peerPercentage={ecosystemWaterSummary.category_average}
                            percentage={ecosystemWaterSummary.water_impact_score}
                            size="lg"
                            bottom={
                                <Text size="sm" fw={500}>
                                    {t('categoryAverage')}: {ecosystemWaterSummary.category_average}
                                </Text>
                            }
                        />
                    </Group>
                ) : (
                    <Skeleton height={180} />
                )}
            </Section>
            {isEnabled(FlagName.DataQualityRating) && (
                <Section mt="md" {...props}>
                    <DataQualityCard score={ecosystemDqr ?? undefined} />
                </Section>
            )}
            <Text c="dimmed" fs="italic" size="sm" my="lg">
                {t('ecosystemImpactFooter')}
            </Text>
        </>
    );
};
