import { useTranslation } from 'react-i18next';
import { Select } from '@mantine/core';
import { useFileSelectOptions } from './use-file-select-options';

export const FileSelect = ({ value, onChange }: { value: string; onChange: (value: string | null) => void }) => {
    const { t } = useTranslation();
    const { options, isLoading } = useFileSelectOptions();

    return (
        <Select
            data-testid="file-select"
            data={options}
            searchable
            disabled={isLoading}
            allowDeselect
            clearable
            label={t('file')}
            nothingFoundMessage={t('noOptionMatchingSearch')}
            onChange={(value) => onChange(value)}
            value={value}
        />
    );
};
