import { RecaptchaVerifier, multiFactor, PhoneAuthProvider } from 'firebase/auth';
import { auth } from 'lib/firebase';
import { showAuthError } from '../../shared/show-auth-error';

export const startEnrollMultiFactor = async (phoneNumber: string | null) => {
    if (!auth.currentUser) return;

    if (!window.userSettingsRecaptchaVerifier) {
        window.userSettingsRecaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', { size: 'invisible' });
    }

    try {
        const multiFactorSession = await multiFactor(auth.currentUser).getSession();

        const phoneInfoOptions = {
            phoneNumber: phoneNumber,
            session: multiFactorSession
        };

        const phoneAuthProvider = new PhoneAuthProvider(auth);
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.userSettingsRecaptchaVerifier);
        return verificationId;
    } catch (error: any) {
        showAuthError(error);
    }
};
