import { useOrganizationId } from 'shared/use-organization-id';
import useSWR from 'swr';
import { Group, GroupUpdate } from 'types';
import { request } from 'lib/request-client';

export const useGroup = (id?: string) => {
    const organizationId = useOrganizationId();
    const { data, error, mutate, isLoading } = useSWR<Group>(id ? `/account_manager/group/group/${id}` : null);

    const update = async (body: GroupUpdate) => {
        await request.url(`/account_manager/group/group/${id}`).patch({
            ...body,
            organization_id: `organization/${organizationId}`
        });
        mutate();
    };

    const create = async (body: Partial<Group>) => {
        await request.url(`/account_manager/group/create`).post({
            ...body,
            organization_id: `organization/${organizationId}`
        });
        mutate();
    };

    return { data, error, mutate, isLoading, update, create };
};
