import { OrderDirection } from 'types/shared';
import { ReactNode } from 'react';
import { TbChevronDown, TbSelector, TbChevronUp } from 'react-icons/tb';
import { Box, Group, Table, Text, UnstyledButton } from '@mantine/core';
import classes from './table-header.module.css';

export interface Column {
    key: string;
    name: string;
    align?: 'left' | 'center' | 'right';
    render?: (value: any, row: any, index: number) => ReactNode;
    width?: number | string;
    sortable?: boolean;
}

export const TableHeader = ({
    sortColumn,
    column,
    isOrderSupported,
    sortDirection,
    onSort
}: {
    column: Column;
    sortColumn?: string;
    isOrderSupported?: boolean;
    sortDirection: OrderDirection;
    onSort: (direction: OrderDirection) => void;
}) => {
    if (!column.sortable || !isOrderSupported) {
        return (
            <Box component={Table.Th} maw={column.width} style={{ whiteSpace: 'nowrap', textAlign: `${column.align ?? 'left'}` }}>
                {column.name}
            </Box>
        );
    }

    const SortIcon = sortDirection === OrderDirection.Ascending ? TbChevronUp : TbChevronDown;
    const Icon = sortColumn === column.key ? SortIcon : TbSelector;

    return (
        <Table.Th style={{ maxWidth: column.width, paddingLeft: 0, paddingRight: 0 }}>
            <UnstyledButton
                onClick={() => onSort(sortDirection === OrderDirection.Ascending ? OrderDirection.Descending : OrderDirection.Ascending)}
                className={classes.button}
            >
                <Group wrap="nowrap">
                    <Text fw={500} fz="sm">
                        {column.name}
                    </Text>
                    <Icon size={16} />
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
};
