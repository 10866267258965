import { useMantineTheme } from '@mantine/core';

export const useBiobasedMaterialsMapping = () => {
    const theme = useMantineTheme();
    return {
        biobased: {
            title: 'Biobased',
            color: theme.colors.blue[4]
        },
        abiotic: {
            title: 'Abiotic',
            color: theme.colors.yellow[5]
        }
    };
};
