import { DownloadButton } from 'shared/download-button';
import { ErrorCard } from 'shared/error-card';
import { ProductFilters } from 'shared/product-filters';
import { Section } from 'shared/section';
import { Table } from 'shared/table';
import { TooManyResultsAlert } from 'shared/too-many-results-alert';
import { useProductFilters } from 'shared/use-product-filters';
import { useTranslation } from 'react-i18next';
import { Box, Skeleton, Text, Title, Group } from '@mantine/core';
import { queryResultToTable } from 'utils/query-result-to-table';
import { DEFAULT_MAX_RESULTS } from 'utils/run-query';
import { useDownloadScoreData } from './use-download-score-data';

export const DownloadScoreData = () => {
    const { t } = useTranslation();
    const { filters, ...rest } = useProductFilters();

    const { data, error, isLoading } = useDownloadScoreData(filters);

    if (error) {
        return <ErrorCard error={error} />;
    }

    const { columns: resultColumns, data: resultData } = queryResultToTable(data?.results);

    return (
        <>
            <Group justify="space-between" align="center" mb="md">
                <Box>
                    <Title size="h3">{t('downloadScoreData')}</Title>
                    <Text size="sm" c="dimmed">
                        {t('downloadScoreDataDescription')}
                    </Text>
                </Box>
            </Group>

            {data?.num_results === DEFAULT_MAX_RESULTS && <TooManyResultsAlert maxResults={DEFAULT_MAX_RESULTS} />}

            <Section>
                {isLoading ? (
                    <Skeleton height={300} data-testid="loading-score-data" />
                ) : (
                    <Table
                        actions={
                            <ProductFilters {...rest}>
                                <DownloadButton
                                    disabled={!data?.results}
                                    exports={[
                                        {
                                            name: 'download_score_data',
                                            data: data?.results
                                        }
                                    ]}
                                />
                            </ProductFilters>
                        }
                        columns={resultColumns}
                        data={resultData}
                    />
                )}
            </Section>
        </>
    );
};
