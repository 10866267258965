import { paths } from 'paths';
import { ErrorCard } from 'shared/error-card';
import { PreviewBadge } from 'shared/preview-badge';
import { Section } from 'shared/section';
import { Table } from 'shared/table';
import { useFeatureEnabled } from 'shared/use-feature-enabled';
import { useOrganizationId } from 'shared/use-organization-id';
import { useUser } from 'shared/use-user';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbDotsVertical } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { ActionIcon, Badge, Button, Code, Group, Menu, Text, Title } from '@mantine/core';
import { formatHeader } from 'utils/format-header';
import { FlagName } from 'utils/get-flag-value';
import { getIdFromKey } from 'utils/get-id-from-key';
import { OrganizationModal } from './organization-modal';
import { useOrganizations } from './use-organizations';

export const Organizations = () => {
    const { isEnabled } = useFeatureEnabled();
    const organizationId = useOrganizationId();
    const [activeOrganizationId, setActiveOrganizationId] = useState<string | undefined>(undefined);
    const { data, isLoading, limit, onLimitChange, search, onSearchChange, page, onPage, order, onOrderChange, error } = useOrganizations();

    const { t } = useTranslation();
    const { hasPermission } = useUser();

    if (error) {
        return <ErrorCard error={error} />;
    }

    return (
        <>
            <Group gap="xs">
                <Title size="h3">{t('organizations')}</Title>
                {isEnabled(FlagName.PartnerOrganizations, 'preview') && <PreviewBadge />}
            </Group>
            <Text c="dimmed" mb="md">
                {t('organizationsDescription')}
            </Text>

            <Section>
                <Table
                    isLoading={isLoading}
                    data={data?.items}
                    rowKey={(row) => row.key}
                    actions={
                        <Group gap="sm">
                            {hasPermission('/account_manager/organization/create', 'POST') && (
                                <Button component={Link} to={paths.accountManagement.createOrganization(organizationId)}>
                                    {t('createPartnerOrganization')}
                                </Button>
                            )}
                        </Group>
                    }
                    columns={[
                        {
                            key: 'name',
                            name: t('name')
                        },
                        {
                            key: 'description',
                            name: t('description'),
                            width: 300
                        },
                        {
                            key: 'auth_domain',
                            name: t('authDomain'),
                            render: (value) => <Code>{value}</Code>
                        },
                        {
                            key: 'type',
                            name: t('type'),
                            render: (value) => <Badge>{value}</Badge>
                        },
                        {
                            key: 'actions',
                            name: '',
                            align: 'right',
                            render: (_, row) => {
                                return (
                                    <Menu shadow="md">
                                        <Menu.Target>
                                            <ActionIcon data-testid={`menu-${row.key}`}>
                                                <TbDotsVertical size={16} />
                                            </ActionIcon>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            {!row.internal && hasPermission('/query_manager/query', 'PATCH') && (
                                                <Menu.Item
                                                    onClick={() => {
                                                        setActiveOrganizationId(getIdFromKey(row.key));
                                                    }}
                                                >
                                                    Update
                                                </Menu.Item>
                                            )}
                                        </Menu.Dropdown>
                                    </Menu>
                                );
                            }
                        }
                    ]}
                    search={formatHeader(search)}
                    onSearchChange={(value) => onSearchChange(value)}
                    limit={limit}
                    onLimitChange={onLimitChange}
                    page={page}
                    onPage={onPage}
                    order={order}
                    onOrderChange={onOrderChange}
                />
            </Section>

            <OrganizationModal
                id={activeOrganizationId}
                isOpen={!!activeOrganizationId}
                onClose={() => setActiveOrganizationId(undefined)}
            />
        </>
    );
};
