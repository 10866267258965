import { useTableFilters } from 'shared/use-table-filters';
import useSWR from 'swr';
import { Group, QueryResult } from 'types';
import { useEffect } from 'react';
import { request } from 'lib/request-client';

export const useOrganizationGroups = () => {
    const { query, page, onPage, limit, onLimitChange, order, onOrderChange, search, onSearchChange, onPageTokenMappingChange } =
        useTableFilters({
            searchKey: 'description'
        });
    const { data, error, mutate, isLoading } = useSWR<QueryResult<Group>>(['/account_manager/groups/query', query]);

    const remove = async (id: string) => {
        await request.url(`/account_manager/group/group/${id}`).delete();
        mutate();
    };

    useEffect(() => {
        if (data && !isLoading && data.page_token) {
            onPageTokenMappingChange((current) => ({
                ...current,
                [page + 1]: data.page_token
            }));
        }
    }, [data, isLoading, onPageTokenMappingChange, page]);

    return { data, error, mutate, isLoading, remove, page, onPage, onLimitChange, onOrderChange, onSearchChange, search, limit, order };
};
