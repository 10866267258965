import { useTranslation } from 'react-i18next';
import { Loader, Modal, Stack, Text } from '@mantine/core';

export const PdfGeneratingModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
    const { t } = useTranslation();
    return (
        <Modal opened={isOpen} onClose={onClose} size="sm" withCloseButton={false}>
            <Stack align="center" my="xl">
                <Text fw={500} ta="center" size="md">
                    {t('scoreReportPdfGenerating')}
                </Text>
                <Loader size="xl" />
            </Stack>
        </Modal>
    );
};
