import { DownloadButton } from 'shared/download-button';
import { ProductFilters } from 'shared/product-filters';
import { Section } from 'shared/section';
import { StaticTable } from 'shared/static-table';
import { useProductFilters } from 'shared/use-product-filters';
import { useTranslation } from 'react-i18next';
import { Box, Group, Text, Title, rem } from '@mantine/core';
import { toFixedIfNeeded } from 'utils/to-fixed-if-needed';
import { ErrorAlert } from '../shared/error-alert';
import { renderCountryCell } from './render-country-cell';
import { useEcosystemImpactsOfMaterialsInsightReport } from './use-ecosystem-impacts-of-materials-insight-report';

const getRowKey = (row: { material_id: string; alop_absolute: string; alop_per_kg: string }) =>
    `${row.material_id}-${row.alop_absolute}-${row.alop_per_kg}`;

export const EcosystemImpactsOfMaterials = () => {
    const { t } = useTranslation();
    const { filters, ...rest } = useProductFilters();
    const { landUseResult, waterImpactResult } = useEcosystemImpactsOfMaterialsInsightReport(filters);

    const columns = [
        {
            key: 'beautified_name',
            name: t('productMaterials'),
            sortable: true
        },
        {
            key: 'region',
            name: t('materialSourceCountry'),
            sortable: true,
            render: renderCountryCell
        },
        {
            key: 'alop_per_kg',
            name: t('perKg'),
            sortable: true,
            render: (value: number, row: { unit: string }) => `${toFixedIfNeeded(value)} ${row.unit}`,
            width: 180
        },
        {
            key: 'alop_absolute',
            name: t('absolute'),
            sortable: true,
            render: (value: number, row: { unit: string }) => `${toFixedIfNeeded(value)} ${row.unit}`,
            width: 180
        }
    ];

    return (
        <>
            <Group justify="space-between" align="center" mb="md">
                <Box>
                    <Title size="h3">{t('ecosystemImpactsOfMaterials')}</Title>
                    <Text c="dimmed" size="sm" style={{ maxWidth: '80%' }}>
                        {t('ecosystemImpactsOfMaterialsDescription')}
                    </Text>
                </Box>
                <ProductFilters enabledFilters={['brand', 'category', 'tags']} {...rest}>
                    {landUseResult.data?.results && waterImpactResult.data?.results && (
                        <DownloadButton
                            exports={[
                                {
                                    name: 'materials_with_the_highest_land_use',
                                    data: landUseResult.data.results
                                },
                                {
                                    name: 'materials_with_the_highest_water_impacts',
                                    data: waterImpactResult.data.results
                                }
                            ]}
                        />
                    )}
                </ProductFilters>
            </Group>

            <Section mb="md">
                <Title size="h4">{t('materialsWithTheHighestLandUse')}</Title>

                {landUseResult.error ? (
                    <ErrorAlert error={landUseResult.error} />
                ) : (
                    <StaticTable
                        isLoading={landUseResult.isLoading}
                        m={`${rem(32)} 0`}
                        columns={columns}
                        showMoreLimit={10}
                        rowKey={getRowKey}
                        data={landUseResult.data?.results}
                    />
                )}

                <Text c="dimmed" size="sm">
                    {t('materialsWithTheHighestLandUseDescription')}
                </Text>
            </Section>

            <Section mb="md">
                <Title size="h4">{t('materialsWithTheHighestWaterImpacts')}</Title>

                {waterImpactResult.error ? (
                    <ErrorAlert error={waterImpactResult.error} />
                ) : (
                    <StaticTable
                        isLoading={waterImpactResult.isLoading}
                        m={`${rem(32)} 0`}
                        columns={columns}
                        showMoreLimit={10}
                        rowKey={getRowKey}
                        data={waterImpactResult.data?.results}
                    />
                )}
                <Text c="dimmed" size="sm">
                    {t('materialsWithTheHighestWaterImpactsDescription')}
                </Text>
            </Section>
        </>
    );
};
