import { TransportTypeCell, TransportType } from 'shared/transport-type-cell';
import { displayRoundedValue } from './display-rounded-value';

export const getTransportationTypeColumns = () => [
    {
        key: 'transport_mode',
        name: 'Transportation type',
        sortable: true,
        render: (value: TransportType) => <TransportTypeCell type={value} />
    },
    {
        key: 'distance_km',
        name: 'Distance in km',
        sortable: true,
        render: (value: number) => `${value.toFixed(3)} km`
    },
    {
        key: 'gwp_absolute',
        name: 'Absolute',
        sortable: true,
        render: displayRoundedValue
    }
];
