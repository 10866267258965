import { links } from 'links';
import { SummaryResult } from 'products/use-summary';
import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import ScoreCircleGraph from 'shared/score-circle-graph';
import { useScoreReportSections } from 'shared/use-score-report-sections';
import { Dimension } from 'types/shared';
import { useParams } from 'react-router-dom';
import { Anchor, Group, Paper, Stack, Text, rem, Image, Skeleton, PaperProps, Box } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { NoDataAlert } from './no-data-alert';
import classes from './summary-card.module.css';
import { useScoreReport } from './use-score-report';

const getDataForDimension = (data: SummaryResult[] | undefined, dimension: Dimension) => {
    if (!data || data.length === 0)
        return {
            score: 0,
            avg: 0
        };

    const match = data.find((current) => current.dimension === dimension);

    return {
        score: match?.score ?? 0,
        avg: match?.category_average ?? 0
    };
};

export const SummaryCard = (props: PaperProps) => {
    const { id } = useParams<{ id: string }>();
    const scoreReportSections = useScoreReportSections();
    const { summary, dqr, isSummaryLoading, isDqrLoading } = useScoreReport(id);

    const hasNoDqrData = !isDqrLoading && dqr?.data?.results.length === 0;
    const totalProductDqr = dqr?.data?.results?.[0]?.total_primary_dqr ?? 0;

    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');

    const hasNoData = !isSummaryLoading && summary?.length === 0;

    return (
        <Paper p="md" {...props}>
            {isSummaryLoading ? (
                <Skeleton height={300} />
            ) : hasNoData ? (
                <NoDataAlert />
            ) : (
                <Stack gap={rem(32)} className={classes.container}>
                    {scoreReportSections.map((section) => {
                        const { avg, score } = getDataForDimension(summary, section.value);
                        return (
                            <Box
                                key={section.value}
                                className={classes.section}
                                style={{
                                    backgroundColor: section.value === 'dqr' ? '#f0f8ff' : 'transparent',
                                    borderRadius: rem(8),
                                    padding: section.value === 'dqr' ? rem(16) : rem(8)
                                }}
                            >
                                <Image className={classes.graphic} width={72} height={72} src={section.icon} alt="" />
                                <Stack gap={rem(4)} className={classes.inner}>
                                    <Group gap="xs">
                                        <Text size="xl" fw={700}>
                                            {section.title}
                                        </Text>
                                        {section.value === 'total' && (
                                            <MoreInfoHoverCard>
                                                <Text size="sm" fw={500} mb="sm">
                                                    How we calculate your score
                                                </Text>
                                                <Text size="sm" c="dimmed" maw={isSmallDevice ? rem(230) : rem(400)}>
                                                    We have assessed this product based on your survey answers and the most up-to-date
                                                    information from cutting edge sustainability and environmental impacts databases. The 5
                                                    dimensions of the Dayrize Score are weighted equally, with each contributing 20% to your
                                                    final score. Within each dimension we provide the category average score for
                                                    benchmarking purposes which refers to all the products we have scored so far in your
                                                    product category. As our database constantly grows, you may see this range changing
                                                    values over time. For more details read about the{' '}
                                                    <Anchor href={links.dayrizeScore} target="_blank">
                                                        Dayrize score here
                                                    </Anchor>{' '}
                                                    .
                                                </Text>
                                            </MoreInfoHoverCard>
                                        )}
                                    </Group>

                                    <Text c="dimmed" className={classes.introduction}>
                                        {section.description}
                                    </Text>
                                </Stack>
                                {section.value === 'dqr' ? (
                                    <Text
                                        style={{
                                            fontWeight: 500,
                                            lineHeight: 1,
                                            fontSize: rem(42),
                                            paddingRight: rem(16)
                                        }}
                                    >
                                        {hasNoDqrData ? 'N/A' : Math.round(totalProductDqr) + '%'}
                                    </Text>
                                ) : (
                                    <ScoreCircleGraph
                                        onClick={section.onClick}
                                        peerPercentage={avg}
                                        percentage={score}
                                        size={isSmallDevice ? 'xl' : 'md'}
                                        bottom={
                                            <Text size="sm" c="dimmed">
                                                Category average: {avg}
                                            </Text>
                                        }
                                    />
                                )}
                            </Box>
                        );
                    })}
                </Stack>
            )}
        </Paper>
    );
};
