import i18n from 'lib/i18n';
import { request } from 'lib/request-client';

interface UploadRequestResult {
    file_key: string;
    url: string;
}

const t = (textCode: string) => i18n?.t(textCode);

export const DUPLICATE_ERROR_MESSAGE_KEY = 'duplicateFileError';

export const uploadFile = async (
    body: {
        file: File;
        file_category_type: string;
        tags: Array<string>;
        rule_config_id?: string;
        nameOverride?: string;
        minimal_requirements: boolean;
    },
    fetcher = fetch
) => {
    let uploadRequestResponse = (await request.url(`/ingest/file/upload-request`).post([
        {
            name: body.nameOverride ?? body.file.name,
            category: body.file_category_type,
            tags: body.tags,
            rule_config_id: body.rule_config_id,
            minimal_requirements: body.minimal_requirements
        }
    ])) as UploadRequestResult[];

    if (!uploadRequestResponse[0].url) {
        throw new Error(t(DUPLICATE_ERROR_MESSAGE_KEY));
    }

    const uploadResponse = await fetcher(uploadRequestResponse[0].url, {
        method: 'PUT',
        body: body.file,
        headers: {
            'Content-Type': 'application/octet-stream'
        }
    });

    if (!uploadResponse.ok) {
        throw new Error(t('uploadFailedError'));
    }
};
