import { Dashboard } from 'types';

export const schema: Dashboard = {
    name: 'Dashboard',
    widgets: [
        {
            type: 'value',
            name: 'Total products',
            description: 'Total number of products for your organization.',
            span: 6,
            query_name: 'product_scoring_results',
            link: '/organization/{organizationId}/products',
            variant: 'count',
            pageSize: 1
        },
        {
            type: 'value',
            name: 'Total categories',
            description: 'Total number of categories baed on the products for your organization.',
            span: 6,
            query_name: 'product_categories',
            link: '/organization/{organizationId}/products',
            variant: 'count',
            pageSize: 1
        },
        {
            type: 'value',
            name: 'Successful data uploads',
            description: 'Total number of times a data has been successfully ingested.',
            span: 6,
            query_name: 'total_files',
            column_name: 'count',
            link: `/organization/{organizationId}/audit-log?level=INFO&component=Ingest`,
            filters: [
                {
                    name: 'level',
                    operation: '=',
                    value: 'INFO'
                }
            ],
            variant: 'count',
            selects: [
                {
                    type: 'interval',
                    column_name: `created_at`
                }
            ]
        },
        {
            type: 'value',
            name: 'Data upload errors',
            description: 'Total number of errors encountered during data ingestion.',
            span: 6,
            query_name: 'total_files',
            column_name: 'count',
            link: `/organization/{organizationId}/audit-log?level=ERROR&component=Ingest`,
            filters: [
                {
                    name: 'level',
                    operation: '=',
                    value: 'ERROR'
                }
            ],
            variant: 'count',
            selects: [
                {
                    type: 'interval',
                    column_name: `created_at`
                }
            ]
        },
        {
            type: 'bar-chart',
            name: 'Products scored by day',
            description:
                'Count showing the number of products scored per a day for the selected period. Days without scored products will be hidden.',
            tooltip_label: 'Count',
            query_name: 'score_count_per_day',
            span: 12,
            selects: [
                {
                    type: 'interval',
                    column_name: 'scored_at'
                }
            ],
            y_axis: {
                column_name: 'score_count',
                type: 'number'
            },
            x_axis: {
                column_name: 'score_date',
                type: 'date'
            }
        },
        {
            type: 'list',
            variant: 'score',
            name: 'Top 5 highest scored',
            description: 'The products with the highest total score for the selected period.',
            pageSize: 5,
            span: 6,
            query_name: 'product_scoring_results',
            selects: [
                {
                    type: 'interval',
                    column_name: 'scored_at'
                }
            ],
            ordering: [
                {
                    name: 'total_score',
                    desc: true
                }
            ],
            label_column_name: 'name',
            value_column_name: 'total_score',
            link: `/organization/{organizationId}/products/{id}/score-report/total?name={name}&sku={sku}`
        },
        {
            type: 'list',
            variant: 'score',
            name: 'Top 5 lowest scored',
            description: 'The products with the lowest total score for the selected period.',
            pageSize: 5,
            span: 6,
            query_name: 'product_scoring_results',
            selects: [
                {
                    type: 'interval',
                    column_name: 'scored_at'
                }
            ],
            ordering: [
                {
                    name: 'total_score',
                    desc: false
                }
            ],
            label_column_name: 'name',
            value_column_name: 'total_score',
            link: `/organization/{organizationId}/products/{id}/score-report/total?name={name}&sku={sku}`
        },
        {
            type: 'list',
            variant: 'count',
            name: 'Top 5 most active users',
            description: 'The most active users on your organization ranked by the number of audit log entries.',
            pageSize: 5,
            span: 12,
            query_name: 'email_activity_count',
            selects: [
                {
                    type: 'interval',
                    column_name: `created_at`
                }
            ],
            label_column_name: 'email',
            value_column_name: 'activity_count',
            link: '/organization/{organizationId}/audit-log?email={email}'
        }
    ]
};
