import { Nullable } from 'types';
import { useTranslation } from 'react-i18next';
import { TbCheck, TbCopy } from 'react-icons/tb';
import { ActionIcon, CopyButton, Modal, MultiSelect, Stack, TextInput } from '@mantine/core';
import { ProductWithScoringResults } from './use-products';

export const ProductModal = ({
    product,
    isOpen,
    onClose
}: {
    product?: Nullable<ProductWithScoringResults>;
    isOpen: boolean;
    onClose: () => void;
}) => {
    const { t } = useTranslation();
    if (!product) return null;
    return (
        <Modal size="lg" title={t('viewProduct')} opened={isOpen} onClose={onClose}>
            <form>
                <Stack mb="xl" mt="lg" gap="xs">
                    <TextInput label={t('name')} value={product.name} readOnly />
                    <TextInput
                        label={t('sku')}
                        value={product.sku}
                        readOnly
                        rightSection={
                            <CopyButton value={product.sku}>
                                {({ copied, copy }) => (
                                    <ActionIcon variant="transparent" onClick={copy}>
                                        {copied ? <TbCheck /> : <TbCopy />}
                                    </ActionIcon>
                                )}
                            </CopyButton>
                        }
                    />
                    <TextInput label={t('category')} value={product.category ?? 'None'} readOnly />
                    <TextInput label={t('brand')} value={product.brand ?? 'None'} readOnly />
                    <MultiSelect label={t('tags')} data={product.tags} value={product.tags} readOnly />
                    <TextInput label={t('updatedOn')} value={new Date(product.updated_at).toLocaleString()} readOnly />
                    <TextInput
                        label={t('scoredOn')}
                        value={product.scored_at ? new Date(product.scored_at).toLocaleString() : t('notScored')}
                        readOnly
                    />
                </Stack>
            </form>
        </Modal>
    );
};
