export type FlattenResult = {
    [key: string]: any;
};

const isValue = (value: any) => typeof value !== 'object' || value === null;
const isObject = (value: any) => typeof value === 'object' && value !== null && !Array.isArray(value);
const objectToPairs = (object: any) =>
    Object.keys(object)
        .map((key) => `${key}: ${JSON.stringify(object[key])}`)
        .join(', ');

export const flattenObj = (obj: any, delimiter = '_', parentKey: string = '', result: FlattenResult = {}): FlattenResult => {
    if (isValue(obj)) {
        result[parentKey] = obj;
    } else {
        Object.keys(obj).forEach((key) => {
            if (obj.hasOwnProperty(key)) {
                const newKey = parentKey ? `${parentKey}${delimiter}${key}` : key;
                if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                    flattenObj(obj[key], delimiter, newKey, result);
                } else if (Array.isArray(obj[key])) {
                    if (obj[key].length === 1) return flattenObj(obj[key][0], delimiter, newKey, result);
                    const valueArr = obj[key].every(isValue);
                    const isObjectArr = obj[key].every(isObject);
                    if (isObjectArr) {
                        result[newKey] = obj[key].map((current: any) => objectToPairs(current));
                    } else if (valueArr) {
                        result[newKey] = obj[key].join(', ');
                    } else {
                        result[newKey] = JSON.stringify(obj[key]);
                    }
                } else {
                    result[newKey] = obj[key];
                }
            }
        });
    }

    return result;
};
