const UPPERCASE_TITLES = ['id', 'eol', 'ip', 'sku', 'gwp', 'eia'];

export const formatHeader = (header?: string | Array<string>) => {
    if (!header) return '';
    const joinedHeader = Array.isArray(header) ? header.join(' ') : header;
    return joinedHeader
        .split(/[_ ]+/)
        .map((word) => {
            const lowercasedWord = word.toLowerCase();
            if (UPPERCASE_TITLES.includes(lowercasedWord)) return lowercasedWord.toUpperCase();
            return lowercasedWord.charAt(0).toUpperCase() + lowercasedWord.slice(1);
        })
        .join(' ');
};
