import { Nullable } from 'types';
import { useTranslation } from 'react-i18next';
import { TbCheck, TbCopy } from 'react-icons/tb';
import { ActionIcon, CopyButton, Modal, Stack, TextInput, Textarea } from '@mantine/core';
import { AuditLogItem } from './use-audit-log';

const formatJSON = (value?: string) => {
    if (!value) return '';
    try {
        const json = JSON.parse(value);
        return JSON.stringify(json, null, 2);
    } catch {
        return value;
    }
};

export const LogEntryModal = ({ data, isOpen, onClose }: { data: Nullable<AuditLogItem>; isOpen: boolean; onClose: () => void }) => {
    const { t } = useTranslation();
    if (!data) return null;
    return (
        <Modal size="xl" title={t('viewLogEntry')} opened={isOpen} onClose={onClose}>
            <form>
                <Stack mb="xl" mt="lg" gap="xs">
                    <TextInput label={t('createdOn')} value={data.created_at ? new Date(data.created_at).toLocaleString() : ''} readOnly />
                    <TextInput label={t('action')} value={data.action ?? ''} readOnly />
                    <TextInput label={t('component')} value={data.component ?? ''} readOnly />
                    <TextInput
                        label={t('correlationId')}
                        value={data.correlation_id ?? ''}
                        readOnly
                        rightSection={
                            data.correlation_id && (
                                <CopyButton value={data.correlation_id}>
                                    {({ copied, copy }) => (
                                        <ActionIcon variant="transparent" onClick={copy}>
                                            {copied ? <TbCheck /> : <TbCopy />}
                                        </ActionIcon>
                                    )}
                                </CopyButton>
                            )
                        }
                    />
                    <TextInput label={t('email')} value={data.email ?? ''} readOnly />
                    <TextInput label={t('ipAddress')} value={data.ip_address ?? ''} readOnly />
                    <TextInput label={t('level')} value={data.level ?? ''} readOnly />
                    <Textarea label={t('message')} value={formatJSON(data.message)} readOnly autosize minRows={3} maxRows={32} />
                    <Textarea label={t('properties')} value={formatJSON(data.properties)} readOnly autosize minRows={3} maxRows={24} />
                    <Textarea label={t('userAgent')} value={data.user_agent ?? ''} readOnly autosize minRows={1} />
                </Stack>
            </form>
        </Modal>
    );
};
