import { usePrism } from 'insights-explorer/use-prism';
import { ErrorCard } from 'shared/error-card';
import { ImpactList } from 'shared/impact-list';
import { ImpactNumberInput } from 'shared/impact-number-input';
import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import { PreviewBadge } from 'shared/preview-badge';
import { StaticTable } from 'shared/static-table';
import { useFeatureEnabled } from 'shared/use-feature-enabled';
import { Filter } from 'types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbAlertCircle, TbArrowRight, TbDotsVertical, TbDownload } from 'react-icons/tb';
import { useParams } from 'react-router-dom';
import { ActionIcon, Badge, Button, Group, Menu, Paper, Text, Stack, Title, rem, Alert } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { formatHeader } from 'utils/format-header';
import { FlagName } from 'utils/get-flag-value';
import { showErrorMessage } from 'utils/show-error-message';
import { sumArr } from 'utils/sum-arr';
import { toFixedIfNeeded } from 'utils/to-fixed-if-needed';
import { CircularityBreakdown } from './circularity-breakdown';
import { getCircularityPercentages } from './get-circularity-percentages';
import { MaterialsAutoComplete } from './materials-autocomplete';
import { useComponentToPdf } from './use-component-to-pdf';
import { Material, useMaterials } from './use-materials';
import { useProduct } from './use-product';

const metersCubedToLiters = (metersCubed: number) => metersCubed * 1000;
const formatStatValue = (value: number | null) => (Number.isNaN(value) || value === null ? '-' : toFixedIfNeeded(value, 2));

const MAX_DIFF = 0.15;

const toRounded = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100;

interface WeightAdjustedProduct {
    category: string;
    material_id: string;
    weight: {
        value: number;
    };
}

const getWeightAdjusted = ({
    productMaterials = [],
    materials = []
}: {
    productMaterials: Array<WeightAdjustedProduct> | undefined;
    materials: Array<Material> | undefined;
}) => {
    const filteredProductMaterials = productMaterials.filter((current) => current.category === 'product');
    const filteredPackagingMaterials = productMaterials.filter((current) => current.category === 'packaging');

    const mapToWeightAdjusted = (current: WeightAdjustedProduct) => {
        const active = materials.find((row) => row.id === current.material_id)!;
        if (!active) return null;
        return {
            gwp: active.gwp.value * current.weight.value,
            wdp: metersCubedToLiters(active.wdp.value * current.weight.value),
            alop: active.alop.value * current.weight.value
        };
    };

    return {
        products: filteredProductMaterials.map(mapToWeightAdjusted).filter(Boolean),
        packaging: filteredPackagingMaterials.map(mapToWeightAdjusted).filter(Boolean)
    };
};

interface ModelledRow {
    id: string;
    name: string;
    category: string;
    weight: number;
    region?: string;
    unit?: string;
    organic?: boolean | null;
}

export const ImpactSimulator = ({ idOverride }: { idOverride?: string }) => {
    const { id: paramId } = useParams<{ id: string }>();
    const id = idOverride ?? paramId;
    const { t } = useTranslation();
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');
    const { isEnabled } = useFeatureEnabled();

    const [isPdfGenerating, setIsPdfGenerating] = useState(false);
    const [modelledRows, setModelledRows] = useState<Array<ModelledRow>>([]);

    const containerRef = useRef<HTMLDivElement>(null);
    const onPdf = useComponentToPdf(containerRef, {
        filename: `product-impact-simulator-${id}.pdf`
    });

    const { data: matchingProducts, error: matchingProductsError, isLoading: isProductLoading } = useProduct(id);
    const {
        data: matchingPrism,
        error: matchingPrismError,
        isLoading: isPrismLoading
    } = usePrism(
        id
            ? [
                  {
                      name: 'id',
                      value: id,
                      operation: '='
                  }
              ]
            : []
    );
    const productPrism = useMemo(() => matchingPrism?.results?.[0], [matchingPrism]);
    const product = useMemo(() => matchingProducts?.results?.[0], [matchingProducts]);
    const productWeightUnit = product?.product_weight.unit;
    const productMaterials = useMemo(() => product?.materials ?? [], [product?.materials]);

    const allMaterialsUsed = product
        ? [
              ...productMaterials.map((material) => material.material_id),
              ...modelledRows.filter((current) => !!current.id).map((current) => current.id)
          ]
        : [];

    const { data: materials, isLoading: isMaterialsLoading, error: materialsError } = useMaterials(allMaterialsUsed);

    const isLoading = isProductLoading || isMaterialsLoading || isPrismLoading;

    const allowedRegionFilter: Filter = useMemo(() => {
        const allowedRegions = ['glo', 'row']; // Global or Rest of World
        if (!productMaterials || !materials)
            return {
                name: 'region',
                operation: '|',
                value: allowedRegions
            };
        productMaterials.forEach((material) => {
            const matchingMaterial = materials.results.find((row) => row.id === material.material_id);
            if (matchingMaterial?.region && !allowedRegions.includes(matchingMaterial.region)) {
                allowedRegions.push(matchingMaterial.region.toLowerCase());
            }
        });
        return {
            name: 'region',
            operation: '|',
            value: allowedRegions
        };
    }, [productMaterials, materials]);

    const originalTotals = useMemo(() => {
        const { products, packaging } = getWeightAdjusted({
            productMaterials: product?.materials,
            materials: materials?.results
        });

        const manufacturingGwp = productPrism?.manufacturing_gwp_absolute ?? 0;
        const transportGwp = productPrism?.transport_gwp_absolute ?? 0;
        const emissionsValue = sumArr(products, 'gwp') + sumArr(packaging, 'gwp') + manufacturingGwp + transportGwp;
        const waterDepletionValue = sumArr(products, 'wdp') + sumArr(packaging, 'wdp');
        const landUseValue = sumArr(products, 'alop') + sumArr(packaging, 'alop');

        return [
            {
                title: t('carbonEmissions'),
                rawValue: emissionsValue,
                value: isLoading ? null : formatStatValue(emissionsValue),
                unit: 'kg CO2e'
            },
            {
                title: t('waterDepletion'),
                rawValue: waterDepletionValue,
                value: isLoading ? null : formatStatValue(waterDepletionValue),
                unit: 'liters'
            },
            {
                title: t('landUse'),
                rawValue: landUseValue,
                value: isLoading ? null : formatStatValue(landUseValue),
                unit: '㎡'
            }
        ];
    }, [materials, product, isLoading, t, productPrism]);

    const modelledTotals = useMemo(() => {
        const formattedRows = modelledRows.map((current) => {
            return {
                category: current.category,
                material_id: current.id,
                weight: {
                    value: current.weight
                }
            };
        });
        const { products, packaging } = getWeightAdjusted({
            productMaterials: formattedRows,
            materials: materials?.results
        });

        const manufacturingGwp = productPrism?.manufacturing_gwp_absolute ?? 0;
        const transportGwp = productPrism?.transport_gwp_absolute ?? 0;
        const emissionsValue = isLoading ? null : sumArr(products, 'gwp') + sumArr(packaging, 'gwp') + manufacturingGwp + transportGwp;
        const waterDepletionValue = isLoading ? null : sumArr(products, 'wdp') + sumArr(packaging, 'wdp');
        const landUseValue = isLoading ? null : sumArr(products, 'alop') + sumArr(packaging, 'alop');

        return [
            {
                title: t('carbonEmissions'),
                rawValue: emissionsValue,
                value: isLoading ? null : formatStatValue(emissionsValue),
                unit: 'kg CO2e',
                original: originalTotals?.[0]?.rawValue
            },
            {
                title: t('waterDepletion'),
                rawValue: waterDepletionValue,
                value: isLoading ? null : formatStatValue(waterDepletionValue),
                unit: 'liters',
                original: originalTotals?.[1]?.rawValue
            },
            {
                title: t('landUse'),
                rawValue: landUseValue,
                value: isLoading ? null : formatStatValue(landUseValue),
                unit: '㎡',
                original: originalTotals?.[2]?.rawValue
            }
        ];
    }, [materials, isLoading, originalTotals, t, productPrism, modelledRows]);

    const originalCircularity = useMemo(() => {
        if (!product?.materials || !materials) return;
        return getCircularityPercentages(
            product.materials.map((current) => ({
                category: current.category,
                id: current.material_id,
                weight: current.weight.value
            })),
            materials.results
        );
    }, [product?.materials, materials]);

    const modelledCircularity = useMemo(() => {
        if (!modelledRows || !materials) return;
        return getCircularityPercentages(
            modelledRows.map((current) => ({
                category: current.category,
                id: current.id,
                weight: current.weight
            })),
            materials.results
        );
    }, [modelledRows, materials]);

    const error = matchingProductsError || materialsError || matchingPrismError;

    const handleDownloadPdf = async () => {
        try {
            setIsPdfGenerating(true);
            await onPdf();
        } catch (error) {
            showErrorMessage(error);
        } finally {
            setIsPdfGenerating(false);
        }
    };

    useEffect(() => {
        if (product && modelledRows.length === 0) {
            setModelledRows(
                product.materials.map((current) => ({
                    id: current.material_id,
                    name: current.material_name,
                    category: current.category,
                    weight: current.weight.value,
                    region: current.source_country,
                    unit: current.weight.unit,
                    organic: current.organic
                }))
            );
        }
    }, [product, modelledRows]);

    const weightDifference = useMemo(() => {
        if (!product) return 0;

        const modelledTotal = modelledRows.reduce((acc, current) => acc + toRounded(current.weight), 0);
        const originalTotal = product.materials.reduce((acc, current) => {
            return acc + toRounded(current.weight.value);
        }, 0);

        return originalTotal - modelledTotal;
    }, [product, modelledTotals, modelledRows]);

    const showWeightsDifferWarning = !isLoading && Math.abs(weightDifference) >= MAX_DIFF;
    const isHeavierThanOriginal = weightDifference < 0;

    if (error) {
        return <ErrorCard error={error} />;
    }
    const attributesCell = {
        key: 'attributes',
        name: t('attributes'),
        render: (_: undefined, row: any) => {
            return (
                <Group align="center" maw={160} gap="xs">
                    {row.organic && <Badge size="xs">Organic</Badge>}
                    {row.reused && <Badge size="xs">Reused</Badge>}
                    {row.recycled && <Badge size="xs">Recycled</Badge>}
                </Group>
            );
        }
    };

    return (
        <>
            <Group mb="lg" justify="space-between" align="center">
                <Group gap={rem(2)} mb={rem(4)}>
                    <Title size="h3">{t('impactSimulator')}</Title>
                    {!isPdfGenerating && (
                        <>
                            <MoreInfoHoverCard>
                                <Text c="dimmed">{t('impactSimulatorDescription')}</Text>
                            </MoreInfoHoverCard>
                            {isEnabled(FlagName.Prism, 'preview') && <PreviewBadge />}
                        </>
                    )}
                </Group>

                <Button loading={isPdfGenerating} onClick={handleDownloadPdf} variant="outline" leftSection={<TbDownload size={16} />}>
                    {t('downloadAsPdf')}
                </Button>
            </Group>

            <Paper ref={containerRef} p="md" pb="xl" shadow={isPdfGenerating ? 'none' : 'sm'}>
                <Title size="h3" ta="center" mb="md">
                    {product?.name}
                </Title>

                <Group gap="xs" justify="center" mb="lg">
                    {product?.sku && (
                        <Badge variant="light">
                            {t('sku')}: {product.sku}
                        </Badge>
                    )}
                    {product?.category && (
                        <Badge variant="light">
                            {t('category')}: {product.category}
                        </Badge>
                    )}
                    {product?.brand && (
                        <Badge variant="light">
                            {t('brand')}: {product.brand}
                        </Badge>
                    )}

                    {!isPdfGenerating && product?.product_weight.value && product?.product_weight.unit && (
                        <Badge variant="light">
                            {t('weight')}: {product.product_weight.value} {product.product_weight.unit}
                        </Badge>
                    )}
                </Group>

                <Group gap="sm" align="stretch">
                    <Stack gap="sm" flex={1} maw="100%">
                        <Text ta="center" fw={600} size="lg">
                            Product Assessment
                        </Text>

                        {!isLoading && originalCircularity && <CircularityBreakdown data={originalCircularity} />}

                        <ImpactList data={originalTotals} />

                        <Paper p="md" pb="xl" withBorder shadow="none">
                            <StaticTable
                                searchable={false}
                                isLoading={isLoading}
                                rowKey={(row) => row.id}
                                data={productMaterials}
                                columns={[
                                    {
                                        key: 'material_name',
                                        name: t('name'),
                                        render: (name) => (
                                            <Group align="center" mih={30} miw={160}>
                                                {name}
                                            </Group>
                                        )
                                    },
                                    {
                                        key: 'type',
                                        name: t('type'),
                                        render: (name) => formatHeader(name)
                                    },
                                    attributesCell,
                                    {
                                        key: 'percentage',
                                        name: t('weight'),
                                        render: (value, row) =>
                                            value !== null && row.weight?.value
                                                ? `${toFixedIfNeeded(row.weight.value)}${productWeightUnit} (${toFixedIfNeeded(value, 2)}%)`
                                                : '-'
                                    }
                                ]}
                            />
                        </Paper>
                    </Stack>

                    {!isSmallDevice && <TbArrowRight size={32} />}

                    <Stack gap="sm" flex={1} maw="100%">
                        <Text ta="center" fw={600} size="lg">
                            Product Modelled
                        </Text>

                        {!isLoading && modelledCircularity && <CircularityBreakdown data={modelledCircularity} />}

                        <ImpactList data={modelledTotals} />

                        <Paper p="md" withBorder shadow="none">
                            <StaticTable
                                searchable={false}
                                isLoading={isLoading}
                                rowKey={(row) => row.id}
                                data={modelledRows}
                                columns={[
                                    {
                                        key: 'name',
                                        name: t('name'),
                                        render: (value, row) => {
                                            if (isPdfGenerating)
                                                return (
                                                    <Group align="center" mih={30} miw={220}>
                                                        {value}
                                                    </Group>
                                                );
                                            return (
                                                <MaterialsAutoComplete
                                                    tags={['organic', 'unit', 'recycled', 'reused']}
                                                    additionalFilters={[allowedRegionFilter]}
                                                    miw={220}
                                                    size="xs"
                                                    onChange={(_, option: any) => {
                                                        setModelledRows((current) =>
                                                            current.map((item) => {
                                                                if (item.id === row.id) {
                                                                    return {
                                                                        id: option.value,
                                                                        name: option.label,
                                                                        region: option.region,
                                                                        unit: option.unit,
                                                                        organic: option.organic,
                                                                        reused: option.reused,
                                                                        recycled: option.recycled,
                                                                        category: row.category,
                                                                        weight: row.weight
                                                                    };
                                                                }
                                                                return item;
                                                            })
                                                        );
                                                    }}
                                                    initialOption={{
                                                        label: row.name,
                                                        value: row.id
                                                    }}
                                                />
                                            );
                                        }
                                    },
                                    attributesCell,
                                    {
                                        key: 'weight',
                                        name: `${t('weight')} ${productWeightUnit ? ` (${productWeightUnit})` : ''}`,
                                        render: (value, row) => {
                                            if (isPdfGenerating) return value;
                                            return (
                                                <ImpactNumberInput
                                                    value={value}
                                                    onChange={(value) => {
                                                        setModelledRows((current) =>
                                                            current.map((item) => {
                                                                if (item.id === row.id) {
                                                                    return {
                                                                        ...item,
                                                                        weight: value ? Number(value) : 0
                                                                    };
                                                                }
                                                                return item;
                                                            })
                                                        );
                                                    }}
                                                />
                                            );
                                        }
                                    },
                                    !isPdfGenerating && {
                                        key: 'actions',
                                        align: 'right',
                                        name: '',
                                        render: (_, row, index) => {
                                            return (
                                                <Menu shadow="md">
                                                    <Menu.Target>
                                                        <ActionIcon data-testid={`menu-${row.key}`}>
                                                            <TbDotsVertical size={16} />
                                                        </ActionIcon>
                                                    </Menu.Target>
                                                    <Menu.Dropdown>
                                                        <Menu.Item
                                                            data-testid={`delete-${row.key}`}
                                                            color="red"
                                                            onClick={() => {
                                                                setModelledRows((current) =>
                                                                    current.filter(
                                                                        (item, itemIndex) => item.id !== row.id && itemIndex !== index
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            {t('removeMaterial')}
                                                        </Menu.Item>
                                                    </Menu.Dropdown>
                                                </Menu>
                                            );
                                        }
                                    }
                                ]}
                            />
                            {showWeightsDifferWarning && (
                                <Alert mt="md" icon={<TbAlertCircle size={20} />} title={t('weightsDiffer')} color="orange" p="sm">
                                    {isHeavierThanOriginal ? t('produtHeavierThanOriginal') : t('produtLighterThanOriginal')}
                                </Alert>
                            )}
                            {!isPdfGenerating && (
                                <Group mt="md" justify="flex-end">
                                    <Button
                                        disabled={isLoading}
                                        onClick={() => {
                                            setModelledRows((current) => [
                                                ...current,
                                                {
                                                    id: '',
                                                    category: 'product',
                                                    name: '',
                                                    weight: 0
                                                }
                                            ]);
                                        }}
                                    >
                                        {t('addMaterial')}
                                    </Button>
                                </Group>
                            )}
                        </Paper>
                    </Stack>
                </Group>
            </Paper>
        </>
    );
};
