import { Cell, Pie, PieChart } from 'recharts';
import { Nullable } from 'types';
import { Stack, Group, Text, Skeleton, rem, useMantineTheme, useMantineColorScheme, Tooltip } from '@mantine/core';
import { toFixedIfNeeded } from 'utils/to-fixed-if-needed';

const CHART_SIZE = 184;
const DEFAULT_ROUNDING = 2;
const LARGE_VALUE_LENGTH = 12;

export const ImpactList = ({
    data
}: {
    data: {
        title: string;
        rawValue: Nullable<number>;
        value: Nullable<string>;
        unit: string;
        original?: Nullable<number>;
    }[];
}) => {
    const theme = useMantineTheme();
    const { colorScheme } = useMantineColorScheme();
    const textColor = colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.dark[7];
    const colors = [
        [theme.colors.orange[5], theme.colors.orange[3]],
        [theme.colors.blue[3], theme.colors.blue[1]],
        [theme.colors.green[4], theme.colors.green[1]]
    ];

    return (
        <Group gap="xs" align="center" justify="center">
            {data.map((current, index) => {
                if (current.value === null || !current.rawValue) return <Skeleton width={160} height={160} circle />;
                const hasChange = current.original && current.rawValue && current.rawValue !== current.original;
                const changePercentage =
                    current.original && current.rawValue ? ((current.rawValue - current.original) / current.original) * 100 : null;
                const changeDirection = changePercentage ? (changePercentage > 0 ? 'increase' : 'decrease') : null;
                const changeAmountRaw = current.original && current.rawValue ? Math.abs(current.rawValue - current.original) : 0;
                const changeAmount = changeAmountRaw ? toFixedIfNeeded(changeAmountRaw, DEFAULT_ROUNDING) : null;
                const isDecrease = hasChange && changePercentage && changeDirection === 'decrease';
                const isIncrease = hasChange && changePercentage && changeDirection === 'increase';

                let data = [{ label: current.title, value: current.rawValue }];

                if (isDecrease) {
                    data = [
                        {
                            label: current.title,
                            value: (current.rawValue * (100 - Math.abs(changePercentage))) / 100
                        },
                        {
                            label: 'Decrease',
                            value: (current.rawValue * Math.abs(changePercentage)) / 100
                        }
                    ];
                }

                const increaseData = isIncrease && [
                    {
                        label: 'Increase',
                        value: (current.rawValue * Math.abs(changePercentage)) / 100
                    },
                    {
                        label: current.title,
                        value: (current.rawValue * (100 - Math.abs(changePercentage))) / 100
                    }
                ];

                const tooltipText =
                    hasChange && current.original
                        ? `${toFixedIfNeeded(changePercentage, DEFAULT_ROUNDING)}% ${changeDirection} from ${toFixedIfNeeded(current.original, 5)} to ${toFixedIfNeeded(current.value, 5)} (${toFixedIfNeeded(changeAmount, 5)})`
                        : undefined;

                return (
                    <Tooltip key={current.title || index} label={tooltipText} disabled={!tooltipText}>
                        <Stack gap={0} pos="relative" mb="md">
                            <PieChart margin={{ top: 0, bottom: 0, left: 0, right: 0 }} width={CHART_SIZE} height={CHART_SIZE}>
                                <Pie stroke="" data={data} cx="50%" cy="50%" innerRadius={62} fill={textColor} dataKey="value">
                                    {data.map((entry, innerIndex) => (
                                        <Cell key={`cell-${entry.label}`} fill={colors[index][innerIndex]} />
                                    ))}
                                </Pie>
                                {increaseData && (
                                    <Pie
                                        startAngle={90}
                                        data={increaseData}
                                        dataKey="value"
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={78}
                                        outerRadius={88}
                                        fill="transparent"
                                    >
                                        {increaseData.map((entry, innerIndex) => (
                                            <Cell
                                                key={`cell-${entry.label}`}
                                                fill={innerIndex === increaseData.length - 1 ? 'transparent' : colors[index][1]}
                                            />
                                        ))}
                                    </Pie>
                                )}
                            </PieChart>
                            <Stack
                                w="100%"
                                h="95%"
                                gap={rem(4)}
                                ta="center"
                                justify="center"
                                align="center"
                                style={{ position: 'absolute', left: 0, top: 0, pointerEvents: 'none', zIndex: 0 }}
                            >
                                <Text size={current.value.length >= LARGE_VALUE_LENGTH ? 'sm' : 'md'} fw={600} maw={110} lh={1}>
                                    {current.value}
                                </Text>
                                <Text c="dimmed" size="xs">
                                    {current.unit}
                                </Text>
                                {hasChange && (
                                    <Text
                                        size="lg"
                                        maw={110}
                                        fw={500}
                                        c={changeDirection === 'increase' ? theme.colors.red[7] : theme.colors.green[7]}
                                    >
                                        {toFixedIfNeeded(changePercentage, DEFAULT_ROUNDING)}%
                                    </Text>
                                )}
                            </Stack>
                            <Text c="dimmed" h={20} w={CHART_SIZE} key={current.title} ta="center" size="sm" fw={500}>
                                {current.title}
                            </Text>
                        </Stack>
                    </Tooltip>
                );
            })}
        </Group>
    );
};
