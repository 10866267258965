import { useTranslation } from 'react-i18next';
import { TbSearch } from 'react-icons/tb';
import { Input } from '@mantine/core';
import classes from './search.module.css';

export const Search = ({ disabled, value, onChange }: { disabled?: boolean; value: string; onChange: (value: string) => void }) => {
    const { t } = useTranslation();
    return (
        <Input
            data-testid="search"
            disabled={disabled}
            className={classes.search}
            placeholder={t('search')}
            leftSection={<TbSearch />}
            value={value}
            onChange={(event) => {
                onChange(event.target.value);
            }}
        />
    );
};
