import { GraphLegend } from 'shared/graph-legend';
import ScoreCircleGraph from 'shared/score-circle-graph';
import { Section } from 'shared/section';
import { StaticTable } from 'shared/static-table';
import { useFeatureEnabled } from 'shared/use-feature-enabled';
import { WaffleGraph } from 'shared/waffle-graph';
import { Nullable } from 'types';
import { Dimension } from 'types/shared';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Group, PaperProps, Skeleton, Stack, Text, Title, rem, useMantineTheme } from '@mantine/core';
import { displayRoundedValue } from 'utils/display-rounded-value';
import { FlagName } from 'utils/get-flag-value';
import { getTransportationTypeColumns } from 'utils/get-transportation-type-columns';
import { sortByKey } from 'utils/sort-by-key';
import { toPercentage } from 'utils/to-percentage';
import classes from './climate-impact-cards.module.css';
import { DataQualityCard } from './data-quality-card';
import { useScoreReport } from './use-score-report';

const formatNumber = (num: Nullable<number>) => (num ?? 0).toFixed(3);

export const ClimateImpactCards = (props: PaperProps) => {
    const { isEnabled } = useFeatureEnabled();
    const { t } = useTranslation();
    const theme = useMantineTheme();
    const { id } = useParams<{ id: string }>();

    const { climate, dqr, summaryResult } = useScoreReport(id);
    const { transportResult, climateProductionStageResult, climateMaterialsResult } = climate;
    const cliimateDqr = dqr?.data?.results[0]?.climate_dqr;
    
    const climateSummary = summaryResult?.results.find((current) => current.dimension === Dimension.Climate);

    const breakdownByProductionStage = useMemo(() => {
        if (!climateProductionStageResult.data?.results || climateProductionStageResult.data.results.length === 0) return [];
        const first = climateProductionStageResult.data?.results[0];
        return [
            {
                title: t('packagingMaterials'),
                description: t('packagingMaterialsDescription'),
                value: first.packaging_materials_percentage ?? 0,
                co2: formatNumber(first.packaging_materials),
                color: theme.colors.yellow[5]
            },
            {
                title: t('productMaterials'),
                description: t('productMaterialsDescription'),
                value: first.product_materials_percentage ?? 0,
                co2: formatNumber(first.product_materials),
                color: theme.colors.blue[4]
            },
            {
                title: t('distribution'),
                description: t('distributionDescription'),
                value: first.distribution_percentage ?? 0,
                co2: formatNumber(first.distribution),
                color: theme.colors.violet[4]
            },
            {
                title: t('manufacturing'),
                description: t('manufacturingDescription'),
                value: first.manufacturing_percentage ?? 0,
                co2: formatNumber(first.manufacturing),
                color: theme.colors.red[6]
            }
        ]
            .sort(sortByKey((row) => row.value))
            .reverse();
    }, [climateProductionStageResult.data?.results, theme, t]);

    return (
        <>
            <Section {...props}>
                <Group align="flex-start" justify="space-between" gap="xs">
                    <Stack gap={rem(4)} className={classes.introduction}>
                        <Title size="h2">{t('climateImpact')}</Title>
                        <Text c="dimmed" size="sm">
                            {t('climateImpactScoreReportDescription')}
                        </Text>
                    </Stack>

                    {climateSummary && (
                        <ScoreCircleGraph
                            peerPercentage={climateSummary.category_average ?? 0}
                            percentage={climateSummary.score ?? 0}
                            size="md"
                            className={classes.scoreCircle}
                            bottom={
                                <Text size="sm" fw={500}>
                                    {t('categoryAverage')}: {climateSummary.category_average ?? 0}
                                </Text>
                            }
                        />
                    )}
                </Group>
            </Section>

            <Section mt="md" {...props}>
                <Title size="h4" mb={rem(4)}>
                    {t('ghgBreakdownByProductionStage')}
                </Title>
                <Text c="dimmed" size="sm">
                    {t('ghgBreakdownByProductionStageDescription')}
                </Text>

                {climateProductionStageResult.isLoading ? (
                    <Skeleton height={180} mt="lg" />
                ) : (
                    <Group
                        my={rem(40)}
                        style={{
                            justifyContent: 'space-evenly'
                        }}
                        gap="xl"
                    >
                        <WaffleGraph data={breakdownByProductionStage} m={0} />
                        <GraphLegend
                            items={breakdownByProductionStage.map((row) => ({
                                ...row,
                                subtitle: `= ${row.co2}kg CO2e`,
                                value: toPercentage(row.value)
                            }))}
                        />
                    </Group>
                )}
            </Section>

            <Section mt="md" {...props}>
                <Title size="h4" mb={rem(4)}>
                    {t('materialsWithHighestEmissions')}
                </Title>
                <Text c="dimmed" size="sm">
                    {t('materialsWithHighestEmissionsDescription')}
                </Text>
                <StaticTable
                    m={`${rem(20)} 0`}
                    searchable={false}
                    defaultSort="gwp_per_kg"
                    rowKey={(row) => row.material_id}
                    isLoading={climateMaterialsResult.isLoading}
                    columns={[
                        {
                            key: 'beautified_name',
                            name: t('material'),
                            sortable: true
                        },
                        {
                            key: 'gwp_per_kg',
                            name: t('perKg'),
                            sortable: true,
                            render: displayRoundedValue,
                            width: 180
                        },
                        {
                            key: 'gwp_absolute',
                            name: t('absolute'),
                            sortable: true,
                            render: displayRoundedValue,
                            width: 180
                        }
                    ]}
                    data={climateMaterialsResult.data?.results ?? []}
                />
            </Section>

            <Section mt="md" {...props}>
                <Title size="h4" mb={rem(4)}>
                    {t('totlKmsTravelled')}
                </Title>
                <Text c="dimmed" size="sm">
                    {t('totlKmsTravelledDescription')}
                </Text>

                <StaticTable
                    m={`${rem(20)} 0`}
                    searchable={false}
                    isLoading={transportResult.isLoading}
                    defaultSort="gwp_absolute"
                    columns={getTransportationTypeColumns()}
                    rowKey={(row) => row.transport_mode}
                    data={transportResult.data?.results ?? []}
                />
            </Section>
            {isEnabled(FlagName.DataQualityRating) && (
                <Section mt="md" {...props}>
                    <DataQualityCard score={cliimateDqr ?? undefined} />
                </Section>
            )}
            <Text c="dimmed" fs="italic" size="sm" my="lg">
                {t('climateImpactFooterDescription')}
            </Text>
        </>
    );
};
