import { DataRow } from './create-empty-row';
import { MAX_COLUMNS } from './data-row-product-schema-conversion';

export const setMaterialTotals = (row: Partial<DataRow>) => {
    let productMaterialsTotal = 0;
    let primaryPackagingTotal = 0;
    let secondaryPackagingTotal = 0;
    let tertiaryPackagingTotal = 0;

    for (let i = 0; i < MAX_COLUMNS; i++) {
        // @ts-ignore
        const currentProductMaterialsTotal = Number.parseFloat(row[`materials_${i}_percentage`]);
        if (currentProductMaterialsTotal && !Number.isNaN(currentProductMaterialsTotal)) {
            productMaterialsTotal = productMaterialsTotal + currentProductMaterialsTotal;
        }
        // @ts-ignore
        const currentPrimaryPackagingTotal = Number.parseFloat(row[`materials_primary_${i}_percentage`]);
        if (currentPrimaryPackagingTotal && !Number.isNaN(currentPrimaryPackagingTotal)) {
            primaryPackagingTotal = primaryPackagingTotal + currentPrimaryPackagingTotal;
        }

        // @ts-ignore
        const currentSecondaryPackagingTotal = Number.parseFloat(row[`materials_secondary_${i}_percentage`]);
        if (currentSecondaryPackagingTotal && !Number.isNaN(currentSecondaryPackagingTotal)) {
            secondaryPackagingTotal = secondaryPackagingTotal + currentSecondaryPackagingTotal;
        }

        // @ts-ignore
        const currentTertiaryPackagingTotal = Number.parseFloat(row[`materials_tertiary_${i}_percentage`]);
        if (currentTertiaryPackagingTotal && !Number.isNaN(currentTertiaryPackagingTotal)) {
            tertiaryPackagingTotal = tertiaryPackagingTotal + currentTertiaryPackagingTotal;
        }
    }

    row.materials_total_percentage = productMaterialsTotal;
    row.materials_primary_total_percentage = primaryPackagingTotal;
    row.materials_secondary_total_percentage = secondaryPackagingTotal;
    row.materials_tertiary_total_percentage = tertiaryPackagingTotal;
};
