import { useRuleConfigs } from 'data-management/rule-config/use-rule-configs';
import { links } from 'links';
import { NameConfirmationModal } from 'shared/name-confirmation-modal';
import { useFeatureEnabled } from 'shared/use-feature-enabled';
import { Nullable } from 'types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, Button, Checkbox, FileInput, Group, Modal, Select, Stack, TagsInput, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FlagName } from 'utils/get-flag-value';
import { getIdFromKey } from 'utils/get-id-from-key';
import { showErrorMessage } from 'utils/show-error-message';
import { showSuccessMessage } from 'utils/show-success-message';
import { convertCsvToJsonl } from './convert-csv-to-jsonl';
import { fileCategoryTypes } from './file-category-types';
import { DUPLICATE_ERROR_MESSAGE_KEY } from './upload-file';
import { useIngestion } from './use-ingestion';

const DEFAULT_JSONL_FILE_FORMATS = '.jsonl,.csv';
const DEFAULT_JSONL_VALUE = 'Default_JSONL';

export const UploadModal = ({ isOpen, onClose, initialFile }: { isOpen: boolean; onClose: () => void; initialFile?: File }) => {
    const { isEnabled } = useFeatureEnabled();
    const { upload, mutate } = useIngestion();
    const { data, isLoading, error } = useRuleConfigs();
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showNameConfirmationModal, setShowNameConfirmationModal] = useState(false);

    const form = useForm({
        initialValues: {
            tags: [] as Array<string>,
            file: null as Nullable<File>,
            categoryType: fileCategoryTypes[0].value,
            ruleConfigId: '',
            minimalRequirements: false
        },
        validate: {
            file: (value) => (!value ? t('dataIsRequired') : null)
        }
    });

    const isDefaultJsonl = form.values.categoryType === DEFAULT_JSONL_VALUE;

    const handleClose = () => {
        form.reset();
        onClose();
    };

    const handleSubmit = form.onSubmit(async (values) => {
        if (!values.file) return;
        try {
            setIsSubmitting(true);
            await upload({
                file: isDefaultJsonl ? await convertCsvToJsonl(values.file) : values.file,
                tags: values.tags,
                file_category_type: values.categoryType,
                rule_config_id: values.ruleConfigId === '' ? undefined : values.ruleConfigId,
                minimal_requirements: values.minimalRequirements
            });
            mutate();
            handleClose();
            showSuccessMessage(t('dataUploaded'));
        } catch (error: any) {
            if (error.message === t(DUPLICATE_ERROR_MESSAGE_KEY)) {
                setShowNameConfirmationModal(true);
            } else {
                showErrorMessage(error);
            }
        } finally {
            setIsSubmitting(false);
        }
    });

    const handleRenameUpload = async (name: string) => {
        if (!form.values.file) return;
        try {
            await upload({
                nameOverride: `${name}.jsonl`,
                file: isDefaultJsonl ? await convertCsvToJsonl(form.values.file) : form.values.file,
                tags: form.values.tags,
                file_category_type: form.values.categoryType,
                rule_config_id: form.values.ruleConfigId === '' ? undefined : form.values.ruleConfigId,
                minimal_requirements: form.values.minimalRequirements
            });
            mutate();
            setShowNameConfirmationModal(false);
            handleClose();
            showSuccessMessage(t('dataUploaded'));
        } catch (error: any) {
            showErrorMessage(error);
        }
    };

    useEffect(() => {
        if (!form.values.file && initialFile) {
            form.setFieldValue('file', initialFile);
        }
    }, [initialFile, form.values.file, form.setFieldValue]);

    useEffect(() => {
        form.values.file && convertCsvToJsonl(form.values.file);
        if (form.values.file?.name.endsWith('.csv')) {
            form.setFieldValue('categoryType', fileCategoryTypes[0].value);
        }
    }, [form.values.file]);

    const ruleConfigOptions = useMemo(() => {
        if (!data) return [];
        return data.items
            .filter((ruleConfig) => ruleConfig.category === form.values.categoryType)
            .map((ruleConfig) => ({
                value: getIdFromKey(ruleConfig.key)!,
                label: ruleConfig.name
            }));
    }, [data, form.values.categoryType]);

    return (
        <>
            <Modal
                size="md"
                opened={isOpen}
                onClose={handleClose}
                title={t('uploadData')}
                centered
                closeButtonProps={{
                    // @ts-ignore
                    'data-testid': 'upload-close'
                }}
            >
                <form onSubmit={handleSubmit}>
                    {isDefaultJsonl && (
                        <Text size="sm" c="dimmed" mb="lg">
                            Upload new data for processing in{' '}
                            <Anchor href={links.jsonlines} target="_blank">
                                JSONL
                            </Anchor>{' '}
                            or{' '}
                            <Anchor href={links.csv} target="_blank">
                                CSV
                            </Anchor>{' '}
                            format. You can also download the{' '}
                            <Anchor href="/dayrize-default-template.csv" download>
                                default CSV template
                            </Anchor>
                            , or an example{' '}
                            <Anchor href="/example.jsonl" download>
                                JSONL
                            </Anchor>{' '}
                            or{' '}
                            <Anchor href="/example.csv" download>
                                CSV
                            </Anchor>{' '}
                            file.
                        </Text>
                    )}
                    <Stack gap="xs">
                        <Select
                            disabled={form.values.file?.name.endsWith('.csv')}
                            label={t('fileCategoryType')}
                            comboboxProps={{ position: 'bottom', withinPortal: true }}
                            data={fileCategoryTypes}
                            {...form.getInputProps('categoryType')}
                            onChange={(value) => {
                                if (value) {
                                    form.setFieldValue('categoryType', value);
                                    form.setFieldValue('ruleConfigId', '');
                                    form.setFieldValue('file', null);
                                }
                            }}
                        />
                        <FileInput
                            description={isDefaultJsonl ? t('defaultJsonlDataDescription') : undefined}
                            clearable
                            label={t('data')}
                            withAsterisk
                            accept={isDefaultJsonl ? DEFAULT_JSONL_FILE_FORMATS : undefined}
                            {...form.getInputProps('file')}
                        />
                        <TagsInput
                            label={t('tags')}
                            data={form.values.tags}
                            placeholder={t('addTags')}
                            onChange={(values) => form.setFieldValue('tags', values)}
                        />

                        {!error && (
                            <Select
                                disabled={isLoading || !ruleConfigOptions.length || !isDefaultJsonl}
                                label={t('ruleConfig')}
                                description={t('ruleConfigInputDescription')}
                                comboboxProps={{ position: 'bottom', withinPortal: true }}
                                data={ruleConfigOptions}
                                {...form.getInputProps('ruleConfigId')}
                            />
                        )}
                        {isEnabled(FlagName.MinimalRequirements) && (
                            <Checkbox
                                name="minimalRequirements"
                                label={t('minimalRequirementsLabel')}
                                description={t('minimalRequirementsDescription')}
                                checked={form.values.minimalRequirements}
                                onChange={(event) => form.setFieldValue('minimalRequirements', event.currentTarget.checked)}
                            />
                        )}
                    </Stack>
                    <Group mt="lg" justify="flex-end">
                        <Button type="submit" loading={isSubmitting}>
                            {t('save')}
                        </Button>
                    </Group>
                </form>
            </Modal>
            <NameConfirmationModal
                variant="duplicate"
                onUpload={handleRenameUpload}
                initialName={form.values.file?.name}
                isOpen={showNameConfirmationModal}
                onClose={() => setShowNameConfirmationModal(false)}
            />
        </>
    );
};
