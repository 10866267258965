import { Nullable } from 'types';
import { List } from '@mantine/core';
import { JsonValue } from 'utils/export-as-json';
import { FlattenResult, flattenObj } from 'utils/flatten-obj';
import { formatHeader } from './format-header';
import { isDate } from './is-date';

const NUMBER_ROUNDING_PRECISION = 5;

const toColumns = (rows?: FlattenResult) => {
    if (!rows || rows.length === 0) return [];
    const headerKeys = Object.keys(rows[0]);

    return headerKeys.map((key) => ({
        key,
        name: formatHeader(key),
        render: (value: Nullable<number | string>) => {
            if (value === null) return '-';
            if (value === 0) return '0';
            if (typeof value === 'boolean') return value ? 'Yes' : 'No';
            if (typeof value === 'number' && value % 1 !== 0) return value.toFixed(NUMBER_ROUNDING_PRECISION);
            if (isDate(value)) return new Date(value).toLocaleString();
            if (Array.isArray(value)) {
                return (
                    <List type="ordered">
                        {value.map((item, index) => (
                            <List.Item key={index}>{item}</List.Item>
                        ))}
                    </List>
                );
            }
            return value;
        }
    }));
};

export const queryResultToTable = (rows?: JsonValue[]) => {
    if (!rows || rows.length === 0)
        return {
            columns: [],
            data: []
        };

    const flatRows = rows.map((row) => flattenObj(row));
    return {
        columns: toColumns(flatRows),
        data: flatRows
    };
};
