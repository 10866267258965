import { AuditLogTable } from 'account-management/audit-log/audit-log-table';
import { Nullable } from 'types';
import { useTranslation } from 'react-i18next';
import { Box, Modal } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

export const FileAuditLogModal = ({
    isOpen,
    onClose,
    correlationId
}: {
    isOpen: boolean;
    onClose: () => void;
    correlationId?: Nullable<string>;
}) => {
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');
    const { t } = useTranslation();
    if (!correlationId) return null;

    return (
        <Modal opened={isOpen} fullScreen={isSmallDevice} size="100%" title={t('dataAuditLog')} onClose={onClose}>
            <Box py="md">
                <AuditLogTable prefix="file_" correlationId={correlationId} />
            </Box>
        </Modal>
    );
};
