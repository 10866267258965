import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbCheck, TbCopyCheck } from 'react-icons/tb';
import { ActionIcon, Group, Loader, MultiSelect, MultiSelectProps, Text, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { formatHeader } from 'utils/format-header';
import { FlagName } from 'utils/get-flag-value';
import { FilterBar } from './filter-bar';
import classes from './product-filters.module.css';
import { useFeatureEnabled } from './use-feature-enabled';

type Filter = 'brand' | 'category' | 'parentCategory' | 'tags' | 'suppliers';

export const SelectOption = ({ label, checked }: { label: string; checked: boolean }) => {
    return (
        <Group gap="xs">
            <Text size="sm" fw={500}>
                {formatHeader(label)}
            </Text>
            {checked && <TbCheck size={16} />}
        </Group>
    );
};

export const renderSelectOption: MultiSelectProps['renderOption'] = ({ option, checked }) => (
    <SelectOption label={option.label} checked={!!checked} />
);

export const SelectAllButton = ({ onClick }: { onClick: () => void }) => {
    const { t } = useTranslation();
    return (
        <Tooltip label={t('selectAllOptions')}>
            <ActionIcon data-testid="select-all" onClick={onClick} size="xs" aria-label={t('selectAll')}>
                <TbCopyCheck />
            </ActionIcon>
        </Tooltip>
    );
};

const useSearchableField = (initialSearchText: string, setUpdatedSearch: (search: string) => void) => {
    const [search, setSearch] = useState(initialSearchText);

    const [debouncedSearchValue] = useDebouncedValue(search, 300);

    useEffect(() => {
        setUpdatedSearch(debouncedSearchValue);
    }, [debouncedSearchValue, setUpdatedSearch]);

    return [search, setSearch] as const;
};

export const ProductFilters = ({
    tagOptions = [],
    supplierOptions = [],
    categoryOptions = [],
    brandOptions = [],
    parentCategoryOptions = [],
    isLoading,
    brand = [],
    tags = [],
    setTags,
    suppliers = [],
    setSuppliers,
    setBrand,
    category = [],
    setCategory,
    parentCategory = [],
    setParentCategory,
    children,
    enabledFilters = ['brand', 'category', 'parentCategory', 'tags', 'suppliers'],
    additionalFilters,
    additionalFiltersCount = 0,
    onClearAll,
    brandSearchText,
    setBrandSearchText,
    isBrandsLoading,
    supplierSearchText,
    setSupplierSearchText,
    isSuppliersLoading,
    tagSearchText,
    setTagSearchText,
    isTagsLoading,
    categorySearchText,
    setCategorySearchText,
    isCategoriesLoading
}: {
    tags: Array<string>;
    suppliers: Array<string>;
    brand: Array<string>;
    category: Array<string>;
    parentCategory: Array<string>;
    isLoading: boolean;
    supplierOptions: Array<{ label: string; value: string }>;
    tagOptions: Array<{ label: string; value: string }>;
    brandOptions: Array<{ label: string; value: string }>;
    categoryOptions: Array<{ label: string; value: string }>;
    parentCategoryOptions: Array<{ label: string; value: string }>;
    setBrand: (value: Array<string>) => void;
    setCategory: (value: Array<string>) => void;
    setTags: (value: Array<string>) => void;
    setSuppliers: (value: Array<string>) => void;
    setParentCategory: (value: Array<string>) => void;
    children?: ReactNode;
    enabledFilters?: Filter[];
    additionalFilters?: ReactNode;
    additionalFiltersCount?: number;
    onClearAll?: () => void;
    brandSearchText: string;
    setBrandSearchText: (search: string) => void;
    isBrandsLoading: boolean;
    supplierSearchText: string;
    setSupplierSearchText: (search: string) => void;
    isSuppliersLoading: boolean;
    tagSearchText: string;
    setTagSearchText: (search: string) => void;
    isTagsLoading: boolean;
    categorySearchText: string;
    setCategorySearchText: (search: string) => void;
    isCategoriesLoading: boolean;
}) => {
    const { isEnabled } = useFeatureEnabled();
    const { t } = useTranslation();
    const filterCount = (brand?.length ?? 0) + (category?.length ?? 0) + (parentCategory?.length ?? 0) + additionalFiltersCount;
    const hasFilters = filterCount > 0;

    const [brandSearch, setBrandSearch] = useSearchableField(brandSearchText, setBrandSearchText);
    const [supplierSearch, setSupplierSearch] = useSearchableField(supplierSearchText, setSupplierSearchText);
    const [tagSearch, setTagSearch] = useSearchableField(tagSearchText, setTagSearchText);
    const [categorySearch, setCategorySearch] = useSearchableField(categorySearchText, setCategorySearchText);

    return (
        <Group gap="xs">
            <FilterBar
                onClearAll={() => {
                    setBrand([]);
                    setCategory([]);
                    setParentCategory([]);
                    if (onClearAll) onClearAll();
                }}
                hasAppliedFilters={hasFilters}
                filterCount={filterCount}
            >
                {additionalFilters}

                {enabledFilters.includes('tags') && isEnabled(FlagName.TagFiltering) && (
                    <MultiSelect
                        data-testid="tags-select"
                        data={tagOptions}
                        disabled={isLoading && !isTagsLoading}
                        searchable
                        searchValue={tagSearch}
                        onSearchChange={setTagSearch}
                        clearable
                        leftSection={
                            tagOptions.length !== tags.length && (
                                <SelectAllButton onClick={() => setTags(tagOptions.map((current) => current.value))} />
                            )
                        }
                        renderOption={renderSelectOption}
                        className={classes.select}
                        hidePickedOptions
                        label={t('tags')}
                        placeholder={t('search')}
                        nothingFoundMessage={isTagsLoading ? t('searching') : t('noOptionMatchingSearch')}
                        rightSection={isTagsLoading && <Loader size="xs" color="blue" />}
                        onChange={setTags}
                        value={!tags || tags.length === 0 ? [] : tags}
                    />
                )}

                {enabledFilters.includes('suppliers') && (
                    <MultiSelect
                        data-testid="suppliers-select"
                        data={supplierOptions}
                        disabled={isLoading && !isSuppliersLoading}
                        searchable
                        searchValue={supplierSearch}
                        onSearchChange={setSupplierSearch}
                        clearable
                        leftSection={
                            supplierOptions.length !== suppliers.length && (
                                <SelectAllButton onClick={() => setSuppliers(supplierOptions.map((current) => current.value))} />
                            )
                        }
                        renderOption={renderSelectOption}
                        className={classes.select}
                        hidePickedOptions
                        placeholder={t('search')}
                        label={t('suppliers')}
                        nothingFoundMessage={isSuppliersLoading ? t('searching') : t('noOptionMatchingSearch')}
                        rightSection={isSuppliersLoading && <Loader size="xs" color="blue" />}
                        onChange={setSuppliers}
                        value={!suppliers || suppliers.length === 0 ? [] : suppliers}
                    />
                )}

                {enabledFilters.includes('parentCategory') && (
                    <MultiSelect
                        leftSection={
                            parentCategoryOptions.length !== parentCategory.length && (
                                <SelectAllButton onClick={() => setParentCategory(parentCategoryOptions.map((current) => current.value))} />
                            )
                        }
                        data-testid="parent-category-select"
                        data={parentCategoryOptions}
                        disabled={isLoading}
                        searchable
                        clearable
                        renderOption={renderSelectOption}
                        className={classes.select}
                        hidePickedOptions
                        label={t('parentCategories')}
                        nothingFoundMessage={t('noOptionMatchingSearch')}
                        onChange={setParentCategory}
                        value={!parentCategory || parentCategory.length === 0 ? [] : parentCategory}
                    />
                )}
                {enabledFilters.includes('category') && (
                    <MultiSelect
                        data-testid="category-select"
                        data={categoryOptions}
                        disabled={isLoading && !isCategoriesLoading}
                        searchable
                        searchValue={categorySearch}
                        onSearchChange={setCategorySearch}
                        clearable
                        leftSection={
                            categoryOptions.length !== category.length && (
                                <SelectAllButton onClick={() => setCategory(categoryOptions.map((current) => current.value))} />
                            )
                        }
                        renderOption={renderSelectOption}
                        className={classes.select}
                        hidePickedOptions
                        label={t('categories')}
                        placeholder={t('search')}
                        nothingFoundMessage={isCategoriesLoading ? t('searching') : t('noOptionMatchingSearch')}
                        rightSection={isCategoriesLoading && <Loader size="xs" color="blue" />}
                        onChange={setCategory}
                        value={!category || category.length === 0 ? [] : category}
                    />
                )}

                {enabledFilters.includes('brand') && (
                    <MultiSelect
                        data-testid="brand-select"
                        data={brandOptions}
                        disabled={isLoading && !isBrandsLoading}
                        searchable
                        searchValue={brandSearch}
                        onSearchChange={setBrandSearch}
                        clearable
                        leftSection={
                            brandOptions.length !== brand.length && (
                                <SelectAllButton onClick={() => setBrand(brandOptions.map((current) => current.value))} />
                            )
                        }
                        renderOption={renderSelectOption}
                        className={classes.select}
                        hidePickedOptions
                        label={t('brand')}
                        placeholder={t('search')}
                        nothingFoundMessage={isBrandsLoading ? t('searching') : t('noOptionMatchingSearch')}
                        rightSection={isBrandsLoading && <Loader size="xs" color="blue" />}
                        onChange={setBrand}
                        value={!brand || brand.length === 0 ? [] : brand}
                    />
                )}
            </FilterBar>
            {children}
        </Group>
    );
};
