import { paths } from 'paths';
import { useOrganizationId } from 'shared/use-organization-id';
import { Dimension } from 'types/shared';
import { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbAdjustments, TbDownload } from 'react-icons/tb';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Group, Title, useMantineColorScheme } from '@mantine/core';
import { showErrorMessage } from 'utils/show-error-message';
import { DimensiontNavLinks } from '../shared/dimension-nav-links';
import { CircularityCards } from './circularity-cards';
import { ClimateImpactCards } from './climate-impact-cards';
import { DataQualityCards } from './data-quality-cards';
import { EcosystemImpactCards } from './ecosystem-impact-cards';
import { LivelihoodsAndWellbeingCards } from './livelihoods-and-wellbeing-cards';
import { PdfGeneratingModal } from './pdf-generating-modal';
import { ProductBadges } from './product-badges';
import { PurposeCards } from './purpose-cards';
import { ScoreReportBreadcrumbs } from './score-report-breadcrumbs';
import { SummaryCard } from './summary-card';
import { useComponentToPdf } from './use-component-to-pdf';
import { useProduct } from './use-product';
import { useScoreReport } from './use-score-report';
import { useScoreReportNavigate } from './use-score-report-navigate';

export const ScoreReportLayout = ({ dimension, children }: { children: ReactNode; dimension: Dimension }) => {
    const handleNavigate = useScoreReportNavigate();
    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const [isPdfGenerating, setPdfGenerating] = useState(false);
    const organizationId = useOrganizationId();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const containerRef = useRef(null);
    const onPdf = useComponentToPdf(containerRef, {
        filename: `score-report-${id}.pdf`,
        delay: 2000
    });

    const { data: matchingProducts, isLoading: isProductLoading } = useProduct(id);

    const { isLoading } = useScoreReport(id);

    const product = matchingProducts?.results?.[0];

    const handleDownloadPdf = async () => {
        try {
            if (colorScheme === 'dark') toggleColorScheme();
            setPdfGenerating(true);
            await onPdf();
        } catch (error) {
            showErrorMessage(error);
        } finally {
            setPdfGenerating(false);
        }
    };

    return (
        <>
            <Group mb="sm" justify="space-between" align="center">
                <ScoreReportBreadcrumbs />
                <Group gap="xs">
                    <Button
                        size="xs"
                        disabled={isLoading || isProductLoading}
                        loading={isPdfGenerating}
                        onClick={() => {
                            navigate(paths.impactSimulator(organizationId, id));
                        }}
                        variant="outline"
                        leftSection={<TbAdjustments size={16} />}
                    >
                        {t('impactSimulator')}
                    </Button>
                    <Button
                        size="xs"
                        disabled={isLoading || isProductLoading}
                        loading={isPdfGenerating}
                        onClick={handleDownloadPdf}
                        variant="outline"
                        leftSection={<TbDownload size={16} />}
                    >
                        {t('downloadAsPdf')}
                    </Button>
                </Group>
            </Group>
            <ProductBadges product={product} />
            <DimensiontNavLinks value={dimension} onChange={handleNavigate} />
            {children}
            <Box ref={containerRef}>
                {isPdfGenerating && (
                    <>
                        <Title size="xl" mb="lg">
                            {product?.name}
                        </Title>
                        <ProductBadges product={product} />
                        <SummaryCard shadow="none" mb="md" />
                        <CircularityCards shadow="none" />
                        <EcosystemImpactCards shadow="none" />
                        <ClimateImpactCards shadow="none" />
                        <LivelihoodsAndWellbeingCards shadow="none" />
                        <PurposeCards shadow="none" />
                        <DataQualityCards shadow="none" />
                    </>
                )}
            </Box>
            <PdfGeneratingModal isOpen={isPdfGenerating} onClose={() => setPdfGenerating(false)} />
        </>
    );
};
