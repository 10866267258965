import { useScoreReportNavigate } from 'products/use-score-report-navigate';
import { Dimension } from 'types/shared';
import { useTranslation } from 'react-i18next';
import { FlagName } from 'utils/get-flag-value';
import circularityIconSrc from './circularity.svg';
import climateChangeIconSrc from './climate-change.svg';
import dqrIconSrc from './data-rating.svg';
import ecosystemIconSrc from './ecosystem.svg';
import livelihoodsIconSrc from './livelihoods.svg';
import purposeIconSrc from './purpose.svg';
import summaryIconSrc from './summary.svg';
import { useFeatureEnabled } from './use-feature-enabled';

export const useScoreReportSections = () => {
    const { t } = useTranslation();
    const handleNavigate = useScoreReportNavigate();
    const { isEnabled } = useFeatureEnabled();

    let sections = [
        {
            title: t('summary'),
            description: t('summaryDescription'),
            value: Dimension.Total,
            icon: summaryIconSrc
        },
        {
            title: t('circularity'),
            description: t('circularityDescription'),
            value: Dimension.Circularity,
            icon: circularityIconSrc,
            onClick: () => handleNavigate(Dimension.Circularity)
        },
        {
            title: t('ecosystemImpact'),
            description: t('ecosystemImpactDescription'),
            value: Dimension.Ecosystem,
            icon: ecosystemIconSrc,
            onClick: () => handleNavigate(Dimension.Ecosystem)
        },
        {
            title: t('climateImpact'),
            description: t('climateImpactDescription'),
            value: Dimension.Climate,
            icon: climateChangeIconSrc,
            onClick: () => handleNavigate(Dimension.Climate)
        },
        {
            title: t('livelihoodsAndWellbeing'),
            description: t('livelihoodsAndWellbeingDescription'),
            value: Dimension.Livelihood,
            icon: livelihoodsIconSrc,
            onClick: () => handleNavigate(Dimension.Livelihood)
        },
        {
            title: t('purpose'),
            description: t('purposeSummaryDescription'),
            value: Dimension.Purpose,
            icon: purposeIconSrc,
            onClick: () => handleNavigate(Dimension.Purpose)
        }
    ];

    if (isEnabled(FlagName.DataQualityRating)) {
        sections.push({
            title: t('dataQualityRatingTitle'),
            description: t('dataQualityRatingDescription'),
            value: Dimension.DataQualityRating,
            icon: dqrIconSrc,
            onClick: () => handleNavigate(Dimension.DataQualityRating)
        });
    }

    return sections;
};
