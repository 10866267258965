import { links } from 'links';
import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import { Section } from 'shared/section';
import { useTranslation } from 'react-i18next';
import { Anchor, Group, Text, Title, rem } from '@mantine/core';
import { AuditLogTable } from './audit-log-table';

export const AuditLog = ({ initialOpen }: { initialOpen?: boolean }) => {
    const { t } = useTranslation();

    return (
        <>
            <Group gap={rem(2)}>
                <Title size="h3">{t('auditLog')}</Title>
                <MoreInfoHoverCard>
                    <Text size="sm" c="dimmed">
                        Allows filtering and sorting of{' '}
                        <Anchor target="_blank" href={links.customerService.auditLog}>
                            audit log events
                        </Anchor>{' '}
                        that have occurred on the platform.
                    </Text>
                </MoreInfoHoverCard>
            </Group>
            <Text c="dimmed" mb="md">
                {t('auditLogDescription')}
            </Text>
            <Section>
                <AuditLogTable initialOpen={initialOpen} />
            </Section>
        </>
    );
};
