import { byIso } from 'country-code-lookup';

export const GLOBAL_COUNTRY_CODES = ['GLO', 'ROW'];

export const getAlpha3CountryCodeName = (countryCode: string) => {
    try {
        const match = byIso(countryCode.toUpperCase());
        if (match) {
            return `${match.country} (${match.iso3})`;
        }
        return countryCode;
    } catch {
        return countryCode;
    }
};
