import { useTranslation } from 'react-i18next';
import { Text, Title } from '@mantine/core';
import { AuthLayout } from './auth-layout';

export const OrganizationCreationPending = () => {
    const { t } = useTranslation();

    return (
        <AuthLayout>
            <Title c="blue" fw={700} order={3} ta="center" mb="lg">
                {t('organizationCreationPending')}
            </Title>
            <Text fw={500} size="sm" mb="lg" ta="center">
                {t('organizationCreationPendingDescription')}
            </Text>
        </AuthLayout>
    );
};
