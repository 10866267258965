import useSWR from 'swr';
import { Organization, OrganizationUpdate } from 'types';
import { request } from 'lib/request-client';

export const useOrganization = (id?: string) => {
    const { data, error, mutate, isLoading } = useSWR<Organization>(id ? `/account_manager/organization/organization/${id}` : null);

    const update = async (body: Partial<OrganizationUpdate>) => {
        const result = await request.url(`/account_manager/organization/organization/${id}`).patch(body);
        mutate();
        return result as OrganizationUpdate;
    };

    const create = async (body: Partial<Organization>) => {
        const result = await request.url('/account_manager/organization/create').post(body);
        return result as Organization;
    };

    const isUserWhitelisted = (email: string) => {
        if (!data) return false;
        return data.user_whitelist.includes(email);
    };

    const removeFromWhitelist = (email: string) =>
        update({
            user_whitelist_remove: [email]
        });

    const addToWhitelist = (email: string) =>
        update({
            user_whitelist_add: [email]
        });

    return { data, error, mutate, isLoading, update, create, isUserWhitelisted, removeFromWhitelist, addToWhitelist };
};
