import { useInsightQuery } from 'insights-explorer/use-insight-query';
import { Filter, Nullable } from 'types';

const LAND_USE_QUERY_NAME = 'insight_ecosystem_materials_land_use';
const WATER_IMPACT_QUERY_NAME = 'insight_ecosystem_materials_water_impact';

export const useEcosystemImpactsOfMaterialsInsightReport = (params?: Filter[]) => {
    const landUseResult = useInsightQuery<{
        material_id: Nullable<string>;
        beautified_name: Nullable<string>;
        region: Nullable<string>;
        alop_per_kg: Nullable<number>;
        alop_absolute: Nullable<number>;
        unit: Nullable<string>;
    }>(LAND_USE_QUERY_NAME, params);

    const waterImpactResult = useInsightQuery<{
        material_id: Nullable<string>;
        beautified_name: Nullable<string>;
        region: Nullable<string>;
        alop_per_kg: Nullable<number>;
        alop_absolute: Nullable<number>;
        unit: Nullable<string>;
    }>(WATER_IMPACT_QUERY_NAME, params);

    return { landUseResult, waterImpactResult };
};
