import { AuditLogItem, useAuditLog } from 'account-management/audit-log/use-audit-log';
import { DownloadButton } from 'shared/download-button';
import { FilterBar } from 'shared/filter-bar';
import { Table } from 'shared/table';
import { Filter } from 'types';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbAlertCircle, TbDotsVertical, TbRefresh } from 'react-icons/tb';
import { ActionIcon, Alert, Badge, Group, Menu, Select, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { getErrorMessage } from 'utils/get-error-message';
import classes from './audit-log.table.module.css';
import { LogEntryModal } from './log-entry-modal';
import { useAuditLogComponents } from './use-audit-log-components';
import { useAuditLogEmails } from './use-audit-log-emails';

const badgeColorMap: {
    [color: string]: string;
} = {
    INFO: 'blue',
    WARNING: 'yellow',
    ERROR: 'red'
};

const levelOptions = [
    {
        label: 'All levels',
        value: ''
    },
    {
        label: 'Info',
        value: 'INFO'
    },
    {
        label: 'Error',
        value: 'ERROR'
    }
];

export const AuditLogTable = ({
    correlationId,
    prefix = '',
    initialOpen = false
}: {
    correlationId?: string;
    prefix?: string;
    initialOpen?: boolean;
}) => {
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');
    const { t } = useTranslation();
    const [activeEmail, setActiveEmail] = useQueryParam('email', withDefault(StringParam, ''));
    const [activeComponent, setActiveComponent] = useQueryParam('component', withDefault(StringParam, ''));
    const [level, setLevel] = useQueryParam('level', withDefault(StringParam, ''));

    const [activeEntry, setActiveEntry] = useState<AuditLogItem | null>(null);

    const filters = useMemo(() => {
        const filters: Filter[] = [];
        if (level) {
            filters.push({
                name: 'level',
                operation: '=',
                value: level
            });
        }

        if (activeEmail) {
            filters.push({
                name: 'email',
                operation: '=',
                value: activeEmail
            });
        }

        if (activeComponent) {
            filters.push({
                name: 'component',
                operation: '=',
                value: activeComponent
            });
        }

        if (correlationId) {
            filters.push({
                name: 'correlation_id',
                operation: '=',
                value: correlationId
            });
        }

        return filters;
    }, [level, activeEmail, activeComponent, correlationId]);

    const { data: emailsData, isLoading: isEmailsLoading, error: emailsError } = useAuditLogEmails();
    const { data: componentsData, isLoading: isComponentsLoading, error: componentsError } = useAuditLogComponents();
    const {
        error,
        data,
        isLoading,
        limit,
        onLimitChange,
        page,
        onPage,
        search,
        onSearchChange,
        mutate,
        isValidating,
        order,
        onOrderChange
    } = useAuditLog(filters, prefix);

    const emailOptions = useMemo(() => {
        if (!emailsData) return [];

        return [
            {
                label: t('allEmails'),
                value: ''
            },
            ...emailsData.results
                .map((current) => ({
                    label: current.email,
                    value: current.email
                }))
                .filter((current) => !!current.label)
        ];
    }, [emailsData, t]);

    const componentOptions = useMemo(() => {
        if (!componentsData) return [];

        return [
            {
                label: t('allComponents'),
                value: ''
            },
            ...componentsData.results.map((current) => ({
                label: current.component,
                value: current.component
            }))
        ];
    }, [componentsData, t]);

    if (error) {
        return (
            <Alert icon={<TbAlertCircle size={20} />} title={t('unexpectedError')} color="red">
                {getErrorMessage(error)}
            </Alert>
        );
    }

    return (
        <>
            <Table
                search={search}
                onSearchChange={(value) => {
                    onPage(1);
                    onSearchChange(value);
                }}
                actions={
                    <Group gap="xs" justify="flex-end">
                        <FilterBar
                            onClearAll={() => {
                                setLevel('');
                                setActiveEmail('');
                                setActiveComponent('');
                            }}
                            initialOpen={initialOpen}
                            hasAppliedFilters={!!level || !!activeComponent || !!activeEmail}
                        >
                            <Select
                                clearable
                                aria-label={t('level')}
                                data-testid="level-select"
                                data={levelOptions}
                                disabled={isLoading}
                                className={classes.select}
                                placeholder={t('level')}
                                onChange={(value) => {
                                    onPage(1);
                                    setLevel(value);
                                }}
                                value={level}
                            />
                            {!componentsError && (
                                <Select
                                    searchable
                                    clearable
                                    aria-label={t('component')}
                                    data-testid="component-select"
                                    data={componentOptions}
                                    disabled={isComponentsLoading}
                                    className={classes.select}
                                    placeholder={t('component')}
                                    onChange={(value) => {
                                        onPage(1);
                                        setActiveComponent(value);
                                    }}
                                    value={activeComponent}
                                />
                            )}
                            {!emailsError && (
                                <Select
                                    searchable
                                    clearable
                                    aria-label={t('email')}
                                    data-testid="email-select"
                                    data={emailOptions}
                                    disabled={isEmailsLoading}
                                    className={classes.select}
                                    placeholder={t('email')}
                                    onChange={(value) => {
                                        onPage(1);
                                        setActiveEmail(value);
                                    }}
                                    value={activeEmail}
                                />
                            )}
                        </FilterBar>

                        <ActionIcon data-testid="refresh" variant="default" size="lg" loading={isValidating} onClick={() => mutate()}>
                            <TbRefresh />
                        </ActionIcon>

                        <DownloadButton
                            disabled={!data?.results}
                            exports={[
                                {
                                    name: 'audit_log',
                                    data: data?.results
                                }
                            ]}
                        />
                    </Group>
                }
                isLoading={isLoading}
                data={data?.results}
                rowKey={(row) => JSON.stringify(row)}
                maxHeight={correlationId ? (isSmallDevice ? '70vh' : '60vh') : undefined}
                order={order}
                onOrderChange={onOrderChange}
                columns={[
                    {
                        key: 'message',
                        name: t('message'),
                        sortable: true,
                        render: (value) => (
                            <Text w={380} lineClamp={2}>
                                {value}
                            </Text>
                        )
                    },
                    {
                        key: 'created_at',
                        sortable: true,
                        name: t('createdOn'),
                        render: (value) => new Date(value).toLocaleString()
                    },
                    {
                        key: 'component',
                        sortable: true,
                        name: t('component'),
                        render: (value) => value ?? '-'
                    },
                    {
                        key: 'level',
                        sortable: true,
                        name: t('level'),
                        render: (value) => (
                            <Group justify="center">
                                <Badge color={badgeColorMap[value] ?? 'grey'}>{value}</Badge>
                            </Group>
                        )
                    },

                    {
                        key: 'email',
                        sortable: true,
                        name: t('email'),
                        render: (value) => value ?? '-'
                    },
                    {
                        key: 'actions',
                        name: '',
                        align: 'right',
                        render: (_, row) => (
                            <Menu shadow="md">
                                <Menu.Target>
                                    <ActionIcon data-testid={`menu-${row.key}`}>
                                        <TbDotsVertical size={16} />
                                    </ActionIcon>
                                </Menu.Target>
                                <Menu.Dropdown>
                                    <Menu.Item
                                        onClick={() => {
                                            setActiveEntry(row);
                                        }}
                                    >
                                        {t('view')}
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        )
                    }
                ]}
                limit={limit}
                onLimitChange={onLimitChange}
                page={page}
                onPage={onPage}
            />
            <LogEntryModal isOpen={!!activeEntry} onClose={() => setActiveEntry(null)} data={activeEntry} />
        </>
    );
};
