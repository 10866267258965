import { notifications } from '@mantine/notifications';
import { request } from 'lib/request-client';
import { getErrorMessage } from 'utils/get-error-message';
import { convertJsonlToCsv } from './convert-jsonl-to-csv';

export const downloadFile = async (key: string, type: 'jsonl' | 'csv' = 'jsonl', fetcher = window.fetch) => {
    try {
        const id = notifications.show({
            loading: true,
            message: 'Preparing download',
            autoClose: false,
            withCloseButton: false
        });
        const result = (await request.url(`/ingest/file/${key}/download-request`).post()) as {
            signed_url: string;
        };
        if (type === 'csv') {
            const response = await fetcher(result.signed_url);
            const blob = await response.blob();
            const file = new File([blob], `${key}-${Date.now()}.jsonl`);
            await convertJsonlToCsv(file);
        } else {
            window.open(result.signed_url, '_blank');
        }
        notifications.update({
            id,
            message: 'Downloading file',
            loading: false,
            autoClose: 2000
        });
        return result.signed_url;
    } catch (error) {
        const message = getErrorMessage(error);
        notifications.show({ message, color: 'red' });
        return message;
    }
};
