import { getAlpha3CountryCodeName } from 'data-management/data-ingestion/product-upload/get-alpha3-country-code-name';
import { useProductSchema } from 'data-management/data-ingestion/product-upload/use-product-schema';
import { Match, MaterialsAutoComplete } from 'products/materials-autocomplete';
import { Nullable } from 'types';
import { Group, Select, Loader, TagsInput, SelectProps } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { formatHeader } from 'utils/format-header';
import { DataRow } from './create-empty-row';
import styles from './edit-cell.module.css';
import { GoogleTaxonomyAutocompleteSelect } from './google-taxonomy-autocomplete-select';
import { useOptions } from './use-options';

const MAX_SELECT_OPTONS = 200;

const DefaultSelect = (props: SelectProps) => (
    <Select
        className={styles.input}
        variant="unstyled"
        limit={MAX_SELECT_OPTONS}
        searchable
        clearable
        selectFirstOptionOnChange
        autoFocus
        {...props}
    />
);

export const TagsCell = ({ row }: { row: DataRow }) => <>{(row.tags ?? []).join(', ')}</>;

export const getFormattedOptions = (value?: Array<string>) => {
    if (!value) return [];
    return value
        .filter((current: Nullable<string>) => !!current)
        .map((current: string) => ({
            value: current,
            label: formatHeader(current)
        }));
};

export const getIndexFromColumnKey = (columnKey?: string) => columnKey?.split('_').find((current) => !isNaN(parseInt(current)));

export const EditCell = ({
    columnKey,
    row,
    onRowChange,
    onClose
}: {
    columnKey: string;
    row: DataRow;
    onRowChange: (row: DataRow, commitChanges?: boolean) => void;
    onClose: (commitChanges?: boolean, shouldFocusCell?: boolean) => void;
}) => {
    const [, setRecentSelectedMaterials] = useLocalStorage<Match[]>({
        key: 'recent-materials',
        defaultValue: []
    });
    const { data: schema, isLoading: isSchemaLoading } = useProductSchema();

    const { data: ecolabelResult, isLoading: isEcoLabelsLoading } = useOptions({
        queryName: 'eco_labels',
        isEnabled: columnKey.includes('eco_label') && columnKey.includes('title')
    });

    const isCitiesEnabled = columnKey.includes('city') && !!row[columnKey.replace('city', 'country') as keyof DataRow];

    const { data: cities, isLoading: isCitiesLoading } = useOptions<{
        country_name: string;
        country: string;
        id: string;
        city: string;
    }>({
        queryName: 'cities',
        isEnabled: isCitiesEnabled,
        filters: [
            {
                name: 'alpha3',
                operation: '=',
                value: row[columnKey.replace('city', 'country') as keyof DataRow] as string
            }
        ]
    });

    const isLoading = isSchemaLoading || isEcoLabelsLoading || isCitiesLoading;

    const handleChange = (value: any) => {
        onRowChange({
            ...row,
            [columnKey]: value
        });
    };

    if (isLoading) {
        return (
            <Group align="center" justify="center" h="100%">
                <Loader size="xs" />
            </Group>
        );
    }

    if (columnKey === 'tags') {
        return (
            <TagsInput
                value={row.tags ?? []}
                className={styles.input}
                autoFocus
                onOptionSubmit={(value) => {
                    onRowChange(
                        {
                            ...row,
                            [columnKey]: row.tags ? [...row.tags, value] : [value]
                        },
                        true
                    );
                }}
                variant="unstyled"
                onChange={handleChange}
                onBlur={() => onClose(true, false)}
            />
        );
    }

    if (columnKey === 'google_taxonomy_path') {
        return (
            <GoogleTaxonomyAutocompleteSelect
                value={row.google_taxonomy_path}
                onChange={handleChange}
                onBlur={() => onClose(true, false)}
            />
        );
    }

    const isValue =
        columnKey.endsWith('weight_value') ||
        columnKey.includes('percentage') ||
        columnKey === 'water_consumption_value' ||
        columnKey.endsWith('energy_use_value') ||
        columnKey === 'extra_guarantee_duration';
    if (isValue) {
        const isPercentage = columnKey.includes('percentage');
        const value = row[columnKey as keyof DataRow] as string | number | undefined;
        return (
            <input
                type="number"
                data-testid={columnKey}
                className={styles.input}
                autoFocus
                min={0}
                max={isPercentage ? '100' : undefined}
                value={value}
                onChange={(event) => {
                    const value = event.target.value;
                    onRowChange({
                        ...row,
                        [columnKey]: value
                    });
                }}
                onBlur={() => onClose(true, false)}
            />
        );
    }

    if (isCitiesEnabled) {
        return (
            <DefaultSelect
                data-testid={columnKey}
                value={row[columnKey as keyof DataRow] as string}
                data={getFormattedOptions(Array.from(new Set(cities?.results.map((current) => current.city))) ?? [])}
                onChange={handleChange}
            />
        );
    }

    if (columnKey.includes('country')) {
        const options = schema?.properties.country_of_production.enum.map((current: string) => ({
            label: ['glo', 'row'].includes(current.toLowerCase()) ? `Unknown (${current})` : getAlpha3CountryCodeName(current),
            value: current
        }));
        return (
            <DefaultSelect
                data-testid={columnKey}
                allowDeselect={columnKey.includes('source_country') || columnKey.includes('transports_')}
                value={row[columnKey as keyof DataRow] as string}
                data={options}
                onChange={handleChange}
            />
        );
    }

    if (columnKey.includes('weight_unit')) {
        const options = schema?.properties.product_weight.properties.unit.enum.map((current: string) => ({
            label: current,
            value: current
        }));
        return (
            <Select
                className={styles.input}
                data-testid={columnKey}
                value={row[columnKey as keyof DataRow] as string}
                variant="unstyled"
                selectFirstOptionOnChange
                searchable
                autoFocus
                data={options}
                onChange={handleChange}
            />
        );
    }

    if (columnKey.includes('eco_label') && columnKey.includes('type')) {
        return (
            <DefaultSelect
                data-testid={columnKey}
                value={row[columnKey as keyof DataRow] as string}
                data={[
                    {
                        label: 'Product',
                        value: 'product'
                    },
                    {
                        label: 'Packaging',
                        value: 'packaging'
                    }
                ]}
                onChange={handleChange}
            />
        );
    }

    if (columnKey.includes('material_name')) {
        const value = row[columnKey as keyof DataRow] as string;
        return (
            <MaterialsAutoComplete
                data-testid={columnKey}
                className={styles.input}
                variant="unstyled"
                autoFocus
                showInitialOption={false}
                initialOption={{
                    label: value,
                    value
                }}
                onClear={() => {
                    const indexOfMaterial = getIndexFromColumnKey(columnKey);
                    if (!indexOfMaterial) return;
                    const name = columnKey.replace(`_${indexOfMaterial}_material_name`, '');
                    onRowChange(
                        {
                            ...row,
                            [columnKey]: '',
                            [`${name}_${indexOfMaterial}_id`]: ''
                        },
                        true
                    );
                }}
                onChange={(value, option: Match) => {
                    if (option) setRecentSelectedMaterials((current) => [...current, option]);
                    const indexOfMaterial = getIndexFromColumnKey(columnKey);
                    if (!indexOfMaterial) return;
                    const name = columnKey.replace(`_${indexOfMaterial}_material_name`, '');
                    onRowChange(
                        {
                            ...row,
                            [columnKey]: option?.label ?? '',
                            [`${name}_${indexOfMaterial}_id`]: value ?? ''
                        },
                        true
                    );
                }}
            />
        );
    }

    const matchingKeys = [
        'recycled',
        'reused',
        'organic',
        'product_components_proprietary_parts',
        'product_components_moving_parts',
        'product_components_standard_fixings',
        'product_components_small_components',
        'repair_service',
        'legal_guarantee'
    ];
    const isBooleanField = matchingKeys.some((key) => columnKey.includes(key));
    if (isBooleanField) {
        const value = row[columnKey as keyof DataRow] as string | null;
        return <DefaultSelect data-testid={columnKey} value={value} data={['Yes', 'No']} onChange={handleChange} />;
    }

    if (columnKey === 'average_product_lifespan') {
        const options = getFormattedOptions(
            schema.properties.product_sustainability_characteristics.properties.average_product_lifespan.enum
        );
        return (
            <DefaultSelect
                data-testid={columnKey}
                value={row[columnKey as keyof DataRow] as string}
                data={options}
                onChange={handleChange}
            />
        );
    }

    if (columnKey === 'best_eol_disposal_method') {
        const options = getFormattedOptions(
            schema.properties.material_category_type_properties.items.properties.best_eol_disposal_method.enum
        );
        return (
            <DefaultSelect
                data-testid={columnKey}
                value={row[columnKey as keyof DataRow] as string}
                data={options}
                onChange={handleChange}
            />
        );
    }

    if (columnKey === 'eol_treatment_type') {
        const options = getFormattedOptions(schema.properties.material_category_type_properties.items.properties.eol_treatment_type.enum);

        return (
            <DefaultSelect
                data-testid={columnKey}
                value={row[columnKey as keyof DataRow] as string}
                data={options}
                onChange={handleChange}
            />
        );
    }

    if (columnKey === 'communication_end_of_life_treatment') {
        const options = getFormattedOptions(
            schema.properties.product_sustainability_characteristics.properties.communication_end_of_life_treatment.enum
        );
        return (
            <DefaultSelect
                data-testid={columnKey}
                value={row[columnKey as keyof DataRow] as string}
                data={options}
                onChange={handleChange}
            />
        );
    }

    if (columnKey.includes('transport_mode')) {
        const options = getFormattedOptions(schema.properties.transports.items.properties.mode.enum);
        return (
            <DefaultSelect
                data-testid={columnKey}
                value={row[columnKey as keyof DataRow] as string}
                data={options}
                onChange={handleChange}
            />
        );
    }

    if (columnKey.includes('transport_medium')) {
        const options = getFormattedOptions(schema.properties.transports.items.properties.medium.enum);

        return (
            <DefaultSelect
                data-testid={columnKey}
                value={row[columnKey as keyof DataRow] as string}
                data={options}
                onChange={handleChange}
            />
        );
    }

    if (columnKey === 'water_consumption_unit') {
        const options = getFormattedOptions(schema.properties.production.properties.water_consumption.properties.unit.enum);
        return (
            <DefaultSelect
                data-testid={columnKey}
                value={row[columnKey as keyof DataRow] as string}
                data={options}
                onChange={handleChange}
            />
        );
    }

    if (columnKey === 'water_consumption_source') {
        const options = getFormattedOptions(schema.properties.production.properties.water_consumption.properties.water_source.enum);
        return (
            <DefaultSelect
                data-testid={columnKey}
                value={row[columnKey as keyof DataRow] as string}
                data={options}
                onChange={handleChange}
            />
        );
    }

    if (columnKey.endsWith('_energy_type')) {
        const options = getFormattedOptions(
            schema.properties.production.properties.energy_consumption.properties.energy_sources.items.properties.energy_type.enum
        );
        return (
            <DefaultSelect
                data-testid={columnKey}
                value={row[columnKey as keyof DataRow] as string}
                data={options}
                onChange={handleChange}
            />
        );
    }

    if (columnKey.endsWith('_usage_type')) {
        const options = getFormattedOptions(
            schema.properties.production.properties.energy_consumption.properties.energy_sources.items.properties.usage_type.enum
        );
        return (
            <DefaultSelect
                data-testid={columnKey}
                value={row[columnKey as keyof DataRow] as string}
                data={options}
                onChange={handleChange}
            />
        );
    }

    if (columnKey.endsWith('_energy_use_unit')) {
        const options = getFormattedOptions(
            schema.properties.production.properties.energy_consumption.properties.energy_sources.items.properties.energy_use.properties.unit
                .enum
        );
        return (
            <DefaultSelect
                data-testid={columnKey}
                value={row[columnKey as keyof DataRow] as string}
                data={options}
                onChange={handleChange}
            />
        );
    }

    if (columnKey.includes('eco_label') && columnKey.includes('title')) {
        const options = getFormattedOptions(ecolabelResult?.results.map((current) => current.name));
        return (
            <DefaultSelect
                data-testid={columnKey}
                value={row[columnKey as keyof DataRow] as string}
                data={options}
                onChange={handleChange}
            />
        );
    }

    return null;
};
