import { GLOBAL_COUNTRY_CODES, getAlpha3CountryCodeName } from 'data-management/data-ingestion/product-upload/get-alpha3-country-code-name';
import { links } from 'links';
import { ColumnOrColumnGroup, textEditor, RenderCellProps } from 'react-data-grid';
import { TbAlertCircle, TbAlertTriangle, TbCheck, TbChevronDown, TbPlus, TbTrash } from 'react-icons/tb';
import { Text, Group, ActionIcon, Code, Anchor, HoverCard, rem, List, Tooltip, useMantineTheme } from '@mantine/core';
import { formatHeader } from 'utils/format-header';
import { showSuccessMessage } from 'utils/show-success-message';
import { ColumnTitle } from './column-title';
import { DataRow } from './create-empty-row';
import { MAX_COLUMNS } from './data-row-product-schema-conversion';
import { EditCell, TagsCell } from './edit-cell';
import styles from './index.module.css';

const getCellClass = (cellKey: string) => (row: DataRow) => {
    return row.invalid_fields?.includes(cellKey) ? styles.invalid : undefined;
};

export const nrColumn: ColumnOrColumnGroup<DataRow, number> = {
    headerCellClass: 'centered',
    name: <ColumnTitle>Nr.</ColumnTitle>,
    maxWidth: 92,
    minWidth: 92,
    frozen: true,
    renderCell: ({ row }) => {
        const isValid = row.validation_errors?.length === 0;
        return (
            <Group gap={rem(2)} align="center" h="100%">
                {row.holding_area_error && (
                    <HoverCard shadow="md">
                        <HoverCard.Target>
                            <ActionIcon c="red">
                                <TbAlertCircle size={16} />
                            </ActionIcon>
                        </HoverCard.Target>
                        <HoverCard.Dropdown p="sm" maw={rem(300)}>
                            <Text size="xs">{row.holding_area_error}</Text>
                        </HoverCard.Dropdown>
                    </HoverCard>
                )}
                <Code>{row.id}</Code>
                <HoverCard shadow="md">
                    <HoverCard.Target>
                        <ActionIcon c={isValid ? 'green' : 'orange'} data-testid={isValid ? 'valid' : 'invalid'}>
                            {isValid ? <TbCheck size={16} /> : <TbAlertTriangle size={16} />}
                        </ActionIcon>
                    </HoverCard.Target>
                    <HoverCard.Dropdown p="sm" maw={rem(280)}>
                        {isValid ? (
                            <Text size="xs">This row is valid and can be uploaded</Text>
                        ) : (
                            <>
                                <Text size="xs" mb="md">
                                    We have encountered the following errors while validating this row:
                                </Text>
                                <List size="xs" pr="sm">
                                    {row.validation_errors.map((current) => (
                                        <List.Item key={current}>{current}</List.Item>
                                    ))}
                                </List>
                            </>
                        )}
                    </HoverCard.Dropdown>
                </HoverCard>
            </Group>
        );
    },
    key: 'id'
};

const skuColumn: ColumnOrColumnGroup<DataRow, number> = {
    key: 'sku',
    name: (
        <ColumnTitle required description="What is the unique identifier for the product?">
            Unique SKU/GTIN/EAN
        </ColumnTitle>
    ),
    cellClass: getCellClass('sku'),
    minWidth: 200,
    maxWidth: 240,
    frozen: true,
    renderEditCell: textEditor
};

const nameColumn: ColumnOrColumnGroup<DataRow, number> = {
    key: 'name',
    name: (
        <ColumnTitle required description="What is the product's name?">
            Product name
        </ColumnTitle>
    ),
    frozen: true,
    cellClass: getCellClass('name'),
    minWidth: 200,
    maxWidth: 240,
    renderEditCell: textEditor
};

const defaultColumns = [nrColumn, skuColumn, nameColumn];

export const renderEditCell = ({ row, onRowChange, column, onClose }: any) => (
    <EditCell row={row} onRowChange={onRowChange} columnKey={column.key} onClose={onClose} />
);

export const renderOptionCell = ({ row, column }: RenderCellProps<DataRow, any>) => {
    let value = row[column.key as keyof DataRow] as unknown as string;
    if (column.key.includes('country') && value) {
        value = GLOBAL_COUNTRY_CODES.includes(value.toUpperCase()) ? `Unknown` : getAlpha3CountryCodeName(value);
    } else if (!column.key.includes('unit') && value) {
        value = formatHeader(value);
    }

    const noDropdown = column.key.endsWith('transport_medium') || column.key.endsWith('source_country');
    if (noDropdown) {
        return value;
    }

    return (
        <Group gap="xs" h="100%" w="100%" align="center" justify="space-between" wrap="nowrap">
            <Text lineClamp={1} size="sm">
                {value}
            </Text>
            <TbChevronDown style={{ minWidth: 16 }} size={16} />
        </Group>
    );
};

export const ListOptions = ({
    current,
    value,
    onChange,
    type
}: {
    type: string;
    current: number;
    value: number;
    onChange: (count: number) => void;
}) => {
    const theme = useMantineTheme();
    const isLast = current + 1 === value;
    const isLimit = value === MAX_COLUMNS;
    if (!isLast || isLimit) return null;

    return (
        <Group gap="xs">
            {value !== 1 && (
                <Tooltip label={`Remove the last ${type} added. This action can not be undone.`}>
                    <ActionIcon
                        size="sm"
                        color="red"
                        variant="light"
                        data-testid="remove"
                        onClick={() => {
                            onChange(value - 1);
                            showSuccessMessage('Removed successfully');
                        }}
                    >
                        <TbTrash size={16} color={theme.colors.red[6]} />
                    </ActionIcon>
                </Tooltip>
            )}

            <Tooltip label={`Add another ${type}`}>
                <ActionIcon
                    size="sm"
                    color="green"
                    variant="light"
                    data-testid="add"
                    onClick={() => {
                        onChange(value + 1);
                        showSuccessMessage('Successfully added');
                    }}
                >
                    <TbPlus size={16} color={theme.colors.green[6]} />
                </ActionIcon>
            </Tooltip>
        </Group>
    );
};

export const getGeneralColumns = ({
    transportCount,
    onCountChange
}: {
    transportCount: number;
    onCountChange: (count: number) => void;
}): ColumnOrColumnGroup<DataRow, number>[] => {
    const children: ColumnOrColumnGroup<DataRow, number>[] = [];

    for (let index = 0; index < transportCount; index++) {
        children.push({
            name: (
                <Group gap="xs">
                    <Text size="sm" fw={500}>
                        Leg {index + 1}
                    </Text>
                    <ListOptions type="transport leg" current={index} value={transportCount} onChange={onCountChange} />
                </Group>
            ),
            children: [
                {
                    key: `transports_${index}_origin_country`,
                    renderEditCell,
                    cellClass: getCellClass(`transports_${index}_origin_country`),
                    renderCell: renderOptionCell,
                    minWidth: 200,
                    name: <ColumnTitle description="What is the origin country for this leg of transportation?">Origin country</ColumnTitle>
                },
                {
                    key: `transports_${index}_origin_city`,
                    renderEditCell,
                    cellClass: getCellClass(`transports_${index}_origin_city`),
                    renderCell: renderOptionCell,
                    minWidth: 200,
                    name: (
                        <ColumnTitle description="What is the origin city for this leg of transportation? Select a origin country to see possible options.">
                            Origin city
                        </ColumnTitle>
                    )
                },

                {
                    key: `transports_${index}_destination_country`,
                    renderEditCell,
                    cellClass: getCellClass(`transports_${index}_destination_country`),
                    renderCell: renderOptionCell,
                    minWidth: 200,
                    name: (
                        <ColumnTitle description="What is the destination country for this leg of transportation?">
                            Destination country
                        </ColumnTitle>
                    )
                },

                {
                    key: `transports_${index}_destination_city`,
                    renderEditCell,
                    cellClass: getCellClass(`transports_${index}_destination_city`),
                    minWidth: 200,
                    name: (
                        <ColumnTitle description="What is the destination city for this leg of transportation? Select a destination country to see possible options.">
                            Destination city
                        </ColumnTitle>
                    )
                },
                {
                    key: `transports_${index}_transport_mode`,
                    minWidth: 200,
                    cellClass: getCellClass(`transports_${index}_transport_mode`),
                    renderCell: renderOptionCell,
                    renderEditCell,
                    name: (
                        <ColumnTitle description="What is the primary mode of transportation for this leg of transportation?">
                            Transportation mode
                        </ColumnTitle>
                    )
                },
                {
                    key: `transports_${index}_transport_medium`,
                    minWidth: 200,
                    cellClass: getCellClass(`transports_${index}_transport_medium`),
                    renderCell: renderOptionCell,
                    name: (
                        <ColumnTitle description="The primary method of transportation for this leg of transportation. This will be autocompleted based on the selected transportation mode.">
                            Transportation medium
                        </ColumnTitle>
                    )
                }
            ]
        });
    }

    const transportColumns: ColumnOrColumnGroup<DataRow, number> = {
        name: (
            <Text size="sm" fw={500}>
                Detailed distribution information
                <Tooltip label="At least one transport leg is required for a product">
                    <Text component="span" size="xs" c="red">
                        *{' '}
                    </Text>
                </Tooltip>
            </Text>
        ),
        children
    };

    return [
        ...defaultColumns,
        {
            name: (
                <Text size="sm" fw={500}>
                    Basic product information
                </Text>
            ),
            children: [
                {
                    key: 'supplier',
                    name: <ColumnTitle description="What is the name of the product's supplier?">Supplier</ColumnTitle>,
                    minWidth: 240,
                    renderEditCell: textEditor
                },
                {
                    key: 'brand',
                    minWidth: 240,
                    renderEditCell: textEditor,
                    name: <ColumnTitle description="What is the product's brand?">Brand</ColumnTitle>
                },
                {
                    key: 'google_taxonomy_path',
                    minWidth: 240,
                    renderEditCell,
                    renderCell: renderOptionCell,
                    name: <ColumnTitle>Google Taxonomy Path</ColumnTitle>
                }
            ]
        },
        {
            name: (
                <Text size="sm" fw={500}>
                    Basic manufacturing information
                </Text>
            ),
            children: [
                {
                    key: 'country_of_production',
                    renderCell: renderOptionCell,
                    cellClass: getCellClass('country_of_production'),
                    minWidth: 200,
                    renderEditCell,
                    name: (
                        <ColumnTitle required description="Where is your final product assembled?">
                            Country of production
                        </ColumnTitle>
                    )
                }
            ]
        },
        {
            name: (
                <Text size="sm" fw={500}>
                    Product weight information
                </Text>
            ),
            children: [
                {
                    key: 'product_weight_value',
                    minWidth: 260,
                    cellClass: getCellClass('product_weight_value'),
                    renderEditCell,
                    name: (
                        <ColumnTitle required description="What is the net weight of the product (excl. any packaging)?">
                            Product weight
                        </ColumnTitle>
                    )
                },
                {
                    key: 'product_weight_unit',
                    renderEditCell,
                    cellClass: getCellClass('product_weight_unit'),
                    renderCell: renderOptionCell,
                    name: (
                        <ColumnTitle required description="What unit is the weight measured in?">
                            Product weight unit
                        </ColumnTitle>
                    ),
                    minWidth: 260
                }
            ]
        },
        transportColumns
    ];
};

export const getOptionalGeneralColumns = ({
    ecolabelCount,
    onCountChange
}: {
    ecolabelCount: number;
    onCountChange: (newCount: number) => void;
}) => {
    const ecolabeColumns: ColumnOrColumnGroup<DataRow, number>[] = [];

    for (let index = 0; index < ecolabelCount; index++) {
        ecolabeColumns.push({
            name: (
                <Group gap="xs">
                    <Text size="sm" fw={500}>
                        Eco-label {index + 1}
                    </Text>
                    <ListOptions type="eco-label" current={index} value={ecolabelCount} onChange={onCountChange} />
                </Group>
            ),
            children: [
                {
                    key: `eco_label_${index}_title`,
                    width: '100%',
                    cellClass: getCellClass(`eco_label_${index}_title`),
                    maxWidth: 200,
                    renderEditCell,
                    renderCell: renderOptionCell,
                    name: <ColumnTitle description="What is the name of the Eco-label?">Title</ColumnTitle>
                },
                {
                    key: `eco_label_${index}_type`,
                    width: '100%',
                    cellClass: getCellClass(`eco_label_${index}_type`),
                    maxWidth: 200,
                    renderEditCell,
                    renderCell: renderOptionCell,
                    name: <ColumnTitle description="Is this a Product or Packaging Eco-label?">Type</ColumnTitle>
                }
            ]
        });
    }

    return [
        ...defaultColumns,
        {
            key: 'division',
            minWidth: 200,
            renderEditCell: textEditor,
            name: <ColumnTitle description="What division of your company does this product belong to?">Division</ColumnTitle>
        },
        {
            key: 'custom_category',
            minWidth: 200,
            renderEditCell: textEditor,
            name: <ColumnTitle>Custom category</ColumnTitle>
        },
        {
            key: 'description',
            minWidth: 200,
            renderEditCell: textEditor,
            name: <ColumnTitle>Description</ColumnTitle>
        },
        {
            key: 'tags',
            minWidth: 200,
            renderEditCell,
            renderCell: ({ row }: any) => <TagsCell row={row} />,
            name: (
                <ColumnTitle
                    description={`Add any custom tags you would like to use to categorize your products (i.e. "Modelled Products" or "Upcoming Products". Use a comma to seperate tags.`}
                >
                    Custom tags
                </ColumnTitle>
            )
        },
        ...ecolabeColumns
    ];
};

export const getSustainabilityColumns = (): ColumnOrColumnGroup<DataRow, number>[] => [
    ...defaultColumns,
    {
        name: (
            <Text size="sm" fw={500}>
                Product Components
            </Text>
        ),
        children: [
            {
                key: 'product_components_proprietary_parts',
                minWidth: 180,
                renderEditCell,
                name: <ColumnTitle description="Does the product contain proprietary parts?">Proprietary parts</ColumnTitle>
            },
            {
                key: 'product_components_moving_parts',
                minWidth: 180,
                renderEditCell,
                name: <ColumnTitle description="Does the product contain moving parts?">Moving parts</ColumnTitle>
            },

            {
                key: 'product_components_small_components',
                minWidth: 180,
                renderEditCell,
                name: <ColumnTitle description="Does the product contain small components?">Small components</ColumnTitle>
            },
            {
                key: 'product_components_standard_fixings',
                minWidth: 180,
                renderEditCell,
                name: <ColumnTitle description="Does the product contain standard fixings?">Standard fixings</ColumnTitle>
            }
        ]
    },
    {
        key: 'repair_service',
        minWidth: 200,
        renderEditCell,
        name: <ColumnTitle description="Indicates if the product seller offers a repair program to its buyers.">Repair service</ColumnTitle>
    },
    {
        key: 'legal_guarantee',
        minWidth: 200,
        renderEditCell,
        name: (
            <ColumnTitle description="Defines if there is a legal guarantee by local regulations in the country of where the product is sold.">
                Legal guarantee
            </ColumnTitle>
        )
    },
    {
        key: 'extra_guarantee_duration',
        minWidth: 200,
        renderEditCell,
        name: (
            <ColumnTitle description="The additional guarantee period in years provided by the seller of the product, 0 means no additional guarantee and 100 means lifetime guarantee.">
                Extra guarantee duration
            </ColumnTitle>
        )
    },
    {
        key: 'average_product_lifespan',
        minWidth: 200,
        renderEditCell,
        renderCell: renderOptionCell,
        name: (
            <ColumnTitle description="How does the average lifespan of this product compare to conventional products of the same type?">
                Product Lifespan
            </ColumnTitle>
        )
    },
    {
        key: 'best_eol_disposal_method',
        minWidth: 200,
        renderEditCell,
        renderCell: renderOptionCell,
        name: <ColumnTitle description="How can the user best dispose of the product at the end of life?">End of life disposal</ColumnTitle>
    },
    {
        key: 'eol_treatment_type',
        minWidth: 200,
        renderCell: renderOptionCell,
        renderEditCell,
        name: <ColumnTitle description="How can the returned product is treated at the end-of-life">End of life treatment</ColumnTitle>
    },
    {
        key: 'communication_end_of_life_treatment',
        minWidth: 200,
        renderEditCell,
        renderCell: renderOptionCell,
        name: (
            <ColumnTitle description="Do you communicate to your customers on how to best treat the product at the end-of-life?">
                End of life communication
            </ColumnTitle>
        )
    }
];

export const getMaterialsColumns = ({
    materialCount,
    onCountChange,
    keyPrefix = 'materials_'
}: {
    materialCount: number;
    onCountChange: (newCount: number) => void;
    keyPrefix?: string;
}): ColumnOrColumnGroup<DataRow, number>[] => {
    const hasWeightInformation = keyPrefix !== 'materials_';
    const columns: ColumnOrColumnGroup<DataRow, number>[] = [
        ...defaultColumns,
        {
            key: `${keyPrefix}total_percentage`,
            frozen: true,
            minWidth: 140,
            maxWidth: 140,
            name: <ColumnTitle description="Please ensure your materials add to a valid percentage">Cumulative %</ColumnTitle>
        }
    ];

    if (hasWeightInformation) {
        const key = keyPrefix.replace('materials', '').replaceAll('_', '');
        columns.push(
            {
                key: `material_category_type_properties_${key}_weight_value`,
                renderEditCell,
                name: (
                    <ColumnTitle description={`What is the weight of the ${key} packaging?`}>
                        {formatHeader(key)} packaging weight
                    </ColumnTitle>
                ),
                minWidth: 260
            },
            {
                key: `material_category_type_properties_${key}_weight_unit`,
                renderEditCell,
                renderCell: renderOptionCell,
                name: (
                    <ColumnTitle description="What unit is the weight measured in?">{formatHeader(key)} packaging weight unit</ColumnTitle>
                ),
                minWidth: 260
            }
        );
    }

    for (let index = 0; index < materialCount; index++) {
        columns.push({
            name: (
                <Group gap="xs">
                    <Text size="sm" fw={500}>
                        Material {index + 1}
                    </Text>
                    <ListOptions type="material" current={index} value={materialCount} onChange={onCountChange} />
                </Group>
            ),
            children: [
                {
                    name: <Text size="xs">Basic material information</Text>,
                    children: [
                        {
                            key: `${keyPrefix}${index}_material_name`,
                            minWidth: 200,
                            cellClass: getCellClass(`${keyPrefix}${index}_material_name`),
                            maxWidth: 360,
                            renderEditCell,
                            renderCell: renderOptionCell,
                            name: <ColumnTitle description="What is the name of the material?">Material name</ColumnTitle>
                        },
                        {
                            key: `${keyPrefix}${index}_percentage`,
                            minWidth: 180,
                            cellClass: getCellClass(`${keyPrefix}${index}_percentage`),
                            maxWidth: 180,
                            renderEditCell,
                            name: (
                                <ColumnTitle description="What % of the overall does this material compose?">
                                    Material breakdown %
                                </ColumnTitle>
                            )
                        }
                    ]
                },
                {
                    name: <Text size="xs">Detailed product material information (optional)</Text>,
                    children: [
                        {
                            key: `${keyPrefix}${index}_recycled`,
                            minWidth: 140,
                            maxWidth: 180,
                            name: (
                                <ColumnTitle description="Indicate if this material is recycled. This is a read only field determined by the selected material.">
                                    Recycled?
                                </ColumnTitle>
                            )
                        },
                        {
                            key: `${keyPrefix}${index}_reused`,
                            minWidth: 140,
                            maxWidth: 180,
                            name: (
                                <ColumnTitle description="Indicate if this material is reused. This is a read only field determined by the selected material.">
                                    Reused?
                                </ColumnTitle>
                            )
                        },
                        {
                            key: `${keyPrefix}${index}_organic`,
                            minWidth: 140,
                            maxWidth: 180,
                            name: (
                                <ColumnTitle description="Indicate if this material is organic. This is a read only field determined by the selected material.">
                                    Organic?
                                </ColumnTitle>
                            )
                        },
                        {
                            key: `${keyPrefix}${index}_source_country`,
                            minWidth: 140,
                            maxWidth: 180,
                            renderCell: renderOptionCell,
                            name: (
                                <ColumnTitle description="Indicates where the material is sourced from. This is a read only field determined by the selected material.">
                                    Source Country
                                </ColumnTitle>
                            )
                        }
                    ]
                }
            ]
        });
    }

    return columns;
};

export const getProductionColumns = ({
    energySourceCount,
    onCountChange
}: {
    energySourceCount: number;
    onCountChange: (newCount: number) => void;
}): ColumnOrColumnGroup<DataRow, number>[] => {
    const energySourcesColumns: ColumnOrColumnGroup<DataRow, number>[] = [];

    for (let index = 0; index < energySourceCount; index++) {
        energySourcesColumns.push({
            name: (
                <Group gap="xs">
                    <Text size="sm" fw={500}>
                        Energy source {index + 1}
                    </Text>
                    <ListOptions type="energy source" current={index} value={energySourceCount} onChange={onCountChange} />
                </Group>
            ),
            children: [
                {
                    key: `energy_sources_${index}_energy_type`,
                    cellClass: getCellClass(`energy_sources_${index}_energy_type`),
                    minWidth: 200,
                    renderEditCell,
                    renderCell: renderOptionCell,
                    name: <ColumnTitle description="Type of energy source such as oil, gas, coal, etc">Type</ColumnTitle>
                },
                {
                    key: `energy_sources_${index}_usage_type`,
                    cellClass: getCellClass(`energy_sources_${index}_usage_type`),
                    minWidth: 180,
                    maxWidth: 180,
                    renderCell: renderOptionCell,
                    renderEditCell,
                    name: <ColumnTitle description="The usage type of the energy source, heat, electricity or both">Usage</ColumnTitle>
                },
                {
                    key: `energy_sources_${index}_energy_use_value`,
                    cellClass: getCellClass(`energy_sources_${index}_energy_use_value`),
                    minWidth: 180,
                    maxWidth: 180,
                    renderEditCell,
                    name: <ColumnTitle>Value</ColumnTitle>
                },
                {
                    key: `energy_sources_${index}_energy_use_unit`,
                    cellClass: getCellClass(`energy_sources_${index}_energy_use_unit`),
                    minWidth: 180,
                    maxWidth: 180,
                    renderCell: renderOptionCell,
                    renderEditCell,
                    name: <ColumnTitle description="The unit of the value for this energy source, such as kWh, J, %, etc">Unit</ColumnTitle>
                }
            ]
        });
    }

    return [
        ...defaultColumns,
        {
            name: (
                <Text size="sm" fw={500}>
                    Water consumption details
                </Text>
            ),
            children: [
                {
                    key: `water_consumption_value`,
                    cellClass: getCellClass('water_consumption_value'),
                    minWidth: 200,
                    renderEditCell,
                    name: <ColumnTitle>Value</ColumnTitle>
                },
                {
                    key: `water_consumption_unit`,
                    cellClass: getCellClass('water_consumption_unit'),
                    minWidth: 200,
                    renderEditCell,
                    renderCell: renderOptionCell,
                    name: <ColumnTitle>Unit</ColumnTitle>
                },
                {
                    key: `water_consumption_source`,
                    cellClass: getCellClass('water_consumption_source'),
                    minWidth: 200,
                    renderEditCell,
                    renderCell: renderOptionCell,
                    name: <ColumnTitle>Water source</ColumnTitle>
                }
            ]
        },
        {
            name: (
                <Text size="sm" fw={500}>
                    Energy consumption details
                </Text>
            ),
            children: energySourcesColumns
        }
    ];
};

export const getLivelihoodsAndWellbeingColumns = (): ColumnOrColumnGroup<DataRow, number>[] => [
    ...defaultColumns,
    {
        key: 'child_and_forced_labor',
        renderEditCell: textEditor,
        minWidth: 300,
        maxWidth: 400,
        name: (
            <ColumnTitle
                description={
                    <Text size="xs">
                        Provide the key to a proof file uploaded in the data ingestion section.{' '}
                        <Anchor href={links.customerService.proofFileUpload} target="_blank">
                            Click here to learn how
                        </Anchor>
                        .
                    </Text>
                }
            >
                Child labor is not tolerable
            </ColumnTitle>
        )
    },
    {
        key: 'youth_labor',
        renderEditCell: textEditor,
        minWidth: 300,
        maxWidth: 400,
        name: (
            <ColumnTitle
                description={
                    <Text size="xs">
                        Youth labor is avoided -and if employed- protection measures are taken based on international standards. Provide the
                        key to a proof file uploaded in the data ingestion section.{' '}
                        <Anchor href={links.customerService.proofFileUpload} target="_blank">
                            Click here to learn how
                        </Anchor>
                        .
                    </Text>
                }
            >
                Youth labor is avoided
            </ColumnTitle>
        )
    },
    {
        key: 'work_safety',
        renderEditCell: textEditor,
        minWidth: 300,
        maxWidth: 400,
        name: (
            <ColumnTitle
                description={
                    <Text size="xs">
                        Working conditions are safe and hygienic (as proclaimed by the Occupational Safety and Health Convention adopted by
                        the ILO, and its several Codes of Practice). Provide the key to a proof file uploaded in the data ingestion section.
                        Provide the key to a proof file uploaded in the data ingestion section.{' '}
                        <Anchor href={links.customerService.proofFileUpload} target="_blank">
                            Click here to learn how
                        </Anchor>
                        .
                    </Text>
                }
            >
                Working conditions are safe and hygienic
            </ColumnTitle>
        )
    },
    {
        key: 'gender_wage_gap',
        renderEditCell: textEditor,
        minWidth: 300,
        maxWidth: 400,
        name: (
            <ColumnTitle
                description={
                    <Text size="xs">
                        Non-discrimination is practiced in the workplace. Provide the key to a proof file uploaded in the data ingestion
                        section.. Provide the key to a proof file uploaded in the data ingestion section. Provide the key to a proof file
                        uploaded in the data ingestion section.{' '}
                        <Anchor href={links.customerService.proofFileUpload} target="_blank">
                            Click here to learn how
                        </Anchor>
                        .
                    </Text>
                }
            >
                Gender wage gap
            </ColumnTitle>
        )
    },
    {
        key: 'collective_bargaining',
        renderEditCell: textEditor,
        minWidth: 300,
        maxWidth: 400,
        name: (
            <ColumnTitle
                description={
                    <Text size="xs">
                        Freedom of association and collective bargaining is respected (as proclaimed by the Universal Declaration of Human
                        Rights and the ILO Declaration on Fundamental Principles and Rights at Work). Provide the key to a proof file
                        uploaded in the data ingestion section.. Provide the key to a proof file uploaded in the data ingestion section.
                        Provide the key to a proof file uploaded in the data ingestion section.{' '}
                        <Anchor href={links.customerService.proofFileUpload} target="_blank">
                            Click here to learn how
                        </Anchor>
                        .
                    </Text>
                }
            >
                Collective bargaining
            </ColumnTitle>
        )
    },
    {
        key: 'forced_labor',
        renderEditCell: textEditor,
        minWidth: 300,
        maxWidth: 400,
        name: (
            <ColumnTitle
                description={
                    <Text size="xs">
                        Employment is freely chosen among workers. Provide the key to a proof file uploaded in the data ingestion section.{' '}
                        <Anchor href={links.customerService.proofFileUpload} target="_blank">
                            Click here to learn how
                        </Anchor>
                        .
                    </Text>
                }
            >
                No forced labor
            </ColumnTitle>
        )
    },
    {
        key: 'living_wage',
        renderEditCell: textEditor,
        minWidth: 300,
        maxWidth: 400,
        name: (
            <ColumnTitle
                description={
                    <Text size="xs">
                        Provide the key to a proof file uploaded in the data ingestion section. Provide the key to a proof file uploaded in
                        the data ingestion section.{' '}
                        <Anchor href={links.customerService.proofFileUpload} target="_blank">
                            Click here to learn how
                        </Anchor>
                        .
                    </Text>
                }
            >
                Living wages are paid
            </ColumnTitle>
        )
    },
    {
        key: 'working_hours',
        renderEditCell: textEditor,
        minWidth: 300,
        maxWidth: 400,
        name: (
            <ColumnTitle
                description={
                    <Text size="xs">
                        Working hours are not excessive (Extra-hours are sporadically accepted and remunerated accordingly). Provide the key
                        to a proof file uploaded in the data ingestion section.. Provide the key to a proof file uploaded in the data
                        ingestion section. Provide the key to a proof file uploaded in the data ingestion section. Provide the key to a
                        proof file uploaded in the data ingestion section.{' '}
                        <Anchor href={links.customerService.proofFileUpload} target="_blank">
                            Click here to learn how
                        </Anchor>
                        .
                    </Text>
                }
            >
                Reasonable working hours
            </ColumnTitle>
        )
    }
];
