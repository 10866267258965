import { Dimension } from 'types/shared';
import { CircularityCards } from './circularity-cards';
import { ScoreReportLayout } from './score-report-layout';

export const Circularity = () => {
    return (
        <ScoreReportLayout dimension={Dimension.Circularity}>
            <CircularityCards />
        </ScoreReportLayout>
    );
};
