import { Filter, Nullable } from 'types';
import { useInsightQuery } from './use-insight-query';

const MATERIALS_EMISSIONS_QUERY_NAME = 'insight_climate_materials_emissions';
const TRANSPORT_EMISSIONS_QUERY_NAME = 'insight_climate_emissions_by_transport';

export const useClimateImpactsOfMaterialsInsightReport = (params?: Filter[]) => {
    const materialsEmissionsResult = useInsightQuery<{
        beautified_name: Nullable<string>;
        gwp_absolute: Nullable<number>;
        gwp_per_kg: Nullable<number>;
        unit: Nullable<number>;
        region?: Nullable<string>;
    }>(MATERIALS_EMISSIONS_QUERY_NAME, params);

    const transportEmissionsResult = useInsightQuery<{
        transport_mode: Nullable<string>;
        gwp_absolute: Nullable<number>;
        distance_km: Nullable<number>;
        unit: Nullable<number>;
    }>(TRANSPORT_EMISSIONS_QUERY_NAME, params);

    return { materialsEmissionsResult, transportEmissionsResult };
};
