import { NoDataAlert } from 'insights-explorer/no-data-alert';
import { dqrDataPoints } from 'shared/data-quality-data-points';
import { Section } from 'shared/section';
import { StaticTable } from 'shared/static-table';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Group, PaperProps, Skeleton, Stack, Text, Title, rem } from '@mantine/core';
import { DataQualityCard } from './data-quality-card';
import classes from './purpose-cards.module.css';
import { useScoreReport } from './use-score-report';

export const DataQualityCards = (props: PaperProps) => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();

    const { dqr, isDqrLoading } = useScoreReport(id);
    const totalDqr = dqr?.data?.results?.[0]?.total_primary_dqr ?? 0;
    const dqrData = dqr?.data?.results[0];

    const getSourceType = (value: number) => (value === 0 ? 'Primary' : 'Secondary');

    const dqrTableData = Object.entries(dqrDataPoints).map(([key, label]) => ({
        data_point: label,
        source: getSourceType(dqrData?.[key as keyof typeof dqrData] ?? 1)
    }));

    const hasNoData = !isDqrLoading && dqr.data?.results.length === 0;

    if (isDqrLoading) {
        return <Skeleton height={300} />;
    }

    if (hasNoData) {
        return <NoDataAlert />;
    }

    return (
        <>
            <Section {...props}>
                <DataQualityCard score={totalDqr ?? undefined} lgHeader={true} />
            </Section>
            <Section mt="md" {...props}>
                <Group align="flex-start" justify="space-between" gap="xs">
                    <Stack gap={rem(4)} className={classes.introduction}>
                        <Title size="h4">{t('dataQualityRatingBreakdown')}</Title>
                        <Text c="dimmed" size="sm">
                            {t('dataQualityRatingBreakdownDescription')}
                        </Text>
                    </Stack>
                </Group>
                <StaticTable
                    m={`${rem(20)} 0`}
                    searchable={false}
                    isLoading={false}
                    columns={[
                        {
                            key: 'data_point',
                            name: 'Data Point',
                            sortable: false
                        },
                        {
                            key: 'source',
                            name: 'Source',
                            sortable: true,
                            width: 180
                        }
                    ]}
                    data={dqrTableData}
                />
            </Section>
        </>
    );
};
