import { Box, Group, Text, useMantineColorScheme } from '@mantine/core';
import planeSrc from './plane.svg';
import shipSrc from './ship.svg';
import trainSrc from './train.svg';
import truckSrc from './truck.svg';

const labelModeMap = {
    heavy_truck: 'Heavy truck',
    medium_truck: 'Medium truck',
    light_truck: 'Light truck',
    inland_waterway_freight: 'Inland waterway freight',
    oceanic_transport: 'Oceanic transport',
    airplane: 'Airplane',
    freight_train: 'Freight train',
    diesel_freight_train: 'Diesel freight train',
    electric_freight_train: 'Electric freight train'
};

const iconSrcMap = {
    heavy_truck: truckSrc,
    medium_truck: truckSrc,
    light_truck: truckSrc,
    inland_waterway_freight: shipSrc,
    oceanic_transport: shipSrc,
    airplane: planeSrc,
    freight_train: trainSrc,
    diesel_freight_train: trainSrc,
    electric_freight_train: trainSrc
};

export type TransportType = keyof typeof labelModeMap;

export const TransportTypeCell = ({ type }: { type: TransportType }) => {
    const { colorScheme } = useMantineColorScheme();
    if (!labelModeMap[type]) return <>{type}</>;
    return (
        <Group gap="sm" wrap="nowrap">
            <Box
                component="img"
                style={{ filter: colorScheme === 'dark' ? 'brightness(10)' : 'none' }}
                src={iconSrcMap[type]}
                alt=""
                width={32}
                height={32}
            />
            <Text>{labelModeMap[type]}</Text>
        </Group>
    );
};
