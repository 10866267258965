import { notifications } from '@mantine/notifications';
import { getErrorMessage } from './get-error-message';
import { report } from './report-error';

export const showErrorMessage = (error: any, shouldLog: boolean = false) => {
    if (shouldLog) {
        report(error);
    }
    return notifications.show({ message: typeof error === 'string' ? error : getErrorMessage(error), color: 'red' });
};
