import { useEffect } from 'react';
import { Center } from '@mantine/core';
import { nprogress } from '@mantine/nprogress';
import { LogoIcon } from './logo-icon';
import { useOrganizationRedirect } from './use-organization-redirect';

export const CheckOrganization = () => {
    useOrganizationRedirect();

    useEffect(() => {
        nprogress.start();
        return () => {
            nprogress.stop();
            nprogress.reset();
        };
    }, []);

    return (
        <Center h="100vh">
            <LogoIcon size="lg" data-testid="loading" />
        </Center>
    );
};
