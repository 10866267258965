import '@fontsource-variable/inter';
import { Providers } from 'providers';
import { Router } from 'router';
import { registerSW } from 'virtual:pwa-register';
import { StrictMode } from 'react';
import 'react-data-grid/lib/styles.css';
import { createRoot } from 'react-dom/client';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/nprogress/styles.css';
import '@mantine/spotlight/styles.css';
import 'lib/i18n';
import { getUpdateAppText } from 'utils/get-update-app-text';
import 'utils/report-error';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <StrictMode>
        <Providers>
            <Router />
        </Providers>
    </StrictMode>
);

const REFETCH_INTERVAL = 1000 * 60 * 15; // 15 minutes

const updateSW = registerSW({
    onNeedRefresh() {
        if (window.confirm(getUpdateAppText())) {
            updateSW(true);
        }
    },
    onRegistered(r) {
        if (r) {
            setInterval(() => {
                r.update().catch((error) => console.error(error));
            }, REFETCH_INTERVAL);
        }
    }
});
