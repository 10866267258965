import { unflatten } from 'flat';
import { isNumber } from './is-number';

export const removeEmptyKeys = (obj: any) => {
    const newObj = { ...obj };
    Object.keys(newObj).forEach((key) => {
        if (newObj[key] === '') {
            delete newObj[key];
        }
    });
    return newObj;
};

const formatKey = (key: string, value: any) => {
    if (typeof value === 'string') {
        if (value.toLowerCase() === 'true') return true;
        if (value.toLowerCase() === 'false') return false;
        if (['null', 'na'].includes(value)) {
            if (key === 'proof_files') return [];
            if (key === 'eco_labels') return [];
            if (key === 'tags') return [];
            return null;
        }
        if (value.endsWith('%')) return Number(value.replace('%', ''));
        if (isNumber(value) && key !== 'sku') return Number(value);
        if (value === '[]') return [];
        if (value.includes(',')) {
            if (key.includes('material') || key.includes('description') || key === 'name') return value;
            return value.split(',');
        }
    }
    return value;
};

const transformObj = (obj: any, formatter: (key: string, value: any) => any) => {
    if (typeof obj !== 'object' || obj === null) {
        return;
    }
    Object.keys(obj).forEach((key) => {
        const value = obj[key];
        if (Array.isArray(value)) {
            obj[key] = value
                .map((current) => {
                    transformObj(current, formatter);
                    return current;
                })
                .filter((current) => {
                    if (key === 'materials' && !current.material_name) return false;
                    return true;
                });
        } else if (typeof value === 'object' && value !== null) {
            transformObj(value, formatter);
        } else {
            obj[key] = formatter(key, value);
        }
    });
};

export const fileToJsonlStr = (file: File): Promise<string> => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = async () => {
            const { parse } = await import('papaparse');
            const result = reader.result as string;
            const parsed = parse(result, { header: true });
            const json = parsed.data;
            let formatted = json.map((item) => {
                const withoutEmptyKeys = removeEmptyKeys(item);
                const current = unflatten(withoutEmptyKeys);
                transformObj(current, formatKey);
                return current;
            });

            const filtered = formatted.filter((item: any) => Object.keys(item).length > 0);

            const jsonlStr = filtered.map((item) => JSON.stringify(item)).join('\n');
            resolve(jsonlStr);
        };
    });
};

/**
 * Converts a CSV file to JSONL file
 * If not a CSV file the input file is returned
 */
export const convertCsvToJsonl = async (file: File): Promise<File> => {
    if (!file.name.endsWith('.csv')) {
        return file;
    }
    const jsonlStr = await fileToJsonlStr(file);
    const newFile = new File([jsonlStr], `${file.name.replace('.csv', '')}.jsonl`);
    return newFile;
};
