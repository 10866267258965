import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TbFilter } from 'react-icons/tb';
import { ActionIcon, Button, Drawer, Group, Indicator, Stack, Tooltip, rem } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';

export const FilterBar = ({
    children,
    hasAppliedFilters = false,
    filterCount,
    initialOpen = false,
    onClearAll
}: {
    initialOpen?: boolean;
    children: ReactNode;
    hasAppliedFilters?: boolean;
    filterCount?: number;
    onClearAll: () => void;
}) => {
    const { t } = useTranslation();
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');
    const [opened, { open, close }] = useDisclosure(initialOpen);

    return (
        <>
            <Drawer
                opened={opened}
                onClose={close}
                title={filterCount ? `${t('filters')} (${filterCount})` : t('filters')}
                size={isSmallDevice ? rem(240) : rem(380)}
                position="right"
            >
                <Stack gap="md">{children}</Stack>
                <Group mt="lg" align="center" justify={hasAppliedFilters ? 'space-between' : 'flex-end'}>
                    {hasAppliedFilters && (
                        <Tooltip label={t('clearAllDescription')}>
                            <Button variant="outline" onClick={onClearAll}>
                                {t('clearAll')}
                            </Button>
                        </Tooltip>
                    )}

                    <Button onClick={close}>{t('done')}</Button>
                </Group>
            </Drawer>
            <Tooltip label={hasAppliedFilters ? t('filtersApplied') : t('noFiltersApplied')}>
                <Indicator zIndex={1} disabled={!hasAppliedFilters}>
                    <ActionIcon variant="default" size="lg" onClick={open}>
                        <TbFilter />
                    </ActionIcon>
                </Indicator>
            </Tooltip>
        </>
    );
};
