import { ErrorLayout } from './error-layout';

export const ErrorFallback = ({
    error,
    onClick = () => {
        window.localStorage.clear();
        window.location.reload();
    },
    message = 'An unexpected error has occurred. Please try again.',
    buttonText = 'Try again'
}: {
    onClick?: () => void;
    error?: Error;
    message?: string;
    buttonText?: string;
}) => {
    const currentMessage = error?.message ?? message;
    return (
        <ErrorLayout status="500" title="Oops! Something went wrong" message={currentMessage} buttonText={buttonText} onClick={onClick} />
    );
};
