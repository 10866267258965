const SERVICE_DESK_URL = 'https://dayrize-io.atlassian.net/servicedesk/customer';

export const links = {
    jsonlines: 'https://jsonlines.org/',
    csv: 'https://en.wikipedia.org/wiki/Comma-separated_values',
    privacyPolicy: 'https://dayrize.io/privacy-policy/',
    dayrizeScore: 'https://dayrize.io/methodology/',
    contactSupport: `${SERVICE_DESK_URL}/portal/3/group/-1`,
    customerService: {
        main: `${SERVICE_DESK_URL}/portals`,
        dataIngestion: `${SERVICE_DESK_URL}/portal/3/topic/d524e6ae-5c42-45e7-b6b9-ca8b22558733/article/20709418`,
        validate: `${SERVICE_DESK_URL}/portal/3/topic/d524e6ae-5c42-45e7-b6b9-ca8b22558733/article/40894465`,
        userWhitelisting: `${SERVICE_DESK_URL}/portal/3/topic/e9bd0dc4-0d3b-4f76-bb95-48e25735f87b/article/20676609`,
        auditLog: `${SERVICE_DESK_URL}/portal/3/topic/e9bd0dc4-0d3b-4f76-bb95-48e25735f87b/article/20742167`,
        groupsAndPermissons: `${SERVICE_DESK_URL}/portal/3/topic/e9bd0dc4-0d3b-4f76-bb95-48e25735f87b/article/20676623`,
        queryingData: `${SERVICE_DESK_URL}/portal/3/topic/7d9725ac-ad10-451b-8bc0-6a0fb18b2052/article/21495809`,
        schemas: `${SERVICE_DESK_URL}/portal/3/topic/7d9725ac-ad10-451b-8bc0-6a0fb18b2052/article/42139690`,
        productHistory: `${SERVICE_DESK_URL}/portal/3/topic/7d9725ac-ad10-451b-8bc0-6a0fb18b2052/article/42696904`,
        scoreReport: `${SERVICE_DESK_URL}/portal/3/topic/a32bdc53-c52d-4524-9b41-bf6a7a4deee9/article/39321620`,
        proofFileUpload: `${SERVICE_DESK_URL}/portal/3/article/94699521`
    }
};
