import { Dimension } from 'types/shared';
import { EcosystemImpactCards } from './ecosystem-impact-cards';
import { ScoreReportLayout } from './score-report-layout';

export const EcosystemImpact = () => {
    return (
        <ScoreReportLayout dimension={Dimension.Ecosystem}>
            <EcosystemImpactCards />
        </ScoreReportLayout>
    );
};
