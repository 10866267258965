import { ReactNode } from 'react';
import { Title, Text, Button, Group, Container } from '@mantine/core';
import classes from './error-layout.module.css';
import { useUser } from './use-user';

export const ErrorLayout = ({
    status,
    title,
    message,
    buttonText,
    onClick
}: {
    status: string;
    title: string;
    message: ReactNode;
    buttonText: string;
    onClick?: () => void;
}) => {
    const { onLogout } = useUser();
    return (
        <Container className={classes.container}>
            <Text className={classes.status}>{status}</Text>
            <Title className={classes.title}>{title}</Title>
            <Text c="dimmed" size="lg" className={classes.description}>
                {message}
            </Text>
            <Group justify="center" gap="sm">
                <Button onClick={onClick}>{buttonText}</Button>
                <Button variant="default" onClick={onLogout}>
                    Sign out
                </Button>
            </Group>
        </Container>
    );
};
