import { useOrganizationId } from 'shared/use-organization-id';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Anchor, Breadcrumbs, Text } from '@mantine/core';
import { useProductName } from './use-product-name';

export const ScoreReportBreadcrumbs = () => {
    const { t } = useTranslation();
    const organizationId = useOrganizationId();
    const name = useProductName();

    return (
        <Breadcrumbs>
            <Anchor component={Link} to={`/organization/${organizationId}/products`}>
                Products
            </Anchor>
            <Text>{name}</Text>
            <Text>{t('scoreReport')}</Text>
        </Breadcrumbs>
    );
};
