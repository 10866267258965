import { Match } from 'products/materials-autocomplete';
import { DataRow } from './create-empty-row';
import { MATERIAL_COLUMN_PREFIXES, MAX_COLUMNS } from './data-row-product-schema-conversion';
import { getTransportMediumFromMode } from './get-transport-medium-from-mode';

export const getMaterialUpdate = (columnKey: string, index: number, option: Match) => {
    const recycled = typeof option.recycled === 'boolean' ? (option.recycled ? 'Yes' : 'No') : null;
    const reused = typeof option.reused === 'boolean' ? (option.reused ? 'Yes' : 'No') : null;
    const organic = typeof option.organic === 'boolean' ? (option.organic ? 'Yes' : 'No') : null;
    return {
        [`${columnKey}${index}_material_name`]: option.label,
        [`${columnKey}${index}_id`]: option.value,
        [`${columnKey}${index}_recycled` as any]: recycled,
        [`${columnKey}${index}_reused` as any]: reused,
        [`${columnKey}${index}_organic` as any]: organic,
        [`${columnKey}${index}_source_country`]: option.region
    };
};

export const clearMaterialFields = (columnKey: string, index: number) => {
    return {
        [`${columnKey}${index}_material_name`]: '',
        [`${columnKey}${index}_id`]: '',
        [`${columnKey}${index}_recycled` as any]: null,
        [`${columnKey}${index}_reused` as any]: null,
        [`${columnKey}${index}_organic` as any]: null,
        [`${columnKey}${index}_source_country`]: null
    };
};

export const setRelatedFields = (row: Partial<DataRow>): DataRow => {
    let updatedRow = { ...row } as DataRow;
    const recentMaterials = JSON.parse(window.localStorage.getItem('recent-materials') || '[]') as Match[];
    for (let i = 0; i < MAX_COLUMNS; i++) {
        // Materials
        MATERIAL_COLUMN_PREFIXES.forEach((prefix) => {
            const materialId = updatedRow[`${prefix}${i}_id` as keyof DataRow];
            const materialName = updatedRow[`${prefix}${i}_material_name` as keyof DataRow];
            const material = recentMaterials.find((material) => material.value === materialId);

            const hasValidValue = materialId && material;
            if (hasValidValue) {
                if (material) {
                    const update = hasValidValue ? getMaterialUpdate(prefix, i, material) : clearMaterialFields(prefix, i);
                    updatedRow = { ...updatedRow, ...update };
                }
            } else if (materialName) {
                const nameMatch = recentMaterials.find((material) => material.label === materialName);
                if (nameMatch) {
                    const update = getMaterialUpdate(prefix, i, nameMatch);
                    updatedRow = { ...updatedRow, ...update };
                }
            } else if (!materialId && !materialName) {
                const update = clearMaterialFields(prefix, i);
                updatedRow = { ...updatedRow, ...update };
            }
        });

        // Transports
        if (updatedRow[`transports_${i}_transport_mode` as keyof DataRow]) {
            // Set medium based on mode
            const mode = updatedRow[`transports_${i}_transport_mode` as keyof DataRow];
            if (typeof mode === 'string') {
                const method = getTransportMediumFromMode(mode);
                const key = `transports_${i}_transport_medium` as keyof DataRow;
                // @ts-ignore
                updatedRow[key] = method;
            }
        }
    }

    return updatedRow;
};
