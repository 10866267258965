import { useTableFilters } from 'shared/use-table-filters';
import useSWR from 'swr';
import { Organization, QueryResult } from 'types';
import { useEffect } from 'react';

export const useOrganizations = () => {
    const { onPageTokenMappingChange, query, page, onPage, limit, onLimitChange, search, onSearchChange, order, onOrderChange } =
        useTableFilters({
            searchKey: 'name'
        });
    const { data, error, mutate, isLoading } = useSWR<QueryResult<Organization>>(['/account_manager/organizations/query', query]);

    useEffect(() => {
        const shouldUpdate = data && !isLoading && data.page_token;
        if (shouldUpdate) {
            onPageTokenMappingChange((current) => ({
                ...current,
                [page + 1]: data.page_token
            }));
        }
    }, [data, isLoading, onPageTokenMappingChange, page]);

    return {
        data,
        error,
        mutate,
        isLoading,
        onPage,
        onLimitChange,
        order,
        onOrderChange,
        onSearchChange,
        search,
        page,
        limit
    };
};
