import { useOrganizationGroups } from 'account-management/groups/use-organization-groups';
import { links } from 'links';
import { paths } from 'paths';
import { ConfirmationModal } from 'shared/confirmation-modal';
import { ErrorCard } from 'shared/error-card';
import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import { Section } from 'shared/section';
import { Table } from 'shared/table';
import { useOrganizationId } from 'shared/use-organization-id';
import { useUser } from 'shared/use-user';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbDotsVertical } from 'react-icons/tb';
import { Link, useNavigate } from 'react-router-dom';
import { ActionIcon, Anchor, Badge, Button, Group, Menu, Text, Title, rem } from '@mantine/core';
import { formatHeader } from 'utils/format-header';
import { getDateColumns } from 'utils/get-date-columns';
import { getIdFromKey } from 'utils/get-id-from-key';
import { showErrorMessage } from 'utils/show-error-message';
import { showSuccessMessage } from 'utils/show-success-message';
import { RESERVED_GROUPS } from './reserved-groups';

export const Groups = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useUser();
    const organizationId = useOrganizationId();
    const { data, error, isLoading, remove, page, onPage, order, onOrderChange, search, onSearchChange, limit, onLimitChange } =
        useOrganizationGroups();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [activeId, setActiveId] = useState<undefined | string>();

    if (error) {
        return <ErrorCard error={error} />;
    }

    const handleDeleteGroupConfirm = async () => {
        if (!activeId) return;
        try {
            await remove(activeId);
            showSuccessMessage(t('groupDeleted'));
            setShowDeleteModal(false);
            setActiveId(undefined);
        } catch (error) {
            showErrorMessage(error);
        }
    };

    return (
        <>
            <Group gap={rem(2)}>
                <Title size="h3">{t('groups')}</Title>
                <MoreInfoHoverCard>
                    <Text size="sm" c="dimmed">
                        Allows you to{' '}
                        <Anchor target="_blank" href={links.customerService.groupsAndPermissons}>
                            create and manage groups
                        </Anchor>{' '}
                        including users and permissions.
                    </Text>
                </MoreInfoHoverCard>
            </Group>
            <Text c="dimmed" mb="md">
                {t('manageUserPermissions')}
            </Text>

            <Section>
                <Table
                    isLoading={isLoading}
                    actions={
                        hasPermission('/account_manager/group/create', 'POST') && (
                            <Button component={Link} to={`/organization/${organizationId}/groups/create`}>
                                {t('createGroup')}
                            </Button>
                        )
                    }
                    data={data?.items}
                    rowKey={(row) => row.key}
                    columns={[
                        {
                            key: 'name',
                            name: t('name'),
                            sortable: true,
                            render: (value) =>
                                RESERVED_GROUPS.includes(value) ? (
                                    <Group gap="xs">
                                        <span>{formatHeader(value)}</span>
                                        <Badge size="sm">{t('predefined')}</Badge>
                                    </Group>
                                ) : (
                                    value
                                )
                        },
                        {
                            key: 'description',
                            name: t('description'),
                            sortable: true
                        },
                        {
                            key: 'user_ids',
                            name: t('assignedUsers'),
                            render: (value) => value.length || '-'
                        },
                        ...getDateColumns(),
                        {
                            key: 'actions',
                            name: '',
                            align: 'right',
                            render: (_, row) => (
                                <Menu shadow="md">
                                    <Menu.Target>
                                        <ActionIcon data-testid={`menu-${row.key}`}>
                                            <TbDotsVertical size={16} />
                                        </ActionIcon>
                                    </Menu.Target>
                                    <Menu.Dropdown>
                                        {hasPermission('/account_manager/group', 'PATCH') && (
                                            <Menu.Item
                                                onClick={() => {
                                                    navigate(paths.accountManagement.groupDetail(organizationId, getIdFromKey(row.key)));
                                                }}
                                            >
                                                {t('update')}
                                            </Menu.Item>
                                        )}

                                        {hasPermission('/account_manager/group', 'DELETE') && !RESERVED_GROUPS.includes(row.name) && (
                                            <Menu.Item
                                                color="red"
                                                onClick={() => {
                                                    setShowDeleteModal(true);
                                                    setActiveId(getIdFromKey(row.key));
                                                }}
                                            >
                                                {t('delete')}
                                            </Menu.Item>
                                        )}
                                    </Menu.Dropdown>
                                </Menu>
                            )
                        }
                    ]}
                    search={search}
                    onSearchChange={onSearchChange}
                    limit={limit}
                    onLimitChange={onLimitChange}
                    page={page}
                    onPage={onPage}
                    order={order}
                    onOrderChange={onOrderChange}
                />
            </Section>

            <ConfirmationModal
                title={t('confirmDeleteTitle')}
                variant="delete"
                isOpen={showDeleteModal}
                onClose={() => {
                    setShowDeleteModal(false);
                }}
                onConfirm={handleDeleteGroupConfirm}
            >
                {t('groupDeleteConfirmation')}
            </ConfirmationModal>
        </>
    );
};
