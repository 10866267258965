import { useTranslation } from 'react-i18next';
import { Group, Stack, Text, Title, rem } from '@mantine/core';
import classes from './circularity-cards.module.css';

interface DataQualityCardProps {
    score: number | undefined;
    lgHeader?: boolean;
}

export const DataQualityCard = ({ score, lgHeader }: DataQualityCardProps) => {
    const { t } = useTranslation();
    const header = lgHeader ? 'h2' : 'h4';

    const formatPercentage = (value: number): string => {
        return `${Math.round(value)}%`;
    };

    return (
        <Group align="center" justify="space-between" gap="xs">
            <Stack gap={rem(4)} className={classes.introduction}>
                <Title size={header} mb={rem(4)}>
                    {t('dataQualityRatingTitle')}
                </Title>

                <Text c="dimmed" size="sm">
                    {t('dataQualityRatingDescription')}
                </Text>
            </Stack>
            <Text
                style={{
                    fontWeight: 500,
                    lineHeight: 1,
                    fontSize: rem(42),
                    paddingRight: rem(16)
                }}
            >
                {score !== null && score !== undefined ? `${formatPercentage(score)}` : 'N/A'}
            </Text>
        </Group>
    );
};
