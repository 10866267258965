import useSWR from 'swr';

export const useResources = () => {
    const { data, isLoading } = useSWR<
        {
            resource: string;
            name: string;
            method: string;
        }[]
    >('/account_manager/users/resources/list');
    return {
        isLoading,
        data
    };
};
