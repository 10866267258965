import { saveAs } from 'file-saver';
import { JsonValue } from './export-as-json';

export const exportAsJsonl = (json: JsonValue[], filename: string) => {
    const jsonlStr = json.map((item) => JSON.stringify(item)).join('\n');
    const result = [jsonlStr];
    const blob = new Blob(result);
    saveAs(blob, `${filename}.jsonl`);
    return result;
};
