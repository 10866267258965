import { saveAs } from 'file-saver';

export type JsonValue = string | number | boolean | null | { [x: string]: JsonValue } | JsonValue[];

export const exportAsJson = (json: JsonValue, filename: string) => {
    const result = [JSON.stringify(json, null, 2)];
    const blob = new Blob(result, { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${filename}.json`);
    return result;
};
