import { GraphLegend } from 'shared/graph-legend';
import { useEolMapping } from 'shared/use-eol-mapping';
import { WaffleGraph } from 'shared/waffle-graph';
import { Group } from '@mantine/core';
import { toPercentage } from 'utils/to-percentage';

export const CircularityBreakdown = ({
    data
}: {
    data: {
        product: { [key: string]: number };
        packaging: { [key: string]: number };
    };
}) => {
    const eolMapping = useEolMapping();

    const products = Object.keys(data.product).map((type: any) => {
        // @ts-ignore
        const match = eolMapping[type];
        return {
            title: match.title,
            color: match.color,
            value: data.product[type]
        };
    });

    const packaging = Object.keys(data.packaging).map((type: any) => {
        // @ts-ignore
        const match = eolMapping[type];
        return {
            title: match.title,
            color: match.color,
            value: data.packaging[type]
        };
    });

    return (
        <>
            {products.length > 0 && (
                <Group
                    mt="md"
                    style={{
                        justifyContent: 'space-evenly'
                    }}
                    gap="sm"
                >
                    <WaffleGraph data={products} m={0} cellSize="18px" />
                    <GraphLegend minWidth={310} items={products.map((current) => ({ ...current, value: toPercentage(current.value) }))} />
                </Group>
            )}

            {packaging.length > 0 && (
                <Group
                    my="md"
                    style={{
                        justifyContent: 'space-evenly'
                    }}
                    gap="sm"
                >
                    <WaffleGraph data={packaging} m={0} cellSize="18px" />
                    <GraphLegend minWidth={310} items={packaging.map((current) => ({ ...current, value: toPercentage(current.value) }))} />
                </Group>
            )}
        </>
    );
};
