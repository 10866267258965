import { DownloadButton } from 'shared/download-button';
import { ErrorAlert } from 'shared/error-alert';
import { JsonView } from 'shared/json-view';
import { StaticTable } from 'shared/static-table';
import { Table } from 'shared/table';
import { Nullable } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Modal, Skeleton, Tabs, rem, useMantineColorScheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { queryResultToTable } from 'utils/query-result-to-table';
import { RunQueryConfig } from 'utils/run-query';
import classes from './query-result-modal.module.css';
import { useQueryResult } from './use-query-result';

export const QueryResultModal = ({
    title,
    name = 'result',
    isOpen,
    onClose,
    query,
    initialTab = 'preview'
}: {
    initialTab?: string;
    title?: string;
    name?: string;
    isOpen: boolean;
    onClose: () => void;
    query?: RunQueryConfig;
}) => {
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');
    const [activeTab, setActiveTab] = useState<Nullable<string>>(initialTab);
    const { t } = useTranslation();
    const { colorScheme } = useMantineColorScheme();

    const { data, isLoading, error, limit, onLimitChange, page, onPage } = useQueryResult(query);
    const { columns: schemaColumns, data: schemaData } = queryResultToTable(data?.results_schema);
    const { columns: resultColumns, data: resultData } = queryResultToTable(data?.results);

    const tabs = ['preview', 'schema', 'raw'];

    if (!query) return null;

    return (
        <Modal opened={isOpen} fullScreen={isSmallDevice} size="100%" title={title ?? t('queryResults')} onClose={onClose}>
            {error && !isLoading ? (
                <ErrorAlert error={error} />
            ) : (
                <Tabs value={activeTab} onChange={setActiveTab}>
                    <Tabs.List mb="lg">
                        {tabs.map((tab) => (
                            <Tabs.Tab disabled={isLoading} key={tab} value={tab} className={classes.tab}>
                                {tab.toUpperCase()}
                            </Tabs.Tab>
                        ))}
                    </Tabs.List>

                    <Tabs.Panel value="preview">
                        {isLoading ? (
                            <Skeleton height="50vh" width="100%" />
                        ) : (
                            <Table
                                isLoading={isLoading}
                                actions={
                                    <DownloadButton
                                        disabled={isLoading}
                                        exports={[
                                            {
                                                name,
                                                data: data?.results
                                            }
                                        ]}
                                    />
                                }
                                columns={resultColumns}
                                data={resultData as any}
                                maxHeight={isSmallDevice ? '70vh' : '60vh'}
                                limit={limit}
                                onLimitChange={onLimitChange}
                                page={page}
                                onPage={onPage}
                            />
                        )}
                    </Tabs.Panel>
                    <Tabs.Panel value="schema">
                        <Box
                            style={{
                                height: '65vh',
                                overflow: 'auto'
                            }}
                        >
                            {activeTab === 'schema' && (
                                <StaticTable
                                    actions={
                                        <DownloadButton
                                            disabled={isLoading}
                                            exports={[
                                                {
                                                    name: `${name}_schema`,
                                                    data: schemaData
                                                }
                                            ]}
                                        />
                                    }
                                    isLoading={isLoading}
                                    columns={schemaColumns}
                                    data={schemaData as any}
                                />
                            )}
                        </Box>
                    </Tabs.Panel>
                    <Tabs.Panel value="raw">
                        {activeTab === 'raw' && (
                            <Box
                                style={{
                                    height: '70vh',
                                    overflow: 'auto',
                                    borderRadius: rem(8),
                                    border: colorScheme === 'dark' ? '0.0625rem solid #373A40' : '0.0625rem solid #ced4da',
                                    backgroundColor: 'transparent'
                                }}
                            >
                                {isLoading ? <Skeleton height="100%" width="100%" /> : <JsonView json={data?.results} />}
                            </Box>
                        )}
                    </Tabs.Panel>
                </Tabs>
            )}
        </Modal>
    );
};
