import useSWR from 'swr';
import { BarChartWidget as BarChartWidgetType, QueryReturnResult } from 'types';
import { useMemo } from 'react';
import { day } from 'lib/day';
import { RunQueryConfig, runQuery } from 'utils/run-query';
import { BarChartCard } from './bar-chart-card';
import { RangeSelect } from './range-select';
import { useWidgetQuery } from './use-widget-query';

const formatType = (value: any, type: 'number' | 'string' | 'date') => {
    if (type === 'date') return day(value).toDate().toLocaleDateString();
    return value;
};

export const BarChartWidget = ({ config, range }: { config: BarChartWidgetType; range: string }) => {
    const query = useWidgetQuery(config, range);

    const result = useSWR<QueryReturnResult<any>>(config ? [config.query_name, query] : null, async ([_, query]) =>
        runQuery(query as RunQueryConfig)
    );

    const data = useMemo(() => {
        if (!result.data) return [];
        return result.data?.results.map((current) => {
            return {
                label: formatType(current[config.x_axis.column_name], config.x_axis.type),
                value: formatType(current[config.y_axis.column_name], config.y_axis.type)
            };
        });
    }, [config, result]);

    return (
        <BarChartCard
            tooltipLabel={config.tooltip_label}
            title={config.name}
            description={config.description}
            filters={config.selects?.map((filter) => {
                if (filter.type === 'interval') return <RangeSelect key={filter.column_name} />;
                return null;
            })}
            isLoading={result.isLoading}
            data={data}
            error={result.error}
        />
    );
};
