import { useTranslation } from 'react-i18next';
import { TbDownload } from 'react-icons/tb';
import { Button, Menu, Tooltip } from '@mantine/core';
import { exportAsCsv } from 'utils/export-as-csv';
import { exportAsExcel } from 'utils/export-as-excel';
import { exportAsJson } from 'utils/export-as-json';
import { exportAsJsonl } from 'utils/export-as-jsonl';

export const DownloadButton = ({ exports, disabled }: { exports: { name: string; data: any }[]; disabled?: boolean }) => {
    const { t } = useTranslation();
    const handleExport = (type: 'json' | 'csv' | 'excel' | 'jsonl') => () => {
        exports.forEach(({ name, data }) => {
            const nameWithTimestamp = `${name}_${Date.now()}`;
            if (type === 'json') exportAsJson(data, nameWithTimestamp);
            if (type === 'csv') exportAsCsv(data, nameWithTimestamp);
            if (type === 'excel') exportAsExcel(data, nameWithTimestamp);
            if (type === 'jsonl') exportAsJsonl(data, nameWithTimestamp);
        });
    };

    return (
        <Menu>
            <Menu.Target>
                <Tooltip label={t('exportDescription')}>
                    <Button disabled={disabled} leftSection={<TbDownload size={20} />}>
                        {t('export')}
                    </Button>
                </Tooltip>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item onClick={handleExport('json')}>{t('asJsonFile')}</Menu.Item>
                <Menu.Item onClick={handleExport('csv')}>{t('asCsvFile')}</Menu.Item>
                <Menu.Item onClick={handleExport('excel')}>{t('asExcelFile')}</Menu.Item>
                <Menu.Item onClick={handleExport('jsonl')}>{t('asJsonlFile')}</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    );
};
