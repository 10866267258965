import { DataRow } from './create-empty-row';
import { MAX_COLUMNS } from './data-row-product-schema-conversion';
import { Counts } from './session-state';

/**
 * If the count for a row has changed we need to reset any values out of the new range
 */
export const resetRowsForCounts = (
    row: any,
    {
        materialCount,
        ecolabelCount,
        transportCount,
        energySourceCount,
        primaryPackagingMaterialsCount,
        secondaryPackagingMaterialsCount,
        tertiaryPackagingMaterialsCount
    }: Counts
): DataRow => {
    for (let i = 0; i < MAX_COLUMNS; i++) {
        // Ecolabels
        const title = row[`eco_label_${i}_title` as keyof DataRow] ?? '';
        const type = row[`eco_label_${i}_type` as keyof DataRow] ?? '';
        const isInRangeEcolabel = i < ecolabelCount;
        row[`eco_label_${i}_title` as keyof DataRow] = isInRangeEcolabel ? title : '';
        row[`eco_label_${i}_type` as keyof DataRow] = isInRangeEcolabel ? type : '';

        // Transports
        const originCountry = row[`transports_${i}_origin_country` as keyof DataRow] ?? null;
        const originCity = row[`transports_${i}_origin_city` as keyof DataRow] ?? null;
        const destinationCountry = row[`transports_${i}_destination_country` as keyof DataRow] ?? null;
        const destinationCity = row[`transports_${i}_destination_city` as keyof DataRow] ?? null;
        const mode = row[`transports_${i}_transport_mode` as keyof DataRow] ?? null;
        const medium = row[`transports_${i}_transport_medium` as keyof DataRow] ?? null;
        const isInRangeTransport = i < transportCount;
        row[`transports_${i}_origin_country` as keyof DataRow] = isInRangeTransport ? originCountry : null;
        row[`transports_${i}_origin_city` as keyof DataRow] = isInRangeTransport ? originCity : null;
        row[`transports_${i}_destination_country` as keyof DataRow] = isInRangeTransport ? destinationCountry : null;
        row[`transports_${i}_destination_city` as keyof DataRow] = isInRangeTransport ? destinationCity : null;
        row[`transports_${i}_transport_mode` as keyof DataRow] = isInRangeTransport ? mode : null;
        row[`transports_${i}_transport_medium` as keyof DataRow] = isInRangeTransport ? medium : null;

        // Energy sources
        const energyType = row[`energy_sources_${i}_energy_type` as keyof DataRow] ?? null;
        const usageType = row[`energy_sources_${i}_usage_type` as keyof DataRow] ?? null;
        const value = row[`energy_sources_${i}_energy_use_value` as keyof DataRow] ?? null;
        const unit = row[`energy_sources_${i}_energy_use_unit` as keyof DataRow] ?? null;
        const isInRangeEnergySource = i < energySourceCount;
        row[`energy_sources_${i}_energy_type` as keyof DataRow] = isInRangeEnergySource ? energyType : null;
        row[`energy_sources_${i}_usage_type` as keyof DataRow] = isInRangeEnergySource ? usageType : null;
        row[`energy_sources_${i}_energy_use_value` as keyof DataRow] = isInRangeEnergySource ? value : null;
        row[`energy_sources_${i}_energy_use_unit` as keyof DataRow] = isInRangeEnergySource ? unit : null;

        // Materials
        const materialName = row[`materials_${i}_material_name` as keyof DataRow] ?? '';
        const percentage = row[`materials_${i}_percentage` as keyof DataRow] ?? 0;
        const recycled = row[`materials_${i}_recycled` as keyof DataRow] ?? null;
        const reused = row[`materials_${i}_reused` as keyof DataRow] ?? null;
        const organic = row[`materials_${i}_organic` as keyof DataRow] ?? null;
        const sourceCountry = row[`materials_${i}_source_country` as keyof DataRow] ?? null;
        const isInRangeMaterial = i < materialCount;
        row[`materials_${i}_material_name` as keyof DataRow] = isInRangeMaterial ? materialName : '';
        row[`materials_${i}_percentage` as keyof DataRow] = isInRangeMaterial ? percentage : 0;
        row[`materials_${i}_recycled` as keyof DataRow] = isInRangeMaterial ? recycled : null;
        row[`materials_${i}_reused` as keyof DataRow] = isInRangeMaterial ? reused : null;
        row[`materials_${i}_organic` as keyof DataRow] = isInRangeMaterial ? organic : null;
        row[`materials_${i}_source_country` as keyof DataRow] = isInRangeMaterial ? sourceCountry : null;

        // Primary packaging materials
        const primaryMaterialName = row[`materials_primary_${i}_material_name` as keyof DataRow] ?? '';
        const primaryPercentage = row[`materials_primary_${i}_percentage` as keyof DataRow] ?? 0;
        const primaryRecycled = row[`materials_primary_${i}_recycled` as keyof DataRow] ?? null;
        const primaryReused = row[`materials_primary_${i}_reused` as keyof DataRow] ?? null;
        const primaryOrganic = row[`materials_primary_${i}_organic` as keyof DataRow] ?? null;
        const primarySourceCountry = row[`materials_primary_${i}_source_country` as keyof DataRow] ?? null;
        const isInRangePrimary = i < primaryPackagingMaterialsCount;
        row[`materials_primary_${i}_material_name` as keyof DataRow] = isInRangePrimary ? primaryMaterialName : '';
        row[`materials_primary_${i}_percentage` as keyof DataRow] = isInRangePrimary ? primaryPercentage : 0;
        row[`materials_primary_${i}_recycled` as keyof DataRow] = isInRangePrimary ? primaryRecycled : null;
        row[`materials_primary_${i}_reused` as keyof DataRow] = isInRangePrimary ? primaryReused : null;
        row[`materials_primary_${i}_organic` as keyof DataRow] = isInRangePrimary ? primaryOrganic : null;
        row[`materials_primary_${i}_source_country` as keyof DataRow] = isInRangePrimary ? primarySourceCountry : null;

        // Secondary packaging materials
        const secondaryMaterialName = row[`materials_secondary_${i}_material_name` as keyof DataRow] ?? '';
        const secondaryPercentage = row[`materials_secondary_${i}_percentage` as keyof DataRow] ?? 0;
        const secondaryRecycled = row[`materials_secondary_${i}_recycled` as keyof DataRow] ?? null;
        const secondaryReused = row[`materials_secondary_${i}_reused` as keyof DataRow] ?? null;
        const secondaryOrganic = row[`materials_secondary_${i}_organic` as keyof DataRow] ?? null;
        const secondarySourceCountry = row[`materials_secondary_${i}_source_country` as keyof DataRow] ?? null;
        const isInRangeSecondary = i < secondaryPackagingMaterialsCount;
        row[`materials_secondary_${i}_material_name` as keyof DataRow] = isInRangeSecondary ? secondaryMaterialName : '';
        row[`materials_secondary_${i}_percentage` as keyof DataRow] = isInRangeSecondary ? secondaryPercentage : 0;
        row[`materials_secondary_${i}_recycled` as keyof DataRow] = isInRangeSecondary ? secondaryRecycled : null;
        row[`materials_secondary_${i}_reused` as keyof DataRow] = isInRangeSecondary ? secondaryReused : null;
        row[`materials_secondary_${i}_organic` as keyof DataRow] = isInRangeSecondary ? secondaryOrganic : null;
        row[`materials_secondary_${i}_source_country` as keyof DataRow] = isInRangeSecondary ? secondarySourceCountry : null;

        // Tertiary packaging materials
        const tertiaryMaterialName = row[`materials_tertiary_${i}_material_name` as keyof DataRow] ?? '';
        const tertiaryPercentage = row[`materials_tertiary_${i}_percentage` as keyof DataRow] ?? 0;
        const tertiaryRecycled = row[`materials_tertiary_${i}_recycled` as keyof DataRow] ?? null;
        const tertiaryReused = row[`materials_tertiary_${i}_reused` as keyof DataRow] ?? null;
        const tertiaryOrganic = row[`materials_tertiary_${i}_organic` as keyof DataRow] ?? null;
        const tertiarySourceCountry = row[`materials_tertiary_${i}_source_country` as keyof DataRow] ?? null;
        const isInRangeTertiary = i < tertiaryPackagingMaterialsCount;
        row[`materials_tertiary_${i}_material_name` as keyof DataRow] = isInRangeTertiary ? tertiaryMaterialName : '';
        row[`materials_tertiary_${i}_percentage` as keyof DataRow] = isInRangeTertiary ? tertiaryPercentage : 0;
        row[`materials_tertiary_${i}_recycled` as keyof DataRow] = isInRangeTertiary ? tertiaryRecycled : null;
        row[`materials_tertiary_${i}_reused` as keyof DataRow] = isInRangeTertiary ? tertiaryReused : null;
        row[`materials_tertiary_${i}_organic` as keyof DataRow] = isInRangeTertiary ? tertiaryOrganic : null;
        row[`materials_tertiary_${i}_source_country` as keyof DataRow] = isInRangeTertiary ? tertiarySourceCountry : null;
    }
    return row;
};
