import { DataRow } from './create-empty-row';
import { MAX_COLUMNS } from './data-row-product-schema-conversion';

export const isValue = (val: any) => {
    if (val === '') return false;
    return typeof val === 'number' || typeof val === 'string' || val === null;
};

const isValidPercentage = (val: number) => {
    return val >= 99 && val <= 101;
};

const isInvalidWeight = ({ value, unit }: { value: string; unit: string }) => {
    const val = Number.parseFloat(value);
    return val > 0 && !unit;
};

export const isValidRow = (
    row: DataRow
): {
    errors: string[];
    invalidFields: string[];
} => {
    const errors: string[] = [];

    const invalidFields: string[] = row.invalid_fields ? [...row.invalid_fields] : [];

    if (!row.sku) {
        errors.push('No SKU provided');
        invalidFields.push('sku');
    }

    if (!row.name) {
        errors.push('No product name provided');
        invalidFields.push('name');
    }

    if (!row.country_of_production) {
        errors.push('No country of production provided');
        invalidFields.push('country_of_production');
    }

    if (!row.product_weight_unit || !row.product_weight_value) {
        errors.push('No product weight or unit provided');
        invalidFields.push('product_weight_unit', 'product_weight_value');
    }

    let hasMaterialValue = false;
    for (let i = 0; i < MAX_COLUMNS; i++) {
        const name = row[`materials_${i}_material_name` as keyof DataRow];
        const percentage = row[`materials_${i}_percentage` as keyof DataRow];
        if (name && percentage) {
            hasMaterialValue = true;
            break;
        }
    }

    if (!hasMaterialValue) {
        errors.push('No materials provided');
        invalidFields.push('materials_0_material_name', 'materials_0_percentage');
    }

    if (!isValidPercentage(row.materials_total_percentage)) {
        errors.push("Product's materials do not add up to valid percentage");
    }

    let hasPrimaryMaterialValue = false;
    for (let i = 0; i < MAX_COLUMNS; i++) {
        const name = row[`materials_primary_${i}_material_name` as keyof DataRow];
        const percentage = row[`materials_primary_${i}_percentage` as keyof DataRow];
        if (name && percentage) {
            hasPrimaryMaterialValue = true;
            break;
        }
    }

    let hasSecondaryMaterialValue = false;
    for (let i = 0; i < MAX_COLUMNS; i++) {
        const name = row[`materials_secondary_${i}_material_name` as keyof DataRow];
        const percentage = row[`materials_secondary_${i}_percentage` as keyof DataRow];
        if (name && percentage) {
            hasSecondaryMaterialValue = true;
            break;
        }
    }

    let hasTertiaryMaterialValue = false;
    for (let i = 0; i < MAX_COLUMNS; i++) {
        const name = row[`materials_tertiary_${i}_material_name` as keyof DataRow];
        const percentage = row[`materials_tertiary_${i}_percentage` as keyof DataRow];
        if (name && percentage) {
            hasTertiaryMaterialValue = true;
            break;
        }
    }

    const hasPrimaryWeight = !!(
        row.material_category_type_properties_primary_weight_value && row.material_category_type_properties_primary_weight_unit
    );

    if (hasPrimaryWeight && !hasPrimaryMaterialValue) {
        errors.push('No primary packaging materials provided');
        invalidFields.push(`materials_primary_0_material_name`, `materials_primary_0_percentage`);
    }

    const hasSecondaryWeight = !!(
        row.material_category_type_properties_secondary_weight_value && row.material_category_type_properties_secondary_weight_unit
    );
    if (hasSecondaryWeight && !hasSecondaryMaterialValue) {
        errors.push('No secondary packaging materials provided');
        invalidFields.push(`materials_secondary_0_material_name`, `materials_secondary_0_percentage`);
    }

    const hasTertiaryWeight = !!(
        row.material_category_type_properties_tertiary_weight_value && row.material_category_type_properties_tertiary_weight_unit
    );
    if (hasTertiaryWeight && !hasTertiaryMaterialValue) {
        errors.push('No tertiary packaging materials provided');
        invalidFields.push(`materials_tertiary_0_material_name`, `materials_tertiary_0_percentage`);
    }

    if (hasPrimaryMaterialValue && !isValidPercentage(row.materials_primary_total_percentage)) {
        errors.push('Primary packaging materials do not add up to valid percentage"');
    }

    if (hasSecondaryMaterialValue && !isValidPercentage(row.materials_secondary_total_percentage)) {
        errors.push('Secondary packaging materials do not add up to valid percentage"');
    }

    if (hasTertiaryMaterialValue && !isValidPercentage(row.materials_tertiary_total_percentage)) {
        errors.push('Tertiary packaging materials do not add up to valid percentage"');
    }

    const isMissingPrimaryWeight = !hasPrimaryWeight && hasPrimaryMaterialValue;
    const isIncompletePrimaryWeightRow = isInvalidWeight({
        value: row.material_category_type_properties_primary_weight_value,
        unit: row.material_category_type_properties_primary_weight_unit
    });
    if (isIncompletePrimaryWeightRow || isMissingPrimaryWeight) {
        errors.push('No primary packaging weight or unit provided');
    }

    const isMissingSecondaryWeight = !hasSecondaryWeight && hasSecondaryMaterialValue;
    const isIncompleteSecondaryWeightRow = isInvalidWeight({
        value: row.material_category_type_properties_secondary_weight_value,
        unit: row.material_category_type_properties_secondary_weight_unit
    });
    if (isIncompleteSecondaryWeightRow || isMissingSecondaryWeight) {
        errors.push('No secondary packaging weight or unit provided');
    }

    const isMissingTertiaryWeight = !hasTertiaryWeight && hasTertiaryMaterialValue;
    const isIncompleteTertiaryWeightRow = isInvalidWeight({
        value: row.material_category_type_properties_tertiary_weight_value,
        unit: row.material_category_type_properties_tertiary_weight_unit
    });
    if (isIncompleteTertiaryWeightRow || isMissingTertiaryWeight) {
        errors.push('No tertiary packaging weight or unit provided');
    }

    for (let i = 0; i < MAX_COLUMNS; i++) {
        const title = row[`eco_label_${i}_title` as keyof DataRow];
        const type = row[`eco_label_${i}_type` as keyof DataRow];
        const isInvalid = (!!title && !type) || (!title && !!type);
        if (isInvalid) {
            invalidFields.push(`eco_label_${i}_title`, `eco_label_${i}_type`);
            errors.push('Incomplete Eco-labels provided');
            break;
        }
    }

    let validTransportLegs = 0;
    for (let i = 0; i < MAX_COLUMNS; i++) {
        const originCountry = row[`transports_${i}_origin_country` as keyof DataRow];
        const destinationCountry = row[`transports_${i}_destination_country` as keyof DataRow];
        const mode = row[`transports_${i}_transport_mode` as keyof DataRow];
        const medium = row[`transports_${i}_transport_medium` as keyof DataRow];
        const fields = [originCountry, destinationCountry, mode, medium];

        const hasFieldValue = fields.some((field) => isValue(field) && field !== null);
        const allFieldsCompleted = fields.every((field) => isValue(field) && field !== null);
        const isInvalid = hasFieldValue && !allFieldsCompleted;

        if (allFieldsCompleted) {
            validTransportLegs++;
        }

        if (isInvalid) {
            invalidFields.push(
                `transports_${i}_origin_country`,
                `transports_${i}_destination_country`,
                `transports_${i}_transport_mode`,
                `transports_${i}_transport_medium`
            );
            errors.push('Incomplete transport leg provided');
            break;
        }
    }

    if (validTransportLegs === 0) {
        errors.push('No transport legs provided');
        invalidFields.push(
            `transports_0_origin_country`,
            `transports_0_origin_city`,
            `transports_0_destination_country`,
            `transports_0_destination_city`,
            `transports_0_transport_mode`,
            `transports_0_transport_medium`
        );
    }

    if (row.water_consumption_source || row.water_consumption_unit || row.water_consumption_value) {
        const hasMissingValue = !row.water_consumption_source || !row.water_consumption_unit || !row.water_consumption_value;
        if (hasMissingValue) {
            errors.push('Incomplete water consumption details provided');
            invalidFields.push('water_consumption_source', 'water_consumption_unit', 'water_consumption_value');
        }
    }

    for (let i = 0; i < MAX_COLUMNS; i++) {
        const type = row[`energy_sources_${i}_energy_type` as keyof DataRow];
        const usageType = row[`energy_sources_${i}_usage_type` as keyof DataRow];
        const value = row[`energy_sources_${i}_energy_use_value` as keyof DataRow];
        const unit = row[`energy_sources_${i}_energy_use_unit` as keyof DataRow];
        const fields = [type, usageType, value, unit];
        const hasFieldValue = fields.some((field) => isValue(field));
        const allFieldsCompleted = fields.every((field) => isValue(field));
        const isInvalid = hasFieldValue && !allFieldsCompleted;

        if (isInvalid) {
            invalidFields.push(
                `energy_sources_${i}_energy_type`,
                `energy_sources_${i}_usage_type`,
                `energy_sources_${i}_energy_use_value`,
                `energy_sources_${i}_energy_use_unit`
            );
            errors.push('Incomplete energy source details provided');
            break;
        }
    }

    return {
        invalidFields,
        errors
    };
};
