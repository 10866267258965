import { Nullable } from 'types';
import { useTranslation } from 'react-i18next';
import { Badge, Group } from '@mantine/core';
import { formatHeader } from 'utils/format-header';

export const ProductBadges = ({
    product
}: {
    product?: {
        sku?: Nullable<string>;
        category?: Nullable<string>;
        parent_category?: Nullable<string>;
        brand?: Nullable<string>;
        production_sector?: Nullable<string>;
    };
}) => {
    const { t } = useTranslation();
    if (!product) return;
    return (
        <Group gap="xs" mb="md">
            {product?.sku && (
                <Badge variant="light">
                    {t('sku')}: {product.sku}
                </Badge>
            )}
            {product?.category && (
                <Badge variant="light">
                    {t('category')}: {formatHeader(product.category)}
                </Badge>
            )}
            {product?.parent_category && (
                <Badge variant="light">
                    {t('parentCategory')}: {formatHeader(product.parent_category)}
                </Badge>
            )}
            {product?.brand && (
                <Badge variant="light">
                    {t('brand')}: {product.brand}
                </Badge>
            )}
        </Group>
    );
};
