import { NAMESPACE_STORAGE_KEY, PARENT_NAMESPACE_KEY, useAuth } from 'account-management/auth/use-auth';
import { OrganizationModal } from 'account-management/organizations/organization-modal';
import { SwitchOrganizationModal } from 'account-management/organizations/switch-organization-modal';
import { paths } from 'paths';
import { useUser } from 'shared/use-user';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbBuilding, TbHandStop, TbKey, TbLogout, TbMoon, TbSun, TbSwitch, TbUser } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { UnstyledButton, Group, Avatar, Text, Box, Menu, useMantineColorScheme } from '@mantine/core';
import { FlagName } from 'utils/get-flag-value';
import { useFeatureEnabled } from './use-feature-enabled';
import { useOrganizationId } from './use-organization-id';
import classes from './user-menu.module.css';

export const UserMenu = () => {
    const navigate = useNavigate();
    const { isEnabled } = useFeatureEnabled();
    const organizationId = useOrganizationId();
    const { t } = useTranslation();
    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const { isMultifactorRequired } = useAuth();
    const { onLogout, activeUser, namespaces, hasPermission, organization } = useUser();
    const [showSwitchNamespaceModal, setShowSwitchNamespaceModal] = useState(false);
    const [showEditOrganizationModal, setShowEditOrganizationModal] = useState(false);

    const activeNamespace = window.sessionStorage.getItem(NAMESPACE_STORAGE_KEY);
    const parentNamespace = window.sessionStorage.getItem(PARENT_NAMESPACE_KEY);

    const isImpersonation = !!activeNamespace;
    const isPartnerOrganization = !!parentNamespace;
    const hasMultipleNamespaces = namespaces && namespaces.length > 1;

    const handleReset = () => {
        window.sessionStorage.clear();
        window.location.href = window.location.origin;
    };

    if (!activeUser) return null;

    return (
        <>
            <Box className={classes.container}>
                <Menu shadow="md" width={220}>
                    <Menu.Target>
                        <UnstyledButton data-testid="user-menu" className={classes.button}>
                            <Group gap="xs" wrap="nowrap">
                                <Avatar src={activeUser.photo_url} radius="xl" />
                                <Box>
                                    <Text size="sm" fw={500} lineClamp={1}>
                                        {activeUser.name || activeUser.email}
                                    </Text>

                                    {activeUser.name && (
                                        <Text c="dimmed" size="xs" lineClamp={1}>
                                            {activeUser.email}
                                        </Text>
                                    )}
                                </Box>
                            </Group>
                        </UnstyledButton>
                    </Menu.Target>

                    <Menu.Dropdown>
                        <Menu.Item p="xs" onClick={() => navigate(paths.userSettings(organizationId))} leftSection={<TbUser size={16} />}>
                            {t('userSettings')}
                        </Menu.Item>
                        {hasPermission('/account_manager/key_management/list') && !isMultifactorRequired && (
                            <Menu.Item p="xs" onClick={() => setShowEditOrganizationModal(true)} leftSection={<TbBuilding size={16} />}>
                                {t('manageOrganization')}
                            </Menu.Item>
                        )}
                        {hasPermission('/account_manager/organizations/query') && !isMultifactorRequired && (
                            <Menu.Item p="xs" onClick={() => navigate(paths.apiKeys(organizationId))} leftSection={<TbKey size={16} />}>
                                {t('manageApiKeys')}
                            </Menu.Item>
                        )}
                        {isEnabled(FlagName.PartnerOrganizations) && hasMultipleNamespaces && !isPartnerOrganization && (
                            <Menu.Item p="xs" onClick={() => setShowSwitchNamespaceModal(true)} leftSection={<TbSwitch size={16} />}>
                                {t('switchOrganization')}
                            </Menu.Item>
                        )}
                        {isImpersonation && (
                            <Menu.Item p="xs" onClick={handleReset} leftSection={<TbHandStop size={16} />}>
                                {t('stopImpersonating')}
                            </Menu.Item>
                        )}
                        {isPartnerOrganization && (
                            <Menu.Item p="xs" onClick={handleReset} leftSection={<TbHandStop size={16} />}>
                                {t('backToMainOrganization')}
                            </Menu.Item>
                        )}
                        <Menu.Item
                            p="xs"
                            onClick={() => toggleColorScheme()}
                            leftSection={colorScheme === 'dark' ? <TbSun size={16} /> : <TbMoon size={16} />}
                        >
                            {colorScheme === 'dark' ? t('switchToLightTheme') : t('switchToDarkTheme')}
                        </Menu.Item>
                        <Menu.Item p="xs" onClick={onLogout} leftSection={<TbLogout size={16} />}>
                            {t('logout')}
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </Box>

            {hasPermission('/account_manager/organizations/query') && (
                <OrganizationModal
                    id={organizationId}
                    isOpen={showEditOrganizationModal}
                    onClose={() => setShowEditOrganizationModal(false)}
                />
            )}

            {showSwitchNamespaceModal && (
                <SwitchOrganizationModal
                    isOpen={showSwitchNamespaceModal}
                    onClose={() => setShowSwitchNamespaceModal(false)}
                    namespaces={namespaces}
                    authDomain={organization?.auth_domain}
                />
            )}
        </>
    );
};
