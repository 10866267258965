export type SortableValueType = string | number | boolean | Date;

export const sortByKey = <T>(keyFn: (item: T) => SortableValueType): ((a: T, b: T) => number) => {
    return (a: T, b: T): number => {
        const aValue: SortableValueType = keyFn(a);
        const bValue: SortableValueType = keyFn(b);

        if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
            return Number(aValue) - Number(bValue);
        }

        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return aValue - bValue;
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
            return aValue.localeCompare(bValue);
        }

        if (aValue instanceof Date && bValue instanceof Date) {
            return aValue.getTime() - bValue.getTime();
        }

        const typeOrder = ['boolean', 'number', 'string', 'object'];
        return typeOrder.indexOf(typeof aValue) - typeOrder.indexOf(typeof bValue);
    };
};
