import { t } from 'i18next';
import { OrderDirection } from 'types/shared';
import { ReactNode, useMemo, useState } from 'react';
import { Box, BoxProps, Button, Group, Table as MantineTable, Skeleton } from '@mantine/core';
import { sortByKey } from 'utils/sort-by-key';
import { Search } from './search';
import { Column, TableHeader } from './table-header';
import { TableRows } from './table-rows';

const NUMBER_OF_PLACEHOLDER_ROWS = 5;

export const StaticTable = ({
    columns,
    data,
    isLoading = false,
    defaultSort = '',
    defaultSortDirection = OrderDirection.Descending,
    rowKey = 'key',
    actions,
    searchable = true,
    stickyHeader = false,
    showMoreLimit,
    ...rest
}: {
    searchable?: boolean;
    columns: (Column | false | undefined)[];
    rowKey?: string | ((row: any) => string);
    defaultSort?: string;
    defaultSortDirection?: OrderDirection;
    data?: any[];
    isLoading?: boolean;
    actions?: ReactNode;
    stickyHeader?: boolean;
    showMoreLimit?: number;
} & BoxProps) => {
    const [sortColumn, setSortColumn] = useState(defaultSort);
    const [sortDirection, setSortDirection] = useState<OrderDirection>(defaultSortDirection);
    const [search, setSearch] = useState('');

    const hasShowMore = showMoreLimit !== undefined;
    const [showAll, setShowAll] = useState(!hasShowMore);

    const filteredData = useMemo(() => {
        if (!data) return [];
        const filtered = data.filter((row) =>
            Object.values(row).some((value) => value?.toString().toLowerCase().includes(search.toLowerCase()))
        );
        if (sortColumn) {
            filtered.sort(sortByKey((row) => row[sortColumn]));
        }

        return sortDirection === OrderDirection.Descending ? filtered.toReversed() : filtered;
    }, [search, data, sortColumn, sortDirection]);

    const filteredColumns = columns.filter((column) => !!column) as Column[];

    const currentData = !hasShowMore || showAll ? filteredData : filteredData.slice(0, showMoreLimit);
    const showMoreButton = !isLoading && hasShowMore && filteredData.length > showMoreLimit;

    return (
        <Box {...rest}>
            {(searchable || actions) && (
                <Group justify="space-between" gap="sm" mb="md">
                    {searchable && <Search value={search} onChange={(value) => setSearch(value)} />}
                    {actions}
                </Group>
            )}

            <Box style={{ maxWidth: '100%', overflowX: 'auto' }}>
                <MantineTable>
                    <Box component={MantineTable.Thead}>
                        <MantineTable.Tr>
                            {filteredColumns.map((column) => (
                                <TableHeader
                                    isOrderSupported
                                    key={column.key}
                                    column={column}
                                    sortColumn={sortColumn}
                                    sortDirection={sortDirection}
                                    onSort={(newSortDirection) => {
                                        if (column.key === sortColumn) {
                                            setSortDirection(newSortDirection);
                                        } else {
                                            setSortColumn(column.key);
                                            setSortDirection(OrderDirection.Descending);
                                        }
                                    }}
                                />
                            ))}
                        </MantineTable.Tr>
                    </Box>

                    <tbody>
                        {isLoading ? (
                            Array.from({ length: NUMBER_OF_PLACEHOLDER_ROWS }).map((_, index) => {
                                return (
                                    <MantineTable.Tr data-testid={`loading-table-row`} key={index}>
                                        {filteredColumns.map((column) => {
                                            return (
                                                <MantineTable.Td key={column.key}>
                                                    <Skeleton width={72} height={18} />
                                                </MantineTable.Td>
                                            );
                                        })}
                                    </MantineTable.Tr>
                                );
                            })
                        ) : (
                            <TableRows
                                rowKey={rowKey}
                                data={currentData}
                                columns={filteredColumns}
                                search={search}
                                isSearchSupported={searchable}
                            />
                        )}
                    </tbody>
                </MantineTable>
                {showMoreButton && (
                    <Group mt="md" w="100%">
                        <Button onClick={() => setShowAll((current) => !current)} variant="outline">
                            {showAll ? t('showLess') : t('showAll')}
                        </Button>
                    </Group>
                )}
            </Box>
        </Box>
    );
};
