import { useNamespaces } from 'account-management/organizations/use-namespaces';
import { ErrorCard } from 'shared/error-card';
import { Section } from 'shared/section';
import { Table } from 'shared/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbDotsVertical } from 'react-icons/tb';
import { ActionIcon, Menu, Text, Title } from '@mantine/core';
import { CreateImpersonationModal } from './create-impersonate-modal';
import { ImpersonateModal } from './impersonate-modal';

const DAYRIZE_NAMESPACE_ID = 'dayrize_com';

export const Namespaces = () => {
    const { t } = useTranslation();
    const [showCreateImpersonationModal, setShowCreateImpersonationModal] = useState(false);
    const [showImpersonationModal, setShowImpersonationModal] = useState(false);
    const [activeNamespace, setActiveNamespace] = useState<string | null>(null);
    const { data, error, isLoading } = useNamespaces();

    if (error) {
        return <ErrorCard error={error} />;
    }

    return (
        <>
            <Title size="h3">{t('namespaces')}</Title>
            <Text c="dimmed" mb="md">
                {t('namespacesDescription')}
            </Text>

            <Section>
                <Table
                    isLoading={isLoading}
                    data={data}
                    rowKey={(row) => row.namespace_id}
                    columns={[
                        {
                            key: 'namespace_id',
                            name: t('namespace'),
                            sortable: true
                        },
                        {
                            key: 'region',
                            name: t('region'),
                            sortable: true,
                            width: 240
                        },
                        {
                            key: 'actions',
                            name: '',
                            align: 'right',
                            render: (_, row) => {
                                if (row.namespace_id === DAYRIZE_NAMESPACE_ID) return null;
                                return (
                                    <Menu shadow="md">
                                        <Menu.Target>
                                            <ActionIcon data-testid={`menu-${row.namespace_id}`}>
                                                <TbDotsVertical size={16} />
                                            </ActionIcon>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            <Menu.Item
                                                onClick={() => {
                                                    setActiveNamespace(row.namespace_id);
                                                    setShowCreateImpersonationModal(true);
                                                }}
                                            >
                                                {t('createImpersonation')}
                                            </Menu.Item>
                                            <Menu.Item
                                                onClick={() => {
                                                    setActiveNamespace(row.namespace_id);
                                                    setShowImpersonationModal(true);
                                                }}
                                            >
                                                Impersonate a user
                                            </Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                );
                            }
                        }
                    ]}
                />
            </Section>
            <CreateImpersonationModal
                isOpen={showCreateImpersonationModal}
                namespace={activeNamespace}
                onClose={() => {
                    setShowCreateImpersonationModal(false);
                    setActiveNamespace(null);
                }}
            />
            <ImpersonateModal
                isOpen={showImpersonationModal}
                namespace={activeNamespace}
                onClose={() => {
                    setShowImpersonationModal(false);
                    setActiveNamespace(null);
                }}
            />
        </>
    );
};
