import { Filter } from "types";
import { useInsightQuery } from "./use-insight-query";


const ACTIONABLE_INSIGHTS_QUERY_NAME = 'actionable_insights';

export const useActionableInsightsReport = (params?: Filter[])  => {
  

    const actionableInsightsResult = useInsightQuery<{
        material_a_beautified_name_with_characteristic : string;
        material_b_beautified_name_with_characteristic : string;
        complexity: number;
        original_absolute_gwp: number;
        swap_absolute_gwp:number;
        tradeoff: number;
    }>(ACTIONABLE_INSIGHTS_QUERY_NAME);

   
    
    return {actionableInsightsResult, params }
};
