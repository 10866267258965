import { Nullable } from 'types';
import { Dimension } from 'types/shared';
import { useScoreReportQuery } from './use-score-report-query';

const QUERY_NAME = 'score_report_summary_product';

export type SummaryResult = {
    sku: Nullable<string>;
    brand: Nullable<string>;
    product_name: Nullable<string>;
    category_average: Nullable<number>;
    score: Nullable<number>;
    dimension: Dimension;
};

export const useSummary = (productId?: string) => {
    return useScoreReportQuery<SummaryResult>(QUERY_NAME, productId);
};
