import { useAuth } from 'account-management/auth/use-auth';
import { User, multiFactor } from 'firebase/auth';
import { ConfirmationModal } from 'shared/confirmation-modal';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbAlertCircle, TbCircleCheck } from 'react-icons/tb';
import { Avatar, Box, Group, SimpleGrid, Stack, Text, Paper, Tooltip, rem, Button, Alert } from '@mantine/core';
import { auth } from 'lib/firebase';
import { showSuccessMessage } from 'utils/show-success-message';
import { EnableMfaModal } from './enable-mfa-modal';
import { unenrollMultifactor } from './unenroll-multifactor';

const getProviderName = (providerId: string) => {
    const providerIdToNameMap = {
        'google.com': 'Google',
        'microsoft.com': 'Microsoft',
        password: 'Passwordless email'
    };
    return providerIdToNameMap[providerId as keyof typeof providerIdToNameMap] || providerId;
};

export const UserSettings = ({ currentUser = auth.currentUser }: { currentUser?: User | null }) => {
    const { t } = useTranslation();
    const { isMultifactorRequired } = useAuth();

    const [showDisableMfa, setShowDisableMfa] = useState(false);
    const [showEnableMfa, setShowEnableMfa] = useState(false);

    const readOnlyFields = useMemo(() => {
        if (!currentUser) return [];
        const fields: {
            label: string;
            value: string;
        }[] = [];
        if (currentUser.metadata.creationTime) {
            fields.push({ label: t('createdAt'), value: new Date(currentUser.metadata.creationTime).toLocaleString() });
        }
        if (currentUser.metadata.lastSignInTime) {
            fields.push({ label: t('lastSignIn'), value: new Date(currentUser.metadata.lastSignInTime).toLocaleString() });
        }
        if (currentUser.providerData.length > 0) {
            const value = currentUser.providerData.map((provider) => getProviderName(provider.providerId)).join(', ');
            fields.push({ label: t('authenticationProvider'), value });
        }
        return fields;
    }, [t, currentUser]);

    if (!currentUser)
        return (
            <Alert icon={<TbAlertCircle size={20} />} title={t('noUserFound')} color="red">
                {t('noUserFoundDescription')}
            </Alert>
        );

    const isMFAEnabled = multiFactor(currentUser).enrolledFactors.length > 0;

    return (
        <>
            <Stack mb="lg" align="center" justify="center">
                <Avatar src={currentUser.photoURL} radius="100%" size={rem(120)} />
                <Box>
                    {currentUser.displayName && (
                        <Text ta="center" size="xl" fw={600}>
                            {currentUser.displayName}
                        </Text>
                    )}
                    <Tooltip label={currentUser.emailVerified ? t('emailHasBeenVerified') : null}>
                        <Group align="center" gap={rem(2)}>
                            <Text size="lg" c="dimmed">
                                {currentUser.email}
                            </Text>
                            {currentUser.emailVerified ? <Box color="green" component={TbCircleCheck} /> : null}
                        </Group>
                    </Tooltip>
                </Box>
            </Stack>
            {isMultifactorRequired && (
                <Alert
                    maw={800}
                    mx="auto"
                    mb="lg"
                    icon={<TbAlertCircle size={20} />}
                    title={t('multiFactorAuthenticationDisabled')}
                    color="red"
                >
                    {t('multiFactorAuthenticationDisabledDescription')}
                </Alert>
            )}

            <SimpleGrid mb="lg" cols={{ base: 1, sm: 2 }} maw={800} mx="auto" spacing="xs" verticalSpacing="xs">
                {readOnlyFields.map((field) => (
                    <Paper p="md" key={field.label}>
                        <Text size="xs" c="dimmed">
                            {field.label}
                        </Text>
                        <Text size="md">{field.value}</Text>
                    </Paper>
                ))}

                <Paper p="md">
                    <Group justify="space-between" align="center">
                        <Box>
                            <Text size="xs" c="dimmed">
                                {t('multiFactorAuthentication')}
                            </Text>
                            <Text size="md">{isMFAEnabled ? t('enabled') : t('disabled')}</Text>
                        </Box>
                        {isMFAEnabled ? (
                            <Button variant="default" size="xs" color="red" onClick={() => setShowDisableMfa(true)}>
                                {t('disable')}
                            </Button>
                        ) : (
                            <Button variant="default" size="xs" onClick={() => setShowEnableMfa(true)}>
                                {t('enable')}
                            </Button>
                        )}
                    </Group>
                </Paper>
            </SimpleGrid>

            {showEnableMfa && <EnableMfaModal isOpen={showEnableMfa} onClose={() => setShowEnableMfa(false)} />}
            <ConfirmationModal
                variant="delete"
                title={t('confirmDeleteTitle')}
                isOpen={showDisableMfa}
                onClose={() => {
                    setShowDisableMfa(false);
                }}
                onConfirm={async () => {
                    const isSuccess = await unenrollMultifactor();
                    if (isSuccess) {
                        showSuccessMessage(t('disableMultiFactorAuthenticationSuccess'));
                        setShowDisableMfa(false);
                    }
                }}
            >
                {t('disableMultiFactorAuthenticationDescription')}
            </ConfirmationModal>
        </>
    );
};
