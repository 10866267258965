export enum Dimension {
    Total = 'total',
    Purpose = 'purpose',
    Circularity = 'circularity',
    Climate = 'climate',
    Ecosystem = 'ecosystem',
    EcosystemBiodiversity = 'ecosystem.biodiversity',
    EcosystemWater = 'ecosystem.water',
    Livelihood = 'livelihood_and_wellbeing',
    CarbonEmissions = 'carbon_emissions',
    WaterDepletion = 'water_depletion',
    LandUse = 'land_use', 
    DataQualityRating = 'dqr'
}

export enum OrderDirection {
    Descending = 'dsc',
    Ascending = 'asc'
}
