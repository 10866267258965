import { useClimateImpactsOfMaterialsInsightReport } from 'insights-explorer/use-climate-impacts-of-materials-insight-report';
import { DownloadButton } from 'shared/download-button';
import { ErrorAlert } from 'shared/error-alert';
import { ProductFilters } from 'shared/product-filters';
import { Section } from 'shared/section';
import { StaticTable } from 'shared/static-table';
import { useProductFilters } from 'shared/use-product-filters';
import { useTranslation } from 'react-i18next';
import { Box, Group, Text, Title, rem } from '@mantine/core';
import { displayRoundedValue } from 'utils/display-rounded-value';
import { getTransportationTypeColumns } from 'utils/get-transportation-type-columns';
import { renderCountryCell } from './render-country-cell';

export const ClimateImpactsOfMaterials = () => {
    const { t } = useTranslation();
    const { filters, ...rest } = useProductFilters();
    const { materialsEmissionsResult, transportEmissionsResult } = useClimateImpactsOfMaterialsInsightReport(filters);

    return (
        <>
            <Group justify="space-between" align="center" mb="md">
                <Box>
                    <Title size="h3">{t('climateImpactsOfMaterials')}</Title>
                    <Text size="sm" c="dimmed">
                        {t('climateImpactsOfMaterialsDescription')}
                    </Text>
                </Box>
                <ProductFilters enabledFilters={['brand', 'category', 'tags']} {...rest}>
                    {materialsEmissionsResult.data?.results && transportEmissionsResult.data?.results && (
                        <DownloadButton
                            exports={[
                                {
                                    name: 'materials_with_the_highest_co2eq_emissions',
                                    data: materialsEmissionsResult.data.results
                                },
                                {
                                    name: 'absolute_co2eq_emissions_per_transportation_type',
                                    data: transportEmissionsResult.data.results
                                }
                            ]}
                        />
                    )}
                </ProductFilters>
            </Group>

            <Section mb="md">
                <Title size="h4">{t('materialsWithTheHighestCO2eqEmissions')}</Title>

                {materialsEmissionsResult.error ? (
                    <ErrorAlert error={materialsEmissionsResult.error} />
                ) : (
                    <StaticTable
                        showMoreLimit={10}
                        isLoading={materialsEmissionsResult.isLoading}
                        m={`${rem(32)} 0`}
                        columns={[
                            {
                                key: 'beautified_name',
                                name: t('productMaterials'),
                                sortable: true
                            },
                            {
                                key: 'region',
                                name: t('materialSourceCountry'),
                                sortable: true,
                                render: renderCountryCell
                            },
                            {
                                key: 'gwp_per_kg',
                                name: t('perKg'),
                                sortable: true,
                                render: displayRoundedValue,
                                width: 180
                            },
                            {
                                key: 'gwp_absolute',
                                name: t('absolute'),
                                sortable: true,
                                render: displayRoundedValue,
                                width: 180
                            }
                        ]}
                        rowKey={(row) => `${row.beautified_name}-${row.gwp_per_kg}-${row.gwp_absolute}`}
                        data={materialsEmissionsResult.data?.results ?? []}
                    />
                )}

                <Text c="dimmed" size="sm">
                    {t('materialsWithTheHighestCO2eqEmissionsDescription')}
                </Text>
            </Section>

            <Section mb="md">
                <Title size="h4">{t('absoluteCO2eqEmissionsPerTransportationType')}</Title>

                {transportEmissionsResult.error ? (
                    <ErrorAlert error={transportEmissionsResult.error} />
                ) : (
                    <StaticTable
                        showMoreLimit={10}
                        isLoading={transportEmissionsResult.isLoading}
                        m={`${rem(32)} 0`}
                        columns={getTransportationTypeColumns()}
                        rowKey={(row) => `${row.transport_mode}-${row.distance_km}`}
                        data={transportEmissionsResult.data?.results ?? []}
                    />
                )}

                <Text c="dimmed" size="sm">
                    {t('absoluteCO2eqEmissionsPerTransportationTypeDescription')}
                </Text>
            </Section>
        </>
    );
};
