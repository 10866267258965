import { NAMESPACE_STORAGE_KEY, useAuth } from 'account-management/auth/use-auth';
import { paths } from 'paths';
import { getOrganizationRedirectPath } from 'shared/get-organization-redirect-path';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { report } from 'utils/report-error';

export const useOrganizationRedirect = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { userAuthDomain, isInitializing } = useAuth();
    const activeNamespace = searchParams.get('as') ?? sessionStorage.getItem(NAMESPACE_STORAGE_KEY);
    const target = activeNamespace ?? userAuthDomain;

    useEffect(() => {
        if (isInitializing || !target) return;

        if (activeNamespace) {
            sessionStorage.setItem(NAMESPACE_STORAGE_KEY, activeNamespace);
        }

        getOrganizationRedirectPath({
            namespaceId: activeNamespace,
            authDomain: userAuthDomain
        })
            .then((path) => {
                navigate(path);
            })
            .catch((error) => {
                report(error);
                navigate(paths.error());
            });
    }, [navigate, userAuthDomain, activeNamespace, isInitializing, target]);
};
