import { TbInfoCircle } from 'react-icons/tb';
import { ActionIcon, HoverCard, HoverCardProps, rem } from '@mantine/core';

export const MoreInfoHoverCard = ({ children, ...rest }: HoverCardProps) => {
    return (
        <HoverCard shadow="md" {...rest}>
            <HoverCard.Target>
                <ActionIcon>
                    <TbInfoCircle size={16} />
                </ActionIcon>
            </HoverCard.Target>
            <HoverCard.Dropdown p="sm" maw={rem(280)}>
                {children}
            </HoverCard.Dropdown>
        </HoverCard>
    );
};
