import useSWR from 'swr';
import { Nullable, QueryReturnResult } from 'types';
import { RunQueryConfig, runQuery } from 'utils/run-query';

export interface Material {
    id: string;
    version: string;
    material_ref: string;
    material_ref_beautified: string;
    beautified_name: string;
    subcategory: string;
    alpha3: Nullable<string>;
    region: string;
    recycled: false;
    organic: false;
    biobased: true;
    gwp: {
        value: number;
        unit: string;
    };
    wdp: {
        value: number;
        unit: string;
    };
    alop: {
        value: number;
        unit: string;
    };
    ced: number;
    end_of_life: string;
    synonyms: Array<string>;
    unit: string;
    isic_code: string;
    isic_section_code: string;
    isic_revision: string;
    common_packaging_material: boolean;
    CAS_number: string;
}

export const useMaterials = (materialIds?: Array<string>) => {
    const query: RunQueryConfig = {
        name: 'materials',
        params: [
            {
                name: 'id',
                value: materialIds ?? [],
                operation: '|'
            }
        ]
    };
    return useSWR<QueryReturnResult<Material>>(materialIds && materialIds.length > 0 ? [query.name, query] : null, async ([_, query]) =>
        runQuery(query as RunQueryConfig)
    );
};
