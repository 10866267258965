import { paths } from 'paths';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbArrowLeft } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { Button, Group, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showErrorMessage } from 'utils/show-error-message';
import { showSuccessMessage } from 'utils/show-success-message';
import { validation } from 'utils/validation';
import { AuthLayout } from './auth-layout';
import { SIGN_IN_EMAIL_STORAGE_KEY, useAuth } from './use-auth';

export const EmailSignIn = ({ isCompleted = false }: { isCompleted?: boolean }) => {
    const [isComplete, setIsComplete] = useState(isCompleted);
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { sendSignInEmailLink } = useAuth();

    const params = new URLSearchParams(window.location.search);

    const form = useForm({
        initialValues: {
            email: window.localStorage.getItem(SIGN_IN_EMAIL_STORAGE_KEY) ?? params.get('email') ?? ''
        },
        validate: {
            email: validation.requiredEmail
        }
    });

    const handleSubmit = form.onSubmit(async (values) => {
        try {
            setIsSubmitting(true);
            await sendSignInEmailLink(values.email);
            showSuccessMessage(t('sendSignInLinkSuccess'));
            setIsComplete(true);
        } catch (error) {
            showErrorMessage(error);
        } finally {
            setIsSubmitting(false);
        }
    });

    return (
        <AuthLayout>
            <Text c="blue" fw={700} size="xl" ta="center" mb="md">
                {t('signInEmail')}
            </Text>
            <Text fw={500} size="sm" mb="md" ta="center" mx="auto">
                {t('signInEmailDescription')}
            </Text>
            <form onSubmit={handleSubmit}>
                <Stack gap="xs">
                    <TextInput
                        disabled={isComplete}
                        data-testid="email-input"
                        type="email"
                        withAsterisk
                        label={t('email')}
                        {...form.getInputProps('email')}
                    />
                </Stack>
                <Group mt="lg" justify="space-between">
                    <Button variant="transparent" component={Link} to={paths.signIn()} leftSection={<TbArrowLeft />}>
                        {t('back')}
                    </Button>
                    <Button disabled={isComplete} data-testid={isSubmitting ? 'loading' : undefined} loading={isSubmitting} type="submit">
                        {isComplete ? t('checkYourEmail') : t('sendSignInLink')}
                    </Button>
                </Group>
            </form>
        </AuthLayout>
    );
};
