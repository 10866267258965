import { DimensiontNavLinks } from 'shared/dimension-nav-links';
import { DownloadButton } from 'shared/download-button';
import { ErrorCard } from 'shared/error-card';
import { ProductFilters } from 'shared/product-filters';
import { Section } from 'shared/section';
import { TooManyResultsAlert } from 'shared/too-many-results-alert';
import { useOrganizationId } from 'shared/use-organization-id';
import { useProductFilters } from 'shared/use-product-filters';
import { Dimension } from 'types/shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Group, Select, Text, Title } from '@mantine/core';
import { DEFAULT_MAX_RESULTS } from 'utils/run-query';
import { ComparisonChart } from './comparison-chart';
import { getComparisonChartNavigationPath } from './get-comparison-chart-navigation-path';
import { useEnterpriseScoreComparison } from './use-enterprise-score-comparison';
import { useOrdering } from './use-ordering';

export const BrandScoreComparsion = () => {
    const navigate = useNavigate();
    const organizationId = useOrganizationId();
    const { ordering, onOrderingChange, orderingOptions, isDescending } = useOrdering();
    const { t } = useTranslation();
    const [dimension, setDimension] = useState(Dimension.Total);
    const { filters, ...rest } = useProductFilters();
    const { formatted, data, error, isLoading } = useEnterpriseScoreComparison('brand', dimension, filters, isDescending);

    if (error) return <ErrorCard error={error} />;

    return (
        <>
            <Group justify="space-between" align="center" mb="sm">
                <Box>
                    <Title size="h3">{t('brandComparison')}</Title>
                    <Text c="dimmed" size="sm">
                        {t('brandComparisonDescription')}
                    </Text>
                </Box>
                <ProductFilters
                    {...rest}
                    enabledFilters={['parentCategory', 'category', 'tags']}
                    additionalFilters={
                        <Select
                            allowDeselect={false}
                            label={t('ordering')}
                            onChange={onOrderingChange}
                            value={ordering}
                            data={orderingOptions}
                        />
                    }
                >
                    <DownloadButton
                        exports={[
                            {
                                name: `${dimension}_brand_score_comparison`,
                                data: data?.results
                            }
                        ]}
                        disabled={!data?.results}
                    />
                </ProductFilters>
            </Group>

            <DimensiontNavLinks value={dimension} onChange={setDimension} />

            {data?.num_results === DEFAULT_MAX_RESULTS ? (
                <TooManyResultsAlert maxResults={DEFAULT_MAX_RESULTS} />
            ) : (
                <Section>
                    <ComparisonChart
                        onClick={(data) => {
                            navigate(getComparisonChartNavigationPath('brand', organizationId, data));
                        }}
                        data={formatted}
                        isLoading={isLoading}
                    />
                </Section>
            )}
        </>
    );
};
