import { CreateApiKeyResult } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group, Modal, NumberInput, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showErrorMessage } from 'utils/show-error-message';
import { showSuccessMessage } from 'utils/show-success-message';
import { validation } from 'utils/validation';
import { useApiKeys } from './use-api-keys';

export const CreateApiKeyModal = ({
    isOpen,
    onClose,
    onComplete
}: {
    isOpen: boolean;
    onClose: () => void;
    onComplete: (result: CreateApiKeyResult) => void;
}) => {
    const { t } = useTranslation();
    const [isSubmitting, setSubmitting] = useState(false);
    const { create } = useApiKeys();

    const form = useForm({
        initialValues: {
            name: '',
            days_valid: 30
        },
        validate: {
            name: validation.required,
            days_valid: validation.requiredNumber
        }
    });

    const handleSubmit = form.onSubmit(async (values) => {
        try {
            setSubmitting(true);
            const result = (await create(values)) as CreateApiKeyResult;
            form.reset();
            showSuccessMessage(t('apiKeyCreated'));
            onComplete(result);
            onClose();
        } catch (error) {
            showErrorMessage(error);
        } finally {
            setSubmitting(false);
        }
    });

    return (
        <Modal size="md" opened={isOpen} onClose={onClose} title={t('createApiKey')} centered>
            <form onSubmit={handleSubmit}>
                <Stack gap="xs">
                    <TextInput withAsterisk label={t('name')} type="name" {...form.getInputProps('name')} />
                    <NumberInput
                        withAsterisk
                        label={t('daysValid')}
                        description={t('daysValidDescription')}
                        {...form.getInputProps('days_valid')}
                    />
                </Stack>
                <Group mt="lg" justify="flex-end">
                    <Button type="submit" loading={isSubmitting}>
                        {t('save')}
                    </Button>
                </Group>
            </form>
        </Modal>
    );
};
