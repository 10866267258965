import { links } from 'links';
import { Anchor, Box, Text } from '@mantine/core';
import classes from './footer.module.css';

export const Footer = () => {
    return (
        <Box component="footer" className={classes.footer}>
            <Text ta="center" mb="xs">
                © Dayrize {new Date().getFullYear()}
            </Text>
            <Text size="sm" ta="center" component="small" display="block" variant="caption">
                All usage of this platform is subject to our
                {` `}
                <Anchor c="blue" href={links.privacyPolicy} target="_blank" td="none">
                    Privacy Policy
                </Anchor>
                .
            </Text>
        </Box>
    );
};
