import { MAX_COLUMNS } from './data-row-product-schema-conversion';

export const mapErrorToFieldKey = (key: string) => {
    if (key === 'product_weight.value') return 'product_weight_value';
    if (key === 'product_weight.unit') return 'product_weight_unit';

    for (let i = 0; i < MAX_COLUMNS; i++) {
        if (key.startsWith(`materials[${i}]`)) return `materials_${0}_material_name`;
    }

    return key;
};
