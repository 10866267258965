import casualUseSrc from './casual-use.svg';
import essentialSrc from './essential.svg';
import functionalSrc from './functional.svg';

const classificationOptions = {
    casualUse: {
        name: 'Casual use',
        src: casualUseSrc
    },
    functional: {
        name: 'Functional',
        src: functionalSrc
    },
    essential: {
        name: 'Essential',
        src: essentialSrc
    }
};

export const getPurposeClassification = (value: number) => {
    if (value <= 40) {
        return classificationOptions.casualUse;
    }

    if (value <= 70) {
        return classificationOptions.functional;
    }

    return classificationOptions.essential;
};
