import { useOrganizationId } from 'shared/use-organization-id';
import { Dimension } from 'types/shared';
import { useNavigate, useParams } from 'react-router-dom';
import { useProductName } from './use-product-name';

export const useScoreReportNavigate = () => {
    const organizationId = useOrganizationId();
    const { id } = useParams<{ id: string }>();
    const name = useProductName();
    const navigate = useNavigate();
    return (value: Dimension) => {
        navigate(`/organization/${organizationId}/products/${id}/score-report/${value}?name=${encodeURIComponent(name ?? '')}`);
    };
};
