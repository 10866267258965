import useSWR from 'swr';
import { Filter, QueryReturnResult } from 'types';
import { RunQueryConfig, runQuery } from 'utils/run-query';

export const useOptions = <T = { name: string }>({
    queryName,
    isEnabled,
    filters
}: {
    queryName: string;
    isEnabled?: boolean;
    filters?: Filter[];
}) => {
    const query: RunQueryConfig = {
        name: queryName,
        params: filters
    };
    return useSWR<QueryReturnResult<T>>(isEnabled ? [query.name, query] : null, async ([_, query]) => runQuery(query as RunQueryConfig));
};
