import { SWRConfiguration } from 'swr';
import { request } from 'lib/request-client';

const fetcher = (args: string | [string, any]) => {
    if (typeof args === 'string') return request.url(args).get();
    const [path, query] = args;
    if (typeof query === 'string') return request.url(path).get();
    return request.url(path).query(query).get();
};

const TEN_SECONDS = 10000;

export const swrConfig: SWRConfiguration = {
    dedupingInterval: TEN_SECONDS,
    fetcher,
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    keepPreviousData: true
};
