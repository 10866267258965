import { paths } from 'paths';
import { ConfirmationModal } from 'shared/confirmation-modal';
import { ErrorCard } from 'shared/error-card';
import { Section } from 'shared/section';
import { Table } from 'shared/table';
import { useOrganizationId } from 'shared/use-organization-id';
import { useUser } from 'shared/use-user';
import { Rule } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbDotsVertical } from 'react-icons/tb';
import { Link, useNavigate } from 'react-router-dom';
import { ActionIcon, Badge, Button, Code, Group, Menu, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { getDateColumns } from 'utils/get-date-columns';
import { getIdFromKey } from 'utils/get-id-from-key';
import { showErrorMessage } from 'utils/show-error-message';
import { showSuccessMessage } from 'utils/show-success-message';
import { useRuleConfigs } from './use-rule-configs';

export const RuleConfig = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const organizationId = useOrganizationId();
    const isSmallDevice = useMediaQuery('screen and (max-width: 60em)');
    const { hasPermission } = useUser();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [activeId, setActiveId] = useState<undefined | string>();
    const { data, isLoading, error, remove } = useRuleConfigs();

    const handleDeleteRuleConfigConfirm = async () => {
        if (!activeId) return;
        try {
            await remove(activeId);
            showSuccessMessage(t('ruleConfigDeleted'));
            setShowDeleteModal(false);
            setActiveId(undefined);
        } catch (error) {
            showErrorMessage(error);
        }
    };

    if (error) {
        return <ErrorCard error={error} />;
    }

    return (
        <>
            <Title size="h3">{t('ruleConfig')}</Title>

            <Text c="dimmed" mb="md">
                {t('ruleConfigDescription')}
            </Text>

            <Section>
                <Table
                    isLoading={isLoading}
                    data={data?.items}
                    actions={
                        hasPermission('/rule_manager/rule_config/create', 'POST') && (
                            <Button component={Link} to={`/organization/${organizationId}/rule-config/create`}>
                                {t('createRuleConfig')}
                            </Button>
                        )
                    }
                    columns={[
                        {
                            key: 'name',
                            name: t('name'),
                            width: 200,
                            sortable: true
                        },
                        {
                            key: 'description',
                            name: t('description'),
                            width: 320,
                            sortable: true
                        },
                        {
                            key: 'category',
                            name: t('category'),
                            render: (value) => <Code>{value}</Code>
                        },
                        ...(isSmallDevice
                            ? []
                            : [
                                  {
                                      key: 'rule',
                                      name: t('rules'),
                                      render: (value: Rule[]) => <Badge>{value.length}</Badge>
                                  },
                                  ...getDateColumns()
                              ]),
                        {
                            key: 'actions',
                            name: '',
                            render: (_, row) => (
                                <Group justify="flex-end">
                                    <Menu shadow="md">
                                        <Menu.Target>
                                            <ActionIcon data-testid={`menu-${row.key}`}>
                                                <TbDotsVertical size={16} />
                                            </ActionIcon>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            {hasPermission('/rule_manager/rule_config', 'PATCH') && (
                                                <Menu.Item
                                                    onClick={() => {
                                                        navigate(
                                                            paths.dataManagement.ruleConfigDetail(organizationId, getIdFromKey(row.key))
                                                        );
                                                    }}
                                                >
                                                    {t('update')}
                                                </Menu.Item>
                                            )}
                                            {hasPermission('/rule_manager/rule_config', 'DELETE') && (
                                                <Menu.Item
                                                    color="red"
                                                    onClick={() => {
                                                        setShowDeleteModal(true);
                                                        setActiveId(getIdFromKey(row.key));
                                                    }}
                                                >
                                                    {t('delete')}
                                                </Menu.Item>
                                            )}
                                        </Menu.Dropdown>
                                    </Menu>
                                </Group>
                            )
                        }
                    ]}
                />
            </Section>

            <ConfirmationModal
                variant="delete"
                title={t('confirmDeleteTitle')}
                isOpen={showDeleteModal}
                onClose={() => {
                    setShowDeleteModal(false);
                }}
                onConfirm={handleDeleteRuleConfigConfirm}
            >
                {t('ruleConfigDeleteConfirmation')}
            </ConfirmationModal>
        </>
    );
};
