export const createInsightData = (item: any) => {
    const calculateValue = (value: number) => Math.round(value * 100) / 100;

    const calculateReductionPercent = (original: number, reduction: number) =>
        Math.round(((original - reduction) / original) * 100 * 100) / 100;

    return {
        name: item.material_a_beautified_name_with_characteristic,
        newName: item.material_b_beautified_name_with_characteristic,
        originalAmount: calculateValue(item.original_absolute_gwp),
        reductionAmount: calculateValue(item.swap_absolute_gwp),
        span: item.complexity,
        insightData: [
            {
                value: calculateValue(item.original_absolute_gwp)
            },
            {
                value: calculateValue(item.swap_absolute_gwp),
                current_reduction_percent: calculateReductionPercent(item.original_absolute_gwp, item.swap_absolute_gwp),
                
            }
        ]
    };
};
