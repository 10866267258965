import { DimensiontNavLinks } from 'shared/dimension-nav-links';
import { DownloadButton } from 'shared/download-button';
import { ErrorCard } from 'shared/error-card';
import { ProductFilters } from 'shared/product-filters';
import { Section } from 'shared/section';
import { TooManyResultsAlert } from 'shared/too-many-results-alert';
import { useOrganizationId } from 'shared/use-organization-id';
import { useProductFilters } from 'shared/use-product-filters';
import { Dimension, OrderDirection } from 'types/shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Group, Select, Text, Title } from '@mantine/core';
import { DEFAULT_MAX_RESULTS } from 'utils/run-query';
import { ComparisonChart } from './comparison-chart';
import { getComparisonChartNavigationPath } from './get-comparison-chart-navigation-path';
import { useEnterpriseScoreComparison } from './use-enterprise-score-comparison';

export const CategoryScoreComparison = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const organizationId = useOrganizationId();
    const [isDesc, setIsDesc] = useState(true);
    const [dimension, setDimension] = useState(Dimension.Total);
    const { filters, ...rest } = useProductFilters();
    const { data, error, isLoading, formatted } = useEnterpriseScoreComparison('category', dimension, filters, isDesc);

    if (error) {
        return <ErrorCard error={error} />;
    }

    return (
        <>
            <Group justify="space-between" align="center" mb="sm">
                <Box>
                    <Title size="h3">{t('categoryComparison')}</Title>
                    <Text c="dimmed" size="sm">
                        {t('categoryComparisonDescription')}
                    </Text>
                </Box>
                <ProductFilters
                    {...rest}
                    enabledFilters={['parentCategory', 'brand', 'tags']}
                    additionalFilters={
                        <Select
                            label={t('ordering')}
                            data={[
                                {
                                    label: t('descending'),
                                    value: OrderDirection.Descending
                                },
                                {
                                    label: t('ascending'),
                                    value: OrderDirection.Ascending
                                }
                            ]}
                            allowDeselect={false}
                            onChange={(value) => {
                                if (value) setIsDesc(value === OrderDirection.Descending);
                            }}
                            value={isDesc ? OrderDirection.Descending : OrderDirection.Ascending}
                        />
                    }
                >
                    <DownloadButton
                        disabled={!data?.results}
                        exports={[
                            {
                                name: `${dimension}_category_score_comparison`,
                                data: data?.results
                            }
                        ]}
                    />
                </ProductFilters>
            </Group>

            <DimensiontNavLinks value={dimension} onChange={setDimension} />

            {data?.num_results === DEFAULT_MAX_RESULTS && <TooManyResultsAlert maxResults={DEFAULT_MAX_RESULTS} />}

            <Section>
                <ComparisonChart
                    onClick={(data) => {
                        navigate(getComparisonChartNavigationPath('category', organizationId, data));
                    }}
                    data={formatted}
                    isLoading={isLoading}
                />
            </Section>
        </>
    );
};
