import { useTranslation } from 'react-i18next';
import { Modal } from '@mantine/core';
import { CreateImpersonationForm } from './create-impersonation-form';

export const CreateImpersonationModal = ({
    namespace,
    isOpen,
    onClose
}: {
    namespace: string | null;
    isOpen: boolean;
    onClose: () => void;
}) => {
    const { t } = useTranslation();
    if (!namespace || !isOpen) return null;

    return (
        <Modal size="md" title={t('createImpersonation')} opened={isOpen} onClose={onClose}>
            <CreateImpersonationForm namespace={namespace} onComplete={onClose} />
        </Modal>
    );
};
