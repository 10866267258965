import { exportAsCsv } from 'utils/export-as-csv';
import { exportAsJsonl } from 'utils/export-as-jsonl';
import { SchemaProduct } from './use-holding-area';

export const DownloadProduct = (product: SchemaProduct, type: 'csv' | 'jsonl') => {
    const {
        // ignored
        error_message,
        error_timestamp,
        file_id,
        record_hash,
        // export the rest
        ...schema
    } = product;

    if (type === 'jsonl') {
        exportAsJsonl([schema as any], schema.sku);
    }

    if (type === 'csv') {
        exportAsCsv([schema as any], schema.sku);
    }
};
