import { useTableFilters } from 'shared/use-table-filters';
import useSWR from 'swr';
import { Filter, Nullable, QueryReturnResult } from 'types';
import { useEffect } from 'react';
import { RunQueryConfig, runQuery } from 'utils/run-query';

export interface SchemaProduct {
    sku: string;
    name: string;
    record_hash: string;
    parent_category: string;
    category: string;
    production_sector: string;
    custom_category: string;
    description: Nullable<string>;
    brand: string;
    division: null;
    tags: Array<string>;
    country_of_production: string;
    product_weight: Weight;
    product_sustainability_characteristics: ProductSustainabilityCharacteristics;
    livelihood_and_wellbeing: LivelihoodAndWellbeing;
    eco_labels: any[];
    production: Production;
    environmental_impact_assessment: EnvironmentalImpactAssessment;
    transports: Transport[];
    materials: Material[];
    material_category_type_properties: MaterialCategoryTypeProperty[];
    supplier: string;
    error_message: string;
    file_id: string;
    error_timestamp: string;
    error_fields: Array<string>;
}

interface Weight {
    value: number;
    unit: string;
}

interface ProductSustainabilityCharacteristics {
    durable: null;
    package_free: null;
    easy_delivery: null;
    low_energy: null;
    shift_behavior: null;
    sustainable_materials: null;
    is_non_consumable: null;
    average_product_lifespan: null;
    legal_guarantee: null;
    extra_guarantee_duration: null;
    repair_service: null;
    leasing_scheme: null;
    spare_parts: null;
    product_components: ProductComponents;
    communication_end_of_life_treatment: null;
}

interface ProductComponents {
    proprietary_parts: null;
    moving_parts: null;
    small_components: null;
    standard_fixings: null;
}

interface LivelihoodAndWellbeing {
    fair_pay: FairPay;
    working_conditions: WorkingConditions;
    workers_protection: WorkersProtection;
    child_and_forced_labor: ChildAndForcedLabor;
}

interface FairPay {
    living_wage: ClaimAndProof;
    gender_wage_gap: ClaimAndProof;
}

interface ClaimAndProof {
    claim: null;
    proof_file_key: null;
}

interface WorkingConditions {
    working_hours: ClaimAndProof;
    collective_bargaining: ClaimAndProof;
}

interface WorkersProtection {
    youth_labor: ClaimAndProof;
    work_safety: ClaimAndProof;
}

interface ChildAndForcedLabor {
    forced_labor: ClaimAndProof;
    child_labor: ClaimAndProof;
}

interface Production {
    water_consumption: WaterConsumption;
    energy_consumption: EnergyConsumption;
}

interface WaterConsumption {
    value: null;
    unit: null;
    water_source: null;
}

interface EnergyConsumption {
    energy_sources: EnergySource[];
}

interface EnergySource {
    energy_type: string;
    percentage: null;
    usage_type: string;
    energy_use: Weight;
}

interface EnvironmentalImpactAssessment {
    EIA_conducted_type: null;
    EIA_type: null;
    reviewed_by_external_organization: null;
    follows_international_standards: null;
    results: EnvironmentalImpact[];
    proof_files: any[];
}

interface EnvironmentalImpact {
    impact: Weight;
    impact_category: null;
    production_stage: null;
}

interface Transport {
    origin: Location;
    destination: Location;
    mode: string;
    medium: string;
}

interface Location {
    country: string;
    city: string;
}

interface Material {
    category: string;
    type: string;
    material_name: string;
    description: string;
    percentage: number;
    weight: Weight;
    source_country: string;
    reused: boolean;
    recycled: boolean;
    organic: boolean;
    biobased: boolean | null;
    component: null;
}

interface MaterialCategoryTypeProperty {
    category: string;
    type: string;
    weight: Weight;
    PVC: null;
    additives: null;
    adhesives: null;
    elastomers: null;
    materials_separability_type: null;
    best_eol_disposal_method: null;
    eol_treatment_type: null;
}

const QUERY_NAME = 'holding_area';

export const useHoldingArea = (params?: Filter[]) => {
    const {
        page,

        limit,
        order,
        query,
        search,

        orderDirection,
        onPage,
        onLimitChange,
        onOrderChange,
        onSearchChange,
        onPageTokenMappingChange
    } = useTableFilters({
        searchKey: 'sku',
        params,
        variant: 'query',
        initialOrdering: 'error_timestamp',
        initialLimit: '50'
    });

    const requestQuery = {
        name: QUERY_NAME,
        params: query.params,
        orderByParams: query.orderByParams,
        pageSize: query.limit,
        pageToken: query.page_token
    };

    const { data, error, mutate, isLoading, isValidating } = useSWR<QueryReturnResult<SchemaProduct>>(
        [QUERY_NAME, requestQuery],
        async ([_, query]) => runQuery(query as RunQueryConfig)
    );

    useEffect(() => {
        if (data && data.next_page_token && !isLoading) {
            onPageTokenMappingChange((current) => ({
                ...current,
                [page + 1]: data.next_page_token
            }));
        }
    }, [data, isLoading, onPageTokenMappingChange, page]);

    return {
        isLoading,
        isValidating,
        data,
        error,
        mutate,
        page,
        onPage,
        limit,
        onLimitChange,

        order,
        onOrderChange,
        orderDirection,
        search,
        onSearchChange,
        onPageTokenMappingChange
    };
};
