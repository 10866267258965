import useSWR from 'swr';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader, Select, SelectProps } from '@mantine/core';
import { RunQueryConfig, runQuery } from 'utils/run-query';
import styles from './edit-cell.module.css';

export const GoogleTaxonomyAutocompleteSelect = (props: SelectProps) => {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const shouldSearch = searchValue && searchValue.length > 3;

    const query: RunQueryConfig = {
        name: 'google_taxonomy_paths',
        pageSize: 50,
        params: [
            {
                name: 'taxonomy_path',
                operation: '~',
                value: searchValue
            }
        ]
    };

    const {
        data: matchingPaths,
        isLoading,
        isValidating
    } = useSWR(shouldSearch ? [query.name, searchValue, query] : null, ([_, __, query]) => runQuery(query));

    const showSpinner = (shouldSearch && isLoading) || isValidating;
    const hasData = matchingPaths && matchingPaths?.results?.length > 0;
    const options = matchingPaths?.results.map((current) => current.google_taxonomy_path) ?? [];

    return (
        <Select
            className={styles.input}
            searchable
            placeholder={t('SearchTaxonomyPath')}
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            variant="unstyled"
            selectFirstOptionOnChange
            nothingFoundMessage={showSpinner ? t('loadingMatches') : hasData ? t('noMatchesFound') : t('searchForTaxonomyPath')}
            autoFocus
            data={options}
            rightSection={showSpinner && <Loader size="xs" color="blue" />}
            {...props}
        />
    );
};
