import { paths } from 'paths';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ErrorLayout } from './error-layout';

export const NotFound = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <ErrorLayout
            status="404"
            title={t('notFoundTitle')}
            message={t('notFoundDescription')}
            buttonText={t('backToHome')}
            onClick={() => navigate(paths.root())}
        />
    );
};
