import { downloadFile } from 'data-management/data-ingestion/download-file';
import { DownloadButton } from 'shared/download-button';
import { ErrorCard } from 'shared/error-card';
import { ProductFilters } from 'shared/product-filters';
import { Section } from 'shared/section';
import { Table } from 'shared/table';
import { useProductFilters } from 'shared/use-product-filters';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbDotsVertical, TbRefresh } from 'react-icons/tb';
import { ActionIcon, Button, HoverCard, Menu, Text, Title, Tooltip } from '@mantine/core';
import { BulkEditModal } from './bulk-edit-modal';
import { DownloadProduct } from './download-product';
import { FileSelect } from './file-select';
import { SchemaProduct, useHoldingArea } from './use-holding-area';

export const HoldingArea = () => {
    const [selected, setSelected] = useState<Array<string>>([]);
    const [selectedRows, setSelectedRows] = useState<SchemaProduct[]>([]);
    const { t } = useTranslation();
    const [fileId, setFileId] = useQueryParam('file', withDefault(StringParam, ''));
    const { filters, ...rest } = useProductFilters();
    const {
        error,
        data,
        isLoading,
        limit,
        onLimitChange,
        page,
        onPage,
        order,
        onOrderChange,
        search,
        onSearchChange,
        mutate,
        orderDirection,
        isValidating
    } = useHoldingArea(fileId ? [{ name: 'file_id', operation: '=', value: fileId }, ...filters] : filters);

    const hasSelected = selected.length > 0;

    const handleEdit = () => {
        if (hasSelected) {
            const matchingProducts =
                data?.results.filter((row) => {
                    return !!selected.find((current) => {
                        const [sku, timestamp] = current.split('_');
                        return row.sku === sku && row.error_timestamp === timestamp;
                    });
                }) ?? [];
            setSelectedRows(matchingProducts);
            setSelected([]);
        } else {
            setSelectedRows(data?.results ?? []);
        }
    };

    if (error) {
        return <ErrorCard error={error} />;
    }

    return (
        <>
            <Title size="h3">{t('holdingArea')}</Title>
            <Text c="dimmed" mb="md">
                {t('holdingAreaDescription')}
            </Text>
            <Section>
                <Table
                    actions={
                        <ProductFilters
                            enabledFilters={['brand', 'parentCategory', 'tags']}
                            additionalFilters={<FileSelect value={fileId} onChange={(value) => setFileId(value)} />}
                            additionalFiltersCount={fileId ? 1 : undefined}
                            onClearAll={() => setFileId('')}
                            {...rest}
                        >
                            <ActionIcon data-testid="refresh" variant="default" size="lg" loading={isValidating} onClick={() => mutate()}>
                                <TbRefresh />
                            </ActionIcon>
                            <Tooltip
                                label={
                                    selected.length > 0 ? t('editSelectedProductsAndReupload') : t('editCurrentFilteredProductsAndReupload')
                                }
                            >
                                <Button onClick={handleEdit} disabled={!data?.results} variant="outline">
                                    {selected.length > 0 ? t('editSelected') : t('edit')}
                                </Button>
                            </Tooltip>
                            <DownloadButton
                                disabled={!data?.results}
                                exports={[
                                    {
                                        name: 'holding_area',
                                        data: data?.results
                                    }
                                ]}
                            />
                        </ProductFilters>
                    }
                    selected={selected}
                    onSelect={setSelected}
                    isLoading={isLoading}
                    data={data?.results}
                    search={search}
                    onSearchChange={onSearchChange}
                    page={page}
                    onPage={(newPage) => {
                        setSelected([]);
                        onPage(newPage);
                    }}
                    limit={limit}
                    onLimitChange={onLimitChange}
                    order={order}
                    onOrderChange={onOrderChange}
                    orderDirection={orderDirection}
                    columns={[
                        {
                            key: 'sku',
                            name: t('sku')
                        },
                        {
                            key: 'error_message',
                            name: t('message'),
                            render: (value) => (
                                <HoverCard width={400} shadow="md">
                                    <HoverCard.Target>
                                        <Text w={640} lineClamp={2}>
                                            {value}
                                        </Text>
                                    </HoverCard.Target>
                                    <HoverCard.Dropdown>
                                        <Text size="sm">{value}</Text>
                                    </HoverCard.Dropdown>
                                </HoverCard>
                            )
                        },
                        {
                            key: 'error_timestamp',
                            name: t('errorAt'),
                            render: (value) => new Date(value).toLocaleString()
                        },

                        {
                            key: 'actions',
                            align: 'right',
                            name: '',
                            render: (_, row) => {
                                return (
                                    <Menu shadow="md">
                                        <Menu.Target>
                                            <ActionIcon data-testid={`menu-${row.sku}`}>
                                                <TbDotsVertical size={16} />
                                            </ActionIcon>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            <Menu.Item
                                                onClick={() => {
                                                    setSelectedRows([row]);
                                                }}
                                            >
                                                {t('editProduct')}
                                            </Menu.Item>
                                            <Menu.Item
                                                onClick={() => {
                                                    setFileId(row.file_id);
                                                    onLimitChange('1000');
                                                }}
                                            >
                                                {t('filterForFile')}
                                            </Menu.Item>
                                            <Menu.Item
                                                onClick={() => {
                                                    downloadFile(row.file_id);
                                                }}
                                            >
                                                {t('downloadFile')}
                                            </Menu.Item>
                                            <Menu.Item onClick={() => DownloadProduct(row, 'jsonl')}>
                                                {t('downloadProductAsJsonl')}
                                            </Menu.Item>
                                            <Menu.Item onClick={() => DownloadProduct(row, 'csv')}>{t('downloadProductAsCsv')}</Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                );
                            }
                        }
                    ]}
                    rowKey={(row) => `${row.sku}_${row.error_timestamp}`}
                />
            </Section>
            {selectedRows.length > 0 && <BulkEditModal onClose={() => setSelectedRows([])} rows={selectedRows} />}
        </>
    );
};
