import { EolResult, Filter, Nullable } from 'types';
import { useInsightQuery } from './use-insight-query';

const QUERY_NAME = 'insight_circularity_of_materials';

export const useCircularityOfMaterials = (params: Filter[] = []) => {
    const biobasedResults = useInsightQuery<{
        material_category: 'product' | 'packaging';
        characteristic_type: Nullable<string>;
        characteristic: Nullable<string>;
        percentage: Nullable<number>;
    }>(QUERY_NAME, [...params, { name: 'characteristic_type', operation: '=', value: 'biobased' }]);

    const circularResults = useInsightQuery<{
        material_category: 'product' | 'packaging';
        characteristic_type: Nullable<string>;
        characteristic: Nullable<string>;
        percentage: Nullable<number>;
    }>(QUERY_NAME, [...params, { name: 'characteristic_type', operation: '=', value: 'circularity' }]);

    const eolResults = useInsightQuery<EolResult>(QUERY_NAME, [
        ...params,
        { name: 'characteristic_type', operation: '=', value: 'end_of_life' }
    ]);

    return {
        biobasedResults,
        circularResults,
        eolResults
    };
};
