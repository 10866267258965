import { ImportButton } from 'shared/import-button';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notifications } from '@mantine/notifications';
import { showErrorMessage } from 'utils/show-error-message';
import { convertCsvToJsonl } from './convert-csv-to-jsonl';
import { getFileValidationError } from './get-file-validation-error';
import { useIngestion } from './use-ingestion';
import { ValidationResultModal } from './validation-result-modal';

export const ValidateFile = ({ onStartUpload }: { onStartUpload: (file: File) => void }) => {
    const { validate } = useIngestion();
    const { t } = useTranslation();
    const [isSubmitting, setSubmitting] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [showResultModal, setShowResultModal] = useState(false);
    const [errors, setErrors] = useState<Array<string>>([]);
    const resetRef = useRef<() => void>(null);

    const handleClearFile = () => {
        setFile(null);
        resetRef.current?.();
    };

    const handleValidate = async (file: File) => {
        setErrors([]);

        const newFile = await convertCsvToJsonl(file);
        const validationError = getFileValidationError(newFile);
        if (validationError) {
            notifications.show({
                message: validationError,
                color: 'red'
            });
            return;
        }

        try {
            setSubmitting(true);
            const result = await validate(newFile);
            setErrors(result.detail);
            setShowResultModal(true);
            setFile(file);
        } catch (error) {
            showErrorMessage(error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <>
            <ImportButton
                isLoading={isSubmitting}
                tooltip={t('validateDataTooltip')}
                onChange={(file) => {
                    if (file) handleValidate(file);
                }}
            >
                {t('validateData')}
            </ImportButton>
            <ValidationResultModal
                file={file}
                errors={errors}
                isOpen={showResultModal}
                onClose={() => {
                    handleClearFile();
                    setErrors([]);
                    setShowResultModal(false);
                }}
                onUpload={() => {
                    if (file) {
                        setShowResultModal(false);
                        onStartUpload(file);
                    }
                }}
            />
        </>
    );
};
