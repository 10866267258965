import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import { ReactNode } from 'react';
import { Group, Text, Tooltip } from '@mantine/core';

export const ColumnTitle = ({
    children,
    description,
    required = false
}: {
    children: ReactNode;
    description?: ReactNode;
    required?: boolean;
}) => {
    return (
        <Group gap={0} w="100%" align="center">
            <Text size="xs">{children}</Text>
            {required && (
                <Tooltip label="Required field">
                    <Text size="xs" c="red">
                        *{' '}
                    </Text>
                </Tooltip>
            )}
            {description && (
                <MoreInfoHoverCard>
                    {typeof description === 'string' ? <Text size="xs">{description}</Text> : description}
                </MoreInfoHoverCard>
            )}
        </Group>
    );
};
