import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group, Modal, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showErrorMessage } from 'utils/show-error-message';
import { removeFileNameExtension } from './remove-file-name-extension';

export const NameConfirmationModal = ({
    isOpen,
    initialName,
    onUpload,
    onClose,
    variant = 'default'
}: {
    initialName?: string;
    isOpen: boolean;
    onUpload: (name: string) => Promise<void>;
    onClose: () => void;
    variant?: 'default' | 'duplicate';
}) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const form = useForm({
        initialValues: {
            name: ''
        }
    });

    const handleSubmit = form.onSubmit(async (values) => {
        try {
            setIsSubmitting(true);
            await onUpload(values.name || `upload_${Date.now()}`);
            form.reset();
        } catch (error) {
            showErrorMessage(error);
        } finally {
            setIsSubmitting(false);
            onClose();
        }
    });

    useEffect(() => {
        if (initialName) form.setFieldValue('name', removeFileNameExtension(initialName));
    }, [initialName]);

    const isExistingName = variant === 'duplicate' && !!initialName && removeFileNameExtension(initialName) === form.values.name;

    return (
        <Modal
            size="sm"
            opened={isOpen}
            onClose={onClose}
            title={variant === 'duplicate' ? t('renameUpload') : t('confirmUploadName')}
            centered
        >
            <form onSubmit={handleSubmit}>
                <Stack gap="xs">
                    <TextInput
                        label="Name"
                        description={variant === 'duplicate' ? t('uploadNameAlreadyExists') : t('provideUploadName')}
                        type="name"
                        {...form.getInputProps('name')}
                    />
                </Stack>
                <Group mt="lg" justify="flex-end">
                    <Button disabled={isExistingName} type="submit" loading={isSubmitting}>
                        {t('startUpload')}
                    </Button>
                </Group>
            </form>
        </Modal>
    );
};
