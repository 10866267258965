import { MultiFactorResolver } from 'firebase/auth';
import { showAuthError } from 'shared/show-auth-error';
import { Nullable } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group, Modal, PinInput, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { validation } from 'utils/validation';
import { finishMfaSignIn } from './finish-mfa-sign-in';

export const MfaSignInModal = ({
    isOpen,
    onClose,
    verificationId,
    multiFactorResolver,
    initialValue
}: {
    isOpen: boolean;
    onClose: () => void;
    initialValue?: string;
    verificationId?: Nullable<string>;
    multiFactorResolver?: MultiFactorResolver | null;
}) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const form = useForm({
        initialValues: {
            code: initialValue ?? ''
        },
        validate: {
            code: validation.required
        }
    });

    const handleSubmit = form.onSubmit(async (values) => {
        try {
            setIsSubmitting(true);
            if (!verificationId || !multiFactorResolver) {
                throw new Error(t('failedToSignInUsingMultiFactorAuthentication'));
            }
            await finishMfaSignIn(values.code, verificationId, multiFactorResolver);
            window.signInRecaptchaVerifier = undefined;
            form.reset();
            onClose();
        } catch (error) {
            showAuthError(error);
        } finally {
            setIsSubmitting(false);
        }
    });

    return (
        <Modal size="md" opened={isOpen} onClose={onClose} title={t('multiFactorAuthenticationEnabled')} centered>
            <form onSubmit={handleSubmit}>
                <Stack mt="xs" gap="sm" justify="center" align="center">
                    <Text size="sm" c="dimmed" ta="center">
                        {t('enterTheCodeSentToYourPhone')}
                    </Text>
                    <PinInput autoFocus data-testid="code-input" length={6} {...form.getInputProps('code')} />
                </Stack>
                <Group mt="xl" justify="flex-end">
                    <Button type="submit" loading={isSubmitting}>
                        {t('signIn')}
                    </Button>
                </Group>
            </form>
        </Modal>
    );
};
