import useSWR from 'swr';
import { QueryReturnResult } from 'types';
import { runQuery } from 'utils/run-query';

const QUERY_NAME = 'audit_log_components';

export const useAuditLogComponents = () => {
    const { data, error, mutate, isLoading } = useSWR<QueryReturnResult<{ component: string }>>(QUERY_NAME, async () =>
        runQuery({
            name: QUERY_NAME
        })
    );
    return {
        data,
        error,
        mutate,
        isLoading
    };
};
