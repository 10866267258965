import { Box, Group, Text, rem, useMantineTheme } from '@mantine/core';
import classes from './graph-legend.module.css';

export const GraphLegend = ({
    items,
    minWidth = 400
}: {
    items: {
        color: string;
        title: string;
        subtitle?: string;
        description?: string;
        value: string;
        rowKey?: string;
    }[];
    minWidth?: number;
}) => {
    const theme = useMantineTheme();
    return (
        <Box component="ul" className={classes.list} style={{ minWidth }}>
            {items.map(({ color, title, value, subtitle, rowKey, description }) => (
                <Box key={rowKey ?? title} component="li">
                    <Group align="center" justify="space-between" gap="xs">
                        <Group align="center" gap="xs">
                            <Box
                                style={{
                                    backgroundColor: color,
                                    height: rem(12),
                                    width: rem(12),
                                    minWidth: rem(12),
                                    borderRadius: theme.radius.xl
                                }}
                            />
                            <Text size="md" fw={500}>
                                {title}
                            </Text>
                        </Group>

                        <Group gap={rem(2)} align="center">
                            <Text size="md" fw={500}>
                                {value}
                            </Text>
                            {subtitle && (
                                <Text size="sm" c="dimmed">
                                    {subtitle}
                                </Text>
                            )}
                        </Group>
                    </Group>
                    {description && (
                        <Text style={{ maxWidth: rem(380), marginLeft: rem(20) }} size="sm" c="dimmed">
                            {description}
                        </Text>
                    )}
                </Box>
            ))}
        </Box>
    );
};
