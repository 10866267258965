import { ColumnMappingUploadModal } from 'data-management/data-ingestion/column-mapping-upload-modal';
import { useColumnMapping } from 'data-management/data-ingestion/use-column-mapping';
import { ErrorCard } from 'shared/error-card';
import { Section } from 'shared/section';
import { Table } from 'shared/table';
import { useUser } from 'shared/use-user';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text, Title, Code, Group, Stack } from '@mantine/core';

export const ColumnMappingConfiguration = () => {
    const { t } = useTranslation();
    const { hasPermission } = useUser();
    const [showUploadModal, setShowUploadModal] = useState(false);
    const { tableData, error, isLoading } = useColumnMapping();

    if (error) {
        return <ErrorCard error={error} />;
    }

    return (
        <>
            <Title size="h3">{t('columnMappingConfiguration')}</Title>
            <Text c="dimmed" mb="md">
                {t('columnMappingConfigurationDescription')}
            </Text>

            <Section>
                <Table
                    isLoading={isLoading}
                    actions={
                        hasPermission('/ingest/file/column-mapping', 'PATCH') && (
                            <Button color="primary" onClick={() => setShowUploadModal(true)}>
                                {t('updateColumnMappings')}
                            </Button>
                        )
                    }
                    data={tableData}
                    columns={[
                        { key: 'name', name: t('name'), sortable: true, width: '30%' },
                        {
                            key: 'columns',
                            name: t('columnMapping'),
                            render: (value: Array<string>) => (
                                <Stack gap="xs">
                                    {value.map((current, index) => {
                                        return (
                                            <Group key={current} gap="xs">
                                                <Code>{index}</Code>
                                                <span>→</span>
                                                <Code>{current}</Code>
                                            </Group>
                                        );
                                    })}
                                </Stack>
                            )
                        }
                    ]}
                />
            </Section>

            <ColumnMappingUploadModal
                isOpen={showUploadModal}
                onClose={() => {
                    setShowUploadModal(false);
                }}
            />
        </>
    );
};
