import { convertCsvToJsonl } from 'data-management/data-ingestion/convert-csv-to-jsonl';
import { convertJsonlToCsv } from 'data-management/data-ingestion/convert-jsonl-to-csv';
import { saveAs } from 'file-saver';
import { Section } from 'shared/section';
import { useTranslation } from 'react-i18next';
import { Button, FileButton, Group, Text, Title } from '@mantine/core';

export const Utilities = () => {
    const { t } = useTranslation();

    return (
        <>
            <Title size="h3">{t('utilities')}</Title>
            <Text c="dimmed" mb="md">
                {t('utilitiesDescription')}
            </Text>

            <Section mb="md">
                <Group justify="space-between" gap="sm">
                    <Text c="dimmed">{t('convertJsonlToCsvDescription')}</Text>
                    <FileButton
                        onChange={(file) => {
                            if (file) convertJsonlToCsv(file);
                        }}
                        accept=".jsonl"
                    >
                        {(props) => (
                            <Button size="sm" variant="outline" {...props}>
                                {t('convertJsonlToCsv')}
                            </Button>
                        )}
                    </FileButton>
                </Group>
            </Section>
            <Section>
                <Group justify="space-between" gap="sm">
                    <Text c="dimmed">Convert a CSV to JSONL. Flattened out structures will be nested.</Text>
                    <FileButton
                        onChange={async (file) => {
                            if (file) {
                                const newFile = await convertCsvToJsonl(file);
                                saveAs(newFile);
                            }
                        }}
                        accept=".csv"
                    >
                        {(props) => (
                            <Button size="sm" variant="outline" {...props}>
                                Convert CSV to JSONL
                            </Button>
                        )}
                    </FileButton>
                </Group>
            </Section>
        </>
    );
};
