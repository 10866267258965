import useSWR from 'swr';
import { ApiKey } from 'types';
import { request } from 'lib/request-client';

export const useApiKeys = () => {
    const { data, isLoading, error, mutate } = useSWR<ApiKey[]>('/account_manager/key_management/list');

    const create = async (
        body: Partial<{
            days_valid: number;
            name: string;
        }>
    ) => {
        const result = await request.url('/account_manager/key_management/create').query(body).post(body);
        await mutate();
        return result;
    };

    const remove = async (id: string) => {
        await request.url(`/account_manager/key_management/${id}`).delete();
        mutate();
    };

    return {
        error,
        isLoading,
        data,
        create,
        remove
    };
};
