import { useMemo } from 'react';
import { useMantineTheme } from '@mantine/core';

export const useEolMapping = () => {
    const theme = useMantineTheme();
    return useMemo(
        () => ({
            c: {
                title: 'Compostable',
                color: theme.colors.teal[7]
            },
            r1: {
                title: 'Recyclable Materials (low)',
                color: theme.colors.violet[4]
            },
            r2: {
                title: 'Recyclable Materials (medium)',
                color: theme.colors.yellow[5]
            },
            r3: {
                title: 'Recyclable Materials (high)',
                color: theme.colors.blue[4]
            },
            nac: {
                title: 'Not Applicable - Consumable',
                color: theme.colors.gray[3]
            },
            na: {
                title: 'Not Applicable',
                color: theme.colors.gray[3]
            },
            nr: {
                title: 'Not Recyclable',
                color: theme.colors.red[6]
            }
        }),
        [theme]
    );
};
