import { Section } from 'shared/section';
import { useOrganizationId } from 'shared/use-organization-id';
import { useUser } from 'shared/use-user';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineAudit } from 'react-icons/ai';
import { BsCardList } from 'react-icons/bs';
import { TbBrandGoogleBigQuery, TbBuilding, TbDashboard, TbDatabaseImport, TbListCheck, TbUsers, TbUsersGroup } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import { SimpleGrid, Title, Text, ThemeIcon, Stack, Box, Alert } from '@mantine/core';
import classes from './index.module.css';

export const Home = () => {
    const { t } = useTranslation();
    const { hasPermission, isDayrize } = useUser();
    const organizationId = useOrganizationId();

    const cards = [
        hasPermission('/query_manager/query/execute', 'POST') && {
            icon: <TbDashboard size={20} color="inherit" />,
            to: `/organization/${organizationId}/dashboard`,
            title: t('dashboard'),
            description: t('dashboardDescription')
        },
        hasPermission('/query_manager/queries/query') && {
            icon: <TbBrandGoogleBigQuery size={20} color="inherit" />,
            to: `/organization/${organizationId}/queries`,
            title: t('queries'),
            description: t('queriesDescription')
        },
        hasPermission('/query_manager/query/execute', 'POST') && {
            icon: <BsCardList size={20} color="inherit" />,
            to: `/organization/${organizationId}/products`,
            title: t('products'),
            description: t('productsDescription')
        },
        hasPermission('/ingest/files/query') && {
            to: `/organization/${organizationId}/ingest`,
            icon: <TbDatabaseImport size={20} />,
            title: t('dataIngestion'),
            description: t('dataIngestionDescription')
        },
        hasPermission('/rule_manager/rule_configs/query') && {
            to: `/organization/${organizationId}/rule-config`,
            icon: <TbListCheck size={20} />,
            title: t('ruleConfig'),
            description: t('ruleConfigDescription')
        },
        hasPermission('/query_manager/query/execute', 'POST') && {
            to: `/organization/${organizationId}/audit-log`,
            icon: <AiOutlineAudit size={20} />,
            title: t('auditLog'),
            description: t('auditLogDescription')
        },
        hasPermission('/account_manager/groups/query') && {
            to: `/organization/${organizationId}/groups`,
            icon: <TbUsersGroup size={20} />,
            title: t('groups'),
            description: t('groupsDescription')
        },
        hasPermission('/') &&
            isDayrize && {
                icon: <TbBuilding size={20} color="inherit" />,
                to: `/organization/${organizationId}/namespaces`,
                title: t('namespaces'),
                description: t('namespacesDescription')
            },
        hasPermission('/account_manager/users/query') && {
            to: `/organization/${organizationId}/users`,
            icon: <TbUsers size={20} />,
            title: t('users'),
            description: t('usersDescription')
        }
    ].filter((current) => !!current) as {
        to: string;
        icon: ReactNode;
        title: string;
        description: string;
    }[];

    const showPermissionsAlert = cards.length <= 1;

    return (
        <>
            <Title mb="md">{t('welcomeToDayrize')}</Title>
            {showPermissionsAlert && (
                <Alert title={t('checkPermissions')} color="orange" variant="light" mb="md">
                    {t('checkPermissionsDescription')}
                </Alert>
            )}
            <SimpleGrid
                spacing="sm"
                cols={{
                    sm: 1,
                    md: 2,
                    lg: 3
                }}
            >
                {cards.map((current) => (
                    <Box key={current.to} component={Link} to={current.to} className={classes.card}>
                        <Section>
                            <Stack gap={0} align="center" ta="center">
                                <ThemeIcon variant="light" size={40} radius={40} mb="sm">
                                    {current.icon}
                                </ThemeIcon>
                                <Title size="h3">{current.title}</Title>
                                <Text size="sm">{current.description}</Text>
                            </Stack>
                        </Section>
                    </Box>
                ))}
            </SimpleGrid>
        </>
    );
};
