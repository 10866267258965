import { saveAs } from 'file-saver';
import { JsonValue } from './export-as-json';
import { flattenObj } from './flatten-obj';

export const exportAsExcel = async (json: Record<string, JsonValue>[], filename: string) => {
    const flattened = json.map((row) => flattenObj(row));
    const Excel = (await import('exceljs')).default;
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('results');

    const columns = Object.keys(flattened[0]).map((columnName) => ({
        header: columnName,
        key: columnName
    }));
    worksheet.columns = columns;
    worksheet.getRow(1).font = { bold: true };
    flattened.forEach((row) => worksheet.addRow(row));

    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), `${filename}.xlsx`);
};
