import useSWR from 'swr';
import { Filter, Nullable, QueryReturnResult } from 'types';
import { RunQueryConfig, runQuery } from 'utils/run-query';

export interface PrismItem {
    id: string;
    sku: string;
    name: string;
    version: string;
    category: string;
    category_id: string;
    parent_category: string;
    brand: Nullable<string>;
    division: Nullable<string>;
    tags: Array<string>;
    country_of_production: string;
    product_weight: {
        value: number;
        unit: string;
    };
    product_version: string;
    total: number;
    transport_gwp_absolute: number;
    product_gwp_absolute: number;
    packaging_gwp_absolute: number;
    manufacturing_gwp_absolute: number;
    absolute_water_depletion_product: number;
    absolute_water_depletion_packaging: number;
    absolute_alop_packaging: number;
    absolute_alop_product: number;
}

export const usePrism = (filters?: Filter[]) => {
    const query: RunQueryConfig = {
        name: 'prism',
        params: filters
    };
    return useSWR<QueryReturnResult<PrismItem>>(filters && filters.length > 0 ? [query.name, query] : null, async ([_, query]) =>
        runQuery(query as RunQueryConfig)
    );
};
