import { JsonView as BaseJsonView, collapseAllNested, darkStyles, defaultStyles, allExpanded } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { useMantineColorScheme } from '@mantine/core';
import styles from './json-view.module.css';

export const JsonView = ({ json, isExpanded = false }: { json?: any; isExpanded?: boolean }) => {
    const { colorScheme } = useMantineColorScheme();
    if (!json) return null;

    const style =
        colorScheme === 'dark'
            ? {
                  ...darkStyles,
                  container: styles.container
              }
            : {
                  ...defaultStyles,
                  container: styles.container
              };

    return <BaseJsonView data={json} shouldExpandNode={isExpanded ? allExpanded : collapseAllNested} style={style} />;
};
