import clsx from 'clsx';
import { ReactNode, useEffect, useState } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Box, Stack, Text, rem, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { useScoreColor } from 'utils/use-score-color';

const roundPercentage = (value: number) => Math.round(value > 100 ? 100 : value);

const sizeMap = {
    xs: 34,
    sm: 70,
    md: 95,
    lg: 150,
    xl: 220,
    xxl: 280
};

const fontSizeMap = {
    xs: rem(14),
    sm: rem(28),
    md: rem(36),
    lg: rem(42),
    xl: rem(48),
    xxl: rem(60)
};

const ProgressProvider = ({
    valueStart,
    valueEnd,
    children
}: {
    valueStart: number;
    valueEnd: number;
    children: (value: number) => JSX.Element;
}) => {
    const [value, setValue] = useState<number>(valueStart);
    useEffect(() => {
        setValue(valueEnd);
    }, [valueEnd]);

    return children(value);
};

const ScoreCircleGraph = ({
    size = 'sm',
    percentage,
    peerPercentage,
    children,
    bottom,
    className,
    background,
    peerTrailColor,
    inverted = false,
    onClick
}: {
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    percentage: number;
    peerPercentage?: number;
    children?: ReactNode;
    bottom?: ReactNode;
    background?: string;
    peerTrailColor?: string;
    className?: string;
    inverted?: boolean;
    onClick?: () => void;
}) => {
    const getScoreColor = useScoreColor();
    const theme = useMantineTheme();
    const { colorScheme } = useMantineColorScheme();
    const outerSize = sizeMap[size];
    const innerSize = outerSize * 0.8;

    const hasPeerPercentage = typeof peerPercentage === 'number';
    const isNoCompareGraph = !hasPeerPercentage && !['xs', 'sm', 'xl', 'md'].includes(size);

    const strokeWidth = isNoCompareGraph ? 8 : 7;

    const content = children ?? (
        <Text
            style={{
                fontWeight: 500,
                lineHeight: 1,
                fontSize: fontSizeMap[size]
            }}
        >
            {roundPercentage(percentage)}
        </Text>
    );

    const defaultBackgroundColor = colorScheme === 'dark' ? 'rgba(0, 0, 0, .3)' : '#F9F9F9';

    return (
        <Stack align="center" gap="sm" justify="center" className={clsx(className)}>
            <Box onClick={onClick} style={{ width: outerSize, height: outerSize, cursor: !!onClick ? 'pointer' : 'auto' }}>
                <ProgressProvider valueEnd={percentage} valueStart={0}>
                    {(value: number) => (
                        <CircularProgressbarWithChildren
                            background={!hasPeerPercentage}
                            strokeWidth={strokeWidth}
                            value={Math.round(value)}
                            styles={{
                                path: {
                                    transition: 'stroke-dashoffset 800ms ease-in-out 300ms',
                                    stroke: isNoCompareGraph ? theme.colors.blue[4] : getScoreColor(percentage, inverted)
                                },
                                trail: {
                                    strokeWidth: isNoCompareGraph ? 6 : strokeWidth,
                                    stroke: isNoCompareGraph ? theme.colors.yellow[7] : background ?? defaultBackgroundColor
                                },
                                background: {
                                    fill: !hasPeerPercentage && background ? background : 'transparent'
                                }
                            }}
                        >
                            {!hasPeerPercentage ? (
                                content
                            ) : (
                                <Box style={{ width: innerSize, height: innerSize }}>
                                    <CircularProgressbarWithChildren
                                        background={!!background}
                                        strokeWidth={strokeWidth}
                                        value={Math.round(roundPercentage(peerPercentage))}
                                        styles={{
                                            path: {
                                                stroke: theme.colors.dark[0]
                                            },
                                            trail: {
                                                stroke: peerTrailColor ?? defaultBackgroundColor
                                            },
                                            background: {
                                                fill: background ?? 'transparent'
                                            }
                                        }}
                                    >
                                        {content}
                                    </CircularProgressbarWithChildren>
                                </Box>
                            )}
                        </CircularProgressbarWithChildren>
                    )}
                </ProgressProvider>
            </Box>
            {bottom}
        </Stack>
    );
};

export default ScoreCircleGraph;
