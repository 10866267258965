import { TbAlertCircle } from 'react-icons/tb';
import { Alert } from '@mantine/core';

export const TooManyResultsAlert = ({ maxResults }: { maxResults: number }) => {
    return (
        <Alert icon={<TbAlertCircle size={20} />} color="orange" variant="light" mb="md" title="Too many results">
            For performance reasons, only <strong>{maxResults}</strong> results can be shown. Please narrow to amount of results by applying
            additonal filters.
        </Alert>
    );
};
