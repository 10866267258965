import { Dimension } from 'types/shared';
import { PurposeCards } from './purpose-cards';
import { ScoreReportLayout } from './score-report-layout';

export const Purpose = () => {
    return (
        <ScoreReportLayout dimension={Dimension.Purpose}>
            <PurposeCards />
        </ScoreReportLayout>
    );
};
