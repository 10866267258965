import { byCountry, byIso } from 'country-code-lookup';
import { Nullable } from 'types';
import { isValidCountryCode } from 'utils/is-valid-country-code';
import { DataRow, createEmptyRow } from './create-empty-row';
import { mapErrorToFieldKey } from './map-error-to-field-key';

export const MAX_COLUMNS = 15;
export const MATERIAL_COLUMN_PREFIXES = ['materials_', 'materials_primary_', 'materials_secondary_', 'materials_tertiary_'];

const TRUE_VALUE = 'Yes';
const FALSE_VALUE = 'No';
const DEFAULT_EMPTY_CLAIM = {
    claim: null,
    proof_file_key: null
};

type DataRowKey = keyof DataRow;

type Material = {
    category: string;
    type: string;
    source_country: Nullable<string>;
    material_name: string;
    description: Nullable<string>;
    percentage: number;
    reused: boolean | null;
    recycled: boolean | null;
    organic: boolean | null;
};

type EnergySource = {
    energy_type: string;
    usage_type: string;
    energy_use: {
        value: null | number;
        unit: string;
    };
};

export const getCategoryAndTypeFromPrefix = (prefix: string) => {
    if (prefix === 'materials_primary_') {
        return {
            category: 'packaging',
            type: 'primary'
        };
    }
    if (prefix === 'materials_secondary_') {
        return {
            category: 'packaging',
            type: 'secondary'
        };
    }
    if (prefix === 'materials_tertiary_') {
        return {
            category: 'packaging',
            type: 'tertiary'
        };
    }

    return {
        category: 'product',
        type: 'product'
    };
};

export const getProofValue = (proofKey?: string | null) => {
    return proofKey
        ? {
              claim: true,
              proof_file_key: proofKey
          }
        : DEFAULT_EMPTY_CLAIM;
};

export const dataRowToProductSchema = (row: DataRow) => {
    const ecoLabels: {
        category: string;
        title: string;
    }[] = [];
    for (let i = 0; i < MAX_COLUMNS; i++) {
        const title = row[`eco_label_${i}_title` as DataRowKey];
        const type = row[`eco_label_${i}_type` as DataRowKey];
        if (title && type) {
            ecoLabels.push({
                title: title.toString(),
                category: type.toString()
            });
        }
    }

    const transports: {
        origin: {
            country: string;
            city?: Nullable<string>;
        };
        destination: {
            country: string;
            city?: Nullable<string>;
        };
        medium: string;
        mode: string;
    }[] = [];

    for (let i = 0; i < MAX_COLUMNS; i++) {
        const originCity = row[`transports_${i}_origin_city` as DataRowKey];
        const originCountry = row[`transports_${i}_origin_country` as DataRowKey];
        const destinationCity = row[`transports_${i}_destination_city` as DataRowKey];
        const destinationCountry = row[`transports_${i}_destination_country` as DataRowKey];
        const transportMode = row[`transports_${i}_transport_mode` as DataRowKey];
        const transportMedium = row[`transports_${i}_transport_medium` as DataRowKey];

        if (originCountry && destinationCountry && transportMode && transportMedium) {
            transports.push({
                origin: {
                    country: originCountry.toString(),
                    city: originCity ? originCity.toString() : null
                },
                destination: {
                    country: destinationCountry.toString(),
                    city: destinationCity ? destinationCity.toString() : null
                },
                medium: transportMedium.toString(),
                mode: transportMode.toString()
            });
        }
    }

    const materials: {
        category: string;
        type: string;
        material_name: string;
        percentage: number;
        reused: boolean | null;
        recycled: boolean | null;
        organic: boolean | null;
        source_country: string | null;
    }[] = [];

    MATERIAL_COLUMN_PREFIXES.forEach((prefix) => {
        for (let i = 0; i < MAX_COLUMNS; i++) {
            const name = row[`${prefix}${i}_material_name` as DataRowKey];
            const percentage = row[`${prefix}${i}_percentage` as DataRowKey];
            const recycled = row[`${prefix}${i}_recycled` as DataRowKey];
            const reused = row[`${prefix}${i}_reused` as DataRowKey];
            const organic = row[`${prefix}${i}_organic` as DataRowKey];
            const country = row[`${prefix}${i}_source_country` as DataRowKey];

            if (name && percentage) {
                materials.push({
                    ...getCategoryAndTypeFromPrefix(prefix),
                    material_name: name.toString(),
                    percentage: Number.parseFloat(percentage as string),
                    reused: getStrAsBool(reused as string),
                    recycled: getStrAsBool(recycled as string),
                    organic: getStrAsBool(organic as string),
                    source_country: country ? country.toString() : 'GLO'
                });
            }
        }
    });

    const materialCategoryTypes: {
        category: string;
        type: string;
        weight: {
            value: number;
            unit: string;
        };
        best_eol_disposal_method?: string | null;
        eol_treatment_type?: string | null;
    }[] = [
        {
            category: 'product',
            type: 'product',
            weight: {
                value: Number.parseFloat(row.product_weight_value),
                unit: row.product_weight_unit || 'kg'
            },
            best_eol_disposal_method: row.best_eol_disposal_method || null,
            eol_treatment_type: row.eol_treatment_type || null
        }
    ];

    if (row.material_category_type_properties_primary_weight_unit && row.material_category_type_properties_primary_weight_value) {
        materialCategoryTypes.push({
            category: 'packaging',
            type: 'primary',
            weight: {
                value: Number.parseFloat(row.material_category_type_properties_primary_weight_value),
                unit: row.material_category_type_properties_primary_weight_unit || 'kg'
            }
        });
    }

    if (row.material_category_type_properties_secondary_weight_unit && row.material_category_type_properties_secondary_weight_value) {
        materialCategoryTypes.push({
            category: 'packaging',
            type: 'secondary',
            weight: {
                value: Number.parseFloat(row.material_category_type_properties_secondary_weight_value),
                unit: row.material_category_type_properties_secondary_weight_unit || 'kg'
            }
        });
    }

    if (row.material_category_type_properties_tertiary_weight_unit && row.material_category_type_properties_tertiary_weight_value) {
        materialCategoryTypes.push({
            category: 'packaging',
            type: 'tertiary',
            weight: {
                value: Number.parseFloat(row.material_category_type_properties_tertiary_weight_value),
                unit: row.material_category_type_properties_tertiary_weight_unit || 'kg'
            }
        });
    }

    const energySources: EnergySource[] = [];
    for (let i = 0; i < MAX_COLUMNS; i++) {
        const type = row[`energy_sources_${i}_energy_type` as DataRowKey];
        const usageType = row[`energy_sources_${i}_usage_type` as DataRowKey];
        const value = row[`energy_sources_${i}_energy_use_value` as DataRowKey];
        const unit = row[`energy_sources_${i}_energy_use_unit` as DataRowKey];
        if (type && usageType && value !== null) {
            energySources.push({
                energy_type: type.toString(),
                usage_type: usageType.toString(),
                energy_use: {
                    value: Number.parseFloat(value.toString()),
                    unit: unit ? unit.toString() : 'j'
                }
            });
        }
    }

    const data: any = {
        sku: row.sku.toString(),
        supplier: row.supplier || '',
        custom_category: row.custom_category || '',
        google_taxonomy_path: row.google_taxonomy_path || '',
        description: row.description || null,
        name: row.name,
        brand: row.brand ? row.brand : null,
        country_of_production: row.country_of_production,
        tags: Array.isArray(row.tags) && row.tags.length > 0 ? row.tags : [],
        division: row.division || null,
        product_weight: {
            value: Number.parseFloat(row.product_weight_value),
            unit: row.product_weight_unit || 'kg'
        },
        livelihood_and_wellbeing: {
            fair_pay: {
                living_wage: getProofValue(row.living_wage),
                gender_wage_gap: getProofValue(row.gender_wage_gap)
            },
            working_conditions: {
                working_hours: getProofValue(row.working_hours),
                collective_bargaining: getProofValue(row.collective_bargaining)
            },
            workers_protection: {
                youth_labor: getProofValue(row.youth_labor),
                work_safety: getProofValue(row.work_safety)
            },
            child_and_forced_labor: {
                forced_labor: getProofValue(row.forced_labor),
                child_labor: getProofValue(row.child_and_forced_labor)
            }
        },
        eco_labels: ecoLabels,
        materials,
        material_category_type_properties: materialCategoryTypes,
        transports
    };

    const hasEnergyConsumptionInfo = energySources.length > 0;
    const hasWaterConsumptionInfo = row.water_consumption_source && row.water_consumption_unit && row.water_consumption_value;
    if (hasEnergyConsumptionInfo || hasWaterConsumptionInfo) {
        data.production = {};
    }

    if (hasEnergyConsumptionInfo) {
        data.production.energy_consumption = {
            energy_sources: energySources
        };
    }

    if (hasWaterConsumptionInfo) {
        data.production.water_consumption = {
            unit: row.water_consumption_unit!,
            value: Number.parseFloat(row.water_consumption_value!),
            water_source: row.water_consumption_source
        };
    }

    if (
        row.average_product_lifespan ||
        row.product_components_proprietary_parts ||
        row.product_components_moving_parts ||
        row.product_components_small_components ||
        row.product_components_standard_fixings ||
        row.repair_service ||
        row.communication_end_of_life_treatment ||
        row.legal_guarantee ||
        row.extra_guarantee_duration
    ) {
        data.product_sustainability_characteristics = {
            durable: null,
            package_free: null,
            easy_delivery: null,
            low_energy: null,
            shift_behavior: null,
            sustainable_materials: null,
            is_non_consumable: null,
            communication_end_of_life_treatment: row.communication_end_of_life_treatment || null,
            repair_service: getStrAsBool(row.repair_service),
            legal_guarantee: getStrAsBool(row.legal_guarantee),
            extra_guarantee_duration: row.extra_guarantee_duration ?? null,
            leasing_scheme: null,
            spare_parts: null,
            average_product_lifespan: row.average_product_lifespan ?? null,
            product_components: {
                proprietary_parts: getStrAsBool(row.product_components_proprietary_parts),
                moving_parts: getStrAsBool(row.product_components_moving_parts),
                small_components: getStrAsBool(row.product_components_small_components),
                standard_fixings: getStrAsBool(row.product_components_standard_fixings)
            }
        };
    }

    return data;
};

export const getBoolAsStr = (value: string | null | undefined | boolean): string | null => {
    return value !== null ? (value ? TRUE_VALUE : FALSE_VALUE) : null;
};

export const getStrAsBool = (value: string | null): boolean | null => {
    return value ? value === TRUE_VALUE : null;
};

export const isProductMaterial = (material: any) => material.category === 'product' && material.type === 'product';
export const isPrimaryPackagingMaterial = (material: any) => material.category === 'packaging' && material.type === 'primary';
export const isSecondaryPackagingMaterial = (material: any) => material.category === 'packaging' && material.type === 'secondary';
export const isTertiaryPackagingMaterial = (material: any) => material.category === 'packaging' && material.type === 'tertiary';
export const convertArrToStr = (value: Array<string> | string | null): string | null => {
    if (!value) return null;
    return Array.isArray(value) ? value.join(', ') : value;
};
export const getCountryCode = (code?: string, defaultValue: null | string = null) => {
    if (!code) return defaultValue;
    if (isValidCountryCode(code)) return code;
    try {
        const match = code.length <= 3 ? byIso(code) : byCountry(code);
        if (match) {
            return match.iso3;
        }
        return defaultValue;
    } catch {
        return defaultValue;
    }
};

export const productSchemaToDataRow = (row: any, index: number): DataRow => {
    const data = createEmptyRow(index);

    data.validation_errors = [];
    data.holding_area_error = row.error_message ?? null;
    data.invalid_fields = row.error_fields ?? [];

    data.invalid_fields = data.invalid_fields.map(mapErrorToFieldKey);

    data.sku = row.sku;
    data.name = row.name;
    data.supplier = row.supplier ?? '';
    data.custom_category = row.custom_category ?? '';
    data.google_taxonomy_path = row.google_taxonomy_path ?? '';
    data.country_of_production = getCountryCode(row.country_of_production);
    data.product_weight_value = row.product_weight?.value?.toString() ?? '';
    data.product_weight_unit = row.product_weight?.unit ?? null;

    data.description = row.description ?? null;

    const primaryCategoryTypeProperties = row.material_category_type_properties?.find(isPrimaryPackagingMaterial);
    if (primaryCategoryTypeProperties) {
        data.material_category_type_properties_primary_weight_value = primaryCategoryTypeProperties?.weight?.value || 0;
        data.material_category_type_properties_primary_weight_unit = primaryCategoryTypeProperties?.weight?.unit || 'kg';
    }

    const secondaryCategoryTypeProperties = row.material_category_type_properties?.find(isSecondaryPackagingMaterial);
    if (secondaryCategoryTypeProperties) {
        data.material_category_type_properties_secondary_weight_value = secondaryCategoryTypeProperties?.weight?.value || 0;
        data.material_category_type_properties_secondary_weight_unit = secondaryCategoryTypeProperties?.weight?.unit || 'kg';
    }

    const tertiaryCategoryTypeProperties = row.material_category_type_properties?.find(isTertiaryPackagingMaterial);
    if (tertiaryCategoryTypeProperties) {
        data.material_category_type_properties_tertiary_weight_value = tertiaryCategoryTypeProperties.weight.value || 0;
        data.material_category_type_properties_tertiary_weight_unit = tertiaryCategoryTypeProperties.weight.unit || 'kg';
    }

    row.transports?.forEach(
        (
            transport: {
                origin: {
                    city?: string;
                    country: string;
                };
                destination: {
                    city?: string;
                    country: string;
                };
                mode: string;
                medium: string;
            },
            index: number
        ) => {
            const defaultOriginCountry = index === 0 ? 'CHN' : null;
            // @ts-ignore
            data[`transports_${index}_origin_city`] = transport.origin?.city || null;
            // @ts-ignore
            data[`transports_${index}_origin_country`] = getCountryCode(transport.origin?.country, defaultOriginCountry);
            // @ts-ignore
            data[`transports_${index}_destination_city`] = transport.destination?.city || null;
            // @ts-ignore
            data[`transports_${index}_destination_country`] = getCountryCode(transport.destination?.country);
            // @ts-ignore
            data[`transports_${index}_transport_mode`] = transport.mode;
            // @ts-ignore
            data[`transports_${index}_transport_medium`] = transport.medium;
        }
    );

    data.brand = row.brand || null;
    data.division = row.division || null;
    data.tags = row.tags || [];

    const productMaterials = row.materials?.filter(isProductMaterial);
    const primaryPackagingMaterials = row.materials?.filter(isPrimaryPackagingMaterial);
    const secondaryPackagingMaterials = row.materials?.filter(isSecondaryPackagingMaterial);
    const tertiaryPackagingMaterials = row.materials?.filter(isTertiaryPackagingMaterial);

    const materialLists = [
        {
            prefix: 'materials_',
            list: productMaterials
        },
        {
            prefix: 'materials_primary_',
            list: primaryPackagingMaterials
        },
        {
            prefix: 'materials_secondary_',
            list: secondaryPackagingMaterials
        },
        {
            prefix: 'materials_tertiary_',
            list: tertiaryPackagingMaterials
        }
    ];

    materialLists.forEach((current) => {
        current.list?.forEach((material: Material, index: number) => {
            // @ts-ignore
            data[`${current.prefix}${index}_material_name`] = material.material_name;
            // @ts-ignore
            data[`${current.prefix}${index}_percentage`] = material.percentage;
            // @ts-ignore
            data[`${current.prefix}${index}_recycled`] = getBoolAsStr(material.recycled);
            // @ts-ignore
            data[`${current.prefix}${index}_reused`] = getBoolAsStr(material.reused);
            // @ts-ignore
            data[`${current.prefix}${index}_organic`] = getBoolAsStr(material.organic);
            // @ts-ignore
            data[`${current.prefix}${index}_source_country`] = getCountryCode(material.source_country);
        });
    });

    row.eco_labels?.forEach(
        (
            ecoLabel: {
                title: string;
                category: string;
                id: string;
            },
            index: number
        ) => {
            // @ts-ignore
            data[`eco_label_${index}_title`] = ecoLabel.title;
            // @ts-ignore
            data[`eco_label_${index}_type`] = ecoLabel.category;
        }
    );

    // Product sustainability characteristics
    data.product_components_proprietary_parts = getBoolAsStr(
        row.product_sustainability_characteristics?.product_components?.proprietary_parts
    );
    data.product_components_moving_parts = getBoolAsStr(row.product_sustainability_characteristics?.product_components?.moving_parts);
    data.product_components_standard_fixings = getBoolAsStr(
        row.product_sustainability_characteristics?.product_components?.standard_fixings
    );
    data.product_components_small_components = getBoolAsStr(
        row.product_sustainability_characteristics?.product_components?.small_components
    );
    data.repair_service = getBoolAsStr(row.product_sustainability_characteristics?.repair_service);
    data.legal_guarantee = getBoolAsStr(row.product_sustainability_characteristics?.legal_guarantee);
    data.extra_guarantee_duration = row.product_sustainability_characteristics?.extra_guarantee_duration || null;
    data.average_product_lifespan = row.product_sustainability_characteristics?.average_product_lifespan || null;
    data.communication_end_of_life_treatment = row.product_sustainability_characteristics?.communication_end_of_life_treatment || null;

    const matchingProductSustainabilityCharacteristics = row.material_category_type_properties?.find(isProductMaterial);

    data.best_eol_disposal_method = matchingProductSustainabilityCharacteristics?.best_eol_disposal_method || null;
    data.eol_treatment_type = matchingProductSustainabilityCharacteristics?.eol_treatment_type || null;

    // Livelihoods & Wellbeing
    data.child_and_forced_labor = row.livelihood_and_wellbeing?.child_and_forced_labor?.child_labor?.proof_file_key ?? null;
    data.forced_labor = row.livelihood_and_wellbeing?.child_and_forced_labor?.forced_labor?.proof_file_key ?? null;
    data.gender_wage_gap = row.livelihood_and_wellbeing?.fair_pay?.gender_wage_gap?.proof_file_key ?? null;
    data.living_wage = row.livelihood_and_wellbeing?.fair_pay?.living_wage?.proof_file_key ?? null;
    data.youth_labor = row.livelihood_and_wellbeing?.workers_protection?.youth_labor?.proof_file_key ?? null;
    data.work_safety = row.livelihood_and_wellbeing?.workers_protection?.work_safety?.proof_file_key ?? null;
    data.working_hours = row.livelihood_and_wellbeing?.working_conditions?.working_hours?.proof_file_key ?? null;
    data.collective_bargaining = row.livelihood_and_wellbeing?.working_conditions?.collective_bargaining?.proof_file_key ?? null;

    // Production
    data.water_consumption_source = row.production?.water_consumption?.water_source ?? null;
    data.water_consumption_unit = row.production?.water_consumption?.unit ?? null;
    data.water_consumption_value = row.production?.water_consumption?.value ?? null;
    row.production?.energy_consumption?.energy_sources?.forEach((source: EnergySource, index: number) => {
        // @ts-ignore
        data[`energy_sources_${index}_energy_type`] = source.energy_type ?? null;
        // @ts-ignore
        data[`energy_sources_${index}_usage_type`] = source.usage_type ?? null;
        // @ts-ignore
        data[`energy_sources_${index}_energy_use_value`] = source.energy_use?.value ?? null;
        // @ts-ignore
        data[`energy_sources_${index}_energy_use_unit`] = source.energy_use?.unit ?? null;
    });

    return data;
};
