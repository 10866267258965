const modeToMediumMap = {
    airplane: 'air',
    diesel_freight_train: 'rail',
    electric_freight_train: 'rail',
    freight_train: 'rail',
    biodiesel_truck: 'road',
    heavy_truck: 'road',
    light_truck: 'road',
    medium_truck: 'road',
    inland_waterway_freight: 'water',
    oceanic_transport: 'water'
};

export const getTransportMediumFromMode = (mode: string) => modeToMediumMap[mode as keyof typeof modeToMediumMap] ?? null;
