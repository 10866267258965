import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stepper, Button, Group, TextInput, Modal, Text, PinInput, Stack, Box } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showErrorMessage } from 'utils/show-error-message';
import { showSuccessMessage } from 'utils/show-success-message';
import { validation } from 'utils/validation';
import { finishEnrollMultiFactor } from './finish-enroll-multifactor';
import { startEnrollMultiFactor } from './start-enroll-multifactor';

export const EnableMfaModal = ({ isOpen, onClose, step = 0 }: { isOpen: boolean; onClose: () => void; step?: number }) => {
    const [showRetry, setShowRetry] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [verificationId, setVerificationId] = useState('');
    const [active, setActive] = useState(step);
    const { t } = useTranslation();

    const handleClose = () => {
        window.userSettingsRecaptchaVerifier = undefined;
        onClose();
    };

    const form = useForm({
        initialValues: {
            phoneNumber: '',
            code: ''
        },
        validate: (values) => {
            if (active === 0) {
                return {
                    phoneNumber: validation.required(values.phoneNumber)
                };
            }
            if (active === 1) {
                return {
                    code: validation.required(values.code)
                };
            }
            return {};
        }
    });

    const nextStep = async () => {
        if (form.validate().hasErrors) return;
        setIsSubmitting(true);
        if (active === 0) {
            try {
                const verificationId = await startEnrollMultiFactor(form.values.phoneNumber);
                if (verificationId) {
                    setActive((current) => current + 1);
                    setVerificationId(verificationId);
                } else {
                    setShowRetry(true);
                    showErrorMessage(t('failedToSendVerificationCode'));
                }
            } catch {
                setShowRetry(true);
                showErrorMessage(t('failedToSendVerificationCode'));
            }
        }

        if (active === 1) {
            const isSuccess = await finishEnrollMultiFactor(verificationId, form.values.code);
            if (isSuccess) {
                showSuccessMessage(t('enableMultiFactorAuthenticationSuccess'));
                handleClose();
            }
        }

        setIsSubmitting(false);
    };

    const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

    const isFirst = active === 0;
    const isComplete = active === 2;

    return (
        <Modal.Root centered size="md" opened={isOpen} onClose={handleClose}>
            <Modal.Overlay />
            <Box id="recaptcha" pos="relative" style={{ zIndex: 9999 }} />
            <Modal.Content>
                <Modal.Header>
                    <Modal.Title>{t('enableMfa')}</Modal.Title>
                    <Modal.CloseButton />
                </Modal.Header>
                <Modal.Body>
                    <Stepper size="sm" mt="sm" active={active}>
                        <Stepper.Step label={t('phoneNumber')} loading={active === 0 && isSubmitting}>
                            <TextInput
                                label={t('phoneNumber')}
                                description={t('phoneNumberMfaDescription')}
                                type="tel"
                                data-testid="phone-input"
                                placeholder="+27749585337"
                                {...form.getInputProps('phoneNumber')}
                            />
                        </Stepper.Step>
                        <Stepper.Step label={t('verificationCode')} loading={active === 2 && isSubmitting}>
                            <Stack py="xl" align="center">
                                <Text size="sm" ta="center">
                                    {t('verificationCodeDescription')}
                                </Text>
                                <PinInput autoFocus data-testid="code-input" oneTimeCode length={6} {...form.getInputProps('code')} />
                            </Stack>
                        </Stepper.Step>
                    </Stepper>

                    <Group justify={!isFirst && !isComplete ? 'space-between' : 'flex-end'} mt="xl">
                        {!isFirst && !isComplete && (
                            <Button disabled={isSubmitting} variant="default" onClick={prevStep}>
                                {t('back')}
                            </Button>
                        )}
                        {!isComplete && (
                            <Button loading={isSubmitting} onClick={nextStep}>
                                {isFirst && showRetry ? t('retry') : t('next')}
                            </Button>
                        )}
                    </Group>
                </Modal.Body>
            </Modal.Content>
        </Modal.Root>
    );
};
