import { ScoringResult } from './use-scoring-results';

export const scoringResultsToProductHistory = (scoringResults?: { results: ScoringResult[] }) => {
    if (!scoringResults?.results) {
        return [];
    }
    return scoringResults.results.map((current) => ({
        scored_at: current.scored_at,
        total: current.scores.total,
        circularity: current.scores.circularity,
        ecosystem: current.scores.ecosystem,
        climate: current.scores.climate,
        livelihood_and_wellbeing: current.scores.livelihood_and_wellbeing,
        purpose: current.scores.purpose
    }));
};
