import { Rule } from 'types';
import { useTranslation } from 'react-i18next';
import { Button, Group, Modal, Select, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { notifications } from '@mantine/notifications';
import { validation } from 'utils/validation';
import { useRules } from './use-rules';

export const RuleModal = ({
    isOpen,
    onClose,
    onSubmit,
    currentRules
}: {
    currentRules: Rule[];
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (values: Rule) => void;
}) => {
    const { data: rules, isLoading } = useRules();
    const { t } = useTranslation();

    const ruleCodes =
        rules?.map((rule) => ({
            value: rule.code_name,
            label: rule.description || rule.name
        })) ?? [];

    const form = useForm({
        initialValues: {
            code_name: ''
        },
        validate: {
            code_name: validation.required
        }
    });

    const handleSubmit = form.onSubmit((values) => {
        const codeAlreadyExists = !!currentRules.find((current) => current.code_name === values.code_name);

        if (codeAlreadyExists) {
            notifications.show({ message: t('codeAlreadyExists'), color: 'red' });
            return;
        }

        const rule = rules?.find((rule) => rule.code_name === values.code_name);

        if (!rule) {
            notifications.show({ message: t('ruleNotFound'), color: 'red' });
            return;
        }

        onSubmit(rule);
        onClose();
        form.reset();
    });

    return (
        <Modal size="md" opened={isOpen} onClose={onClose} title={t('addRule')} centered>
            <form onSubmit={handleSubmit}>
                <Stack gap="xs">
                    <Select
                        disabled={isLoading}
                        label={t('rule')}
                        withAsterisk
                        comboboxProps={{ position: 'bottom', withinPortal: true }}
                        data={ruleCodes}
                        value={form.values.code_name}
                        onChange={(value) => {
                            if (value) {
                                form.setFieldValue('code_name', value);
                            }
                        }}
                    />
                </Stack>
                <Group mt="lg" justify="flex-end">
                    <Button data-testid={isLoading ? 'loading' : undefined} loading={isLoading} type="submit">
                        {t('add')}
                    </Button>
                </Group>
            </form>
        </Modal>
    );
};
