import { Box, Group, Text, rem } from '@mantine/core';
import src from './legend-gradient.png';
import classes from './score-color-legend.module.css';

const ScoreColorLegend = () => {
    return (
        <Group align="center" gap={rem(8)} className={classes.container}>
            <Text c="dimmed" size="sm">
                0
            </Text>
            <Box component="img" height={14} src={src} width={228} />
            <Text c="dimmed" size="sm">
                100
            </Text>
        </Group>
    );
};

export default ScoreColorLegend;
