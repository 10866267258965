import { paths } from 'paths';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './use-auth';

/**
 * Checks the authentication state of the user and redirects to the home page if authenticated.
 */
export const GuestGuard = ({ children }: { children: ReactNode }) => {
    const { isInitializing, user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isInitializing) return;
        if (user) {
            navigate(paths.root(), { replace: true });
        }
    }, [navigate, isInitializing, user]);

    return <>{children}</>;
};
