import { Footer } from 'shared/footer';
import { LogoIcon } from 'shared/logo-icon';
import { ReactNode } from 'react';
import { Paper, Center, rem } from '@mantine/core';
import classes from './auth-layout.module.css';

export const AuthLayout = ({ children }: { children: ReactNode }) => {
    return (
        <Center className={classes.container}>
            <LogoIcon size="lg" mb="lg" />
            <Paper w="100%" maw={rem(400)} p="lg" mb="md">
                {children}
            </Paper>
            <Footer />
            <div id="recaptcha"></div>
        </Center>
    );
};
