import { useOrganizationId } from 'shared/use-organization-id';
import useSWR from 'swr';
import { QueryReturnResult, ValueWidget as ValueWidgetType } from 'types';
import { useMemo } from 'react';
import { RunQueryConfig, runQuery } from 'utils/run-query';
import { RangeSelect } from './range-select';
import { useWidgetQuery } from './use-widget-query';
import { ValueCard } from './value-card';

export const ValueWidget = ({ config, range }: { config: ValueWidgetType; range: string }) => {
    const organizationId = useOrganizationId();
    const query = useWidgetQuery(config, range);

    const result = useSWR<QueryReturnResult<any>>(config ? [config.query_name, query] : null, async ([_, query]) =>
        runQuery(query as RunQueryConfig)
    );

    const value = useMemo(() => {
        if (!result.data) return undefined;
        if (!config.column_name) return result.data.total_rows;
        return result.data.results[0][config.column_name];
    }, [result, config]);

    return (
        <ValueCard
            title={config.name}
            description={config.description}
            filters={config.selects?.map((filter) => {
                if (filter.type === 'interval') return <RangeSelect key={filter.column_name} />;
                return null;
            })}
            value={value}
            error={result.error}
            to={organizationId ? config.link?.replaceAll('{organizationId}', organizationId) : undefined}
        />
    );
};
