import { useIngestion } from 'data-management/data-ingestion/use-ingestion';
import { links } from 'links';
import { paths } from 'paths';
import { ConfirmationModal } from 'shared/confirmation-modal';
import { ErrorCard } from 'shared/error-card';
import { MoreInfoHoverCard } from 'shared/more-info-hover-card';
import { Section } from 'shared/section';
import { Table } from 'shared/table';
import { useUser } from 'shared/use-user';
import { IngestedFile } from 'types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbAlertTriangle, TbDotsVertical } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { ActionIcon, Anchor, Button, Group, Menu, Text, ThemeIcon, Title, Tooltip, rem } from '@mantine/core';
import { formatBytes } from 'utils/format-bytes';
import { getIdFromKey } from 'utils/get-id-from-key';
import { showErrorMessage } from 'utils/show-error-message';
import { showSuccessMessage } from 'utils/show-success-message';
import { TagList } from '../../shared/tag-list';
import { DataModal } from './data-modal';
import { downloadFile } from './download-file';
import { FileAuditLogModal } from './file-audit-log-modal';
import { fileCategoryTypes } from './file-category-types';
import { isFileCloseToExpiration } from './is-file-close-to-expiration';
import { UploadModal } from './upload-modal';
import { ValidateFile } from './validate-file';

const MAX_CSV_EXPORT_SIZE = 25 * 1024 * 1024; // 25 MB
const DEFAULT_JSONL_VALUE = 'Default_JSONL';

export const DataIngestion = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { hasPermission } = useUser();
    const [file, setFile] = useState<File | undefined>();
    const [correlationId, setCorrelationId] = useState<string | null>();
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showAuditLog, setShowAuditLog] = useState(false);
    const [activeRow, setActiveRow] = useState<IngestedFile | null>(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [activeId, setActiveId] = useState<undefined | string>();

    const { data, error, isLoading, remove, onLimitChange, onOrderChange, page, onPage, search, onSearchChange, limit, order } =
        useIngestion();

    const handleUpload = () => setShowUploadModal(true);

    const handleDeleteConfirm = async () => {
        if (!activeId) return;
        try {
            await remove(activeId);
            showSuccessMessage('Data deleted');
            setShowDeleteModal(false);
            setActiveId(undefined);
        } catch (error) {
            showErrorMessage(error);
        }
    };

    if (error) {
        return <ErrorCard error={error} />;
    }

    return (
        <>
            <Group gap={rem(2)}>
                <Title size="h3">{t('dataIngestion')}</Title>
                <MoreInfoHoverCard>
                    <Text size="sm" c="dimmed">
                        Allows you{' '}
                        <Anchor target="_blank" href={links.customerService.validate}>
                            validate
                        </Anchor>{' '}
                        and{' '}
                        <Anchor target="_blank" href={links.customerService.dataIngestion}>
                            upload
                        </Anchor>{' '}
                        data for processing. Uploaded files are removed after 12 months while ingested data is stored indefinitely.
                    </Text>
                </MoreInfoHoverCard>
            </Group>
            <Text c="dimmed" mb="md">
                {t('dataIngestionDescription')}
            </Text>
            <Section>
                <Table
                    isLoading={isLoading}
                    rowKey={(row) => row.key}
                    actions={
                        <Group gap="xs">
                            {hasPermission('/ingest/file/validate/schema', 'POST') && (
                                <ValidateFile
                                    onStartUpload={(file) => {
                                        setFile(file);
                                        setShowUploadModal(true);
                                    }}
                                />
                            )}
                            {hasPermission('/ingest/file/upload-request', 'POST') && (
                                <Button color="primary" onClick={handleUpload}>
                                    {t('uploadData')}
                                </Button>
                            )}
                        </Group>
                    }
                    data={data?.items}
                    columns={[
                        {
                            key: 'name',
                            name: t('name'),
                            width: 360,
                            render: (value, row) => (
                                <Group gap="xs">
                                    <Tooltip label={value}>
                                        <Text maw={280} truncate>
                                            {value}
                                        </Text>
                                    </Tooltip>
                                    {isFileCloseToExpiration(row) && (
                                        <Tooltip multiline w={260} label={t('fileCloseToExpiration')}>
                                            <ThemeIcon color="orange" variant="light" size="sm">
                                                <TbAlertTriangle size={16} />
                                            </ThemeIcon>
                                        </Tooltip>
                                    )}
                                </Group>
                            )
                        },
                        {
                            key: 'category',
                            name: t('category'),
                            render: (value) => fileCategoryTypes.find((current) => current.value === value)?.label
                        },
                        {
                            key: 'size',
                            name: t('size'),
                            render: (value) => (value ? formatBytes(value) : '-')
                        },
                        {
                            key: 'upload_time',
                            name: t('uploadTime'),
                            render: (value) => new Date(value).toLocaleString()
                        },
                        {
                            key: 'tags',
                            name: t('tags'),
                            width: 280,
                            render: (value) => <TagList data={value} />
                        },
                        {
                            key: 'actions',
                            align: 'right',
                            name: '',
                            render: (_, row) => {
                                const isDefaultJsonl = row.category === DEFAULT_JSONL_VALUE;
                                return (
                                    <Menu shadow="md">
                                        <Menu.Target>
                                            <ActionIcon data-testid={`menu-${row.key}`}>
                                                <TbDotsVertical size={16} />
                                            </ActionIcon>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            <Menu.Item
                                                onClick={() => {
                                                    setActiveRow(row);
                                                }}
                                            >
                                                {t('viewData')}
                                            </Menu.Item>
                                            <Menu.Item
                                                onClick={() => {
                                                    navigate(paths.dataManagement.holdingArea(getIdFromKey(row.organization_id), row.key));
                                                }}
                                            >
                                                {t('viewInHoldingArea')}
                                            </Menu.Item>
                                            {row.correlation_id && (
                                                <Menu.Item
                                                    onClick={() => {
                                                        setShowAuditLog(true);
                                                        setCorrelationId(row.correlation_id);
                                                    }}
                                                >
                                                    {t('auditLog')}
                                                </Menu.Item>
                                            )}
                                            <Menu.Item
                                                onClick={() => {
                                                    downloadFile(row.key);
                                                }}
                                            >
                                                {isDefaultJsonl ? t('downloadJsonlData') : t('downloadData')}
                                            </Menu.Item>
                                            {row.size <= MAX_CSV_EXPORT_SIZE && isDefaultJsonl && (
                                                <Menu.Item
                                                    onClick={() => {
                                                        downloadFile(row.key, 'csv');
                                                    }}
                                                >
                                                    {t('downloadCsvData')}
                                                </Menu.Item>
                                            )}

                                            <Menu.Item
                                                color="red"
                                                onClick={() => {
                                                    setShowDeleteModal(true);
                                                    setActiveId(getIdFromKey(row.key));
                                                }}
                                            >
                                                {t('delete')}
                                            </Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                );
                            }
                        }
                    ]}
                    search={search}
                    onSearchChange={onSearchChange}
                    limit={limit}
                    onLimitChange={onLimitChange}
                    page={page}
                    onPage={onPage}
                    order={order}
                    onOrderChange={onOrderChange}
                />

                <ConfirmationModal
                    variant="delete"
                    title={t('confirmDeleteTitle')}
                    isOpen={showDeleteModal}
                    onClose={() => {
                        setShowDeleteModal(false);
                    }}
                    onConfirm={handleDeleteConfirm}
                >
                    {t('deleteDataConfirmation')}
                </ConfirmationModal>
            </Section>
            <UploadModal
                isOpen={showUploadModal}
                initialFile={file}
                onClose={() => {
                    setFile(undefined);
                    setShowUploadModal(false);
                }}
            />
            <FileAuditLogModal
                isOpen={showAuditLog}
                correlationId={correlationId}
                onClose={() => {
                    setShowAuditLog(false);
                    setCorrelationId(null);
                }}
            />
            <DataModal isOpen={!!activeRow} data={activeRow} onClose={() => setActiveRow(null)} />
        </>
    );
};
