import { Filter, Ordering } from 'types';
import { OrderDirection } from 'types/shared';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { useDeferredValue, useMemo, useState } from 'react';

const DEFAULT_LIMIT = '50';
const QUERY_LIMIT = '200';
const REPORT_LIMIT = '1000';
const MIN_SEARCH_LENGTH = 2;

export type TableFilterVariant = 'default' | 'query' | 'report';

export const useTableFilters = ({
    searchKey,
    variant = 'default',
    initialOrdering = '',
    initialLimit,
    params = [],
    prefix = ''
}: {
    searchKey?: string | Array<string>;
    variant?: TableFilterVariant;
    initialOrdering?: string;
    initialLimit?: string;
    params?: Filter[];
    prefix?: string;
}) => {
    const [search, setSearch] = useQueryParam(`${prefix}search}`, withDefault(StringParam, ''));
    const [limit, setLimit] = useQueryParam(
        `${prefix}limit`,
        withDefault(StringParam, initialLimit ?? variant === 'default' ? DEFAULT_LIMIT : variant === 'report' ? REPORT_LIMIT : QUERY_LIMIT)
    );
    const [order, setOrder] = useQueryParam(`${prefix}order`, withDefault(StringParam, initialOrdering));
    const [orderDirection, setOrderDirection] = useQueryParam(
        `${prefix}orderDirection`,
        withDefault(StringParam, OrderDirection.Descending)
    );

    const searchValue = useDeferredValue(search);
    const [page, setPage] = useState(1);
    const [pageTokeMapping, setPageTokenMapping] = useState<{
        [page: string]: string;
    }>({});

    const query = useMemo(() => {
        const current: {
            [filter: string]: string | number | Filter[] | Ordering[];
        } = {
            limit: Number.parseInt(limit, 10)
        };

        if (pageTokeMapping[page]) {
            current.page_token = pageTokeMapping[page];
        }

        const searchKeys = Array.isArray(searchKey) ? searchKey : typeof searchKey === 'string' && searchKey.length > 0 ? [searchKey] : [];
        const isMinSearchLength = searchValue.length > MIN_SEARCH_LENGTH;
        const hasSearch = searchKeys.length > 0 && isMinSearchLength;

        if (variant === 'default') {
            if (order) {
                current.order = order;
            }

            if (hasSearch) {
                current.filter = `${searchKeys[0]} == "${searchValue}"`;
            }
        }

        if (variant === 'query' || variant === 'report') {
            current.params = [...params];

            if (hasSearch && Array.isArray(current.params)) {
                current.params = [
                    ...params,
                    ...searchKeys.map((name) => ({
                        name,
                        operation: '~',
                        value: searchValue
                    }))
                ];
            }

            if (order) {
                current.orderByParams = [
                    {
                        name: order,
                        desc: orderDirection === OrderDirection.Descending
                    }
                ];
            }
        }

        return current;
    }, [limit, pageTokeMapping, page, order, searchValue, searchKey, variant, params, orderDirection]);

    return {
        query,
        order,
        orderDirection: orderDirection as OrderDirection,
        onPage: setPage,
        limit,
        onLimitChange: (newLimit: string) => {
            setPage(1);
            setPageTokenMapping({});
            setLimit(newLimit);
        },
        onOrderDirectionChange: (orderDirection: string) => {
            setPage(1);
            setPageTokenMapping({});
            setOrderDirection(orderDirection);
        },
        onOrderChange: (newOrder: string) => {
            setPage(1);
            setPageTokenMapping({});
            setOrderDirection(
                order !== newOrder
                    ? OrderDirection.Descending
                    : orderDirection === OrderDirection.Descending
                      ? OrderDirection.Ascending
                      : OrderDirection.Descending
            );
            setOrder(newOrder);
        },
        onSearchChange: setSearch,
        search,
        page,
        pageTokeMapping,
        onPageTokenMappingChange: setPageTokenMapping
    };
};
